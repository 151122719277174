// src/onboard.js

import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import networkConfig from './components/network.json' // Adjust the path if necessary

// Initialize injected wallets (e.g., MetaMask)
const injected = injectedModule()

// Initialize WalletConnect with supported chains
const walletConnect = walletConnectModule({
  // Optionally, specify WalletConnect options here
  // For WalletConnect v2, you might need to provide a projectId
  // Ensure that REACT_APP_REOWN_PROJECT_ID is set in your .env file
  projectId: process.env.REACT_APP_REOWN_PROJECT_ID
})

// Map networkConfig to only include properties required by Onboard
const onboardChains = networkConfig.map(net => ({
  id: net.chainId, // Ensure this is a hexadecimal string, e.g., '0x1'
  token: net.nativeCurrency.symbol,
  label: net.name,
  rpcUrl: net.rpcUrl
}))

// Define app metadata (optional but recommended)
const appMetadata = {
  name: 'zerebro',
  icon: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="100%" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
<path fill="#1B1B1A" opacity="1.000000" stroke="none" 
	d="
M554.000000,1001.000000 
	C369.333344,1001.000000 185.166672,1001.000000 1.000000,1001.000000 
	C1.000000,667.666687 1.000000,334.333344 1.000000,1.000000 
	C334.333344,1.000000 667.666687,1.000000 1001.000000,1.000000 
	C1001.000000,334.333344 1001.000000,667.666687 1001.000000,1001.000000 
	C852.166687,1001.000000 703.333313,1001.000000 554.000000,1001.000000 
M467.053345,212.121628 
	C465.346222,212.141678 463.639069,212.161728 461.068054,212.032043 
	C459.022644,212.082260 456.977234,212.132462 454.087097,212.061752 
	C452.081696,212.061752 450.076263,212.061752 447.670135,212.061752 
	C447.670135,214.441528 447.670135,216.186722 446.946930,218.404526 
	C445.367859,218.612259 443.662933,218.525970 442.253235,219.129349 
	C441.286438,219.543152 440.730133,220.916000 439.211029,221.878815 
	C436.672363,221.995193 433.687653,220.028336 431.902863,223.831482 
	C431.614075,224.446884 427.949097,224.496017 427.702576,223.957047 
	C424.975250,217.994751 419.946228,220.486526 416.204041,220.171127 
	C414.638916,222.344864 413.362030,224.118240 411.183075,226.016357 
	C409.240173,226.016357 407.297241,226.016357 405.122375,226.016357 
	C405.007385,228.635910 404.914551,230.750641 404.346252,233.407394 
	C404.248627,234.595901 404.151001,235.784393 403.375977,236.794052 
	C401.413147,236.099304 399.450317,235.404556 397.058167,234.105789 
	C391.158264,232.524261 390.033142,233.490784 389.255493,240.327377 
	C382.391754,239.987259 382.177521,240.253128 384.335663,247.734589 
	C386.095184,247.913483 387.854706,248.092377 389.738007,248.283859 
	C389.921783,250.801773 390.077545,252.935883 390.148193,255.833450 
	C389.428833,257.888885 388.709503,259.944336 387.251984,261.786102 
	C386.355896,259.429352 385.459778,257.072571 384.563660,254.715820 
	C384.125397,254.737366 383.687164,254.758911 383.248901,254.780457 
	C382.676178,259.485840 382.103424,264.191193 381.547241,268.760620 
	C378.652954,268.760620 376.055664,268.760620 374.162354,268.760620 
	C372.390625,263.951416 370.806549,259.651550 369.091278,254.995560 
	C366.928467,254.995560 364.551300,254.995560 362.099915,254.995560 
	C362.099915,257.313721 362.099915,259.262085 362.099915,261.233063 
	C363.704010,261.640503 365.103607,261.995972 366.618225,262.380707 
	C366.618225,274.123688 366.618225,285.683380 366.618225,296.361877 
	C364.169067,297.884735 362.249054,299.078583 359.749542,300.801544 
	C358.507233,301.058990 357.264923,301.316437 356.014618,300.805176 
	C355.852722,300.163696 355.366608,299.376373 355.579376,298.903412 
	C357.563751,294.491211 357.807495,285.355988 355.361298,279.898102 
	C355.075745,284.201508 354.869537,287.308990 354.665771,290.380066 
	C351.954285,290.646271 349.732849,290.864380 347.567017,291.077026 
	C347.326874,293.698975 347.132416,295.822327 346.108765,298.133972 
	C341.257690,297.575409 340.138306,299.785309 340.193573,304.470917 
	C340.443909,325.704590 340.267273,346.943237 340.244934,368.180115 
	C340.242737,370.275543 340.244629,372.370972 340.244629,374.532776 
	C342.952728,374.783112 344.921173,374.965088 347.009949,376.016479 
	C346.974945,378.657440 346.939941,381.298370 346.180878,383.945587 
	C344.296417,383.945587 342.411957,383.945587 340.656921,383.945587 
	C340.656921,388.622131 340.656921,392.535309 340.656921,396.753021 
	C342.568542,396.753021 344.130463,396.753021 345.805237,396.753021 
	C346.245911,394.330139 346.650665,392.104675 347.757599,389.961700 
	C348.560089,389.102844 350.013611,388.276215 350.058014,387.379761 
	C350.320038,382.088806 350.297943,376.781525 350.291504,371.479858 
	C350.264374,349.104492 350.208984,326.729095 350.371490,304.395172 
	C350.371490,304.395172 350.635956,304.116425 351.647583,304.186890 
	C353.111389,303.655762 354.575195,303.124634 355.436798,303.509949 
	C355.580475,303.985077 355.851166,304.460663 355.849396,304.935242 
	C355.794769,319.515778 355.696503,334.096100 355.642426,348.676636 
	C355.608917,357.705231 355.636200,366.734039 355.636200,375.045044 
	C359.054199,376.183929 361.330688,376.942474 363.393372,377.629791 
	C364.265686,377.322052 365.480591,376.607483 366.685303,376.624298 
	C367.104553,376.630127 367.666962,378.412872 367.858826,379.441040 
	C368.068665,380.565277 367.925232,381.755432 367.098511,383.097198 
	C365.410706,383.255249 363.722931,383.413300 361.216888,383.616272 
	C359.627625,383.727509 358.038391,383.838715 356.273865,383.962219 
	C356.273865,386.241333 356.273865,388.160431 355.904938,390.599762 
	C355.516388,391.759430 354.770233,392.928650 354.800842,394.077148 
	C354.936096,399.154114 355.450562,404.222290 355.543274,409.298828 
	C355.819855,424.440308 355.962708,439.584259 355.517639,455.163025 
	C353.725220,455.423523 351.932831,455.684021 349.905609,455.486176 
	C349.854553,455.322266 349.803467,455.158386 349.940704,454.077240 
	C350.101074,445.757477 350.402924,437.436829 350.353271,429.118317 
	C350.332977,425.722382 349.551239,422.330963 349.157288,418.903412 
	C349.157288,418.903412 349.120392,418.864563 349.052490,417.946289 
	C348.876648,411.462006 348.700806,404.977722 348.524963,398.493439 
	C348.081604,398.512268 347.638245,398.531067 347.194885,398.549896 
	C347.000641,400.958160 346.806396,403.366394 346.653412,405.263184 
	C344.374969,406.366302 341.418640,406.877136 340.995148,408.199280 
	C340.179779,410.745026 340.315399,413.887970 341.063141,416.494537 
	C341.353058,417.505249 344.376923,417.716553 346.142426,418.329285 
	C346.605927,418.490143 347.021210,418.790070 347.417419,419.954224 
	C347.292206,424.645538 347.166992,429.336853 346.188599,434.074921 
	C340.259583,433.963074 339.901489,434.347748 341.165009,439.695923 
	C343.069702,439.802582 345.027191,439.912170 347.079315,440.952362 
	C347.081879,450.347382 347.084442,459.742371 346.280823,469.415466 
	C344.377808,470.002655 341.018311,470.301117 340.834412,471.229034 
	C340.182526,474.517822 340.122925,478.144379 340.928040,481.371185 
	C341.179108,482.377472 344.878326,482.523438 347.103912,483.876221 
	C347.729156,485.887329 348.354370,487.898407 349.831940,490.070679 
	C351.564575,490.062347 353.297211,490.054016 355.006653,489.997284 
	C355.006653,489.997284 354.983887,490.045837 354.929413,490.478302 
	C354.929413,490.478302 355.045654,490.898438 354.970215,491.845551 
	C354.970215,498.222931 354.970215,504.600311 354.970215,511.228363 
	C357.448639,511.228363 359.239685,511.228363 361.276642,511.517639 
	C361.454407,511.699982 361.652222,511.857147 361.950165,512.826355 
	C362.596344,514.883301 363.242493,516.940186 363.773895,519.777283 
	C363.880798,524.355896 363.987701,528.934509 363.407776,533.780334 
	C362.790497,535.827820 362.173248,537.875366 361.544830,539.959778 
	C368.694000,541.485168 368.694000,541.485168 369.635071,534.057495 
	C371.561707,533.777161 373.488312,533.496826 375.562500,534.115234 
	C375.562500,540.921509 375.562500,547.727722 375.562500,554.943359 
	C378.468140,554.943359 380.764832,554.943359 382.985748,555.826782 
	C383.085663,557.869263 383.185547,559.911804 383.455811,562.785706 
	C383.644196,564.686401 383.832581,566.587097 384.023468,568.512695 
	C386.290710,568.702515 388.213623,568.863464 390.093445,569.854797 
	C390.655487,572.153931 388.451202,575.303650 392.088593,577.610168 
	C392.053741,583.266541 392.175140,588.931152 391.879608,594.573853 
	C391.800934,596.075623 390.693604,598.536743 389.629700,598.795654 
	C385.340179,599.839783 384.961487,602.615967 384.899963,606.270813 
	C384.782379,613.251709 383.923248,613.959900 377.086609,613.950562 
	C373.473572,613.945679 369.858246,613.595215 369.658783,618.898621 
	C369.631073,619.635559 367.764008,620.263428 366.806824,621.015991 
	C365.719421,621.870911 364.015991,622.654724 363.770325,623.739014 
	C362.906097,627.553894 360.504669,628.226746 357.147247,628.017212 
	C354.163757,627.830994 351.159851,627.983948 348.165131,627.963074 
	C344.601501,627.938171 340.876007,627.509033 340.708038,632.860474 
	C340.685577,633.574646 339.226562,634.325500 338.333984,634.908020 
	C337.415649,635.507385 335.579193,635.831421 335.511749,636.429993 
	C334.741760,643.261841 329.649261,641.669617 325.348175,642.120972 
	C323.546112,642.310120 320.487610,643.675720 320.386047,644.735168 
	C319.825470,650.581726 316.548920,650.373901 312.286499,649.330139 
	C309.068298,648.541992 306.486115,648.921631 305.639984,653.134705 
	C305.401031,654.324768 302.787628,655.750977 301.200348,655.831665 
	C294.893646,656.152100 288.556305,655.840454 282.243622,656.093750 
	C280.621368,656.158813 279.047638,657.433289 277.451599,658.152161 
	C278.979431,658.784851 280.491058,659.913574 282.037933,659.964050 
	C288.686493,660.181091 295.348663,659.973694 301.998169,660.174744 
	C303.602203,660.223267 305.176941,661.241943 305.864288,661.872253 
	C296.620300,662.052795 287.376282,662.233337 277.386047,662.571289 
	C276.880493,662.533691 276.374908,662.496033 275.570862,662.486084 
	C275.327148,662.782715 275.083435,663.079285 274.942627,664.320618 
	C274.862518,665.749695 274.782410,667.178772 274.671783,669.152527 
	C270.001099,669.152527 265.643372,669.152527 261.217133,669.152527 
	C261.026337,671.806824 260.875610,673.903442 260.712921,676.166687 
	C258.784058,676.496216 257.047546,676.792847 255.013870,677.140259 
	C255.013870,679.141113 255.013870,681.082947 254.731964,683.605225 
	C253.894058,683.930664 253.012314,684.615967 252.225769,684.520142 
	C248.587708,684.076782 247.538513,685.824646 247.913391,689.500305 
	C247.913391,689.500305 247.895599,689.933289 247.115768,690.224670 
	C241.879074,689.855164 239.255814,692.134766 240.093491,697.064392 
	C237.613297,698.019226 233.898758,698.817017 233.912338,699.545288 
	C234.010117,704.792542 230.967880,705.767578 226.562546,705.404175 
	C226.562546,708.197632 226.562546,710.607361 226.379089,713.877197 
	C227.259079,719.282104 223.696838,719.387695 219.530304,719.255432 
	C219.241669,719.406616 219.130630,719.641663 219.039459,720.891174 
	C219.039459,729.466125 219.039459,738.041016 219.039459,746.447937 
	C221.932709,746.856201 223.842056,747.125610 226.309509,747.473755 
	C226.309509,759.373962 226.309509,770.957581 226.309509,783.161011 
	C234.117020,781.396912 233.313080,786.321533 233.193802,790.763062 
	C233.141693,792.703491 233.185028,794.646484 233.185028,797.242554 
	C236.851395,797.242554 239.972000,797.319458 243.086960,797.219727 
	C245.892853,797.129822 247.682495,797.909729 247.167023,801.157349 
	C246.510254,805.295044 248.555954,806.124512 252.309708,806.089111 
	C270.295776,805.919250 288.284149,805.992981 306.271729,805.992188 
	C442.177704,805.986633 578.083679,805.983887 713.989624,805.980225 
	C717.567261,805.980103 721.144958,805.980164 724.638611,805.980164 
	C724.928833,802.635498 725.138000,800.224609 725.402527,797.176270 
	C734.741577,797.176270 743.666809,797.176270 752.441467,797.176270 
	C753.678894,790.752991 753.678894,790.752991 760.599915,789.360474 
	C760.857544,787.362305 761.122131,785.310059 761.375854,783.342163 
	C763.660583,783.113342 765.448425,782.934326 767.500183,782.728821 
	C767.500183,773.484924 767.500183,764.432251 767.500183,755.310547 
	C765.241211,755.056458 763.315063,754.839844 760.649170,754.539978 
	C760.649170,747.598389 760.649170,740.882874 760.649170,734.327148 
	C753.464844,733.191101 753.466736,733.190979 753.103088,726.398682 
	C753.076477,725.900940 752.992920,725.406311 753.083557,724.128174 
	C753.126099,722.527832 753.168640,720.927490 753.660522,718.637024 
	C753.660522,716.556824 753.660522,714.476685 753.660522,712.231201 
	C750.859802,712.052795 748.431824,711.898193 746.016357,710.898010 
	C746.016357,709.126892 746.016357,707.355835 746.016357,705.149841 
	C740.267273,706.116638 738.095825,703.707581 738.873169,698.587891 
	C736.496582,698.218323 734.445435,697.899414 732.249756,697.557983 
	C732.117310,695.259644 732.003662,693.287842 731.946167,690.613342 
	C731.717834,688.429077 731.489441,686.244751 731.328369,684.704041 
	C728.736084,684.007507 726.837463,683.497375 725.025940,682.111816 
	C725.025940,680.218628 725.025940,678.325500 725.025940,676.377930 
	C722.148499,676.155823 720.034790,675.992676 717.948364,675.106079 
	C717.948364,673.341858 717.948364,671.577698 717.948364,669.192139 
	C715.453796,669.413879 713.390625,669.597290 711.135803,668.827759 
	C711.772034,663.828552 710.265747,661.025452 704.587646,662.283752 
	C703.856140,662.445862 702.999023,662.040649 702.153992,661.052307 
	C702.530762,657.358826 701.019165,655.617493 697.161438,655.863098 
	C694.579834,656.027527 691.973877,655.808411 688.678284,655.218262 
	C679.581726,655.218262 670.485229,655.218262 661.359375,655.218262 
	C661.075745,652.332275 660.886292,650.405029 660.643311,647.933350 
	C655.955933,647.933350 651.536804,647.933350 647.071106,647.933350 
	C646.769226,645.290771 646.532349,643.217224 646.245667,640.707886 
	C641.743652,640.707886 637.631714,640.707886 633.627258,640.707886 
	C632.143982,633.144287 632.143982,633.141907 624.631104,633.105530 
	C622.533936,633.095398 620.436707,633.104004 618.265747,633.104004 
	C618.051636,630.417175 617.897034,628.477173 617.690125,625.881104 
	C613.087280,625.881104 608.703125,625.881104 604.316345,625.881104 
	C604.080261,623.421570 603.908997,621.637329 603.718811,619.655518 
	C601.503174,619.655518 599.562500,619.655518 597.408020,619.655518 
	C597.113892,617.386108 596.845398,615.314758 596.506897,612.703735 
	C590.329041,614.305359 587.873596,611.725037 589.266479,605.699036 
	C586.593567,605.288513 584.520264,604.970093 582.425720,604.648438 
	C582.142883,602.529785 581.888733,600.625427 581.617737,598.595459 
	C579.394043,598.282715 577.473083,598.012573 574.951477,597.657898 
	C574.951477,592.954529 574.951477,588.390320 574.951477,583.526733 
	C572.384888,583.526733 570.456665,583.526733 568.345276,583.526733 
	C568.060364,581.010376 567.808533,578.786926 567.551758,576.519531 
	C565.195312,576.315063 563.289001,576.149597 560.881470,575.940674 
	C560.881470,568.840088 560.881470,562.033447 560.910522,555.155884 
	C560.910522,555.155884 560.909729,555.078491 561.809265,554.773376 
	C566.072998,554.773376 570.336670,554.773376 575.031311,554.773376 
	C574.893799,552.051392 574.795471,550.105164 574.729797,547.187622 
	C574.850891,544.909485 574.971924,542.631287 575.906982,540.691162 
	C577.813354,540.691162 579.719666,540.691162 581.894104,540.691162 
	C581.894104,538.078979 581.894104,535.818848 582.419800,533.346069 
	C582.585938,533.255798 582.752014,533.165588 583.814880,533.222839 
	C585.715149,533.222839 587.615417,533.222839 590.157776,533.222839 
	C590.157776,527.648621 590.157776,522.865967 591.029907,518.087585 
	C592.822083,518.087585 594.614258,518.087585 596.432861,518.087585 
	C596.432861,510.947479 596.432861,504.273041 596.432861,497.254272 
	C593.987366,497.254272 592.017273,497.254272 589.973328,496.287628 
	C589.800049,489.524933 589.626770,482.762238 589.536560,475.082672 
	C589.471252,470.453766 589.405945,465.824890 589.789917,460.337128 
	C589.857605,456.148865 589.925293,451.960632 590.798523,447.556061 
	C597.197021,447.460815 597.197021,447.460815 596.903381,439.309326 
	C596.590637,437.125214 596.277893,434.941132 596.252441,431.906250 
	C596.153259,427.649048 596.054077,423.391846 596.077515,418.186829 
	C596.070984,411.498077 596.064453,404.809357 596.549866,397.468567 
	C596.633911,395.015778 596.718018,392.563019 596.924744,389.168579 
	C596.979126,384.665070 597.033508,380.161591 597.877991,375.439178 
	C599.753235,375.232178 601.628540,375.025208 603.221313,374.849365 
	C603.221313,367.515778 603.221313,360.796387 603.325928,353.123291 
	C603.325928,346.018311 603.325928,338.913300 603.325928,331.894684 
	C603.725159,332.120544 604.408569,332.507202 605.957031,332.967743 
	C607.535767,332.866211 609.114502,332.764679 610.607910,332.668610 
	C610.607910,325.633728 610.607910,319.049530 610.607910,312.122131 
	C608.159302,312.122131 606.022034,312.122131 603.904480,311.169769 
	C603.904480,304.496216 603.904480,297.822632 603.904480,290.899475 
	C601.322449,290.899475 599.217651,290.899475 597.045105,289.980560 
	C597.128052,285.278381 597.210938,280.576233 598.083801,275.837463 
	C601.172729,275.722565 605.128296,276.083740 603.324402,271.205048 
	C602.902405,270.063751 599.256409,270.114563 597.011108,268.657166 
	C597.011108,266.542633 597.011108,264.428070 597.011108,262.214905 
	C594.209412,262.214905 592.074463,262.214905 589.556335,261.884186 
	C589.379272,261.600433 589.202271,261.316681 589.119690,260.172089 
	C589.679138,256.275665 587.958679,254.771927 584.110046,255.125473 
	C581.167908,255.395782 578.197266,255.354935 575.060791,254.765930 
	C574.994690,254.538895 574.928650,254.311844 575.020264,253.208054 
	C574.955566,251.434174 574.890808,249.660294 575.031982,247.500641 
	C575.031982,247.500641 575.315979,247.168152 576.122925,247.147049 
	C578.212646,246.852280 581.408630,247.607590 580.747314,244.075394 
	C580.521057,242.867020 577.844299,242.103699 576.263977,241.176529 
	C576.008057,241.026413 575.630859,241.082947 575.141235,240.554581 
	C575.077942,240.394104 575.014587,240.233643 575.018921,239.183731 
	C574.913269,237.581055 574.807678,235.978394 574.676697,233.990067 
	C567.633240,233.990067 560.893616,233.990067 553.251221,233.876053 
	C551.459412,233.764908 549.667542,233.653763 547.651733,233.129425 
	C547.414612,232.915680 547.146301,232.753510 546.869385,231.874237 
	C546.830811,230.849060 546.792175,229.823883 546.918762,227.948029 
	C546.918762,225.058212 546.918762,222.168396 546.918762,219.214279 
	C543.991089,219.062363 541.723511,218.944687 539.730774,218.841278 
	C539.607422,216.567093 539.494995,214.494827 539.376404,212.308289 
	C536.920044,212.073456 534.846680,211.875244 532.844727,210.852112 
	C532.844727,208.964050 532.844727,207.075974 532.844727,205.138733 
	C529.768677,205.138733 527.337952,205.138733 524.060791,205.002014 
	C522.081360,205.141983 520.101868,205.281952 517.431519,205.708832 
	C515.442993,205.702637 513.454407,205.696426 510.813660,205.192749 
	C508.252625,205.512833 505.691620,205.832916 502.330963,205.805176 
	C493.674469,205.805176 485.017975,205.805176 476.094269,205.805176 
	C476.094269,207.703873 476.094269,209.832779 475.895447,212.709991 
	C475.991486,214.705490 476.087524,216.701004 475.445862,218.673462 
	C473.332916,217.774323 471.220001,216.875198 468.987366,215.288666 
	C468.620728,214.282700 468.254089,213.276733 467.053345,212.121628 
z"/>
<path fill="#9F4A8F" opacity="1.000000" stroke="none" 
	d="
M752.935791,724.910217 
	C752.992920,725.406311 753.076477,725.900940 753.103088,726.398682 
	C753.466736,733.190979 753.464844,733.191101 760.649170,734.327148 
	C760.649170,740.882874 760.649170,747.598389 760.649170,754.539978 
	C763.315063,754.839844 765.241211,755.056458 767.500183,755.310547 
	C767.500183,764.432251 767.500183,773.484924 767.500183,782.728821 
	C765.448425,782.934326 763.660583,783.113342 761.375854,783.342163 
	C761.122131,785.310059 760.857544,787.362305 760.599915,789.360474 
	C753.678894,790.752991 753.678894,790.752991 752.441467,797.176270 
	C743.666809,797.176270 734.741577,797.176270 725.402527,797.176270 
	C725.138000,800.224609 724.928833,802.635498 724.638611,805.980164 
	C721.144958,805.980164 717.567261,805.980103 713.989624,805.980225 
	C578.083679,805.983887 442.177704,805.986633 306.271729,805.992188 
	C288.284149,805.992981 270.295776,805.919250 252.309708,806.089111 
	C248.555954,806.124512 246.510254,805.295044 247.167023,801.157349 
	C247.682495,797.909729 245.892853,797.129822 243.086960,797.219727 
	C239.972000,797.319458 236.851395,797.242554 233.185028,797.242554 
	C233.185028,794.646484 233.141693,792.703491 233.193802,790.763062 
	C233.313080,786.321533 234.117020,781.396912 226.309509,783.161011 
	C226.309509,770.957581 226.309509,759.373962 226.309509,747.473755 
	C223.842056,747.125610 221.932709,746.856201 219.039459,746.447937 
	C219.039459,738.041016 219.039459,729.466125 219.253754,720.322388 
	C219.643036,719.607422 219.802612,719.445984 219.946747,719.269287 
	C223.696838,719.387695 227.259079,719.282104 226.837006,713.448120 
	C231.286331,712.300171 231.917862,715.368408 232.995697,718.226562 
	C233.066772,718.439392 233.153168,718.879761 233.115479,719.300903 
	C232.481567,726.212097 237.278229,724.883850 240.842941,724.885132 
	C326.442139,724.915833 412.041351,724.891724 498.012939,724.819946 
	C499.582092,723.135254 500.778839,721.508179 502.060181,719.785095 
	C502.270203,719.577148 502.376923,719.449524 502.818329,719.048950 
	C504.985229,712.906372 504.390656,711.992676 498.614685,711.943176 
	C487.409882,711.847107 476.204895,711.775208 465.386230,711.557739 
	C476.443481,711.422058 487.114441,711.422058 497.785400,711.422058 
	C498.233765,710.484131 498.682098,709.546204 499.130463,708.608276 
	C496.953949,707.758423 494.777435,706.908569 491.999756,705.823975 
	C494.897003,703.798706 496.189636,703.229736 496.994934,702.257202 
	C498.349609,700.621094 500.739166,698.168152 500.279388,697.065125 
	C499.422089,695.008484 496.925598,693.545776 494.926575,692.089294 
	C493.802429,691.270203 492.309082,690.957764 491.380493,690.343628 
	C493.376007,690.235474 494.973083,690.200867 496.915070,690.117676 
	C497.491669,690.055115 497.723419,690.041077 498.296906,690.177979 
	C500.577362,690.062927 502.516083,689.796814 504.457550,689.547119 
	C504.460297,689.563599 504.415649,689.542786 504.528442,689.093628 
	C504.781494,687.109924 504.921722,685.575439 505.371826,683.853882 
	C507.481262,683.764099 509.280823,683.861389 511.044312,684.390991 
	C510.599884,688.380371 511.517059,691.278503 515.674988,689.775879 
	C516.955261,689.313171 517.169800,685.901550 518.171204,683.596069 
	C520.241577,683.106689 522.013184,682.861572 524.147949,682.730713 
	C525.003418,682.968384 525.495667,683.091614 525.963501,683.634888 
	C525.935120,690.273193 526.110535,690.417725 533.335083,689.756897 
	C597.638794,689.785278 661.465210,689.795593 725.291443,689.897644 
	C727.491699,689.901184 729.690552,690.822510 731.890015,691.316040 
	C732.003662,693.287842 732.117310,695.259644 732.249756,697.557983 
	C734.445435,697.899414 736.496582,698.218323 738.873169,698.587891 
	C738.095825,703.707581 740.267273,706.116638 746.016357,705.149841 
	C746.016357,707.355835 746.016357,709.126892 745.549683,711.369629 
	C742.918945,711.876587 740.754822,711.943420 738.590698,711.942810 
	C676.976318,711.925964 615.362000,711.899475 553.747620,711.883728 
	C546.644958,711.881897 546.325500,712.296387 547.889404,719.374939 
	C549.304138,719.382324 550.495300,719.311523 552.061890,719.208496 
	C552.938538,719.201782 553.439697,719.227173 553.972412,719.634033 
	C554.190002,725.740051 558.520752,724.853516 562.030334,724.856323 
	C625.665466,724.907532 689.300659,724.902954 752.935791,724.910217 
z"/>
<path fill="#2F7EBD" opacity="1.000000" stroke="none" 
	d="
M525.987976,683.214844 
	C525.495667,683.091614 525.003418,682.968384 523.846558,682.600891 
	C519.118652,680.369141 515.055359,678.381531 511.075592,676.186401 
	C511.159149,675.978943 511.254211,675.542480 511.425842,675.431274 
	C511.597443,675.320007 511.940216,675.096924 512.209167,674.953308 
	C517.139771,671.420349 517.009155,669.616394 511.796539,666.521973 
	C511.786133,662.402954 511.887665,658.705139 512.182373,654.648865 
	C512.696106,647.567200 513.888000,641.098083 517.775513,635.385742 
	C518.021667,635.024048 517.927124,634.430603 518.082642,633.892761 
	C518.174194,633.840576 518.336243,633.975220 518.632935,633.677246 
	C518.807678,630.182922 518.685730,626.986633 518.566406,623.484802 
	C518.650818,622.781921 518.732605,622.384583 519.185303,621.920471 
	C521.033447,618.499573 522.510559,615.145447 524.248657,611.524963 
	C525.030884,606.864868 525.552246,602.471130 526.099243,598.116455 
	C526.124878,598.155518 526.115234,598.066833 526.644653,598.024231 
	C528.919495,597.726379 530.664917,597.471130 532.410645,597.243286 
	C532.410889,597.270630 532.377747,597.298645 532.564636,596.862061 
	C532.875244,593.615723 532.998962,590.805908 533.431885,587.889038 
	C535.487854,586.202332 537.234680,584.622864 539.178589,582.706116 
	C540.077820,579.104980 539.801147,575.043518 545.170349,575.716614 
	C545.638000,575.775208 546.763062,574.352478 546.780273,573.599365 
	C546.862305,570.030029 548.562012,568.568787 552.060486,568.517395 
	C552.725281,568.507751 553.837830,567.060852 553.945007,566.179993 
	C554.657349,560.324585 549.990417,562.427856 547.117615,561.256714 
	C547.010437,556.481140 547.241638,552.194275 546.857605,547.963257 
	C546.430542,543.258179 547.614624,540.328064 553.170166,540.728760 
	C553.811035,545.571228 554.181152,550.122253 554.571472,554.922241 
	C556.934082,555.036316 558.907776,555.131592 560.881470,555.226868 
	C560.881470,562.033447 560.881470,568.840088 560.881470,575.940674 
	C563.289001,576.149597 565.195312,576.315063 567.551758,576.519531 
	C567.808533,578.786926 568.060364,581.010376 568.345276,583.526733 
	C570.456665,583.526733 572.384888,583.526733 574.951477,583.526733 
	C574.951477,588.390320 574.951477,592.954529 574.951477,597.657898 
	C577.473083,598.012573 579.394043,598.282715 581.617737,598.595459 
	C581.888733,600.625427 582.142883,602.529785 582.425720,604.648438 
	C584.520264,604.970093 586.593567,605.288513 589.266479,605.699036 
	C587.873596,611.725037 590.329041,614.305359 596.506897,612.703735 
	C596.845398,615.314758 597.113892,617.386108 597.408020,619.655518 
	C599.562500,619.655518 601.503174,619.655518 603.718811,619.655518 
	C603.908997,621.637329 604.080261,623.421570 604.316345,625.881104 
	C608.703125,625.881104 613.087280,625.881104 617.690125,625.881104 
	C617.897034,628.477173 618.051636,630.417175 618.265747,633.104004 
	C620.436707,633.104004 622.533936,633.095398 624.631104,633.105530 
	C632.143982,633.141907 632.143982,633.144287 633.627258,640.707886 
	C637.631714,640.707886 641.743652,640.707886 646.245667,640.707886 
	C646.532349,643.217224 646.769226,645.290771 647.071106,647.933350 
	C651.536804,647.933350 655.955933,647.933350 660.643311,647.933350 
	C660.886292,650.405029 661.075745,652.332275 661.359375,655.218262 
	C670.485229,655.218262 679.581726,655.218262 688.958740,655.931763 
	C689.206604,658.428101 689.174133,660.210876 688.667908,662.060730 
	C674.378540,662.185608 660.563049,662.276855 646.747375,662.293823 
	C622.442871,662.323730 598.138245,662.339478 573.833923,662.273560 
	C570.653687,662.264954 568.278198,662.564819 568.050171,666.583496 
	C568.002075,667.431885 566.138672,668.673523 564.973694,668.856750 
	C562.700256,669.214355 560.225281,668.538879 558.035095,669.090454 
	C556.546936,669.465210 554.356934,671.145569 554.294312,672.337463 
	C554.088013,676.259399 551.820374,676.256042 548.927734,676.448853 
	C545.905396,676.650330 542.925659,677.490723 539.740845,678.299561 
	C538.702759,679.970154 537.851135,681.392944 536.626038,682.917969 
	C535.228699,683.148254 534.204834,683.276306 532.875732,683.277161 
	C532.370911,683.056213 532.171326,682.962463 531.713257,682.663818 
	C529.648560,682.594360 527.842407,682.729736 526.055054,682.956909 
	C526.073792,683.048584 525.987976,683.214844 525.987976,683.214844 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M306.765167,661.814453 
	C305.176941,661.241943 303.602203,660.223267 301.998169,660.174744 
	C295.348663,659.973694 288.686493,660.181091 282.037933,659.964050 
	C280.491058,659.913574 278.979431,658.784851 277.451599,658.152161 
	C279.047638,657.433289 280.621368,656.158813 282.243622,656.093750 
	C288.556305,655.840454 294.893646,656.152100 301.200348,655.831665 
	C302.787628,655.750977 305.401031,654.324768 305.639984,653.134705 
	C306.486115,648.921631 309.068298,648.541992 312.286499,649.330139 
	C316.548920,650.373901 319.825470,650.581726 320.386047,644.735168 
	C320.487610,643.675720 323.546112,642.310120 325.348175,642.120972 
	C329.649261,641.669617 334.741760,643.261841 335.511749,636.429993 
	C335.579193,635.831421 337.415649,635.507385 338.333984,634.908020 
	C339.226562,634.325500 340.685577,633.574646 340.708038,632.860474 
	C340.876007,627.509033 344.601501,627.938171 348.165131,627.963074 
	C351.159851,627.983948 354.163757,627.830994 357.147247,628.017212 
	C360.504669,628.226746 362.906097,627.553894 363.770325,623.739014 
	C364.015991,622.654724 365.719421,621.870911 366.806824,621.015991 
	C367.764008,620.263428 369.631073,619.635559 369.658783,618.898621 
	C369.858246,613.595215 373.473572,613.945679 377.086609,613.950562 
	C383.923248,613.959900 384.782379,613.251709 384.899963,606.270813 
	C384.961487,602.615967 385.340179,599.839783 389.629700,598.795654 
	C390.693604,598.536743 391.800934,596.075623 391.879608,594.573853 
	C392.175140,588.931152 392.053741,583.266541 392.513489,577.026489 
	C394.194824,576.273132 395.451233,576.103455 396.805115,575.920532 
	C396.777496,573.018921 398.584869,569.659851 394.098663,568.584473 
	C395.828308,563.864746 397.382568,559.499329 399.357361,555.043823 
	C401.206879,554.952759 402.635895,554.951660 404.099426,555.415405 
	C404.147644,565.585449 404.225891,575.291321 404.138947,584.995789 
	C404.109711,588.260437 404.122864,591.035278 408.662323,590.649963 
	C409.454254,590.582764 411.126984,591.876892 411.091614,592.463867 
	C410.705383,598.877136 414.998535,598.041870 418.929871,598.167114 
	C418.882416,600.616699 418.882416,602.673523 418.882416,604.757812 
	C421.393890,604.757812 423.151581,604.757812 425.156677,604.757812 
	C425.156677,602.451172 425.156677,600.420715 425.156677,598.212341 
	C422.800262,598.085449 420.837402,597.979797 418.787415,597.422974 
	C418.251526,591.164307 421.568207,583.663269 412.316132,581.822693 
	C413.114899,579.981140 413.872314,578.304993 413.932281,576.604187 
	C414.102203,571.786560 413.986664,566.958923 414.406921,562.079468 
	C420.424164,561.296204 417.398956,566.557190 419.828461,568.954834 
	C421.528992,568.954834 423.790955,568.954834 425.957306,569.418396 
	C425.861664,576.849915 425.861664,583.817871 425.861664,590.949219 
	C428.845825,590.949219 430.953491,590.949219 433.026154,591.416382 
	C432.991150,596.113892 432.991150,600.344238 432.991150,604.635803 
	C435.172455,604.871399 436.635193,605.029358 438.149780,605.556274 
	C441.441589,609.195251 445.047882,608.840332 447.401886,605.027100 
	C449.568634,604.974731 451.303192,604.957520 453.247833,605.275269 
	C453.953857,607.574768 454.449738,609.539429 455.020813,611.801819 
	C456.182068,612.025085 457.571259,612.292236 458.973145,613.055359 
	C458.988434,619.184570 458.807220,624.827698 459.110840,630.444580 
	C459.196838,632.035583 460.698273,633.550049 461.556305,635.479370 
	C461.558807,636.871704 461.555542,637.884338 461.204376,639.004272 
	C458.041199,642.700562 458.198761,648.956055 461.194794,652.473633 
	C461.198151,653.085266 461.193146,653.434814 460.847046,653.954712 
	C459.768982,656.657227 459.032013,659.189270 457.835968,661.847778 
	C455.491852,661.904907 453.606873,661.835632 451.588623,661.435547 
	C450.228699,660.750671 449.002808,660.090942 447.775146,660.087646 
	C426.013214,660.028687 404.250916,660.017395 382.489319,660.127686 
	C381.076447,660.134827 379.668976,661.209595 377.851440,661.870605 
	C369.076080,659.476501 360.789062,658.587646 352.342316,662.003235 
	C350.401245,662.788086 347.772705,662.073669 345.495239,661.780090 
	C341.791901,661.302673 338.113403,660.119995 334.431580,660.148804 
	C331.420563,660.172363 328.439270,662.073608 325.410767,662.180847 
	C319.207642,662.400513 312.981720,661.974609 306.765167,661.814453 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M232.924637,718.013672 
	C231.917862,715.368408 231.286331,712.300171 226.928741,713.018127 
	C226.562546,710.607361 226.562546,708.197632 226.562546,705.404175 
	C230.967880,705.767578 234.010117,704.792542 233.912338,699.545288 
	C233.898758,698.817017 237.613297,698.019226 240.093491,697.064392 
	C239.255814,692.134766 241.879074,689.855164 247.716736,690.041016 
	C248.317719,689.857300 248.706238,689.675964 249.189377,689.739868 
	C307.428772,689.789429 365.185059,689.793030 422.941315,689.746765 
	C435.574280,689.736633 448.207092,689.548828 461.307343,689.565125 
	C466.501495,689.796143 471.228333,689.904968 476.388123,690.041260 
	C482.631348,691.195435 483.643524,687.575256 484.180420,683.114136 
	C486.316559,683.295349 488.025299,683.424500 489.775146,684.004333 
	C490.204865,686.442444 490.593445,688.429749 490.982056,690.417114 
	C492.309082,690.957764 493.802429,691.270203 494.926575,692.089294 
	C496.925598,693.545776 499.422089,695.008484 500.279388,697.065125 
	C500.739166,698.168152 498.349609,700.621094 496.994934,702.257202 
	C496.189636,703.229736 494.897003,703.798706 491.999756,705.823975 
	C494.777435,706.908569 496.953949,707.758423 499.130463,708.608276 
	C498.682098,709.546204 498.233765,710.484131 497.785400,711.422058 
	C487.114441,711.422058 476.443481,711.422058 464.994507,711.614746 
	C463.427917,711.774414 462.639404,711.741455 461.481323,711.568726 
	C459.292969,711.286133 457.474213,711.018677 455.655365,711.018127 
	C385.183990,710.996094 314.712616,710.994324 244.241272,711.019287 
	C242.259201,711.019958 240.277237,711.341187 237.866852,711.578857 
	C232.356415,711.232300 233.343506,715.121216 232.924637,718.013672 
z"/>
<path fill="#9CB84F" opacity="1.000000" stroke="none" 
	d="
M255.013870,683.024780 
	C255.013870,681.082947 255.013870,679.141113 255.013870,677.140259 
	C257.047546,676.792847 258.784058,676.496216 260.712921,676.166687 
	C260.875610,673.903442 261.026337,671.806824 261.217133,669.152527 
	C265.643372,669.152527 270.001099,669.152527 274.671783,669.152527 
	C274.782410,667.178772 274.862518,665.749695 275.057983,663.689392 
	C275.389893,662.840332 275.621918,662.640442 275.869354,662.458435 
	C276.374908,662.496033 276.880493,662.533691 278.207764,662.598267 
	C302.945984,662.463379 326.862396,662.186584 350.778992,662.171204 
	C375.386414,662.155396 399.993866,662.432434 424.601562,662.505859 
	C436.740417,662.542053 448.879822,662.408264 461.055847,662.844849 
	C460.998352,669.013733 460.907501,674.602905 466.896271,678.000244 
	C467.773132,678.497620 467.727661,680.621094 468.131134,682.028809 
	C468.153290,682.065552 468.080475,682.110962 467.954712,682.262695 
	C467.648956,682.603699 467.460510,682.784119 466.825378,683.036926 
	C464.609314,683.235535 462.831360,683.352966 460.587769,683.392029 
	C444.652740,683.272705 429.183350,683.212830 413.713928,683.194092 
	C360.813904,683.129822 307.913879,683.080017 255.013870,683.024780 
z"/>
<path fill="#9CB84F" opacity="1.000000" stroke="none" 
	d="
M539.927246,678.051758 
	C542.925659,677.490723 545.905396,676.650330 548.927734,676.448853 
	C551.820374,676.256042 554.088013,676.259399 554.294312,672.337463 
	C554.356934,671.145569 556.546936,669.465210 558.035095,669.090454 
	C560.225281,668.538879 562.700256,669.214355 564.973694,668.856750 
	C566.138672,668.673523 568.002075,667.431885 568.050171,666.583496 
	C568.278198,662.564819 570.653687,662.264954 573.833923,662.273560 
	C598.138245,662.339478 622.442871,662.323730 646.747375,662.293823 
	C660.563049,662.276855 674.378540,662.185608 689.116028,662.119995 
	C694.091980,662.040894 698.145996,661.969604 702.200073,661.898315 
	C702.999023,662.040649 703.856140,662.445862 704.587646,662.283752 
	C710.265747,661.025452 711.772034,663.828552 711.180481,669.664978 
	C711.313965,676.390442 711.472473,676.516541 717.921082,675.829529 
	C720.034790,675.992676 722.148499,676.155823 725.025940,676.377930 
	C725.025940,678.325500 725.025940,680.218628 724.522400,682.621582 
	C723.520447,683.162964 723.021973,683.222229 722.523560,683.222290 
	C663.072510,683.232483 603.621460,683.241516 544.170410,683.225891 
	C542.682800,683.225464 541.195374,682.949158 539.745972,682.408691 
	C539.831787,680.694397 539.879517,679.373047 539.927246,678.051758 
z"/>
<path fill="#627735" opacity="1.000000" stroke="none" 
	d="
M254.872925,683.314941 
	C307.913879,683.080017 360.813904,683.129822 413.713928,683.194092 
	C429.183350,683.212830 444.652740,683.272705 460.594910,683.768677 
	C460.991791,685.963440 460.915894,687.703186 460.839996,689.442932 
	C448.207092,689.548828 435.574280,689.736633 422.941315,689.746765 
	C365.185059,689.793030 307.428772,689.789429 249.090637,689.626099 
	C248.350800,689.305725 248.179657,689.181152 247.995316,689.074707 
	C247.538513,685.824646 248.587708,684.076782 252.225769,684.520142 
	C253.012314,684.615967 253.894058,683.930664 254.872925,683.314941 
z"/>
<path fill="#F5A081" opacity="1.000000" stroke="none" 
	d="
M553.940918,719.252625 
	C553.439697,719.227173 552.938538,719.201782 551.723633,719.169922 
	C549.895264,719.207886 548.780579,719.252319 547.665955,719.296753 
	C546.325500,712.296387 546.644958,711.881897 553.747620,711.883728 
	C615.362000,711.899475 676.976318,711.925964 738.590698,711.942810 
	C740.754822,711.943420 742.918945,711.876587 745.543457,711.792358 
	C748.431824,711.898193 750.859802,712.052795 753.660522,712.231201 
	C753.660522,714.476685 753.660522,716.556824 752.955933,718.958008 
	C716.784912,719.217346 681.318481,719.114746 645.851990,719.106567 
	C615.214966,719.099487 584.577942,719.199768 553.940918,719.252625 
z"/>
<path fill="#627735" opacity="1.000000" stroke="none" 
	d="
M539.707825,682.801514 
	C541.195374,682.949158 542.682800,683.225464 544.170410,683.225891 
	C603.621460,683.241516 663.072510,683.232483 722.523560,683.222290 
	C723.021973,683.222229 723.520447,683.162964 724.478882,683.059326 
	C726.837463,683.497375 728.736084,684.007507 731.328369,684.704041 
	C731.489441,686.244751 731.717834,688.429077 731.918091,690.964722 
	C729.690552,690.822510 727.491699,689.901184 725.291443,689.897644 
	C661.465210,689.795593 597.638794,689.785278 533.331238,689.361511 
	C532.960327,687.109924 533.070679,685.257141 533.180969,683.404297 
	C534.204834,683.276306 535.228699,683.148254 536.959778,682.993164 
	C538.347290,682.911316 539.027527,682.856384 539.707825,682.801514 
z"/>
<path fill="#A5574A" opacity="1.000000" stroke="none" 
	d="
M553.972412,719.634033 
	C584.577942,719.199768 615.214966,719.099487 645.851990,719.106567 
	C681.318481,719.114746 716.784912,719.217346 752.731323,719.303101 
	C753.168640,720.927490 753.126099,722.527832 753.009644,724.519165 
	C689.300659,724.902954 625.665466,724.907532 562.030334,724.856323 
	C558.520752,724.853516 554.190002,725.740051 553.972412,719.634033 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M356.157806,454.727234 
	C355.962708,439.584259 355.819855,424.440308 355.543274,409.298828 
	C355.450562,404.222290 354.936096,399.154114 354.800842,394.077148 
	C354.770233,392.928650 355.516388,391.759430 356.417816,390.436157 
	C358.280670,390.511658 359.630615,390.750732 361.014465,391.458801 
	C361.048370,410.490143 361.048370,429.052490 361.048370,448.022125 
	C363.719269,447.875763 365.267426,447.790894 366.835693,447.704956 
	C366.835693,428.540955 366.835693,409.841400 366.835693,390.929749 
	C364.670197,390.671173 362.871307,390.456421 361.118347,389.848877 
	C361.454590,387.494537 361.744873,385.532959 362.035126,383.571350 
	C363.722931,383.413300 365.410706,383.255249 367.804932,383.151794 
	C370.308319,383.135834 372.105316,383.065308 373.989990,383.456787 
	C374.107910,392.959656 374.138092,402.000488 374.023682,411.488281 
	C373.961334,420.274719 374.043549,428.614136 374.071655,437.423279 
	C374.581116,444.815674 375.144745,451.738403 375.495758,458.994934 
	C375.171326,459.841492 375.059509,460.354248 374.607300,460.933411 
	C373.214813,461.108704 372.162750,461.217590 371.110657,461.326477 
	C371.126251,461.421051 371.141846,461.515656 371.157440,461.610229 
	C372.456879,461.683960 373.756287,461.757690 375.141418,462.269104 
	C375.310730,464.774750 375.394379,466.842712 375.400085,469.350891 
	C375.209259,471.915405 375.096313,474.039673 374.542419,476.098816 
	C372.321564,476.033661 370.541626,476.033661 368.287689,476.033661 
	C368.072357,471.332733 367.872528,466.970703 367.654205,462.204742 
	C365.836853,462.204742 364.395599,462.204742 362.598206,462.025757 
	C361.650543,459.559296 361.058960,457.271820 360.426239,454.825165 
	C359.331665,454.940491 358.193024,455.060455 356.842407,455.043030 
	C356.472900,454.846130 356.315369,454.786682 356.157806,454.727234 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M373.902283,382.994751 
	C372.105316,383.065308 370.308319,383.135834 368.223572,383.061646 
	C367.925232,381.755432 368.068665,380.565277 367.858826,379.441040 
	C367.666962,378.412872 367.104553,376.630127 366.685303,376.624298 
	C365.480591,376.607483 364.265686,377.322052 363.393372,377.629791 
	C361.330688,376.942474 359.054199,376.183929 355.636200,375.045044 
	C355.636200,366.734039 355.608917,357.705231 355.642426,348.676636 
	C355.696503,334.096100 355.794769,319.515778 355.849396,304.935242 
	C355.851166,304.460663 355.580475,303.985077 355.709595,302.941498 
	C355.961090,302.104828 355.974487,301.838470 356.022644,301.573914 
	C357.264923,301.316437 358.507233,301.058990 360.188751,300.800201 
	C361.466980,301.897217 362.306000,302.995544 363.018433,304.287048 
	C362.891876,304.480194 362.674377,304.888214 362.417725,305.043945 
	C362.092438,305.776794 362.023743,306.353943 361.880188,307.402100 
	C361.810425,326.808044 361.803741,345.743011 361.837616,364.677887 
	C361.841309,366.741089 361.313507,369.368042 365.351746,368.869385 
	C366.249756,367.290863 367.092499,365.720093 367.109772,364.140350 
	C367.324280,344.509064 367.418762,324.876495 367.893555,305.086853 
	C369.895264,304.269897 371.547577,303.610382 373.347290,303.327576 
	C373.632812,307.631439 373.770935,311.558594 373.748413,315.910767 
	C373.702637,327.411835 373.817535,338.487854 373.862213,349.670959 
	C373.791992,349.778046 373.956207,349.974640 373.904541,350.442780 
	C373.951660,356.293304 374.050385,361.675659 374.051025,367.506836 
	C374.041840,371.949371 374.130737,375.943146 374.181702,380.320129 
	C374.063324,381.467133 373.982819,382.230927 373.902283,382.994751 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M362.954346,462.204742 
	C364.395599,462.204742 365.836853,462.204742 367.654205,462.204742 
	C367.872528,466.970703 368.072357,471.332733 368.287689,476.033661 
	C370.541626,476.033661 372.321564,476.033661 374.591888,476.498322 
	C375.364014,479.142090 375.645782,481.321198 375.950684,483.679199 
	C377.698273,483.458710 378.948151,483.300995 380.406952,483.324768 
	C381.311279,483.805573 382.006653,484.104889 382.618286,484.808746 
	C382.756073,486.932281 382.977570,488.651245 383.197021,490.354065 
	C385.798584,490.568237 387.901215,490.741302 390.112030,491.276550 
	C391.523102,495.070709 391.124176,497.489990 386.344086,497.476929 
	C384.999084,497.587738 384.027771,497.746155 382.677185,497.925537 
	C381.540924,497.999115 380.783844,498.051758 379.621521,498.009888 
	C377.872620,497.622345 376.528931,497.329285 375.433319,496.678864 
	C375.752869,494.616699 375.824341,492.911896 375.913391,490.866333 
	C375.875580,489.290161 375.820251,488.054779 375.770691,486.948944 
	C368.616302,488.014832 361.800110,489.030334 354.983887,490.045837 
	C354.983887,490.045837 355.006653,489.997284 354.987152,489.609833 
	C354.790649,486.278900 355.151184,482.877167 350.291748,483.394592 
	C350.179382,473.943634 350.159882,464.944092 350.140381,455.944519 
	C351.932831,455.684021 353.725220,455.423523 355.837708,454.945129 
	C356.315369,454.786682 356.472900,454.846130 356.854797,455.437622 
	C357.675690,458.109467 358.077606,460.328949 358.913269,462.370911 
	C360.257599,465.655640 361.713715,464.952026 362.954346,462.204742 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M589.453552,475.999542 
	C589.626770,482.762238 589.800049,489.524933 589.983643,497.211487 
	C590.048584,504.784637 590.103149,511.433990 590.157715,518.083313 
	C590.157776,522.865967 590.157776,527.648621 590.157776,533.222839 
	C587.615417,533.222839 585.715149,533.222839 583.332031,532.676880 
	C582.852722,515.403198 582.862549,498.675476 582.852905,481.947723 
	C582.851440,479.433929 583.081421,476.857635 579.257935,476.792664 
	C575.696045,476.732086 575.160034,478.795319 575.254639,481.592682 
	C575.282593,482.419464 575.125610,483.266174 575.248901,484.073853 
	C576.033386,489.211487 573.873962,491.509033 568.746460,490.578583 
	C568.543884,488.092072 568.301025,486.012421 568.089600,483.501221 
	C568.050476,480.795868 567.979919,478.522003 567.926392,475.802277 
	C568.014404,473.288818 568.085388,471.221252 568.585083,469.148926 
	C575.762146,469.302765 575.762146,469.302765 575.943542,462.644287 
	C578.505859,462.322998 580.768066,462.170532 583.015381,462.005951 
	C583.000549,461.993835 582.971313,461.969025 582.943176,462.435791 
	C582.894531,465.184540 582.855957,467.466522 582.856995,469.748474 
	C582.859741,476.164398 582.866150,476.164398 589.453552,475.999542 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M373.199890,302.950836 
	C371.547577,303.610382 369.895264,304.269897 367.689697,305.035492 
	C367.136444,305.141541 366.729126,305.036560 366.697784,304.892365 
	C366.526672,304.539734 366.356049,304.510254 365.808899,304.487976 
	C364.953705,304.338379 364.444244,304.360535 363.742188,304.297058 
	C363.549652,304.211487 363.144989,304.093903 363.144989,304.093903 
	C362.306000,302.995544 361.466980,301.897217 360.478516,300.535645 
	C362.249054,299.078583 364.169067,297.884735 366.618225,296.361877 
	C366.618225,285.683380 366.618225,274.123688 366.618225,262.380707 
	C365.103607,261.995972 363.704010,261.640503 362.099915,261.233063 
	C362.099915,259.262085 362.099915,257.313721 362.099915,254.995560 
	C364.551300,254.995560 366.928467,254.995560 369.091278,254.995560 
	C370.806549,259.651550 372.390625,263.951416 374.162354,268.760620 
	C376.055664,268.760620 378.652954,268.760620 381.547241,268.760620 
	C382.103424,264.191193 382.676178,259.485840 383.248901,254.780457 
	C383.687164,254.758911 384.125397,254.737366 384.563660,254.715820 
	C385.459778,257.072571 386.355896,259.429352 387.875916,261.911438 
	C388.839996,262.027710 389.180115,262.018646 389.653625,262.373413 
	C389.731842,263.508942 389.676697,264.280579 389.494293,265.330505 
	C389.231293,266.617706 389.095520,267.626648 388.649780,268.722565 
	C387.911346,268.800934 387.482880,268.792297 386.929016,268.694977 
	C386.487061,268.506104 386.199432,268.556610 385.564148,268.822571 
	C384.310120,270.248749 383.432648,271.610168 382.408722,273.227844 
	C382.258484,274.066589 382.254669,274.649048 382.240417,275.448120 
	C382.230042,275.664795 382.135803,276.088196 381.803192,276.118225 
	C381.248138,276.156769 381.025665,276.165344 380.601562,276.153076 
	C380.399902,276.132172 380.012146,276.250427 379.655396,276.170074 
	C378.577942,276.176178 377.857239,276.262604 376.745789,276.379456 
	C375.553192,277.624359 374.161133,278.793793 374.064331,280.062073 
	C373.812347,283.363708 374.128662,286.708710 374.137604,290.268677 
	C374.056946,290.500977 373.999115,290.989349 373.776062,291.227753 
	C373.748596,292.977631 373.944153,294.489105 374.019836,296.283752 
	C373.892578,297.175659 373.885193,297.784393 373.715088,298.606201 
	C373.581268,299.164398 373.610138,299.509491 373.535217,300.232941 
	C373.354218,301.391144 373.277039,302.170990 373.199890,302.950836 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M589.993042,447.772369 
	C589.925293,451.960632 589.857605,456.148865 589.273804,460.924225 
	C586.828857,461.663879 584.900085,461.816467 582.971313,461.969025 
	C582.971313,461.969025 583.000549,461.993835 582.957642,461.541382 
	C582.830994,456.664001 582.747131,452.239044 582.739136,447.362732 
	C582.780029,437.923462 582.745117,428.935577 582.779236,419.494629 
	C582.886047,409.698639 582.923889,400.355652 583.349487,390.920898 
	C585.832947,390.559540 587.928650,390.289978 590.010742,390.007202 
	C589.997131,389.993927 589.970520,389.966888 589.995789,390.415619 
	C590.041260,393.256561 590.061401,395.648743 590.024536,398.504150 
	C590.020142,405.902618 590.072754,412.837860 590.093018,420.163879 
	C590.060669,424.512146 590.060669,428.469696 590.060669,432.757019 
	C592.399841,432.757019 594.182495,432.757019 595.965149,432.757019 
	C596.277893,434.941132 596.590637,437.125214 596.469788,439.893921 
	C591.170837,440.547668 589.004883,442.853394 589.993042,447.772369 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M568.706116,490.985413 
	C573.873962,491.509033 576.033386,489.211487 575.248901,484.073853 
	C575.125610,483.266174 575.282593,482.419464 575.254639,481.592682 
	C575.160034,478.795319 575.696045,476.732086 579.257935,476.792664 
	C583.081421,476.857635 582.851440,479.433929 582.852905,481.947723 
	C582.862549,498.675476 582.852722,515.403198 582.883667,532.603149 
	C582.752014,533.165588 582.585938,533.255798 581.756836,533.352661 
	C579.235046,533.490906 577.376282,533.622559 575.466919,533.240662 
	C575.341797,527.771057 575.279175,522.814819 575.189453,517.859070 
	C575.080017,511.817963 574.823181,511.606293 568.355835,511.646423 
	C568.299866,509.036682 568.357483,506.872620 568.811523,504.746002 
	C572.878174,505.183472 576.042725,504.413605 574.552307,499.929047 
	C574.106689,498.588226 570.686401,498.236084 568.527100,496.976410 
	C568.433838,494.666229 568.442383,492.810577 568.515381,490.957123 
	C568.579773,490.959290 568.706116,490.985413 568.706116,490.985413 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M346.889587,375.147064 
	C344.921173,374.965088 342.952728,374.783112 340.244629,374.532776 
	C340.244629,372.370972 340.242737,370.275543 340.244934,368.180115 
	C340.267273,346.943237 340.443909,325.704590 340.193573,304.470917 
	C340.138306,299.785309 341.257690,297.575409 346.532104,298.511292 
	C346.933472,324.308105 346.911530,349.727570 346.889587,375.147064 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M574.862610,254.084808 
	C574.928650,254.311844 574.994690,254.538895 575.092651,255.473633 
	C575.234436,262.067047 575.234436,262.067047 582.813599,262.221252 
	C582.925049,267.355927 583.022278,272.052734 583.044556,277.232056 
	C582.913879,280.371307 582.813721,283.028107 582.814941,285.684814 
	C582.816101,288.311432 582.290649,290.170685 578.973816,290.128113 
	C575.577393,290.084564 575.303284,288.030548 575.313110,285.504944 
	C575.335876,279.690979 575.441528,273.874329 575.299377,268.064026 
	C575.236389,265.490967 576.326477,261.753937 571.728760,261.930969 
	C567.373352,262.098663 568.366821,265.725433 568.247253,268.343903 
	C568.065430,272.322479 568.387634,276.336304 567.991455,280.284546 
	C567.888550,281.310211 565.944885,282.827423 564.788574,282.881775 
	C563.622559,282.936554 561.332397,281.430023 561.349792,280.647919 
	C561.456055,275.864960 558.347839,275.830261 555.013062,275.424438 
	C554.803040,273.748535 554.628174,272.464630 554.478027,270.796448 
	C554.645264,269.919495 554.787720,269.426819 555.357178,268.949524 
	C561.832214,269.833588 561.079651,265.663055 561.403809,261.946899 
	C563.916138,261.160126 567.751099,260.460510 567.782654,259.618256 
	C567.973083,254.541595 571.358093,254.413132 574.862610,254.084808 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M597.087891,375.658081 
	C597.033508,380.161591 596.979126,384.665070 596.439453,389.660950 
	C593.959595,390.091187 591.965088,390.029022 589.970520,389.966888 
	C589.970520,389.966888 589.997131,389.993927 589.967407,389.538208 
	C589.940613,372.807068 589.915527,356.531616 589.968750,340.256378 
	C589.980042,336.794952 590.290710,333.490112 585.242310,333.331604 
	C584.435120,333.306213 583.270935,331.053375 583.087463,329.718170 
	C582.972107,328.879120 584.315186,327.105225 585.114746,327.036285 
	C586.533936,326.913910 589.310364,327.764313 589.312012,328.215851 
	C589.332458,333.741119 593.681946,333.103577 597.005859,333.986389 
	C597.021973,334.014191 597.047913,334.073029 596.993774,334.534546 
	C596.989014,348.550079 597.038452,362.104065 597.087891,375.658081 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M546.753540,228.798706 
	C546.792175,229.823883 546.830811,230.849060 546.445251,232.359039 
	C544.068848,233.613022 540.496094,234.261551 540.428345,235.171021 
	C540.002686,240.889435 536.752319,240.517349 532.804382,239.081146 
	C533.572998,233.076843 530.139343,232.725632 525.590088,233.259247 
	C522.949097,233.504959 520.697388,233.771851 518.083618,233.802643 
	C515.452332,233.389114 513.183289,233.211685 511.080200,232.823318 
	C511.253082,232.433624 511.260040,232.254852 511.313019,231.633850 
	C512.300293,224.612137 507.658508,226.023346 504.040863,225.579422 
	C504.088348,222.916168 504.084351,220.715958 504.049194,218.501663 
	C504.018036,218.487595 503.993988,218.551620 504.382141,218.552643 
	C506.814392,218.614120 508.858521,218.674591 511.363342,218.777710 
	C513.987061,218.925995 516.150146,219.031631 518.302368,219.554916 
	C518.421753,225.699677 519.779480,227.419373 523.589050,225.626450 
	C525.047058,224.940262 525.178040,221.434235 526.371704,219.189545 
	C528.661316,219.239838 530.492920,219.313782 532.348694,219.769104 
	C531.374939,226.683075 535.850830,225.908096 540.261108,225.783936 
	C542.693420,226.806213 544.723511,227.802460 546.753540,228.798706 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M346.949768,375.581787 
	C346.911530,349.727570 346.933472,324.308105 346.946686,298.417114 
	C347.132416,295.822327 347.326874,293.698975 347.567017,291.077026 
	C349.732849,290.864380 351.954285,290.646271 354.665771,290.380066 
	C354.869537,287.308990 355.075745,284.201508 355.361298,279.898102 
	C357.807495,285.355988 357.563751,294.491211 355.579376,298.903412 
	C355.366608,299.376373 355.852722,300.163696 356.018616,301.189545 
	C355.974487,301.838470 355.961090,302.104828 356.010712,302.483276 
	C354.575195,303.124634 353.111389,303.655762 351.071594,304.192322 
	C350.495605,304.197754 350.163940,304.353729 350.163940,304.353729 
	C350.208984,326.729095 350.264374,349.104492 350.291504,371.479858 
	C350.297943,376.781525 350.320038,382.088806 350.058014,387.379761 
	C350.013611,388.276215 348.560089,389.102844 347.421570,389.495239 
	C347.025360,387.332306 346.965149,385.635803 346.904938,383.939331 
	C346.939941,381.298370 346.974945,378.657440 346.949768,375.581787 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M574.941406,253.646423 
	C571.358093,254.413132 567.973083,254.541595 567.782654,259.618256 
	C567.751099,260.460510 563.916138,261.160126 561.419250,261.962769 
	C560.999756,261.999908 561.030579,262.031555 561.026855,261.564148 
	C561.024841,258.640839 560.799744,256.159027 561.076660,253.734543 
	C561.613892,249.030823 559.785400,246.968948 554.961426,247.435913 
	C554.658875,246.811737 554.435059,246.404663 554.208862,245.616928 
	C554.190063,244.475128 554.173645,243.713989 554.210938,242.845779 
	C554.323608,242.493805 554.283203,242.266418 554.140686,241.607544 
	C554.143250,238.769043 554.148621,236.379562 554.154053,233.990067 
	C560.893616,233.990067 567.633240,233.990067 574.676697,233.990067 
	C574.807678,235.978394 574.913269,237.581055 574.564270,239.601105 
	C569.445740,240.304779 567.227234,242.747589 569.310425,245.759735 
	C570.236938,247.099380 572.933167,247.215149 574.826050,247.886414 
	C574.890808,249.660294 574.955566,251.434174 574.941406,253.646423 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M380.026794,498.104401 
	C380.783844,498.051758 381.540924,497.999115 382.661987,498.396729 
	C383.065125,503.189941 383.104279,507.532959 383.139160,512.359375 
	C383.072144,526.916504 383.009369,540.990295 382.974945,555.033569 
	C383.003296,555.002991 383.061493,554.943359 383.061493,554.943359 
	C380.764832,554.943359 378.468140,554.943359 375.562500,554.943359 
	C375.562500,547.727722 375.562500,540.921509 375.563171,533.191650 
	C375.717865,527.849060 375.871918,523.430054 376.398743,518.915649 
	C377.514465,517.174438 378.723724,515.580444 378.913391,513.873108 
	C379.494629,508.640198 379.684662,503.363861 380.026794,498.104401 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M597.112854,290.899475 
	C599.217651,290.899475 601.322449,290.899475 603.904480,290.899475 
	C603.904480,297.822632 603.904480,304.496216 603.913086,311.622223 
	C603.921631,312.074677 603.936768,312.017273 603.514771,311.952454 
	C602.274841,311.897034 601.250000,311.597534 600.680908,311.978577 
	C599.406067,312.832214 598.357971,314.024445 597.217529,315.078796 
	C598.374512,316.084259 599.427063,317.268799 600.728210,318.026917 
	C601.489197,318.470337 602.646790,318.233093 603.710083,318.716919 
	C604.115234,326.018494 604.115234,326.018494 597.158386,325.624084 
	C596.255554,323.116272 595.787781,319.627991 594.451660,319.255676 
	C591.377319,318.398987 589.383057,317.436340 590.032227,314.077362 
	C590.676758,310.742310 587.275940,305.607117 594.067200,304.669647 
	C594.885803,304.556641 595.729370,302.207977 595.967651,300.795319 
	C596.513672,297.558441 596.692749,294.259613 597.043701,290.964172 
	C597.066589,290.942444 597.112854,290.899475 597.112854,290.899475 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M589.939514,262.214905 
	C592.074463,262.214905 594.209412,262.214905 597.011108,262.214905 
	C597.011108,264.428070 597.011108,266.542633 597.048584,269.505951 
	C597.155396,272.194519 597.224609,274.034271 597.293823,275.874054 
	C597.210938,280.576233 597.128052,285.278381 597.078979,290.440002 
	C597.112854,290.899475 597.066589,290.942444 596.612305,290.960144 
	C590.064636,291.107697 588.562561,292.823975 589.386414,298.736877 
	C589.500488,299.555573 590.077942,300.640686 589.745483,301.127777 
	C588.794556,302.520905 587.485657,303.669678 586.317505,304.914520 
	C585.218506,303.827393 583.523010,302.908417 583.158203,301.614288 
	C582.590393,299.600494 582.949402,297.330902 582.873901,295.171204 
	C582.788147,292.716095 583.460632,291.316650 586.313721,290.730469 
	C587.733215,290.438782 589.682312,288.073425 589.739563,286.582703 
	C590.038696,278.785889 589.808167,270.968689 589.826904,262.926605 
	C589.892151,262.694611 589.939514,262.214905 589.939514,262.214905 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M439.990356,221.860107 
	C440.730133,220.916000 441.286438,219.543152 442.253235,219.129349 
	C443.662933,218.525970 445.367859,218.612259 447.649597,218.233429 
	C450.269531,218.314346 452.186859,218.566376 454.118835,219.247787 
	C454.074097,221.665451 454.014679,223.653763 453.511108,225.669556 
	C446.782623,224.505280 446.591461,228.554230 446.399902,232.980392 
	C442.022308,232.513474 439.275360,233.414185 439.759979,238.254288 
	C439.814911,238.803345 439.262146,239.413239 438.564697,240.023804 
	C436.429993,240.027817 434.721802,240.002930 432.998230,239.756363 
	C432.982880,239.534683 432.827637,239.117798 432.871429,238.722107 
	C432.859253,237.534317 432.803284,236.742203 432.810425,235.564453 
	C430.781281,234.106918 428.688995,233.035065 426.181976,231.750748 
	C426.062042,230.486786 425.891602,228.690918 425.787903,226.665436 
	C425.854675,226.435822 426.011475,225.984055 426.473816,225.942368 
	C430.247742,225.894440 433.561432,225.812195 436.870331,225.904373 
	C439.886292,225.988403 440.696747,224.555099 439.990356,221.860107 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M532.324585,219.387726 
	C530.492920,219.313782 528.661316,219.239838 525.957458,219.067871 
	C522.827942,219.025665 520.570557,219.081467 518.313232,219.137268 
	C516.150146,219.031631 513.987061,218.925995 511.387817,218.382629 
	C511.784393,216.368881 512.619812,213.430923 513.449402,213.432556 
	C520.010803,213.445374 519.357971,209.694489 518.122437,205.421921 
	C520.101868,205.281952 522.081360,205.141983 524.507812,205.470291 
	C525.823975,212.131058 526.366272,212.529114 532.773376,211.677048 
	C534.846680,211.875244 536.920044,212.073456 539.376404,212.308289 
	C539.494995,214.494827 539.607422,216.567093 539.730774,218.841278 
	C541.723511,218.944687 543.991089,219.062363 546.918762,219.214279 
	C546.918762,222.168396 546.918762,225.058212 546.836182,228.373367 
	C544.723511,227.802460 542.693420,226.806213 540.274536,225.403961 
	C539.361633,219.904327 537.621826,218.613434 532.324585,219.387726 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M461.018982,662.351440 
	C448.879822,662.408264 436.740417,662.542053 424.601562,662.505859 
	C399.993866,662.432434 375.386414,662.155396 350.778992,662.171204 
	C326.862396,662.186584 302.945984,662.463379 278.580872,662.519592 
	C287.376282,662.233337 296.620300,662.052795 306.314728,661.843384 
	C312.981720,661.974609 319.207642,662.400513 325.410767,662.180847 
	C328.439270,662.073608 331.420563,660.172363 334.431580,660.148804 
	C338.113403,660.119995 341.791901,661.302673 345.495239,661.780090 
	C347.772705,662.073669 350.401245,662.788086 352.342316,662.003235 
	C360.789062,658.587646 369.076080,659.476501 378.297577,661.911743 
	C403.341431,661.835510 427.531647,661.800903 451.721863,661.766357 
	C453.606873,661.835632 455.491852,661.904907 458.145630,661.900269 
	C459.537842,661.904785 460.161285,661.983154 460.895782,662.092407 
	C461.006866,662.123291 461.018982,662.351440 461.018982,662.351440 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M568.469360,512.092041 
	C574.823181,511.606293 575.080017,511.817963 575.189453,517.859070 
	C575.279175,522.814819 575.341797,527.771057 575.376465,533.575073 
	C575.255371,536.399719 575.174194,538.376404 575.093018,540.353149 
	C574.971924,542.631287 574.850891,544.909485 574.306519,547.632690 
	C569.293518,548.559448 567.771118,546.316101 568.102905,541.971558 
	C568.301453,539.372009 567.941833,536.729736 567.949219,533.635498 
	C568.199951,526.140259 568.334656,519.116150 568.469360,512.092041 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M453.955231,225.642059 
	C454.014679,223.653763 454.074097,221.665451 454.265350,218.923004 
	C454.575378,216.173462 454.753601,214.178070 454.931824,212.182678 
	C456.977234,212.132462 459.022644,212.082260 461.385254,212.438477 
	C461.198090,218.592010 461.740143,219.089157 468.532654,219.363846 
	C468.770752,221.868179 468.884613,224.004150 468.964569,226.565842 
	C468.974426,228.981400 469.018250,230.971237 468.637207,232.929565 
	C466.084045,233.299423 463.955688,233.700775 461.596497,234.038239 
	C461.365662,233.974350 460.924713,233.787155 460.987549,233.363098 
	C461.390472,226.285263 461.281677,226.162567 454.623016,225.899139 
	C454.389740,225.844315 453.955231,225.642059 453.955231,225.642059 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M567.833069,534.106689 
	C567.941833,536.729736 568.301453,539.372009 568.102905,541.971558 
	C567.771118,546.316101 569.293518,548.559448 574.290161,548.118286 
	C574.795471,550.105164 574.893799,552.051392 575.031311,554.773376 
	C570.336670,554.773376 566.072998,554.773376 561.387451,554.449585 
	C560.971558,547.412170 560.977600,540.698608 561.415405,533.984131 
	C563.842407,534.024414 565.837769,534.065552 567.833069,534.106689 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M476.094269,211.961685 
	C476.094269,209.832779 476.094269,207.703873 476.094269,205.805176 
	C485.017975,205.805176 493.674469,205.805176 502.712311,206.383728 
	C503.275665,213.435028 498.391449,211.849487 494.394775,212.227661 
	C492.921844,212.232925 491.789398,212.227722 490.171387,212.189560 
	C487.431091,212.186554 485.176361,212.216522 482.496582,212.200882 
	C480.079102,212.090744 478.086700,212.026215 476.094269,211.961685 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M376.025970,519.011047 
	C375.871918,523.430054 375.717865,527.849060 375.489380,532.742310 
	C373.488312,533.496826 371.561707,533.777161 368.927673,534.035889 
	C366.845062,533.847229 365.469849,533.680176 364.094604,533.513123 
	C363.987701,528.934509 363.880798,524.355896 364.189178,519.293030 
	C365.974213,518.443726 367.343964,518.078674 368.953857,517.938721 
	C371.255402,518.417175 373.316895,518.670593 375.541260,518.938416 
	C375.704132,518.952820 376.025970,519.011047 376.025970,519.011047 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M532.843628,239.442673 
	C536.752319,240.517349 540.002686,240.889435 540.428345,235.171021 
	C540.496094,234.261551 544.068848,233.613022 546.433960,232.743378 
	C547.146301,232.753510 547.414612,232.915680 547.479980,233.685669 
	C547.190979,238.480652 547.073730,242.719376 546.559448,247.015717 
	C544.001282,247.662491 541.840088,248.251663 539.413696,248.995117 
	C537.182800,249.730789 535.217102,250.312164 533.200317,250.545868 
	C533.047546,249.739746 532.945862,249.281342 532.815796,248.382568 
	C532.748596,245.269379 532.709778,242.596558 532.670776,239.787918 
	C532.670654,239.652100 532.843628,239.442673 532.843628,239.442673 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M597.482910,375.548645 
	C597.038452,362.104065 596.989014,348.550079 597.146912,334.901978 
	C597.502380,339.574860 597.922302,344.353516 597.717834,349.105286 
	C597.534302,353.369781 599.231445,354.802856 603.221313,354.076996 
	C603.221313,360.796387 603.221313,367.515778 603.221313,374.849365 
	C601.628540,375.025208 599.753235,375.232178 597.482910,375.548645 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M603.273621,353.600159 
	C599.231445,354.802856 597.534302,353.369781 597.717834,349.105286 
	C597.922302,344.353516 597.502380,339.574860 597.201050,334.440491 
	C597.047913,334.073029 597.021973,334.014191 597.075073,333.554169 
	C597.151794,330.749023 597.175415,328.403931 597.199036,326.058807 
	C604.115234,326.018494 604.115234,326.018494 603.808594,318.263794 
	C603.915894,315.856354 604.008850,314.316895 604.270996,313.167969 
	C604.657410,320.003601 604.874634,326.448700 605.091919,332.893829 
	C604.408569,332.507202 603.725159,332.120544 603.325928,331.894684 
	C603.325928,338.913300 603.325928,346.018311 603.273621,353.600159 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M350.023010,455.715332 
	C350.159882,464.944092 350.179382,473.943634 350.064056,483.714966 
	C349.612701,486.294312 349.296143,488.101898 348.979584,489.909515 
	C348.354370,487.898407 347.729156,485.887329 347.077637,483.000549 
	C347.063263,477.795746 347.075134,473.466553 347.087036,469.137390 
	C347.084442,459.742371 347.081879,450.347382 347.067535,440.060425 
	C347.051086,437.455048 347.046417,435.741608 347.041779,434.028168 
	C347.166992,429.336853 347.292206,424.645538 347.716431,419.479248 
	C348.383789,418.957703 348.752075,418.911133 349.120392,418.864563 
	C349.120392,418.864563 349.157288,418.903412 349.121552,419.391937 
	C349.080139,421.863892 349.032562,423.848206 349.075836,425.830566 
	C349.288116,435.552155 349.524536,445.273254 349.752411,454.994507 
	C349.803467,455.158386 349.854553,455.322266 350.023010,455.715332 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M560.983643,533.984985 
	C560.977600,540.698608 560.971558,547.412170 560.937622,554.602173 
	C560.909729,555.078491 560.910522,555.155884 560.895996,555.191406 
	C558.907776,555.131592 556.934082,555.036316 554.571472,554.922241 
	C554.181152,550.122253 553.811035,545.571228 553.165649,540.306152 
	C552.812744,538.020386 552.735107,536.448730 552.997559,534.876282 
	C555.896545,534.589233 558.455383,534.302917 561.006836,534.008606 
	C560.999329,534.000610 560.983582,533.984985 560.983643,533.984985 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M468.998505,226.140137 
	C468.884613,224.004150 468.770752,221.868179 468.556213,219.027802 
	C468.449829,217.651474 468.444153,216.979523 468.607117,216.227692 
	C468.775757,216.147827 469.107056,215.976074 469.107056,215.976074 
	C471.220001,216.875198 473.332916,217.774323 476.108307,218.681625 
	C478.851196,218.642914 480.931671,218.596039 483.441437,218.647568 
	C485.926086,218.828201 487.981384,218.910446 490.021057,218.969666 
	C490.005402,218.946655 489.955048,218.971268 489.983734,219.411301 
	C490.847870,224.721054 488.908569,226.861877 483.920074,226.170166 
	C482.981018,226.039948 481.983215,226.333130 480.588928,226.351349 
	C478.719849,226.281128 477.274384,226.290512 475.410156,226.214844 
	C472.993774,226.133240 470.996124,226.136688 468.998505,226.140137 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M590.593811,518.085449 
	C590.103149,511.433990 590.048584,504.784637 590.020569,497.694794 
	C592.017273,497.254272 593.987366,497.254272 596.432861,497.254272 
	C596.432861,504.273041 596.432861,510.947479 596.432861,518.087585 
	C594.614258,518.087585 592.822083,518.087585 590.593811,518.085449 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M361.030731,511.228363 
	C359.239685,511.228363 357.448639,511.228363 354.970215,511.228363 
	C354.970215,504.600311 354.970215,498.222931 355.434052,491.386536 
	C358.797211,491.038055 362.534790,489.900330 361.978821,495.513611 
	C361.915344,500.984436 361.919037,505.997803 361.974182,511.012390 
	C362.025665,511.013550 362.028168,510.910645 361.760986,510.934204 
	C361.339447,511.047943 361.185089,511.138153 361.030731,511.228363 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M349.086426,418.405396 
	C348.752075,418.911133 348.383789,418.957703 347.737061,419.015198 
	C347.021210,418.790070 346.605927,418.490143 346.142426,418.329285 
	C344.376923,417.716553 341.353058,417.505249 341.063141,416.494537 
	C340.315399,413.887970 340.179779,410.745026 340.995148,408.199280 
	C341.418640,406.877136 344.374969,406.366302 346.653412,405.263184 
	C346.806396,403.366394 347.000641,400.958160 347.194885,398.549896 
	C347.638245,398.531067 348.081604,398.512268 348.524963,398.493439 
	C348.700806,404.977722 348.876648,411.462006 349.086426,418.405396 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M605.524475,332.930786 
	C604.874634,326.448700 604.657410,320.003601 604.275635,312.968018 
	C604.111084,312.377594 603.936768,312.017273 603.936768,312.017273 
	C603.936768,312.017273 603.921631,312.074677 603.903198,312.098389 
	C606.022034,312.122131 608.159302,312.122131 610.607910,312.122131 
	C610.607910,319.049530 610.607910,325.633728 610.607910,332.668610 
	C609.114502,332.764679 607.535767,332.866211 605.524475,332.930786 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M590.125366,419.773132 
	C590.072754,412.837860 590.020142,405.902618 590.451599,398.494507 
	C592.643127,398.054626 594.350525,398.087616 596.057983,398.120605 
	C596.064453,404.809357 596.070984,411.498077 595.597900,418.682434 
	C593.454041,419.376404 591.789673,419.574768 590.125366,419.773132 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M494.735291,212.217194 
	C498.391449,211.849487 503.275665,213.435028 503.112122,206.557648 
	C505.691620,205.832916 508.252625,205.512833 511.056671,205.877777 
	C510.534119,208.349960 509.763702,211.678406 509.003662,211.676041 
	C503.763794,211.659698 503.834869,214.960327 503.993988,218.551620 
	C503.993988,218.551620 504.018036,218.487595 503.625549,218.650024 
	C501.140717,218.874557 499.048340,218.936691 496.956177,218.601471 
	C496.216003,216.208481 495.475647,214.212830 494.735291,212.217194 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M589.747925,262.049561 
	C589.939514,262.214905 589.892151,262.694611 589.662598,263.275024 
	C589.298645,266.320160 588.993652,268.789978 589.067566,271.248413 
	C589.195007,275.486755 587.500122,277.506531 583.119507,276.749573 
	C583.022278,272.052734 582.925049,267.355927 583.174377,262.056122 
	C585.355774,261.313080 587.190491,261.173004 589.025269,261.032898 
	C589.202271,261.316681 589.379272,261.600433 589.747925,262.049561 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M504.382141,218.552643 
	C503.834869,214.960327 503.763794,211.659698 509.003662,211.676041 
	C509.763702,211.678406 510.534119,208.349960 511.382782,206.126526 
	C513.454407,205.696426 515.442993,205.702637 517.776978,205.565369 
	C519.357971,209.694489 520.010803,213.445374 513.449402,213.432556 
	C512.619812,213.430923 511.784393,216.368881 510.927155,218.339981 
	C508.858521,218.674591 506.814392,218.614120 504.382141,218.552643 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M582.943176,462.435791 
	C584.900085,461.816467 586.828857,461.663879 589.049072,461.353638 
	C589.405945,465.824890 589.471252,470.453766 589.495056,475.541107 
	C582.866150,476.164398 582.859741,476.164398 582.856995,469.748474 
	C582.855957,467.466522 582.894531,465.184540 582.943176,462.435791 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M346.683929,469.276428 
	C347.075134,473.466553 347.063263,477.795746 347.025208,482.594086 
	C344.878326,482.523438 341.179108,482.377472 340.928040,481.371185 
	C340.122925,478.144379 340.182526,474.517822 340.834412,471.229034 
	C341.018311,470.301117 344.377808,470.002655 346.683929,469.276428 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M439.600677,221.869461 
	C440.696747,224.555099 439.886292,225.988403 436.870331,225.904373 
	C433.561432,225.812195 430.247742,225.894440 426.258240,225.646423 
	C422.970703,223.635056 420.422150,222.875107 417.668945,225.870285 
	C415.518738,225.890686 413.801941,225.891159 412.085175,225.891617 
	C413.362030,224.118240 414.638916,222.344864 416.204041,220.171127 
	C419.946228,220.486526 424.975250,217.994751 427.702576,223.957047 
	C427.949097,224.496017 431.614075,224.446884 431.902863,223.831482 
	C433.687653,220.028336 436.672363,221.995193 439.600677,221.869461 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M590.093018,420.163879 
	C591.789673,419.574768 593.454041,419.376404 595.536621,419.156342 
	C596.054077,423.391846 596.153259,427.649048 596.108765,432.331635 
	C594.182495,432.757019 592.399841,432.757019 590.060669,432.757019 
	C590.060669,428.469696 590.060669,424.512146 590.093018,420.163879 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M346.542908,383.942444 
	C346.965149,385.635803 347.025360,387.332306 347.070496,389.453979 
	C346.650665,392.104675 346.245911,394.330139 345.805237,396.753021 
	C344.130463,396.753021 342.568542,396.753021 340.656921,396.753021 
	C340.656921,392.535309 340.656921,388.622131 340.656921,383.945587 
	C342.411957,383.945587 344.296417,383.945587 346.542908,383.942444 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M411.634125,225.953979 
	C413.801941,225.891159 415.518738,225.890686 417.884583,226.100983 
	C418.634644,227.145493 418.735596,227.979263 418.687805,229.180267 
	C418.475464,234.763321 414.533325,232.628876 411.488556,233.018829 
	C408.971039,232.957428 406.896393,232.911407 404.821716,232.865387 
	C404.914551,230.750641 405.007385,228.635910 405.122375,226.016357 
	C407.297241,226.016357 409.240173,226.016357 411.634125,225.953979 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M589.072510,260.602478 
	C587.190491,261.173004 585.355774,261.313080 583.160217,261.618286 
	C575.234436,262.067047 575.234436,262.067047 575.181885,255.816925 
	C578.197266,255.354935 581.167908,255.395782 584.110046,255.125473 
	C587.958679,254.771927 589.679138,256.275665 589.072510,260.602478 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M546.956482,246.958115 
	C547.073730,242.719376 547.190979,238.480652 547.591919,233.892273 
	C549.667542,233.653763 551.459412,233.764908 553.702637,233.933060 
	C554.148621,236.379562 554.143250,238.769043 554.129395,241.831085 
	C554.120911,242.503647 554.157227,242.952850 554.157227,242.952850 
	C554.173645,243.713989 554.190063,244.475128 554.156799,245.938400 
	C554.083618,246.856949 554.060059,247.073380 553.617615,247.296478 
	C551.537781,247.289871 549.876892,247.276581 547.917480,247.188110 
	C547.416382,247.079712 547.213867,247.046509 546.997803,246.999374 
	C546.984314,246.985458 546.956482,246.958115 546.956482,246.958115 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M362.045990,495.056213 
	C362.534790,489.900330 358.797211,491.038055 355.471771,490.912994 
	C355.045654,490.898438 354.929413,490.478302 354.956665,490.262085 
	C361.800110,489.030334 368.616302,488.014832 375.770691,486.948944 
	C375.820251,488.054779 375.875580,489.290161 375.483063,490.809998 
	C368.422424,490.724060 368.422424,490.724060 367.772125,496.887451 
	C365.632202,496.214935 363.839081,495.635590 362.045990,495.056213 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M702.177002,661.475342 
	C698.145996,661.969604 694.091980,662.040894 689.589783,662.052979 
	C689.174133,660.210876 689.206604,658.428101 689.308960,656.203125 
	C691.973877,655.808411 694.579834,656.027527 697.161438,655.863098 
	C701.019165,655.617493 702.530762,657.358826 702.177002,661.475342 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M390.233307,255.069992 
	C390.077545,252.935883 389.921783,250.801773 389.738007,248.283859 
	C387.854706,248.092377 386.095184,247.913483 384.422699,247.441132 
	C386.278687,247.100632 388.047638,247.053619 390.239258,247.044983 
	C391.793243,247.047974 392.924591,247.012589 394.363525,247.201492 
	C399.072601,248.919556 396.317780,252.463501 396.444702,255.043152 
	C394.097961,255.038773 392.165649,255.054382 390.233307,255.069992 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M483.012146,218.549164 
	C480.931671,218.596039 478.851196,218.642914 476.477173,218.693146 
	C476.087524,216.701004 475.991486,214.705490 475.994873,212.335846 
	C478.086700,212.026215 480.079102,212.090744 482.495178,212.592178 
	C482.949951,214.869110 482.981049,216.709137 483.012146,218.549164 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M596.303955,397.794586 
	C594.350525,398.087616 592.643127,398.054626 590.508667,398.031311 
	C590.061401,395.648743 590.041260,393.256561 589.995789,390.415619 
	C591.965088,390.029022 593.959595,390.091187 596.378113,390.131775 
	C596.718018,392.563019 596.633911,395.015778 596.303955,397.794586 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M575.500000,540.522156 
	C575.174194,538.376404 575.255371,536.399719 575.427002,534.088623 
	C577.376282,533.622559 579.235046,533.490906 581.494019,533.458984 
	C581.894104,535.818848 581.894104,538.078979 581.894104,540.691162 
	C579.719666,540.691162 577.813354,540.691162 575.500000,540.522156 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M368.713745,517.713684 
	C367.343964,518.078674 365.974213,518.443726 364.246552,518.902954 
	C363.242493,516.940186 362.596344,514.883301 362.090332,512.158752 
	C362.163055,511.297638 362.095612,511.104156 362.028168,510.910645 
	C362.028168,510.910645 362.025665,511.013550 362.339417,511.253540 
	C364.564362,511.493561 366.475555,511.493561 368.713745,511.493561 
	C368.713745,513.912476 368.713745,515.813049 368.713745,517.713684 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M363.751190,533.646729 
	C365.469849,533.680176 366.845062,533.847229 368.556519,534.097412 
	C368.694000,541.485168 368.694000,541.485168 361.544830,539.959778 
	C362.173248,537.875366 362.790497,535.827820 363.751190,533.646729 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M532.809082,211.264587 
	C526.366272,212.529114 525.823975,212.131058 524.931030,205.538651 
	C527.337952,205.138733 529.768677,205.138733 532.844727,205.138733 
	C532.844727,207.075974 532.844727,208.964050 532.809082,211.264587 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M393.923279,568.938843 
	C398.584869,569.659851 396.777496,573.018921 396.805115,575.920532 
	C395.451233,576.103455 394.194824,576.273132 392.557556,576.536438 
	C388.451202,575.303650 390.655487,572.153931 390.071838,569.427795 
	C390.050262,569.000793 390.028473,569.087341 390.419495,569.090942 
	C391.848083,569.042664 392.885681,568.990723 393.923279,568.938843 
z"/>
<path fill="#7C7DDF" opacity="1.000000" stroke="none" 
	d="
M404.583984,233.136383 
	C406.896393,232.911407 408.971039,232.957428 411.415222,233.435059 
	C411.615295,235.830994 411.445862,237.795334 411.008972,239.813187 
	C410.401245,239.999466 410.060944,240.132233 409.325317,240.292725 
	C407.618103,240.233826 406.306244,240.147202 404.842102,239.771362 
	C404.477692,238.645721 404.265533,237.809311 404.053375,236.972900 
	C404.151001,235.784393 404.248627,234.595901 404.583984,233.136383 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M454.509460,212.122223 
	C454.753601,214.178070 454.575378,216.173462 454.250671,218.493637 
	C452.186859,218.566376 450.269531,218.314346 448.011169,217.997131 
	C447.670135,216.186722 447.670135,214.441528 447.670135,212.061752 
	C450.076263,212.061752 452.081696,212.061752 454.509460,212.122223 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M717.934692,675.467773 
	C711.472473,676.516541 711.313965,676.390442 711.276367,670.141479 
	C713.390625,669.597290 715.453796,669.413879 717.948364,669.192139 
	C717.948364,671.577698 717.948364,673.341858 717.934692,675.467773 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M383.285461,561.954285 
	C383.185547,559.911804 383.085663,557.869263 383.023621,555.385071 
	C383.061493,554.943359 383.003296,555.002991 383.399658,554.964111 
	C385.513672,554.891602 387.231293,554.857910 389.165375,555.094604 
	C389.630035,556.248108 389.878235,557.131226 390.063232,558.408813 
	C389.861633,559.856079 389.723267,560.908875 389.190430,561.977783 
	C386.959137,561.980591 385.122314,561.967407 383.285461,561.954285 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M383.370636,562.369995 
	C385.122314,561.967407 386.959137,561.980591 389.174500,562.422852 
	C389.711487,564.930420 389.869995,567.008911 390.028473,569.087341 
	C390.028473,569.087341 390.050262,569.000793 390.093384,569.012573 
	C388.213623,568.863464 386.290710,568.702515 384.023468,568.512695 
	C383.832581,566.587097 383.644196,564.686401 383.370636,562.369995 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M389.816589,247.006592 
	C388.047638,247.053619 386.278687,247.100632 384.070465,247.227722 
	C382.177521,240.253128 382.391754,239.987259 389.653992,240.161804 
	C390.052521,239.996246 389.961609,240.034760 389.868347,240.462708 
	C389.788879,242.929306 389.802734,244.967941 389.816589,247.006592 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M590.395752,447.664215 
	C589.004883,442.853394 591.170837,440.547668 596.379150,440.374298 
	C597.197021,447.460815 597.197021,447.460815 590.395752,447.664215 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M597.688843,275.855774 
	C597.224609,274.034271 597.155396,272.194519 597.089905,269.985809 
	C599.256409,270.114563 602.902405,270.063751 603.324402,271.205048 
	C605.128296,276.083740 601.172729,275.722565 597.688843,275.855774 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M390.076538,240.039383 
	C390.033142,233.490784 391.158264,232.524261 397.212616,234.850037 
	C397.262451,237.066483 397.157837,238.538666 396.610168,240.048553 
	C394.098572,240.069077 392.030090,240.051926 389.961609,240.034760 
	C389.961609,240.034760 390.052521,239.996246 390.076538,240.039383 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M574.929077,247.693527 
	C572.933167,247.215149 570.236938,247.099380 569.310425,245.759735 
	C567.227234,242.747589 569.445740,240.304779 574.530396,240.045837 
	C575.014587,240.233643 575.077942,240.394104 575.210083,241.229431 
	C575.291321,243.658890 575.303650,245.413528 575.315979,247.168152 
	C575.315979,247.168152 575.031982,247.500641 574.929077,247.693527 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M390.190735,255.451721 
	C392.165649,255.054382 394.097961,255.038773 396.482666,255.469833 
	C396.862518,257.520477 396.790070,259.124451 396.727600,260.507599 
	C394.004974,261.074982 391.762634,261.542297 389.520264,262.009583 
	C389.180115,262.018646 388.839996,262.027710 388.244995,262.018311 
	C388.709503,259.944336 389.428833,257.888885 390.190735,255.451721 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M468.438446,216.307571 
	C468.444153,216.979523 468.449829,217.651474 468.431976,218.659454 
	C461.740143,219.089157 461.198090,218.592010 461.817200,212.513336 
	C463.639069,212.161728 465.346222,212.141678 467.503296,212.602982 
	C468.114960,214.158752 468.276703,215.233154 468.438446,216.307571 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M346.615173,434.051544 
	C347.046417,435.741608 347.051086,437.455048 347.020203,439.595154 
	C345.027191,439.912170 343.069702,439.802582 341.165009,439.695923 
	C339.901489,434.347748 340.259583,433.963074 346.615173,434.051544 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M349.846558,454.535889 
	C349.524536,445.273254 349.288116,435.552155 349.075836,425.830566 
	C349.032562,423.848206 349.080139,421.863892 349.100891,419.408997 
	C349.551239,422.330963 350.332977,425.722382 350.353271,429.118317 
	C350.402924,437.436829 350.101074,445.757477 349.846558,454.535889 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M361.626007,383.593811 
	C361.744873,385.532959 361.454590,387.494537 361.158264,390.043640 
	C361.152222,390.631134 360.980591,390.989838 360.980591,390.989807 
	C359.630615,390.750732 358.280670,390.511658 356.602295,390.176025 
	C356.273865,388.160431 356.273865,386.241333 356.273865,383.962219 
	C358.038391,383.838715 359.627625,383.727509 361.626007,383.593811 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M575.719482,247.157593 
	C575.303650,245.413528 575.291321,243.658890 575.294373,241.474121 
	C575.630859,241.082947 576.008057,241.026413 576.263977,241.176529 
	C577.844299,242.103699 580.521057,242.867020 580.747314,244.075394 
	C581.408630,247.607590 578.212646,246.852280 575.719482,247.157593 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M349.405762,489.990112 
	C349.296143,488.101898 349.612701,486.294312 350.156921,484.166321 
	C355.151184,482.877167 354.790649,486.278900 354.998749,489.634033 
	C353.297211,490.054016 351.564575,490.062347 349.405762,489.990112 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M397.053253,240.010864 
	C397.157837,238.538666 397.262451,237.066483 397.427277,235.152039 
	C399.450317,235.404556 401.413147,236.099304 403.714661,236.883484 
	C404.265533,237.809311 404.477692,238.645721 404.847778,239.729492 
	C405.005707,239.976852 405.089447,239.988174 404.637970,240.027237 
	C401.774323,240.030273 399.362122,239.994247 396.981232,239.960663 
	C397.012512,239.963120 397.053253,240.010864 397.053253,240.010864 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M468.607117,216.227692 
	C468.276703,215.233154 468.114960,214.158752 467.920349,212.677551 
	C468.254089,213.276733 468.620728,214.282700 469.047211,215.632370 
	C469.107056,215.976074 468.775757,216.147827 468.607117,216.227692 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M275.720093,662.472290 
	C275.621918,662.640442 275.389893,662.840332 275.006531,663.217041 
	C275.083435,663.079285 275.327148,662.782715 275.720093,662.472290 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M361.760986,510.934204 
	C362.095612,511.104156 362.163055,511.297638 362.050293,511.740173 
	C361.652222,511.857147 361.454407,511.699982 361.153687,511.372986 
	C361.185089,511.138153 361.339447,511.047943 361.760986,510.934204 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M247.954346,689.287476 
	C248.179657,689.181152 248.350800,689.305725 248.607513,689.562195 
	C248.706238,689.675964 248.317719,689.857300 248.106659,689.895264 
	C247.895599,689.933289 247.913391,689.500305 247.954346,689.287476 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M219.738525,719.262329 
	C219.802612,719.445984 219.643036,719.607422 219.332596,719.857117 
	C219.130630,719.641663 219.241669,719.406616 219.738525,719.262329 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M350.267700,304.374451 
	C350.163940,304.353729 350.495605,304.197754 350.565796,304.157104 
	C350.635956,304.116425 350.371490,304.395172 350.267700,304.374451 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M489.734070,683.553589 
	C488.025299,683.424500 486.316559,683.295349 484.140442,683.083740 
	C483.673065,683.001282 483.552368,682.760010 483.400574,682.328613 
	C483.136688,664.463684 482.963867,647.029907 483.047913,629.597412 
	C483.053558,628.422363 484.934052,627.190979 486.057343,626.104431 
	C487.267792,624.933655 488.613312,623.902466 489.899902,622.810364 
	C488.739502,621.595703 487.593353,620.367004 486.414612,619.170410 
	C485.306458,618.045471 483.272217,617.015381 483.191681,615.837830 
	C482.852692,610.880615 482.881897,605.866882 483.281464,600.914551 
	C483.371521,599.798584 485.493896,598.047363 486.676056,598.051147 
	C487.782318,598.054688 489.441498,599.823242 489.850250,601.130005 
	C490.421234,602.955566 490.081360,605.073608 490.086578,607.066589 
	C490.093323,609.638977 489.909943,612.158203 493.759796,612.087891 
	C497.322113,612.022705 497.165802,609.647095 497.152435,607.282776 
	C497.108307,599.477600 497.024902,591.672546 497.400604,583.831909 
	C501.411102,583.479431 504.608490,583.511414 504.579132,588.544800 
	C504.574982,589.254333 506.322815,590.449585 507.351807,590.559631 
	C508.441040,590.676208 510.578430,589.862854 510.638428,589.286987 
	C511.013245,585.688660 511.244904,582.005066 510.806427,578.437927 
	C510.684052,577.442322 507.955627,576.943848 506.762909,575.847290 
	C505.807220,574.968689 504.980255,573.590637 504.781677,572.326538 
	C504.424530,570.052917 504.835999,567.651672 504.420593,565.396973 
	C504.199188,564.194946 502.845795,562.195496 502.129517,562.248901 
	C496.107391,562.697815 497.044220,558.527039 497.402496,554.958618 
	C502.913818,555.666809 504.486298,553.411865 504.233368,548.447998 
	C503.861694,541.154724 504.140533,533.827881 504.157227,526.515381 
	C504.172760,519.708069 504.181396,519.708069 511.026825,519.810120 
	C511.036926,530.336121 511.024780,540.326538 511.026733,550.316895 
	C511.027161,552.556030 511.030090,554.941284 514.197449,554.851685 
	C517.696716,554.752625 518.341492,556.778381 518.248596,559.650391 
	C518.184143,561.644592 517.827637,563.827637 518.497925,565.584351 
	C519.032104,566.984375 520.943237,568.758789 522.201904,568.727173 
	C523.437378,568.696167 525.530518,566.688049 525.661926,565.387390 
	C526.111572,560.935486 526.131470,556.388916 525.724609,551.932312 
	C525.598328,550.548950 523.756348,548.480774 522.413818,548.224548 
	C519.594604,547.686584 518.871826,546.274963 518.867371,543.799927 
	C518.857117,538.138916 518.771606,532.478088 518.728943,526.817078 
	C518.671021,519.137268 518.675903,519.137207 511.008606,518.769653 
	C510.737762,515.962524 512.437805,512.306335 508.524780,511.976166 
	C504.262177,511.616486 503.986938,509.096466 503.950012,505.529114 
	C504.107117,498.181976 504.205750,491.299408 504.702545,484.309357 
	C507.048492,484.261353 508.996216,484.320801 510.946594,484.787109 
	C510.958313,487.103882 510.967407,489.013794 510.973755,491.393311 
	C511.002289,494.019348 511.015503,496.176270 511.068176,498.332214 
	C511.228333,504.885010 511.678101,505.275726 518.806641,504.821259 
	C521.303955,504.871429 523.406311,504.903168 525.563599,505.404358 
	C525.622803,515.138123 525.619507,524.402405 525.633911,533.666687 
	C525.644775,540.656555 525.654846,540.656555 532.395264,541.535645 
	C532.593323,555.669250 532.806213,569.339661 532.581970,583.051025 
	C529.112549,583.696411 524.142212,581.577698 525.080750,588.217407 
	C520.606873,590.155945 517.962891,592.981934 518.446289,597.985962 
	C518.326965,598.002747 518.086365,598.015869 517.649658,598.002563 
	C510.919586,598.086426 510.833893,598.189514 511.115387,605.236328 
	C509.303864,606.786133 507.750824,608.113953 505.931519,609.669434 
	C507.956848,610.932678 509.554352,611.929138 511.129822,613.393555 
	C511.058472,617.641663 511.009155,621.421753 510.580994,625.348022 
	C507.269348,625.680725 504.288574,625.614685 501.423798,626.158936 
	C499.964233,626.436218 497.876068,627.548096 497.505524,628.731567 
	C495.349457,635.617188 497.355530,638.969910 504.126923,641.144165 
	C504.160126,648.061646 504.066071,654.491638 504.214813,660.915955 
	C504.331390,665.950562 504.748474,670.978210 505.254456,676.148193 
	C506.327454,676.229553 507.178009,676.171631 508.419312,676.143799 
	C509.329498,676.126709 509.848999,676.079651 510.519714,676.130859 
	C510.670990,676.229248 510.992035,676.393921 510.992035,676.393921 
	C515.055359,678.381531 519.118652,680.369141 523.483398,682.486572 
	C522.013184,682.861572 520.241577,683.106689 517.748962,683.612183 
	C515.045410,683.901245 513.062927,683.929993 511.080414,683.958679 
	C509.280823,683.861389 507.481262,683.764099 505.014282,683.737549 
	C498.601837,682.996399 498.192139,683.395020 497.955170,690.026978 
	C497.723419,690.041077 497.491669,690.055115 496.967377,689.677002 
	C496.782990,682.552856 496.570160,682.377136 489.734070,683.553589 
z"/>
<path fill="#F5A081" opacity="1.000000" stroke="none" 
	d="
M232.995697,718.226562 
	C233.343506,715.121216 232.356415,711.232300 238.300461,711.665222 
	C243.155151,711.757141 247.147797,711.888733 251.140518,711.890808 
	C296.746918,711.914062 342.353363,711.943237 387.959747,711.916016 
	C412.590149,711.901306 437.220490,711.781067 461.850861,711.708435 
	C462.639404,711.741455 463.427917,711.774414 464.608215,711.750366 
	C476.204895,711.775208 487.409882,711.847107 498.614685,711.943176 
	C504.390656,711.992676 504.985229,712.906372 502.407654,718.995483 
	C431.108582,719.167297 360.573669,719.148926 290.038757,719.094238 
	C271.076874,719.079529 252.115036,718.954285 233.153168,718.879761 
	C233.153168,718.879761 233.066772,718.439392 232.995697,718.226562 
z"/>
<path fill="#A5574A" opacity="1.000000" stroke="none" 
	d="
M233.115479,719.300903 
	C252.115036,718.954285 271.076874,719.079529 290.038757,719.094238 
	C360.573669,719.148926 431.108582,719.167297 502.054199,719.252808 
	C502.376923,719.449524 502.270203,719.577148 501.709045,719.831665 
	C500.062439,721.608704 498.851532,723.243164 497.640564,724.877563 
	C412.041351,724.891724 326.442139,724.915833 240.842941,724.885132 
	C237.278229,724.883850 232.481567,726.212097 233.115479,719.300903 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M498.296906,690.177979 
	C498.192139,683.395020 498.601837,682.996399 504.704407,683.924622 
	C504.921722,685.575439 504.781494,687.109924 504.524292,689.074463 
	C504.407318,689.504517 504.454803,689.530640 504.454803,689.530640 
	C502.516083,689.796814 500.577362,690.062927 498.296906,690.177979 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M532.875732,683.277100 
	C533.070679,685.257141 532.960327,687.109924 532.853882,689.358154 
	C526.110535,690.417725 525.935120,690.273193 525.963501,683.634888 
	C525.987976,683.214844 526.073792,683.048584 526.472900,683.017578 
	C528.571960,682.947327 530.271851,682.908020 531.971802,682.868713 
	C532.171326,682.962463 532.370911,683.056213 532.875732,683.277100 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M489.775146,684.004333 
	C496.570160,682.377136 496.782990,682.552856 496.622498,689.725464 
	C494.973083,690.200867 493.376007,690.235474 491.380493,690.343628 
	C490.593445,688.429749 490.204865,686.442444 489.775146,684.004333 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M511.044312,684.390991 
	C513.062927,683.929993 515.045410,683.901245 517.450134,683.856445 
	C517.169800,685.901550 516.955261,689.313171 515.674988,689.775879 
	C511.517059,691.278503 510.599884,688.380371 511.044312,684.390991 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M498.012939,724.819946 
	C498.851532,723.243164 500.062439,721.608704 501.624481,719.927734 
	C500.778839,721.508179 499.582092,723.135254 498.012939,724.819946 
z"/>
<path fill="#A5574A" opacity="1.000000" stroke="none" 
	d="
M547.889404,719.375000 
	C548.780579,719.252319 549.895264,719.207886 551.348206,719.202087 
	C550.495300,719.311523 549.304138,719.382324 547.889404,719.375000 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M504.457550,689.547119 
	C504.454803,689.530640 504.407318,689.504517 504.411499,689.523682 
	C504.415649,689.542786 504.460297,689.563599 504.457550,689.547119 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M552.657471,534.877075 
	C552.735107,536.448730 552.812744,538.020386 552.894836,540.014648 
	C547.614624,540.328064 546.430542,543.258179 546.857605,547.963257 
	C547.241638,552.194275 547.010437,556.481140 547.103943,561.276672 
	C547.157288,561.809937 547.106567,561.786316 546.675598,561.896851 
	C544.239929,562.139771 542.235291,562.272156 540.128296,561.942566 
	C539.976685,554.655396 539.927429,547.830139 539.923096,540.537842 
	C539.877136,530.758057 539.786316,521.445312 539.727661,511.656250 
	C539.726624,506.445312 539.693359,501.710663 539.735596,496.514404 
	C539.882690,487.355072 539.954163,478.657349 540.373779,469.806702 
	C542.741455,469.400269 544.761047,469.146790 547.228394,469.000732 
	C552.390381,469.857971 554.457153,468.447906 554.136658,463.103546 
	C553.689331,455.644714 554.191711,448.128906 554.689819,440.721344 
	C559.949646,441.542084 562.409241,439.682648 561.846924,434.559814 
	C561.542786,431.788696 561.230713,429.018494 560.990723,426.133331 
	C561.059143,426.018799 561.254028,425.836487 561.665894,425.883881 
	C566.817261,425.463715 568.808105,427.582001 568.176147,432.301514 
	C568.023499,433.441650 567.788269,434.822510 568.267029,435.739807 
	C569.106140,437.347534 570.235291,439.571014 571.660156,439.944427 
	C574.529968,440.696503 575.108276,438.311188 575.082153,435.893677 
	C575.021118,430.252625 575.003113,424.611145 575.380066,418.963196 
	C578.098389,419.286957 580.404236,419.617340 582.710205,419.947693 
	C582.745117,428.935577 582.780029,437.923462 582.321716,447.350525 
	C576.800537,447.081543 574.532837,448.994659 575.138000,454.295715 
	C575.391541,456.516663 574.774963,458.836975 574.254150,461.349792 
	C570.929077,463.272247 567.139526,464.371338 568.156372,469.153656 
	C568.085388,471.221252 568.014404,473.288818 567.577515,475.919678 
	C562.749634,476.846039 559.775940,478.368805 561.541199,484.041779 
	C561.365967,486.297028 561.276184,488.161163 561.195679,490.215210 
	C561.204956,490.405090 561.348206,490.759216 561.251221,490.889709 
	C561.154175,491.020172 560.883301,491.200043 560.461548,491.133728 
	C555.504395,490.225586 553.767761,491.998474 553.933411,496.683350 
	C554.225342,504.940796 554.147156,513.217712 553.952515,521.480042 
	C553.917969,522.945374 552.674927,525.560059 551.805176,525.640259 
	C545.273254,526.242615 546.829712,530.174927 547.529358,533.744934 
	C549.550110,534.191040 551.103760,534.534058 552.657471,534.877075 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M510.368469,676.032532 
	C509.848999,676.079651 509.329498,676.126709 508.049774,676.087585 
	C506.537018,676.003906 505.784485,676.006409 505.031982,676.008911 
	C504.748474,670.978210 504.331390,665.950562 504.214813,660.915955 
	C504.066071,654.491638 504.160126,648.061646 504.190063,640.751465 
	C503.266907,634.489624 506.370209,633.271912 510.988647,633.704468 
	C511.284912,634.152527 511.411072,634.326599 511.537231,634.911621 
	C511.687866,641.884155 511.838501,648.445679 511.989166,655.007263 
	C511.887665,658.705139 511.786133,662.402954 511.662109,666.959839 
	C511.739807,670.244934 511.840027,672.670898 511.940216,675.096924 
	C511.940216,675.096924 511.597443,675.320007 511.251129,675.453979 
	C510.675964,675.675659 510.497192,675.823914 510.368469,676.032532 
z"/>
<path fill="#99B66B" opacity="1.000000" stroke="none" 
	d="
M547.106567,561.786316 
	C547.106567,561.786316 547.157288,561.809937 547.170959,561.789917 
	C549.990417,562.427856 554.657349,560.324585 553.945007,566.179993 
	C553.837830,567.060852 552.725281,568.507751 552.060486,568.517395 
	C548.562012,568.568787 546.862305,570.030029 546.780273,573.599365 
	C546.763062,574.352478 545.638000,575.775208 545.170349,575.716614 
	C539.801147,575.043518 540.077820,579.104980 538.750000,582.645752 
	C536.397278,582.944275 534.670166,582.965942 532.968811,582.970886 
	C532.994568,582.954163 533.019165,583.010132 533.019165,583.010132 
	C532.806213,569.339661 532.593323,555.669250 532.404053,541.499573 
	C532.427734,541.000366 532.493286,540.966187 532.618286,541.082581 
	C532.743347,541.198975 532.986389,541.439148 532.993164,541.865112 
	C532.989807,545.925171 533.239258,549.583191 532.908752,553.187988 
	C532.411560,558.612366 533.405457,562.653809 539.949402,563.365662 
	C540.074890,566.410400 539.654297,569.262634 543.797546,569.108704 
	C547.894592,568.956543 546.595764,565.948059 546.869873,563.735962 
	C546.950378,563.086304 547.027771,562.436218 547.106567,561.786316 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M510.959839,625.201843 
	C511.009155,621.421753 511.058472,617.641663 511.485901,613.252197 
	C514.234985,612.422302 516.605896,612.201660 518.982239,611.985962 
	C518.987671,611.990906 518.991577,611.991455 518.944153,612.444458 
	C518.869263,615.927368 518.841797,618.957275 518.814392,621.987183 
	C518.732605,622.384583 518.650818,622.781921 518.383179,623.838867 
	C518.243591,627.657410 518.289917,630.816284 518.336243,633.975220 
	C518.336243,633.975220 518.174194,633.840576 517.673950,633.868896 
	C515.294922,634.098328 513.416077,634.299561 511.537231,634.500732 
	C511.411072,634.326599 511.284912,634.152527 511.028564,633.315369 
	C510.918884,630.168823 510.939362,627.685364 510.959839,625.201843 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M525.249512,587.998962 
	C524.142212,581.577698 529.112549,583.696411 532.581970,583.051025 
	C533.019165,583.010132 532.994568,582.954163 532.953247,583.389038 
	C532.982239,585.214661 533.052490,586.605408 533.122681,587.996155 
	C532.998962,590.805908 532.875244,593.615723 532.573486,596.842407 
	C532.395508,597.259277 532.410339,597.215881 532.410339,597.215942 
	C530.664917,597.471130 528.919495,597.726379 526.629883,598.008728 
	C526.085693,598.035828 526.038818,598.029175 526.014038,598.007019 
	C525.989258,597.984863 525.940002,597.940308 525.935669,597.499817 
	C525.704041,594.039185 525.476746,591.019104 525.249512,587.998962 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M526.073608,598.077332 
	C525.552246,602.471130 525.030884,606.864868 523.833252,611.480225 
	C521.768494,611.798340 520.380005,611.894897 518.991577,611.991455 
	C518.991577,611.991455 518.987671,611.990906 518.919922,611.606445 
	C518.512207,609.191406 518.172241,607.160889 517.938721,604.689270 
	C518.058960,602.170776 518.072632,600.093323 518.086304,598.015869 
	C518.086365,598.015869 518.326965,598.002747 518.836975,597.953613 
	C521.544678,597.916443 523.742310,597.928406 525.940002,597.940308 
	C525.940002,597.940308 525.989258,597.984863 526.015869,598.024231 
	C526.042419,598.063599 526.073608,598.077332 526.073608,598.077332 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M511.537231,634.911621 
	C513.416077,634.299561 515.294922,634.098328 517.582397,633.921021 
	C517.927124,634.430603 518.021667,635.024048 517.775513,635.385742 
	C513.888000,641.098083 512.696106,647.567200 512.182373,654.648865 
	C511.838501,648.445679 511.687866,641.884155 511.537231,634.911621 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M518.944153,612.444458 
	C520.380005,611.894897 521.768494,611.798340 523.572327,611.746521 
	C522.510559,615.145447 521.033447,618.499573 519.185303,621.920471 
	C518.841797,618.957275 518.869263,615.927368 518.944153,612.444458 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M512.209167,674.953308 
	C511.840027,672.670898 511.739807,670.244934 511.774048,667.380981 
	C517.009155,669.616394 517.139771,671.420349 512.209167,674.953308 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M518.632935,633.677246 
	C518.289917,630.816284 518.243591,627.657410 518.380615,624.144409 
	C518.685730,626.986633 518.807678,630.182922 518.632935,633.677246 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M533.431885,587.889038 
	C533.052490,586.605408 532.982239,585.214661 532.927490,583.405762 
	C534.670166,582.965942 536.397278,582.944275 538.552979,582.982971 
	C537.234680,584.622864 535.487854,586.202332 533.431885,587.889038 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M531.713257,682.663818 
	C530.271851,682.908020 528.571960,682.947327 526.454102,682.925903 
	C527.842407,682.729736 529.648560,682.594360 531.713257,682.663818 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M539.745972,682.408569 
	C539.027527,682.856384 538.347290,682.911316 537.333252,682.890991 
	C537.851135,681.392944 538.702759,679.970154 539.740845,678.299561 
	C539.879517,679.373047 539.831787,680.694397 539.745972,682.408569 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M532.410645,597.243286 
	C532.410339,597.215881 532.395508,597.259277 532.386597,597.278931 
	C532.377747,597.298645 532.410889,597.270630 532.410645,597.243286 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M526.099243,598.116455 
	C526.073608,598.077332 526.042419,598.063599 526.040649,598.046387 
	C526.038818,598.029175 526.085693,598.035828 526.100464,598.051331 
	C526.115234,598.066833 526.124878,598.155518 526.099243,598.116455 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M510.519714,676.130859 
	C510.497192,675.823914 510.675964,675.675659 511.079529,675.565186 
	C511.254211,675.542480 511.159149,675.978943 511.075592,676.186401 
	C510.992035,676.393921 510.670990,676.229248 510.519714,676.130859 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M418.977356,597.774353 
	C414.998535,598.041870 410.705383,598.877136 411.091614,592.463867 
	C411.126984,591.876892 409.454254,590.582764 408.662323,590.649963 
	C404.122864,591.035278 404.109711,588.260437 404.138947,584.995789 
	C404.225891,575.291321 404.147644,565.585449 404.272949,555.091431 
	C403.948090,551.855896 403.484192,549.409180 403.118347,546.587097 
	C403.798889,541.026001 405.555725,539.232056 409.444214,541.136719 
	C411.017334,541.907288 411.150879,545.616638 411.753540,548.353882 
	C411.686584,553.144104 411.805603,557.569153 411.845001,562.456787 
	C411.829041,569.271851 411.892700,575.624329 411.956329,581.976807 
	C421.568207,583.663269 418.251526,591.164307 418.804810,597.389465 
	C418.909271,597.807068 418.977356,597.774353 418.977356,597.774353 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M461.552307,638.896912 
	C461.555542,637.884338 461.558807,636.871704 461.679901,635.013428 
	C461.891235,629.084351 461.984711,624.000916 462.504456,618.937012 
	C467.605408,618.235229 468.899841,620.392883 468.832367,624.869324 
	C468.647430,637.133972 468.874786,649.404846 468.515594,661.775269 
	C466.065674,661.912354 464.039124,661.947449 462.030060,661.557007 
	C461.761108,658.682495 461.474609,656.233398 461.188141,653.784363 
	C461.193146,653.434814 461.198151,653.085266 461.414734,652.109070 
	C461.601685,647.287231 461.576996,643.092102 461.552307,638.896912 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M411.924622,561.994202 
	C411.805603,557.569153 411.686584,553.144104 412.179871,548.391968 
	C414.518463,548.064819 416.244781,548.064819 418.363617,548.064819 
	C417.615662,553.720642 420.065582,555.920776 425.312744,555.405762 
	C425.544006,560.234375 425.754913,564.638367 425.985535,569.018311 
	C426.005249,568.994385 426.052948,568.954834 426.052948,568.954834 
	C423.790955,568.954834 421.528992,568.954834 419.828461,568.954834 
	C417.398956,566.557190 420.424164,561.296204 414.068481,562.006836 
	C412.845978,561.991333 412.385284,561.992737 411.924622,561.994202 
z"/>
<path fill="#7C7DDF" opacity="1.000000" stroke="none" 
	d="
M433.061157,590.949219 
	C430.953491,590.949219 428.845825,590.949219 425.861664,590.949219 
	C425.861664,583.817871 425.861664,576.849915 425.957306,569.418396 
	C426.052948,568.954834 426.005249,568.994385 426.427216,568.998413 
	C428.747375,569.102478 430.645599,569.202515 432.960388,569.324463 
	C432.960388,576.937378 432.960388,583.995422 432.985413,591.027222 
	C433.010437,591.001038 433.061157,590.949219 433.061157,590.949219 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M451.588623,661.435547 
	C427.531647,661.800903 403.341431,661.835510 378.705078,661.828918 
	C379.668976,661.209595 381.076447,660.134827 382.489319,660.127686 
	C404.250916,660.017395 426.013214,660.028687 447.775146,660.087646 
	C449.002808,660.090942 450.228699,660.750671 451.588623,661.435547 
z"/>
<path fill="#1B1B1A" opacity="1.000000" stroke="none" 
	d="
M390.419495,569.090942 
	C389.869995,567.008911 389.711487,564.930420 389.568939,562.406860 
	C389.723267,560.908875 389.861633,559.856079 390.267365,558.115845 
	C390.734955,553.269958 394.514252,552.238525 397.036621,550.430664 
	C397.710419,552.248230 398.323608,553.691101 398.936798,555.133911 
	C397.382568,559.499329 395.828308,563.864746 394.098663,568.584473 
	C392.885681,568.990723 391.848083,569.042664 390.419495,569.090942 
z"/>
<path fill="#7C7DDF" opacity="1.000000" stroke="none" 
	d="
M438.097900,605.187378 
	C436.635193,605.029358 435.172455,604.871399 432.991150,604.635803 
	C432.991150,600.344238 432.991150,596.113892 433.026154,591.416382 
	C433.061157,590.949219 433.010437,591.001038 433.427856,591.009155 
	C435.911499,591.005371 437.977692,590.993469 440.047852,591.375366 
	C440.114655,592.553406 440.177521,593.337708 440.180359,594.482544 
	C440.160400,595.824097 440.200439,596.805115 440.187805,598.229370 
	C440.076691,600.737549 440.018219,602.802612 439.649811,604.927490 
	C438.925873,605.054016 438.511902,605.120667 438.097900,605.187378 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M462.078186,618.917480 
	C461.984711,624.000916 461.891235,629.084351 461.674164,634.633667 
	C460.698273,633.550049 459.196838,632.035583 459.110840,630.444580 
	C458.807220,624.827698 458.988434,619.184570 459.317078,612.976807 
	C460.123169,612.383301 460.597931,612.364319 461.204590,612.535767 
	C461.336426,612.726257 461.739227,612.963135 461.769104,613.389648 
	C461.892059,615.516541 461.985138,617.217041 462.078186,618.917480 
z"/>
<path fill="#7C7DDF" opacity="1.000000" stroke="none" 
	d="
M399.357361,555.043823 
	C398.323608,553.691101 397.710419,552.248230 397.201294,550.107300 
	C399.210358,548.593567 401.115326,547.778015 403.020294,546.962402 
	C403.484192,549.409180 403.948090,551.855896 404.238434,554.626648 
	C402.635895,554.951660 401.206879,554.952759 399.357361,555.043823 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M461.072723,612.345337 
	C460.597931,612.364319 460.123169,612.383301 459.304443,612.480835 
	C457.571259,612.292236 456.182068,612.025085 455.020813,611.801819 
	C454.449738,609.539429 453.953857,607.574768 453.221771,605.299927 
	C452.985596,604.989685 452.932068,605.037415 453.384644,605.020386 
	C456.275635,605.036255 458.714020,605.069153 461.178345,605.510986 
	C461.160431,608.061707 461.116577,610.203491 461.072723,612.345337 
z"/>
<path fill="#7C7DDF" opacity="1.000000" stroke="none" 
	d="
M418.891907,597.840576 
	C420.837402,597.979797 422.800262,598.085449 425.156677,598.212341 
	C425.156677,600.420715 425.156677,602.451172 425.156677,604.757812 
	C423.151581,604.757812 421.393890,604.757812 418.882416,604.757812 
	C418.882416,602.673523 418.882416,600.616699 418.929871,598.167114 
	C418.977356,597.774353 418.909271,597.807068 418.891907,597.840576 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M453.011658,604.964966 
	C451.303192,604.957520 449.568634,604.974731 447.428528,604.987671 
	C447.022980,604.983276 447.049011,604.891663 447.042450,604.452393 
	C446.950745,601.952393 446.865631,599.891541 446.946167,597.665100 
	C447.376099,597.335876 447.648193,597.186462 448.346802,597.129272 
	C449.893402,597.134399 451.021240,597.061462 452.194092,597.049072 
	C452.239044,597.109619 452.337982,597.226501 452.472107,597.385010 
	C453.007782,597.614075 453.409302,597.684509 453.973145,598.158203 
	C453.734314,600.720093 453.333191,602.878784 452.932068,605.037415 
	C452.932068,605.037415 452.985596,604.989685 453.011658,604.964966 
z"/>
<path fill="#1B1B1A" opacity="1.000000" stroke="none" 
	d="
M411.845001,562.456787 
	C412.385284,561.992737 412.845978,561.991333 413.645081,562.062500 
	C413.986664,566.958923 414.102203,571.786560 413.932281,576.604187 
	C413.872314,578.304993 413.114899,579.981140 412.316132,581.822693 
	C411.892700,575.624329 411.829041,569.271851 411.845001,562.456787 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M461.204407,639.004272 
	C461.576996,643.092102 461.601685,647.287231 461.406433,651.847046 
	C458.198761,648.956055 458.041199,642.700562 461.204407,639.004272 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M438.149780,605.556274 
	C438.511902,605.120667 438.925873,605.054016 440.089905,604.976807 
	C442.909637,604.941345 444.979340,604.916504 447.049011,604.891663 
	C447.049011,604.891663 447.022980,604.983276 446.996338,605.022705 
	C445.047882,608.840332 441.441589,609.195251 438.149780,605.556274 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M460.847046,653.954712 
	C461.474609,656.233398 461.761108,658.682495 461.726379,661.629272 
	C461.198303,662.105164 460.991486,662.083313 460.784698,662.061523 
	C460.161285,661.983154 459.537842,661.904785 458.604736,661.773865 
	C459.032013,659.189270 459.768982,656.657227 460.847046,653.954712 
z"/>
<path fill="#9F4A8F" opacity="1.000000" stroke="none" 
	d="
M461.481323,711.568726 
	C437.220490,711.781067 412.590149,711.901306 387.959747,711.916016 
	C342.353363,711.943237 296.746918,711.914062 251.140518,711.890808 
	C247.147797,711.888733 243.155151,711.757141 238.728851,711.599304 
	C240.277237,711.341187 242.259201,711.019958 244.241272,711.019287 
	C314.712616,710.994324 385.183990,710.996094 455.655365,711.018127 
	C457.474213,711.018677 459.292969,711.286133 461.481323,711.568726 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M461.307343,689.565125 
	C460.915894,687.703186 460.991791,685.963440 461.060577,683.847046 
	C462.831360,683.352966 464.609314,683.235535 467.011475,682.952881 
	C467.883423,682.627502 468.031647,682.401978 468.080475,682.110962 
	C468.080475,682.110962 468.153290,682.065552 468.250824,681.648132 
	C468.578491,677.130981 468.808624,673.031311 469.475311,668.978882 
	C471.676697,669.079163 473.441589,669.132141 475.230530,669.553772 
	C475.530365,671.329834 475.806122,672.737244 476.078156,674.584595 
	C476.080078,677.385376 476.085724,679.746155 476.093872,682.545532 
	C476.049255,685.327271 476.002197,687.670532 475.955139,690.013794 
	C471.228333,689.904968 466.501495,689.796143 461.307343,689.565125 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M476.388123,690.041260 
	C476.002197,687.670532 476.049255,685.327271 476.463318,682.712341 
	C479.070953,682.547119 481.311676,682.653564 483.552368,682.760010 
	C483.552368,682.760010 483.673065,683.001282 483.713043,683.031677 
	C483.643524,687.575256 482.631348,691.195435 476.388123,690.041260 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M469.038757,668.931580 
	C468.808624,673.031311 468.578491,677.130981 468.228668,681.611450 
	C467.727661,680.621094 467.773132,678.497620 466.896271,678.000244 
	C460.907501,674.602905 460.998352,669.013733 461.055847,662.844849 
	C461.018982,662.351440 461.006866,662.123291 460.895782,662.092407 
	C460.991486,662.083313 461.198303,662.105164 461.708862,662.054688 
	C464.039124,661.947449 466.065674,661.912354 468.510468,662.238403 
	C468.965363,664.710266 469.002075,666.820923 469.038757,668.931580 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M467.954712,682.262695 
	C468.031647,682.401978 467.883423,682.627502 467.449646,682.871582 
	C467.460510,682.784119 467.648956,682.603699 467.954712,682.262695 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M373.877808,298.393097 
	C373.885193,297.784393 373.892578,297.175659 374.200531,296.007019 
	C374.333771,293.961182 374.166443,292.475250 373.999115,290.989349 
	C373.999115,290.989349 374.056946,290.500977 374.535645,290.257050 
	C375.624969,288.893311 376.657196,287.813049 376.766693,286.646179 
	C377.087128,283.232300 377.039795,279.783936 377.136566,276.349060 
	C377.857239,276.262604 378.577942,276.176178 379.743744,276.312256 
	C380.325500,276.713776 380.489166,276.863159 380.778809,277.424072 
	C380.915466,280.570953 380.953186,283.276642 380.837036,286.211792 
	C380.772034,287.295227 380.860901,288.149200 380.984467,289.273743 
	C380.999298,289.725037 380.979401,289.905762 380.937958,290.202148 
	C380.916382,290.317810 380.917694,290.553711 380.650513,290.789337 
	C379.819000,292.037048 379.254700,293.049103 378.552765,294.278809 
	C378.491943,295.309845 378.568695,296.123199 378.751953,297.398621 
	C378.931427,318.113342 379.030396,338.365967 379.068359,358.618713 
	C379.094177,372.396484 379.024719,386.174408 379.021393,399.952271 
	C379.020752,402.662811 378.817688,405.622528 382.912354,405.398346 
	C382.873505,407.888489 382.973877,409.954620 382.653320,411.919708 
	C378.177155,411.540955 379.373169,414.675964 379.349060,416.625916 
	C379.166779,431.377533 379.128448,446.130920 379.010864,461.320679 
	C378.958130,464.060577 378.939819,466.363556 378.585876,468.780457 
	C377.326202,468.899780 376.402100,468.905212 375.478027,468.910675 
	C375.394379,466.842712 375.310730,464.774750 375.128387,462.026428 
	C375.029694,461.346100 374.947693,460.867004 374.947693,460.867004 
	C375.059509,460.354248 375.171326,459.841492 375.585175,458.641479 
	C376.420715,445.395111 376.954193,432.836029 377.487701,420.276917 
	C376.966309,420.264923 376.444916,420.252960 375.923523,420.240967 
	C375.923523,423.367645 376.152344,426.516602 375.851166,429.613983 
	C375.610138,432.093018 374.725006,434.509399 374.125763,436.953583 
	C374.043549,428.614136 373.961334,420.274719 374.328186,411.376770 
	C375.529053,406.718109 376.886047,402.624603 376.930969,398.516754 
	C377.162384,377.356628 377.061646,356.192810 377.058258,335.030304 
	C377.058044,333.769226 376.896362,332.508148 376.809845,331.247070 
	C375.925354,335.775726 376.108032,340.264069 375.835266,344.724579 
	C375.726318,346.506195 374.612579,348.226440 373.956177,349.974640 
	C373.956207,349.974640 373.791992,349.778046 373.893250,349.158875 
	C373.966034,337.521698 373.937561,326.503723 373.909058,315.485718 
	C373.770935,311.558594 373.632812,307.631439 373.347290,303.327576 
	C373.277039,302.170990 373.354218,301.391144 373.610962,299.997253 
	C373.863647,299.057037 373.892731,298.726990 373.877808,298.393097 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M361.014465,391.458832 
	C360.980591,390.989838 361.152222,390.631134 361.112305,390.436401 
	C362.871307,390.456421 364.670197,390.671173 366.835693,390.929749 
	C366.835693,409.841400 366.835693,428.540955 366.835693,447.704956 
	C365.267426,447.790894 363.719269,447.875763 361.048370,448.022125 
	C361.048370,429.052490 361.048370,410.490143 361.014465,391.458832 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M373.904541,350.442780 
	C374.612579,348.226440 375.726318,346.506195 375.835266,344.724579 
	C376.108032,340.264069 375.925354,335.775726 376.809845,331.247070 
	C376.896362,332.508148 377.058044,333.769226 377.058258,335.030304 
	C377.061646,356.192810 377.162384,377.356628 376.930969,398.516754 
	C376.886047,402.624603 375.529053,406.718109 374.472778,410.929779 
	C374.138092,402.000488 374.107910,392.959656 373.989990,383.456787 
	C373.982819,382.230927 374.063324,381.467133 374.474792,380.104492 
	C376.266571,375.267853 377.183899,371.058716 374.149139,367.058044 
	C374.050385,361.675659 373.951660,356.293304 373.904541,350.442780 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M375.400085,469.350891 
	C376.402100,468.905212 377.326202,468.899780 378.864502,468.804230 
	C379.847931,468.748108 380.217163,468.782104 380.857422,469.144073 
	C381.644958,473.265778 382.161407,477.059509 382.724365,481.202209 
	C382.688385,482.005554 382.605927,482.459900 382.227081,482.915131 
	C381.353119,482.991791 380.775574,483.067566 380.197998,483.143311 
	C378.948151,483.300995 377.698273,483.458710 375.950684,483.679199 
	C375.645782,481.321198 375.364014,479.142090 375.032837,476.563477 
	C375.096313,474.039673 375.209259,471.915405 375.400085,469.350891 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M374.071655,437.423279 
	C374.725006,434.509399 375.610138,432.093018 375.851166,429.613983 
	C376.152344,426.516602 375.923523,423.367645 375.923523,420.240967 
	C376.444916,420.252960 376.966309,420.264923 377.487701,420.276917 
	C376.954193,432.836029 376.420715,445.395111 375.797791,458.307648 
	C375.144745,451.738403 374.581116,444.815674 374.071655,437.423279 
z"/>
<path fill="#1B1B1A" opacity="1.000000" stroke="none" 
	d="
M362.598206,462.025757 
	C361.713715,464.952026 360.257599,465.655640 358.913269,462.370911 
	C358.077606,460.328949 357.675690,458.109467 357.066742,455.575012 
	C358.193024,455.060455 359.331665,454.940491 360.426239,454.825165 
	C361.058960,457.271820 361.650543,459.559296 362.598206,462.025757 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M374.607300,460.933411 
	C374.947693,460.867004 375.029694,461.346100 375.042725,461.588776 
	C373.756287,461.757690 372.456879,461.683960 371.157440,461.610229 
	C371.141846,461.515656 371.126251,461.421051 371.110657,461.326477 
	C372.162750,461.217590 373.214813,461.108704 374.607300,460.933411 
z"/>
<path fill="#1B1B1A" opacity="1.000000" stroke="none" 
	d="
M363.934753,304.382660 
	C364.444244,304.360535 364.953705,304.338379 365.895630,304.562012 
	C366.328064,304.807800 366.729126,305.036560 366.729126,305.036560 
	C366.729126,305.036560 367.136444,305.141541 367.340271,305.192871 
	C367.418762,324.876495 367.324280,344.509064 367.109772,364.140350 
	C367.092499,365.720093 366.249756,367.290863 365.181458,368.550842 
	C364.658936,365.623840 364.962830,362.997437 364.791229,360.402496 
	C364.289673,352.818573 363.544495,345.250854 363.024689,337.667908 
	C362.914001,336.052826 363.170349,334.354034 363.568909,332.772247 
	C365.786011,323.973053 366.566071,315.291962 361.955078,306.931091 
	C362.023743,306.353943 362.092438,305.776794 362.637634,304.985779 
	C363.403015,304.674500 363.676544,304.544739 363.934753,304.382660 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M361.880188,307.402100 
	C366.566071,315.291962 365.786011,323.973053 363.568909,332.772247 
	C363.170349,334.354034 362.914001,336.052826 363.024689,337.667908 
	C363.544495,345.250854 364.289673,352.818573 364.791229,360.402496 
	C364.962830,362.997437 364.658936,365.623840 364.742615,368.554443 
	C361.313507,369.368042 361.841309,366.741089 361.837616,364.677887 
	C361.803741,345.743011 361.810425,326.808044 361.880188,307.402100 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M373.748413,315.910767 
	C373.937561,326.503723 373.966034,337.521698 373.963470,349.051758 
	C373.817535,338.487854 373.702637,327.411835 373.748413,315.910767 
z"/>
<path fill="#1B1B1A" opacity="1.000000" stroke="none" 
	d="
M374.051025,367.506836 
	C377.183899,371.058716 376.266571,375.267853 374.512665,379.721313 
	C374.130737,375.943146 374.041840,371.949371 374.051025,367.506836 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M363.742188,304.297058 
	C363.676544,304.544739 363.403015,304.674500 362.894257,304.830048 
	C362.674377,304.888214 362.891876,304.480194 363.018433,304.287048 
	C363.144989,304.093903 363.549652,304.211487 363.742188,304.297058 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M383.143433,511.875977 
	C383.104279,507.532959 383.065125,503.189941 383.041199,498.375732 
	C384.027771,497.746155 384.999084,497.587738 386.723755,497.638367 
	C388.206116,498.420319 389.580200,499.010956 389.565002,499.563416 
	C389.403107,505.448975 393.132050,505.221039 397.630310,504.650330 
	C401.329834,504.685547 404.577209,504.804382 407.813416,504.665009 
	C411.052338,504.525574 412.258453,505.962738 411.688110,509.157837 
	C411.515869,509.533478 411.562378,509.778625 411.784363,510.342041 
	C411.905304,511.145325 411.987518,511.602417 411.599121,512.019653 
	C406.566040,511.979858 402.003571,511.979858 396.884033,511.979858 
	C396.884033,523.941162 396.884033,535.424622 396.884033,547.210083 
	C394.424438,547.350769 392.515442,547.460022 390.350342,547.583862 
	C390.088837,545.757446 389.884186,544.328125 389.752167,542.480469 
	C389.785736,540.086121 389.746643,538.110168 389.755768,535.726440 
	C389.771576,534.499695 389.739197,533.680664 389.775208,532.524414 
	C389.799438,531.503967 389.755249,530.820618 389.765076,529.691650 
	C389.786133,527.454956 389.753143,525.663879 389.789124,523.426025 
	C389.858093,519.448608 389.858093,515.918030 389.858093,512.251648 
	C387.043793,512.094238 385.093597,511.985107 383.143433,511.875977 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M397.173279,504.620758 
	C393.132050,505.221039 389.403107,505.448975 389.565002,499.563416 
	C389.580200,499.010956 388.206116,498.420319 387.097412,497.685974 
	C391.124176,497.489990 391.523102,495.070709 390.110168,491.316711 
	C390.000122,490.994720 389.919769,490.991028 390.253540,490.878296 
	C392.714844,490.853333 394.842346,490.941101 396.951599,491.443298 
	C397.013336,496.112061 397.093323,500.366425 397.173279,504.620758 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M379.621552,498.009888 
	C379.684662,503.363861 379.494629,508.640198 378.913391,513.873108 
	C378.723724,515.580444 377.514465,517.174438 376.398743,518.915649 
	C376.025970,519.011047 375.704132,518.952820 375.672913,518.546875 
	C375.363373,511.155365 375.085083,504.169769 374.883026,497.100098 
	C374.959259,497.016052 375.185272,497.036224 375.185272,497.036224 
	C376.528931,497.329285 377.872620,497.622345 379.621552,498.009888 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M375.433319,496.678864 
	C375.185272,497.036224 374.959259,497.016052 374.543610,497.187866 
	C372.124969,497.233307 370.121948,497.106964 368.118958,496.980591 
	C368.422424,490.724060 368.422424,490.724060 375.465515,491.150757 
	C375.824341,492.911896 375.752869,494.616699 375.433319,496.678864 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M390.002014,490.954559 
	C387.901215,490.741302 385.798584,490.568237 383.197021,490.354065 
	C382.977570,488.651245 382.756073,486.932281 382.745239,484.624634 
	C382.955902,484.036011 383.170807,483.645782 383.289429,483.527466 
	C383.634583,483.352753 383.832672,483.244324 384.243469,483.115082 
	C384.484589,483.146301 384.960876,483.048584 385.307983,483.211670 
	C391.729767,483.307983 389.400726,487.946899 389.919769,490.991028 
	C389.919769,490.991028 390.000122,490.994720 390.002014,490.954559 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M380.406952,483.324768 
	C380.775574,483.067566 381.353119,482.991791 382.401672,482.882812 
	C382.872681,482.849640 383.225494,482.904327 383.213959,483.089844 
	C383.202423,483.275360 383.170807,483.645782 383.170807,483.645782 
	C383.170807,483.645782 382.955902,484.036011 382.828979,484.220093 
	C382.006653,484.104889 381.311279,483.805573 380.406952,483.324768 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M574.553955,461.112091 
	C574.774963,458.836975 575.391541,456.516663 575.138000,454.295715 
	C574.532837,448.994659 576.800537,447.081543 582.245850,447.801880 
	C582.747131,452.239044 582.830994,456.664001 582.972534,461.553528 
	C580.768066,462.170532 578.505859,462.322998 575.819092,462.482758 
	C575.114319,462.030762 574.834106,461.571442 574.553955,461.112091 
z"/>
<path fill="#A5574A" opacity="1.000000" stroke="none" 
	d="
M561.186401,490.025330 
	C561.276184,488.161163 561.365967,486.297028 562.007568,484.113464 
	C564.392273,483.840271 566.225220,483.886505 568.058167,483.932739 
	C568.301025,486.012421 568.543884,488.092072 568.746460,490.578583 
	C568.706116,490.985413 568.579773,490.959290 568.037109,490.956726 
	C565.658752,490.904388 563.823059,490.854584 561.856934,490.670227 
	C561.563049,490.348053 561.382996,490.177917 561.186401,490.025330 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M574.254150,461.349792 
	C574.834106,461.571442 575.114319,462.030762 575.518982,462.651611 
	C575.762146,469.302765 575.762146,469.302765 568.585083,469.148926 
	C567.139526,464.371338 570.929077,463.272247 574.254150,461.349792 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M568.089600,483.501221 
	C566.225220,483.886505 564.392273,483.840271 562.093018,483.722351 
	C559.775940,478.368805 562.749634,476.846039 567.560486,476.365540 
	C567.979919,478.522003 568.050476,480.795868 568.089600,483.501221 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M389.653625,262.373413 
	C391.762634,261.542297 394.004974,261.074982 396.727600,260.507599 
	C396.790070,259.124451 396.862518,257.520477 396.897064,255.489807 
	C396.317780,252.463501 399.072601,248.919556 394.586670,247.072403 
	C394.959625,246.478058 395.417023,246.237091 396.245361,246.187271 
	C399.069916,246.560745 401.523499,246.743103 404.203308,246.858521 
	C404.716827,246.558563 404.785004,246.273148 404.668976,245.510590 
	C404.832397,243.386612 404.960938,241.687393 405.089478,239.988174 
	C405.089447,239.988174 405.005707,239.976852 405.000061,240.018707 
	C406.306244,240.147202 407.618103,240.233826 409.565369,240.448578 
	C410.725342,240.800522 411.249847,241.024338 411.800751,241.445282 
	C411.827118,241.642395 412.004761,241.998199 411.956299,242.437546 
	C412.002106,244.261261 412.096405,245.645630 412.191467,247.041214 
	C414.418152,247.041214 416.187744,247.041214 418.050537,247.465469 
	C418.374298,254.711914 418.604858,261.534119 418.769989,268.467163 
	C418.704559,268.578064 418.715668,268.835358 418.572235,268.801941 
	C418.428802,268.768494 418.193878,268.946167 417.751038,268.905304 
	C415.542664,268.908020 413.777161,268.951599 411.983215,268.623840 
	C411.830872,266.514160 411.706940,264.775848 411.665039,262.904419 
	C411.802582,262.424561 411.686188,262.158844 411.359802,261.554321 
	C411.439453,259.455780 411.557068,257.777039 411.744385,255.929260 
	C411.584900,255.543503 411.355713,255.326813 410.682404,255.042694 
	C408.338745,254.975266 406.439240,254.975266 404.591095,254.975266 
	C404.355316,257.747314 404.174835,259.869385 403.552246,261.995331 
	C398.956360,261.117218 397.378174,262.797577 397.666260,266.992493 
	C397.822388,269.266296 397.226624,271.591766 396.531494,273.891785 
	C394.176636,273.889648 392.260345,273.889648 391.261719,273.889648 
	C389.949310,276.933411 389.034851,279.054199 387.751526,281.238647 
	C385.915863,281.992889 384.449066,282.683502 382.135803,283.772705 
	C382.135803,280.515747 382.135803,278.301971 382.135803,276.088196 
	C382.135803,276.088196 382.230042,275.664795 382.407593,275.199097 
	C382.575165,274.146118 382.565155,273.558838 382.555145,272.971558 
	C383.432648,271.610168 384.310120,270.248749 385.841339,268.881470 
	C386.681519,268.844971 386.867981,268.814331 387.054443,268.783691 
	C387.482880,268.792297 387.911346,268.800934 388.930542,268.553284 
	C389.554749,267.215424 389.588165,266.133850 389.621582,265.052246 
	C389.676697,264.280579 389.731842,263.508942 389.653625,262.373413 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M381.803192,276.118225 
	C382.135803,278.301971 382.135803,280.515747 382.135803,283.772705 
	C384.449066,282.683502 385.915863,281.992889 387.926483,281.423950 
	C388.976501,282.176056 389.482697,282.806519 390.006653,283.914490 
	C390.010651,286.279205 389.996948,288.166443 389.548279,290.085541 
	C387.082947,290.166626 385.052521,290.215851 382.677521,290.245605 
	C381.875122,290.179596 381.417328,290.133057 380.959534,290.086517 
	C380.979401,289.905762 380.999298,289.725037 381.114410,289.042725 
	C381.136749,287.688232 381.063843,286.835297 380.990936,285.982361 
	C380.953186,283.276642 380.915466,280.570953 380.822998,277.223846 
	C380.768219,276.582458 380.803192,276.173950 380.803192,276.173950 
	C381.025665,276.165344 381.248138,276.156769 381.803192,276.118225 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M376.745789,276.379456 
	C377.039795,279.783936 377.087128,283.232300 376.766693,286.646179 
	C376.657196,287.813049 375.624969,288.893311 374.616272,290.024780 
	C374.128662,286.708710 373.812347,283.363708 374.064331,280.062073 
	C374.161133,278.793793 375.553192,277.624359 376.745789,276.379456 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M373.776062,291.227753 
	C374.166443,292.475250 374.333771,293.961182 374.320435,295.723816 
	C373.944153,294.489105 373.748596,292.977631 373.776062,291.227753 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M389.494324,265.330505 
	C389.588165,266.133850 389.554749,267.215424 389.240540,268.466309 
	C389.095520,267.626648 389.231293,266.617706 389.494324,265.330505 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M382.408722,273.227844 
	C382.565155,273.558838 382.575165,274.146118 382.417999,274.982422 
	C382.254669,274.649048 382.258484,274.066589 382.408722,273.227844 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M380.601562,276.153076 
	C380.803192,276.173950 380.768219,276.582458 380.724060,276.782715 
	C380.489166,276.863159 380.325500,276.713776 380.100525,276.392578 
	C380.012146,276.250427 380.399902,276.132172 380.601562,276.153076 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M366.697784,304.892365 
	C366.729126,305.036560 366.328064,304.807800 366.241333,304.733765 
	C366.356049,304.510254 366.526672,304.539734 366.697784,304.892365 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M373.715088,298.606201 
	C373.892731,298.726990 373.863647,299.057037 373.714783,299.618896 
	C373.610138,299.509491 373.581268,299.164398 373.715088,298.606201 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M386.929016,268.695007 
	C386.867981,268.814331 386.681519,268.844971 386.217896,268.816711 
	C386.199432,268.556610 386.487061,268.506104 386.929016,268.695007 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M583.044556,277.232056 
	C587.500122,277.506531 589.195007,275.486755 589.067566,271.248413 
	C588.993652,268.789978 589.298645,266.320160 589.597412,263.507019 
	C589.808167,270.968689 590.038696,278.785889 589.739563,286.582703 
	C589.682312,288.073425 587.733215,290.438782 586.313721,290.730469 
	C583.460632,291.316650 582.788147,292.716095 582.873901,295.171204 
	C582.949402,297.330902 582.590393,299.600494 583.158203,301.614288 
	C583.523010,302.908417 585.218506,303.827393 586.317505,304.914520 
	C587.485657,303.669678 588.794556,302.520905 589.745483,301.127777 
	C590.077942,300.640686 589.500488,299.555573 589.386414,298.736877 
	C588.562561,292.823975 590.064636,291.107697 596.589355,290.981873 
	C596.692749,294.259613 596.513672,297.558441 595.967651,300.795319 
	C595.729370,302.207977 594.885803,304.556641 594.067200,304.669647 
	C587.275940,305.607117 590.676758,310.742310 590.032227,314.077362 
	C589.383057,317.436340 591.377319,318.398987 594.451660,319.255676 
	C595.787781,319.627991 596.255554,323.116272 597.158386,325.624084 
	C597.175415,328.403931 597.151794,330.749023 597.058899,333.526367 
	C593.681946,333.103577 589.332458,333.741119 589.312012,328.215851 
	C589.310364,327.764313 586.533936,326.913910 585.114746,327.036285 
	C584.315186,327.105225 582.972107,328.879120 583.087463,329.718170 
	C583.270935,331.053375 584.435120,333.306213 585.242310,333.331604 
	C590.290710,333.490112 589.980042,336.794952 589.968750,340.256378 
	C589.915527,356.531616 589.940613,372.807068 589.981018,389.551453 
	C587.928650,390.289978 585.832947,390.559540 583.291809,390.876282 
	C582.846313,390.923462 582.735352,390.828827 582.806763,390.407898 
	C583.134521,383.140656 583.134521,383.140656 575.059753,382.476868 
	C575.053528,366.065674 574.971375,350.114685 574.962036,333.721008 
	C575.082336,331.221619 575.129883,329.164948 575.292542,326.796143 
	C575.613708,323.959747 575.819702,321.435455 576.442322,318.876984 
	C579.167114,318.540375 582.155334,319.387451 582.121826,315.383179 
	C582.092285,311.848694 579.502380,312.191467 577.251160,311.925812 
	C576.758667,311.867706 576.270752,311.770752 575.721558,311.250427 
	C575.659180,308.202637 575.662292,305.596069 575.651367,302.989563 
	C575.628601,297.531342 574.758972,296.900146 568.672974,298.173889 
	C563.650330,298.063995 559.100342,298.030304 554.404785,297.923828 
	C554.259155,297.851044 553.999756,297.654358 554.026550,297.207947 
	C554.042297,289.833649 554.031250,282.905762 554.014099,275.984497 
	C554.007996,275.991089 553.996216,276.004181 554.260071,275.962250 
	C554.698669,275.885681 554.873352,275.851074 555.048096,275.816437 
	C558.347839,275.830261 561.456055,275.864960 561.349792,280.647919 
	C561.332397,281.430023 563.622559,282.936554 564.788574,282.881775 
	C565.944885,282.827423 567.888550,281.310211 567.991455,280.284546 
	C568.387634,276.336304 568.065430,272.322479 568.247253,268.343903 
	C568.366821,265.725433 567.373352,262.098663 571.728760,261.930969 
	C576.326477,261.753937 575.236389,265.490967 575.299377,268.064026 
	C575.441528,273.874329 575.335876,279.690979 575.313110,285.504944 
	C575.303284,288.030548 575.577393,290.084564 578.973816,290.128113 
	C582.290649,290.170685 582.816101,288.311432 582.814941,285.684814 
	C582.813721,283.028107 582.913879,280.371307 583.044556,277.232056 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M582.779175,419.494659 
	C580.404236,419.617340 578.098389,419.286957 575.394409,418.981567 
	C574.996338,419.006500 575.035034,419.030884 575.054443,418.559814 
	C574.900208,411.284027 574.726562,404.479279 574.727783,397.362915 
	C575.004944,395.187500 575.107239,393.323730 575.687012,391.405518 
	C578.354736,391.176971 580.545044,391.002899 582.735352,390.828827 
	C582.735352,390.828827 582.846313,390.923462 582.904053,390.968079 
	C582.923889,400.355652 582.886047,409.698639 582.779175,419.494659 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M561.987366,490.804749 
	C563.823059,490.854584 565.658752,490.904388 567.972656,490.954590 
	C568.442383,492.810577 568.433838,494.666229 568.450806,497.369446 
	C568.455872,500.380859 568.435486,502.544678 568.415100,504.708527 
	C568.357483,506.872620 568.299866,509.036682 568.355835,511.646423 
	C568.334656,519.116150 568.199951,526.140259 567.949219,533.635498 
	C565.837769,534.065552 563.842407,534.024414 561.415344,533.984131 
	C560.983582,533.984985 560.999329,534.000610 561.054932,533.540649 
	C561.034729,519.120422 560.959045,505.160248 560.883301,491.200043 
	C560.883301,491.200043 561.154175,491.020172 561.407959,490.936401 
	C561.661682,490.852631 561.987366,490.804749 561.987366,490.804749 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M568.811523,504.746002 
	C568.435486,502.544678 568.455872,500.380859 568.552612,497.823975 
	C570.686401,498.236084 574.106689,498.588226 574.552307,499.929047 
	C576.042725,504.413605 572.878174,505.183472 568.811523,504.746002 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M554.930115,268.934113 
	C554.787720,269.426819 554.645264,269.919495 554.376221,271.106018 
	C554.165100,273.201294 554.080627,274.602753 553.996216,276.004181 
	C553.996216,276.004181 554.007996,275.991089 553.574219,276.018188 
	C551.085632,276.037964 549.030945,276.030640 546.977905,275.583038 
	C546.978455,273.086914 546.977234,271.031097 547.430054,269.011749 
	C550.015076,269.058044 552.146057,269.067841 554.458374,269.067871 
	C554.639587,269.058105 554.997559,268.997986 554.980835,268.981934 
	C554.964172,268.965881 554.930115,268.934113 554.930115,268.934113 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M560.984375,261.984039 
	C561.079651,265.663055 561.832214,269.833588 555.357178,268.949524 
	C554.930115,268.934113 554.964172,268.965881 554.912476,268.731140 
	C554.684021,268.016968 554.507202,267.537537 554.328003,266.646179 
	C554.295898,264.883698 554.266174,263.533142 554.658325,262.142670 
	C557.063660,262.079010 559.047119,262.055267 561.030579,262.031555 
	C561.030579,262.031555 560.999756,261.999908 560.984375,261.984039 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M554.260010,275.962250 
	C554.080627,274.602753 554.165100,273.201294 554.351440,271.490295 
	C554.628174,272.464630 554.803040,273.748535 555.013062,275.424438 
	C554.873352,275.851074 554.698669,275.885681 554.260010,275.962250 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M518.302368,219.554916 
	C520.570557,219.081467 522.827942,219.025665 525.499512,219.091522 
	C525.178040,221.434235 525.047058,224.940262 523.589050,225.626450 
	C519.779480,227.419373 518.421753,225.699677 518.302368,219.554916 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M518.445740,234.038757 
	C520.697388,233.771851 522.949097,233.504959 525.597778,233.678284 
	C525.987427,236.116730 525.980103,238.114929 525.975342,240.074554 
	C525.977844,240.035950 526.055176,240.037903 525.594543,240.048172 
	C522.927490,240.033371 520.721130,240.008316 518.530396,239.999878 
	C518.546143,240.016479 518.570435,240.055740 518.491150,239.630249 
	C518.423157,237.482758 518.434448,235.760757 518.445740,234.038757 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M532.348694,219.769104 
	C537.621826,218.613434 539.361633,219.904327 539.872253,225.377960 
	C535.850830,225.908096 531.374939,226.683075 532.348694,219.769104 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M518.083618,233.802658 
	C518.434448,235.760757 518.423157,237.482758 518.004272,239.657166 
	C511.339569,240.198151 511.339569,240.198151 511.000366,232.984085 
	C510.972321,233.001938 510.914215,233.034241 510.914215,233.034241 
	C513.183289,233.211685 515.452332,233.389114 518.083618,233.802658 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M525.972778,240.113144 
	C525.980103,238.114929 525.987427,236.116730 525.987061,233.699463 
	C530.139343,232.725632 533.572998,233.076843 532.804382,239.081146 
	C532.843628,239.442673 532.670654,239.652100 532.276001,239.839600 
	C529.911804,240.055771 527.942322,240.084457 525.972778,240.113144 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M503.989349,226.042450 
	C507.658508,226.023346 512.300293,224.612137 510.940002,231.637665 
	C509.344940,232.373032 508.168945,232.662323 506.646698,233.017136 
	C505.600037,233.068527 504.899628,233.054398 504.209045,232.596863 
	C504.157135,230.085678 504.095337,228.017929 504.034241,225.978882 
	C504.034973,226.007568 503.989349,226.042450 503.989349,226.042450 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M496.955963,218.998810 
	C499.048340,218.936691 501.140717,218.874557 503.656708,218.664093 
	C504.084351,220.715958 504.088348,222.916168 504.040863,225.579422 
	C503.989349,226.042450 504.034973,226.007568 503.643188,225.980698 
	C498.732544,225.802322 496.997559,223.177673 497.017639,218.959137 
	C496.989410,218.949539 496.955963,218.998810 496.955963,218.998810 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M506.992950,232.951630 
	C508.168945,232.662323 509.344940,232.373032 510.893982,232.079895 
	C511.260040,232.254852 511.253082,232.433624 511.080200,232.823303 
	C510.914215,233.034241 510.972321,233.001938 510.596252,232.994812 
	C509.144409,232.975662 508.068695,232.963638 506.992950,232.951630 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M561.026855,261.564148 
	C559.047119,262.055267 557.063660,262.079010 554.593018,262.092957 
	C554.105835,262.083221 553.987610,261.985199 554.010986,261.789001 
	C554.034302,261.592834 554.046204,261.197876 554.065796,260.722839 
	C554.129883,256.165955 554.174255,252.084137 554.429749,247.928482 
	C554.640869,247.854645 555.040161,247.653015 555.040161,247.653015 
	C559.785400,246.968948 561.613892,249.030823 561.076660,253.734543 
	C560.799744,256.159027 561.024841,258.640839 561.026855,261.564148 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M554.961426,247.435913 
	C555.040161,247.653015 554.640869,247.854645 554.364136,247.755676 
	C554.087402,247.656708 554.036560,247.289795 554.036560,247.289795 
	C554.060059,247.073380 554.083618,246.856949 554.159180,246.319061 
	C554.435059,246.404663 554.658875,246.811737 554.961426,247.435913 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M554.210938,242.845779 
	C554.157227,242.952850 554.120911,242.503647 554.132202,242.280106 
	C554.283203,242.266418 554.323608,242.493805 554.210938,242.845779 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M389.711090,530.137268 
	C389.755249,530.820618 389.799438,531.503967 389.512146,532.640259 
	C389.356323,534.106873 389.531952,535.120483 389.707581,536.134155 
	C389.746643,538.110168 389.785736,540.086121 389.498688,542.720825 
	C389.098022,547.194397 389.023499,551.009338 388.948944,554.824219 
	C387.231293,554.857910 385.513672,554.891602 383.371307,554.994629 
	C383.009369,540.990295 383.072144,526.916504 383.139160,512.359375 
	C385.093597,511.985107 387.043793,512.094238 389.858093,512.251648 
	C389.858093,515.918030 389.858093,519.448608 389.490906,523.555420 
	C386.988831,526.361450 386.023010,528.476929 389.711090,530.137268 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M603.514771,311.952454 
	C603.936768,312.017273 604.111084,312.377594 604.106445,312.577515 
	C604.008850,314.316895 603.915894,315.856354 603.724487,317.848907 
	C602.646790,318.233093 601.489197,318.470337 600.728210,318.026917 
	C599.427063,317.268799 598.374512,316.084259 597.217529,315.078796 
	C598.357971,314.024445 599.406067,312.832214 600.680908,311.978577 
	C601.250000,311.597534 602.274841,311.897034 603.514771,311.952454 
z"/>
<path fill="#1B1B1A" opacity="1.000000" stroke="none" 
	d="
M418.836548,228.813034 
	C418.735596,227.979263 418.634644,227.145493 418.318054,226.081024 
	C420.422150,222.875107 422.970703,223.635056 425.795929,225.688110 
	C426.011475,225.984055 425.854675,226.435822 425.588257,227.000534 
	C425.201691,229.392654 425.081512,231.220062 424.536316,233.049820 
	C422.401367,233.058807 420.691437,233.065445 419.029449,232.645981 
	C418.997131,231.084259 418.916840,229.948639 418.836548,228.813034 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M453.511108,225.669556 
	C453.955231,225.642059 454.389740,225.844315 454.583984,226.289917 
	C454.542816,228.796478 454.307373,230.857468 454.035095,233.203369 
	C453.970245,233.677887 453.942291,233.867493 453.538452,234.022461 
	C451.462341,233.731812 449.762085,233.475769 447.754578,233.137283 
	C447.237579,233.024048 447.027802,232.993256 446.818024,232.962448 
	C446.591461,228.554230 446.782623,224.505280 453.511108,225.669556 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M433.013580,239.978043 
	C434.721802,240.002930 436.429993,240.027817 438.728546,240.069580 
	C439.318878,240.086456 439.651428,240.015015 439.757690,240.444229 
	C439.919434,242.930756 439.974976,244.988083 439.674194,247.216980 
	C437.215912,247.623703 435.113892,247.858856 432.982391,247.775909 
	C432.925232,247.246796 432.897522,247.035767 432.878632,246.397430 
	C432.944092,243.969513 433.000763,241.968918 433.041504,239.953522 
	C433.025574,239.938721 433.013580,239.978043 433.013580,239.978043 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M439.651428,240.015015 
	C439.651428,240.015015 439.318878,240.086456 439.155029,240.040680 
	C439.262146,239.413239 439.814911,238.803345 439.759979,238.254288 
	C439.275360,233.414185 442.022308,232.513474 446.399902,232.980392 
	C447.027802,232.993256 447.237579,233.024048 447.610596,233.478638 
	C447.531616,235.922058 447.289429,237.941696 446.592529,240.020081 
	C443.975677,240.057556 441.813568,240.036285 439.651428,240.015015 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M432.747314,235.950104 
	C432.803284,236.742203 432.859253,237.534317 432.729004,238.844452 
	C432.365570,239.544220 432.210022,239.742935 431.641907,240.042557 
	C429.454956,240.097702 427.702332,240.068939 425.856903,239.669098 
	C425.682739,237.532257 425.601349,235.766510 425.841858,234.100952 
	C428.358276,234.784119 430.552795,235.367111 432.747314,235.950104 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M432.810455,235.564453 
	C430.552795,235.367111 428.358276,234.784119 425.682465,233.874207 
	C425.121216,233.380676 425.041260,233.214081 424.961304,233.047470 
	C425.081512,231.220062 425.201691,229.392654 425.521515,227.230148 
	C425.891602,228.690918 426.062042,230.486786 426.181976,231.750748 
	C428.688995,233.035065 430.781281,234.106918 432.810455,235.564453 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M432.076172,239.958633 
	C432.210022,239.742935 432.365570,239.544220 432.685242,239.240143 
	C432.827637,239.117798 432.982880,239.534683 432.998230,239.756363 
	C433.013580,239.978043 433.025574,239.938721 432.795837,239.984497 
	C432.566132,240.030258 432.076172,239.958633 432.076172,239.958633 
z"/>
<path fill="#7C7DDF" opacity="1.000000" stroke="none" 
	d="
M461.827362,234.102127 
	C463.955688,233.700775 466.084045,233.299423 468.623718,232.947479 
	C469.035034,232.996887 469.014984,232.966980 469.009491,233.424713 
	C468.864624,236.012085 468.725281,238.141739 468.177307,240.243240 
	C465.837769,240.146591 463.906830,240.078110 461.874451,239.606369 
	C461.728760,237.818741 461.684540,236.434341 461.731598,234.821793 
	C461.822876,234.593628 461.827362,234.102127 461.827362,234.102127 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M468.964569,226.565842 
	C470.996124,226.136688 472.993774,226.133240 475.436035,226.632996 
	C475.918274,229.098770 475.955841,231.061340 475.557678,233.002792 
	C473.086304,232.976761 471.050629,232.971878 469.014984,232.966980 
	C469.014984,232.966980 469.035034,232.996887 469.048523,232.978989 
	C469.018250,230.971237 468.974426,228.981400 468.964569,226.565842 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M454.071960,232.918427 
	C454.307373,230.857468 454.542816,228.796478 454.817261,226.344727 
	C461.281677,226.162567 461.390472,226.285263 460.937195,233.477951 
	C460.823975,234.016876 461.040375,234.141190 460.672241,233.984955 
	C458.226746,233.525299 456.149353,233.221863 454.071960,232.918427 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M461.596497,234.038239 
	C461.827362,234.102127 461.822876,234.593628 461.574432,234.715469 
	C461.165222,234.632172 461.070007,234.400131 461.040375,234.141190 
	C461.040375,234.141190 460.823975,234.016876 460.874329,233.902008 
	C460.924713,233.787155 461.365662,233.974350 461.596497,234.038239 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M483.441467,218.647568 
	C482.981049,216.709137 482.949951,214.869110 482.920227,212.637787 
	C485.176361,212.216522 487.431091,212.186554 490.214417,212.515442 
	C490.507568,214.913742 490.272156,216.953217 490.036713,218.992676 
	C487.981384,218.910446 485.926086,218.828201 483.441467,218.647568 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M490.021057,218.969666 
	C490.272156,216.953217 490.507568,214.913742 490.699951,212.548401 
	C491.789398,212.227722 492.921844,212.232925 494.394806,212.227661 
	C495.475647,214.212830 496.216003,216.208481 496.956177,218.601471 
	C496.955963,218.998810 496.989410,218.949539 496.574524,218.941681 
	C494.091461,218.946304 492.023254,218.958786 489.955048,218.971252 
	C489.955048,218.971268 490.005402,218.946655 490.021057,218.969666 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M367.772125,496.887451 
	C370.121948,497.106964 372.124969,497.233307 374.467377,497.271912 
	C375.085083,504.169769 375.363373,511.155365 375.510010,518.532471 
	C373.316895,518.670593 371.255402,518.417175 368.953857,517.938721 
	C368.713745,515.813049 368.713745,513.912476 368.713745,511.493561 
	C366.475555,511.493561 364.564362,511.493561 362.287964,511.252380 
	C361.919037,505.997803 361.915344,500.984436 361.978821,495.513611 
	C363.839081,495.635590 365.632202,496.214935 367.772125,496.887451 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M525.975342,240.074554 
	C527.942322,240.084457 529.911804,240.055771 532.276123,239.975403 
	C532.709778,242.596558 532.748596,245.269379 532.692383,248.642151 
	C532.595520,249.906097 532.593811,250.470093 532.523804,251.454895 
	C532.651062,254.923950 532.846558,257.972168 533.020691,261.273071 
	C533.009766,261.694519 533.020142,261.863251 532.595337,262.030426 
	C531.665894,262.037201 531.098816,261.890015 530.690247,262.081360 
	C529.115356,262.819000 526.743469,263.309998 526.226501,264.545013 
	C525.186096,267.030609 526.537903,268.753357 529.477478,269.313812 
	C533.310242,270.044586 532.264038,273.106018 531.625854,275.770569 
	C529.330139,276.316223 527.450500,276.763306 525.217407,277.185059 
	C522.771423,276.796997 520.679016,276.434235 518.594543,275.679169 
	C518.551086,269.480835 518.499573,263.674835 518.485229,257.307983 
	C518.467163,256.000671 518.411865,255.254135 518.394165,254.502426 
	C518.431763,254.497253 518.356323,254.488251 518.423096,254.026978 
	C518.549561,251.714737 518.609253,249.863754 518.822998,247.906830 
	C518.976990,247.800903 519.197510,247.498993 519.600647,247.498505 
	C521.565552,247.498032 523.127319,247.498032 524.774170,247.498032 
	C525.246399,244.747879 525.650818,242.392883 526.055176,240.037903 
	C526.055176,240.037903 525.977844,240.035950 525.975342,240.074554 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M539.678833,248.840820 
	C541.840088,248.251663 544.001282,247.662491 546.559448,247.015717 
	C546.956482,246.958115 546.984314,246.985458 546.993042,247.242645 
	C547.001709,247.499847 547.049316,247.984207 547.046448,248.450424 
	C547.006592,253.301529 546.969666,257.686401 546.946655,262.056671 
	C546.960632,262.042053 546.986084,262.012177 546.799561,261.987488 
	C546.612976,261.962769 546.236511,261.958466 545.788940,261.947937 
	C543.554565,261.965393 541.767700,261.993347 539.979736,261.586182 
	C539.914917,259.139618 539.851196,257.128204 539.839600,254.671875 
	C539.820801,252.431580 539.749817,250.636200 539.678833,248.840820 
z"/>
<path fill="#9F4A8F" opacity="1.000000" stroke="none" 
	d="
M539.413696,248.995117 
	C539.749817,250.636200 539.820801,252.431580 539.690552,254.998535 
	C539.314575,257.757751 539.139771,259.745392 538.554810,261.790955 
	C536.768738,261.763977 535.392822,261.679047 533.863525,261.482788 
	C533.498962,261.232697 533.276184,261.115662 533.041992,261.020386 
	C532.846558,257.972168 532.651062,254.923950 532.688232,251.420700 
	C532.920837,250.965668 533.251404,250.893539 533.251404,250.893555 
	C535.217102,250.312164 537.182800,249.730789 539.413696,248.995117 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M533.200317,250.545853 
	C533.251404,250.893539 532.920837,250.965668 532.756470,250.999878 
	C532.593811,250.470093 532.595520,249.906097 532.720703,249.082520 
	C532.945862,249.281342 533.047546,249.739746 533.200317,250.545853 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M560.461548,491.133728 
	C560.959045,505.160248 561.034729,519.120422 561.062378,533.548584 
	C558.455383,534.302917 555.896545,534.589233 552.997559,534.876282 
	C551.103760,534.534058 549.550110,534.191040 547.529358,533.744934 
	C546.829712,530.174927 545.273254,526.242615 551.805176,525.640259 
	C552.674927,525.560059 553.917969,522.945374 553.952515,521.480042 
	C554.147156,513.217712 554.225342,504.940796 553.933411,496.683350 
	C553.767761,491.998474 555.504395,490.225586 560.461548,491.133728 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M489.983734,219.411285 
	C492.023254,218.958786 494.091461,218.946304 496.602783,218.951294 
	C496.997559,223.177673 498.732544,225.802322 503.642456,225.951996 
	C504.095337,228.017929 504.157135,230.085678 504.154602,232.601379 
	C504.090332,233.049332 503.982361,233.047150 503.528625,233.037659 
	C500.947388,233.070953 498.819916,233.113754 496.283081,233.121216 
	C493.788971,233.404541 491.704193,233.723190 489.362183,234.031036 
	C488.764984,233.979813 488.425018,233.939392 487.833252,233.680450 
	C485.391785,231.118271 483.202148,228.774597 481.012543,226.430939 
	C481.983215,226.333130 482.981018,226.039948 483.920074,226.170166 
	C488.908569,226.861877 490.847870,224.721054 489.983734,219.411285 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M480.588928,226.351349 
	C483.202148,228.774597 485.391785,231.118271 487.364380,233.731598 
	C483.404968,233.927155 479.662567,233.853073 475.931396,233.589539 
	C475.942627,233.400085 475.993408,233.023926 475.993408,233.023926 
	C475.955841,231.061340 475.918274,229.098770 475.854797,226.718048 
	C477.274384,226.290512 478.719849,226.281128 480.588928,226.351349 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M418.687805,229.180267 
	C418.916840,229.948639 418.997131,231.084259 419.037598,233.078461 
	C418.999054,235.958130 419.000336,237.979218 418.666321,240.079025 
	C417.874847,240.210068 417.418701,240.262375 416.572205,240.268433 
	C415.135529,240.238663 414.089142,240.255112 412.782379,240.219452 
	C412.344971,240.163727 412.167969,240.160126 411.823456,240.037292 
	C411.655945,239.918060 411.276428,239.759659 411.276428,239.759659 
	C411.445862,237.795334 411.615295,235.830994 411.858063,233.450439 
	C414.533325,232.628876 418.475464,234.763321 418.687805,229.180267 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M546.932739,262.071289 
	C546.969666,257.686401 547.006592,253.301529 547.250977,248.348419 
	C547.727905,247.632812 547.980408,247.460526 548.216003,247.263306 
	C549.876892,247.276581 551.537781,247.289871 553.617615,247.296478 
	C554.036560,247.289795 554.087402,247.656708 554.153015,247.829514 
	C554.174255,252.084137 554.129883,256.165955 553.881714,260.806793 
	C553.442566,261.499847 553.223267,261.656555 552.586487,261.841492 
	C550.412720,261.921814 548.672729,261.996552 546.932739,262.071289 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M547.917480,247.188110 
	C547.980408,247.460526 547.727905,247.632812 547.253845,247.882202 
	C547.049316,247.984207 547.001709,247.499847 547.006531,247.256561 
	C547.213867,247.046509 547.416382,247.079712 547.917480,247.188110 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M395.874390,245.996124 
	C395.417023,246.237091 394.959625,246.478058 394.279053,246.848099 
	C392.924591,247.012589 391.793243,247.047974 390.239258,247.044983 
	C389.802734,244.967941 389.788879,242.929306 389.868347,240.462708 
	C392.030090,240.051926 394.098572,240.069077 396.610168,240.048553 
	C397.053253,240.010864 397.012512,239.963120 396.971405,240.353302 
	C396.578339,242.494354 396.226349,244.245239 395.874390,245.996124 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M411.774384,241.248169 
	C411.249847,241.024338 410.725342,240.800522 409.960754,240.420837 
	C410.060944,240.132233 410.401245,239.999466 411.008972,239.813187 
	C411.276428,239.759659 411.655945,239.918060 411.784912,240.313232 
	C411.867401,240.888321 411.820892,241.068253 411.774384,241.248169 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M389.165375,555.094604 
	C389.023499,551.009338 389.098022,547.194397 389.426056,543.139099 
	C389.884186,544.328125 390.088837,545.757446 390.350342,547.583862 
	C392.515442,547.460022 394.424438,547.350769 396.884033,547.210083 
	C396.884033,535.424622 396.884033,523.941162 396.884033,511.979858 
	C402.003571,511.979858 406.566040,511.979858 411.571106,511.993713 
	C412.013763,512.007568 411.958557,511.954773 411.836365,512.355957 
	C411.714172,514.721191 411.714172,516.685242 411.714172,519.092468 
	C416.427032,519.092468 421.201965,519.092468 425.986328,519.059937 
	C425.995728,519.027344 425.928253,519.024170 425.880524,519.461304 
	C425.910126,521.952820 425.987457,524.007202 425.629578,526.108582 
	C418.533844,526.064270 418.256042,526.422546 419.845490,533.704285 
	C421.791595,533.454407 423.781555,533.198853 425.958435,532.919373 
	C425.958435,530.230042 425.958435,528.095459 426.380005,525.974976 
	C428.491943,525.974548 430.182312,525.959961 432.009583,526.245239 
	C435.718628,529.146057 436.329193,526.198303 436.710876,523.839844 
	C436.956451,522.322510 436.279449,520.655884 436.341675,519.023315 
	C437.100952,519.018433 437.534576,519.046326 437.973846,519.487671 
	C438.188446,521.376831 438.395966,522.852661 438.606720,524.328125 
	C439.237823,528.746338 442.581757,527.934326 445.520996,527.922241 
	C448.956757,527.908081 449.001923,525.475891 448.958496,523.051208 
	C448.879120,518.615967 448.038513,513.813171 454.498505,512.934265 
	C454.803101,512.892822 455.008423,512.121704 455.605377,511.625763 
	C457.966248,511.721191 459.981720,511.881226 462.007751,512.476807 
	C462.018311,514.539307 462.018311,516.166260 462.018311,518.410461 
	C464.257874,518.410461 466.036407,518.410461 467.846741,518.831665 
	C468.030670,521.525024 468.182739,523.797302 467.887817,526.093750 
	C462.381256,524.923218 461.847351,528.592346 460.820251,532.121948 
	C460.019653,532.752686 459.491730,533.286011 458.576385,533.851624 
	C457.153687,533.949280 456.118439,534.014648 454.807892,534.054443 
	C454.348389,534.025024 454.164124,534.021240 453.544434,533.986816 
	C451.074677,534.028687 449.040375,534.101135 446.576721,534.063477 
	C444.274353,533.953308 442.401276,533.953308 440.391785,533.953308 
	C440.250488,536.822205 440.146118,538.941345 439.601562,541.007690 
	C437.100616,540.997375 435.039764,541.039795 432.886841,540.662109 
	C432.794708,538.193481 432.794708,536.144897 432.794708,534.002197 
	C430.239227,534.002197 428.326324,534.002197 425.961456,534.002197 
	C426.069244,536.298950 426.163574,538.308716 426.262787,540.422791 
	C428.875275,540.640076 430.974091,540.814575 433.054413,541.421753 
	C433.035919,543.655334 433.035919,545.456360 433.035919,546.701782 
	C435.026245,548.012451 437.583435,548.834595 437.695862,549.911865 
	C438.367981,556.353333 442.656708,554.743286 446.676819,554.785522 
	C446.832458,557.139832 446.815308,559.045227 446.474548,561.155151 
	C445.772552,563.070557 445.116150,564.773560 445.066925,566.493835 
	C444.906738,572.093872 444.968445,577.700256 444.614868,583.403442 
	C440.910217,582.890076 439.495667,584.201294 440.034760,587.663940 
	C440.205017,588.757446 439.988647,589.911194 439.979034,591.033936 
	C440.008881,591.030518 440.043884,590.981628 440.043884,590.981628 
	C437.977692,590.993469 435.911499,591.005371 433.402832,591.035400 
	C432.960388,583.995422 432.960388,576.937378 432.960388,569.324463 
	C430.645599,569.202515 428.747375,569.102478 426.407532,569.022400 
	C425.754913,564.638367 425.544006,560.234375 425.312744,555.405762 
	C420.065582,555.920776 417.615662,553.720642 418.363617,548.064819 
	C416.244781,548.064819 414.518463,548.064819 412.365845,548.026733 
	C411.150879,545.616638 411.017334,541.907288 409.444214,541.136719 
	C405.555725,539.232056 403.798889,541.026001 403.118347,546.587097 
	C401.115326,547.778015 399.210358,548.593567 397.140686,549.732544 
	C394.514252,552.238525 390.734955,553.269958 390.330597,557.721375 
	C389.878235,557.131226 389.630035,556.248108 389.165375,555.094604 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M396.245361,246.187256 
	C396.226349,244.245239 396.578339,242.494354 396.940125,240.350845 
	C399.362122,239.994247 401.774323,240.030273 404.638000,240.027237 
	C404.960938,241.687393 404.832397,243.386612 404.524811,245.649933 
	C404.192596,246.435501 404.069733,246.672623 403.977112,246.925446 
	C401.523499,246.743103 399.069916,246.560745 396.245361,246.187256 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M483.400574,682.328613 
	C481.311676,682.653564 479.070953,682.547119 476.460815,682.273804 
	C476.085724,679.746155 476.080078,677.385376 476.398590,674.366943 
	C477.108063,672.311890 477.823792,670.915771 477.829346,669.516724 
	C477.918121,647.186218 477.956390,624.855286 477.848785,602.525146 
	C477.841217,600.953430 476.603546,599.387634 475.925110,597.364380 
	C475.908295,592.257080 475.925446,587.604431 475.895081,582.952087 
	C475.850677,576.154419 475.836151,576.154541 468.439636,576.178833 
	C466.012451,576.032898 464.008392,575.944336 461.964478,575.397522 
	C461.829285,566.014343 461.733917,557.089478 462.094574,548.100464 
	C464.356476,548.035034 466.162384,548.033752 468.118469,548.389160 
	C468.654419,550.634644 469.040192,552.523438 469.354034,554.060242 
	C471.792206,555.042114 473.895447,555.889160 475.949738,557.213318 
	C475.900787,561.366638 475.900787,565.042847 475.900787,569.067261 
	C478.848907,569.067261 480.954865,569.067261 483.056183,569.496826 
	C483.117035,576.005676 483.117035,576.005676 490.017487,576.013672 
	C490.008820,575.991211 489.964355,575.972656 489.965515,576.410156 
	C488.939911,582.709595 492.482574,583.602234 497.030090,583.493652 
	C497.000580,583.617249 496.956543,583.867493 496.956543,583.867493 
	C497.024902,591.672546 497.108307,599.477600 497.152435,607.282776 
	C497.165802,609.647095 497.322113,612.022705 493.759796,612.087891 
	C489.909943,612.158203 490.093323,609.638977 490.086578,607.066589 
	C490.081360,605.073608 490.421234,602.955566 489.850250,601.130005 
	C489.441498,599.823242 487.782318,598.054688 486.676056,598.051147 
	C485.493896,598.047363 483.371521,599.798584 483.281464,600.914551 
	C482.881897,605.866882 482.852692,610.880615 483.191681,615.837830 
	C483.272217,617.015381 485.306458,618.045471 486.414612,619.170410 
	C487.593353,620.367004 488.739502,621.595703 489.899902,622.810364 
	C488.613312,623.902466 487.267792,624.933655 486.057343,626.104431 
	C484.934052,627.190979 483.053558,628.422363 483.047913,629.597412 
	C482.963867,647.029907 483.136688,664.463684 483.400574,682.328613 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M511.009705,519.274475 
	C504.181396,519.708069 504.172760,519.708069 504.157227,526.515381 
	C504.140533,533.827881 503.861694,541.154724 504.233368,548.447998 
	C504.486298,553.411865 502.913818,555.666809 497.420898,554.976929 
	C497.001282,555.003906 497.037476,555.040466 497.044952,554.597717 
	C496.992035,552.101807 496.931610,550.048645 496.896973,547.530884 
	C496.942871,543.760132 497.244598,540.418701 496.837372,537.166077 
	C496.685455,535.952515 495.054626,534.353516 493.786804,533.976257 
	C491.560120,533.313477 490.022583,534.402405 490.090515,537.111694 
	C490.173309,540.415100 490.077118,543.723022 490.019592,547.211304 
	C489.980865,547.393555 490.026367,547.763489 489.583130,547.835083 
	C486.743134,547.953552 484.346436,548.000366 481.999634,547.604187 
	C481.973633,545.068909 481.897705,542.976685 481.911499,540.480469 
	C482.285156,532.704529 482.653351,525.334656 482.827972,517.960083 
	C482.985474,511.310272 482.891968,511.308044 475.974426,510.886200 
	C475.966766,489.573517 475.987213,468.752594 476.444366,447.833496 
	C481.808197,446.750488 483.449341,448.928162 482.951782,453.674072 
	C482.660095,456.456421 482.952606,459.298492 482.958252,462.113770 
	C482.975861,470.917572 482.864349,469.169952 489.999115,470.334961 
	C489.909332,472.761566 489.821564,474.710785 489.336548,476.612488 
	C484.334137,475.657227 482.676270,477.553131 482.934631,482.180084 
	C483.238159,487.614868 482.949127,493.080719 482.994049,498.532501 
	C483.046844,504.940247 483.082703,504.939941 489.888611,505.217194 
	C490.026520,510.994293 490.112244,516.295776 490.081024,521.596558 
	C490.065857,524.166443 490.283264,526.115112 493.726013,526.030151 
	C496.964386,525.950134 496.966980,524.017151 496.983490,521.722229 
	C497.043091,513.439758 497.157043,505.157623 497.624817,497.002014 
	C499.013489,498.670685 500.022308,500.216400 501.043762,501.753723 
	C501.985992,503.171692 502.941711,504.580719 503.891479,505.993683 
	C503.986938,509.096466 504.262177,511.616486 508.524780,511.976166 
	C512.437805,512.306335 510.737762,515.962524 511.022217,518.787598 
	C510.967377,519.252014 511.009705,519.274475 511.009705,519.274475 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M532.493286,540.966187 
	C532.493286,540.966187 532.427734,541.000366 532.418884,541.036377 
	C525.654846,540.656555 525.644775,540.656555 525.633911,533.666687 
	C525.619507,524.402405 525.622803,515.138123 525.666504,504.980469 
	C525.837280,501.762482 525.959961,499.437805 526.516113,497.129364 
	C529.723816,497.272339 532.347229,497.372711 532.380859,493.359406 
	C532.447205,485.435547 532.676758,477.513062 532.862000,469.084961 
	C532.944580,461.763947 533.004028,454.947998 533.445740,448.094055 
	C535.835815,447.859467 537.843689,447.662872 539.893250,447.537201 
	C539.934998,447.608124 540.058533,447.716919 540.008301,448.201172 
	C539.980530,455.776825 540.003113,462.868225 540.025635,469.959625 
	C539.954163,478.657349 539.882690,487.355072 539.472473,496.609253 
	C537.244507,497.476257 535.355286,497.786865 533.153564,498.148834 
	C533.153564,502.811157 533.153564,507.400299 533.089417,512.455017 
	C532.847961,522.269104 532.670654,531.617676 532.493286,540.966187 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M511.026825,519.810120 
	C511.009705,519.274475 510.967377,519.252014 510.953796,519.234070 
	C518.675903,519.137207 518.671021,519.137268 518.728943,526.817078 
	C518.771606,532.478088 518.857117,538.138916 518.867371,543.799927 
	C518.871826,546.274963 519.594604,547.686584 522.413818,548.224548 
	C523.756348,548.480774 525.598328,550.548950 525.724609,551.932312 
	C526.131470,556.388916 526.111572,560.935486 525.661926,565.387390 
	C525.530518,566.688049 523.437378,568.696167 522.201904,568.727173 
	C520.943237,568.758789 519.032104,566.984375 518.497925,565.584351 
	C517.827637,563.827637 518.184143,561.644592 518.248596,559.650391 
	C518.341492,556.778381 517.696716,554.752625 514.197449,554.851685 
	C511.030090,554.941284 511.027161,552.556030 511.026733,550.316895 
	C511.024780,540.326538 511.036926,530.336121 511.026825,519.810120 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M497.400604,583.831909 
	C496.956543,583.867493 497.000580,583.617249 497.000671,583.021423 
	C497.014221,576.270447 497.014221,576.270447 489.964355,575.972656 
	C489.964355,575.972656 490.008820,575.991211 490.010986,575.563477 
	C489.931061,572.735535 489.848969,570.335327 489.809326,567.510071 
	C489.829895,565.379456 489.807983,563.673767 489.855164,561.534424 
	C490.010925,559.052002 490.097534,557.003418 490.606537,554.934204 
	C493.031769,554.955933 495.034607,554.998230 497.037476,555.040527 
	C497.037476,555.040466 497.001282,555.003906 496.982880,554.985535 
	C497.044220,558.527039 496.107391,562.697815 502.129517,562.248901 
	C502.845795,562.195496 504.199188,564.194946 504.420593,565.396973 
	C504.835999,567.651672 504.424530,570.052917 504.781677,572.326538 
	C504.980255,573.590637 505.807220,574.968689 506.762909,575.847290 
	C507.955627,576.943848 510.684052,577.442322 510.806427,578.437927 
	C511.244904,582.005066 511.013245,585.688660 510.638428,589.286987 
	C510.578430,589.862854 508.441040,590.676208 507.351807,590.559631 
	C506.322815,590.449585 504.574982,589.254333 504.579132,588.544800 
	C504.608490,583.511414 501.411102,583.479431 497.400604,583.831909 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M510.976501,490.923706 
	C510.967407,489.013794 510.958313,487.103882 510.976166,484.283630 
	C510.991821,478.572083 510.980530,473.770782 511.411804,468.992462 
	C518.802551,469.082336 518.807373,469.072571 518.851135,462.327118 
	C518.882019,457.553741 518.930176,452.780487 519.407715,447.966248 
	C521.884399,447.959229 523.924011,447.993103 525.948364,448.493805 
	C525.906921,451.097687 525.922485,453.236237 525.846985,455.371582 
	C525.592651,462.559631 525.305969,469.746521 524.650208,476.924164 
	C519.400085,475.827911 517.746460,477.901611 518.178345,482.662903 
	C518.427551,485.410645 517.966187,488.222900 517.399292,490.971008 
	C514.974854,490.931854 512.975708,490.927795 510.976501,490.923706 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M503.950012,505.529114 
	C502.941711,504.580719 501.985992,503.171692 501.043762,501.753723 
	C500.022308,500.216400 499.013489,498.670685 497.561218,496.635071 
	C497.028839,494.369110 496.934357,492.596741 496.862732,490.407959 
	C496.843536,488.000946 496.801514,486.010315 496.756592,483.775391 
	C496.709900,483.201752 496.578644,482.912079 496.554749,482.392456 
	C497.005157,480.355896 497.260742,478.588959 497.905212,476.707092 
	C500.133606,476.186554 501.973145,475.780945 503.877686,475.868774 
	C504.063263,479.047089 504.183807,481.731964 504.304352,484.416840 
	C504.205750,491.299408 504.107117,498.181976 503.950012,505.529114 
z"/>
<path fill="#9F4A8F" opacity="1.000000" stroke="none" 
	d="
M510.580994,625.348022 
	C510.939362,627.685364 510.918884,630.168823 510.858459,633.041382 
	C506.370209,633.271912 503.266907,634.489624 504.167480,640.261536 
	C497.355530,638.969910 495.349457,635.617188 497.505524,628.731567 
	C497.876068,627.548096 499.964233,626.436218 501.423798,626.158936 
	C504.288574,625.614685 507.269348,625.680725 510.580994,625.348022 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M504.702545,484.309357 
	C504.183807,481.731964 504.063263,479.047089 503.879700,475.522247 
	C503.934021,472.923157 504.051422,471.164001 504.587769,469.320953 
	C507.011475,469.165283 509.016235,469.093536 511.008209,469.008820 
	C510.995453,468.995819 510.969238,468.969513 510.969238,468.969513 
	C510.980530,473.770782 510.991821,478.572083 510.973541,483.876801 
	C508.996216,484.320801 507.048492,484.261353 504.702545,484.309357 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M526.082703,497.113129 
	C525.959961,499.437805 525.837280,501.762482 525.611572,504.511047 
	C523.406311,504.903168 521.303955,504.871429 518.818970,504.471313 
	C518.363647,499.759399 518.290833,495.415894 518.705444,490.992981 
	C524.279602,489.879303 526.270447,492.285675 526.082703,497.113129 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M518.218079,491.072388 
	C518.290833,495.415894 518.363647,499.759399 518.424072,504.452881 
	C511.678101,505.275726 511.228333,504.885010 511.068176,498.332214 
	C511.015503,496.176270 511.002289,494.019348 510.973755,491.393311 
	C512.975708,490.927795 514.974854,490.931854 517.496643,491.003510 
	C518.019226,491.071106 518.218079,491.072388 518.218079,491.072388 
z"/>
<path fill="#9F4A8F" opacity="1.000000" stroke="none" 
	d="
M517.832214,605.130249 
	C518.172241,607.160889 518.512207,609.191406 518.914551,611.601501 
	C516.605896,612.201660 514.234985,612.422302 511.507935,612.784180 
	C509.554352,611.929138 507.956848,610.932678 505.931519,609.669434 
	C507.750824,608.113953 509.303864,606.786133 511.513245,605.234985 
	C514.057129,605.051147 515.944641,605.090698 517.832214,605.130249 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M525.935669,597.499878 
	C523.742310,597.928406 521.544678,597.916443 518.956299,597.936829 
	C517.962891,592.981934 520.606873,590.155945 525.080750,588.217407 
	C525.476746,591.019104 525.704041,594.039185 525.935669,597.499878 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M517.938721,604.689209 
	C515.944641,605.090698 514.057129,605.051147 511.771729,605.012939 
	C510.833893,598.189514 510.919586,598.086426 517.649597,598.002563 
	C518.072632,600.093323 518.058960,602.170776 517.938721,604.689209 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M505.254456,676.148193 
	C505.784485,676.006409 506.537018,676.003906 507.659058,676.057556 
	C507.178009,676.171631 506.327454,676.229553 505.254456,676.148193 
z"/>
<path fill="#99B66B" opacity="1.000000" stroke="none" 
	d="
M554.287659,440.637482 
	C554.191711,448.128906 553.689331,455.644714 554.136658,463.103546 
	C554.457153,468.447906 552.390381,469.857971 547.256165,468.546448 
	C546.795227,461.436890 546.754333,454.889099 546.817627,447.854675 
	C546.643738,442.582611 546.365723,437.797119 546.238525,432.633514 
	C546.586243,427.832916 546.783203,423.410461 547.418823,419.015015 
	C552.703186,419.947052 554.385681,417.838989 554.080994,413.085236 
	C553.796204,408.642487 553.979492,404.159332 554.143677,399.700836 
	C554.342651,394.301605 554.737854,388.909637 555.469971,383.379517 
	C557.608093,383.438873 559.324402,383.633270 561.094238,384.295654 
	C561.259338,386.956390 561.370850,389.149109 561.350769,391.709229 
	C561.492371,394.159088 561.765442,396.241577 561.840942,398.668274 
	C561.531799,403.038391 561.420410,407.064301 561.237793,411.518738 
	C561.210022,414.003693 561.253479,416.060120 561.297363,418.411865 
	C561.333557,418.900848 561.369202,419.094513 561.321533,419.694702 
	C561.243530,422.013000 561.248779,423.924744 561.254028,425.836487 
	C561.254028,425.836487 561.059143,426.018799 560.574280,426.132568 
	C555.249146,425.454773 553.619263,427.664307 554.127075,432.296906 
	C554.428833,435.049866 554.250244,437.855530 554.287659,440.637482 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M532.618347,541.082581 
	C532.670654,531.617676 532.847961,522.269104 533.501404,512.467834 
	C535.883545,512.054260 537.789490,512.093384 539.695496,512.132507 
	C539.786316,521.445312 539.877136,530.758057 539.494141,540.477234 
	C537.008911,541.068787 534.997681,541.253967 532.986389,541.439148 
	C532.986389,541.439148 532.743347,541.198975 532.618347,541.082581 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M561.665894,425.883881 
	C561.248779,423.924744 561.243530,422.013000 561.753784,419.630554 
	C566.524475,419.116852 570.779785,419.073853 575.035034,419.030884 
	C575.035034,419.030884 574.996338,419.006500 574.981995,418.988159 
	C575.003113,424.611145 575.021118,430.252625 575.082153,435.893677 
	C575.108276,438.311188 574.529968,440.696503 571.660156,439.944427 
	C570.235291,439.571014 569.106140,437.347534 568.267029,435.739807 
	C567.788269,434.822510 568.023499,433.441650 568.176147,432.301514 
	C568.808105,427.582001 566.817261,425.463715 561.665894,425.883881 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M532.993164,541.865112 
	C534.997681,541.253967 537.008911,541.068787 539.449219,540.944214 
	C539.927429,547.830139 539.976685,554.655396 540.031494,562.069153 
	C540.037109,562.657654 539.885132,562.941833 539.885132,562.941833 
	C533.405457,562.653809 532.411560,558.612366 532.908752,553.187988 
	C533.239258,549.583191 532.989807,545.925171 532.993164,541.865112 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M546.713440,448.341309 
	C546.754333,454.889099 546.795227,461.436890 546.808411,468.439026 
	C544.761047,469.146790 542.741455,469.400269 540.373779,469.806702 
	C540.003113,462.868225 539.980530,455.776825 540.371704,448.239014 
	C542.761353,447.975494 544.737427,448.158417 546.713440,448.341309 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M554.689819,440.721344 
	C554.250244,437.855530 554.428833,435.049866 554.127075,432.296906 
	C553.619263,427.664307 555.249146,425.454773 560.505859,426.247070 
	C561.230713,429.018494 561.542786,431.788696 561.846924,434.559814 
	C562.409241,439.682648 559.949646,441.542084 554.689819,440.721344 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M539.727661,511.656250 
	C537.789490,512.093384 535.883545,512.054260 533.565552,512.002258 
	C533.153564,507.400299 533.153564,502.811157 533.153564,498.148834 
	C535.355286,497.786865 537.244507,497.476257 539.396851,497.070831 
	C539.693359,501.710663 539.726624,506.445312 539.727661,511.656250 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M539.949402,563.365662 
	C539.885132,562.941833 540.037109,562.657654 540.133850,562.531067 
	C542.235291,562.272156 544.239929,562.139771 546.675598,561.896851 
	C547.027771,562.436218 546.950378,563.086304 546.869873,563.735962 
	C546.595764,565.948059 547.894592,568.956543 543.797546,569.108704 
	C539.654297,569.262634 540.074890,566.410400 539.949402,563.365662 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M561.856934,490.670227 
	C561.987366,490.804749 561.661682,490.852631 561.504944,490.805908 
	C561.348206,490.759216 561.204956,490.405090 561.195679,490.215210 
	C561.382996,490.177917 561.563049,490.348053 561.856934,490.670227 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M469.475281,668.978882 
	C469.002075,666.820923 468.965363,664.710266 468.933807,662.136414 
	C468.874786,649.404846 468.647430,637.133972 468.832367,624.869324 
	C468.899841,620.392883 467.605408,618.235229 462.504456,618.937012 
	C461.985138,617.217041 461.892059,615.516541 462.115143,613.188477 
	C467.419250,612.446106 469.991516,610.243530 469.436310,604.960815 
	C471.610138,604.914307 473.347687,604.867615 475.391724,605.128723 
	C475.720367,622.396790 475.780212,639.357300 475.738342,656.317444 
	C475.727753,660.607117 475.392517,664.895935 475.206451,669.185181 
	C473.441589,669.132141 471.676697,669.079163 469.475281,668.978882 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M452.149109,596.988525 
	C451.021240,597.061462 449.893402,597.134399 448.296570,596.779297 
	C445.996368,593.233276 443.480652,592.444641 440.240387,594.121948 
	C440.177521,593.337708 440.114655,592.553406 440.047852,591.375366 
	C440.043884,590.981628 440.008881,591.030518 440.390625,591.020935 
	C442.893951,590.335083 445.015533,589.658813 447.490479,588.949829 
	C451.225494,586.999329 454.607147,585.081482 458.381104,583.100708 
	C459.563568,582.953369 460.353699,582.868835 461.114502,582.790344 
	C461.085175,582.796326 461.063049,582.853516 461.171692,583.207764 
	C461.270081,584.296509 461.259766,585.031006 460.906219,585.921631 
	C459.304047,587.013611 458.068817,588.716003 456.782257,588.755798 
	C450.375702,588.953674 450.753174,592.604065 452.149109,596.988525 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M447.042450,604.452393 
	C444.979340,604.916504 442.909637,604.941345 440.399841,604.916931 
	C440.018219,602.802612 440.076691,600.737549 440.595520,598.268921 
	C442.964111,597.853760 444.872284,597.842224 446.780487,597.830750 
	C446.865631,599.891541 446.950745,601.952393 447.042450,604.452393 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M446.946167,597.665100 
	C444.872284,597.842224 442.964111,597.853760 440.648193,597.825684 
	C440.200439,596.805115 440.160400,595.824097 440.180359,594.482544 
	C443.480652,592.444641 445.996368,593.233276 447.877869,596.701294 
	C447.648193,597.186462 447.376099,597.335876 446.946167,597.665100 
z"/>
<path fill="#A5574A" opacity="1.000000" stroke="none" 
	d="
M469.000000,604.960754 
	C469.991516,610.243530 467.419250,612.446106 462.085266,612.761963 
	C461.739227,612.963135 461.336426,612.726257 461.204590,612.535767 
	C461.116577,610.203491 461.160431,608.061707 461.322815,605.454468 
	C461.441376,604.989014 461.751312,605.024536 462.210510,605.008789 
	C464.799164,604.997314 466.928619,605.001526 469.029144,605.013184 
	C469.000214,605.020630 469.000000,604.960754 469.000000,604.960754 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M461.751312,605.024536 
	C461.751312,605.024536 461.441376,604.989014 461.296906,605.045532 
	C458.714020,605.069153 456.275635,605.036255 453.384644,605.020386 
	C453.333191,602.878784 453.734314,600.720093 453.883301,597.952515 
	C453.200073,597.304504 452.769043,597.265503 452.337982,597.226501 
	C452.337982,597.226501 452.239044,597.109619 452.194092,597.049072 
	C450.753174,592.604065 450.375702,588.953674 456.782257,588.755798 
	C458.068817,588.716003 459.304047,587.013611 461.116699,586.226929 
	C461.495850,588.284302 461.321320,590.192444 460.721375,592.055359 
	C458.866577,592.812622 456.240112,593.579224 456.214752,594.424011 
	C456.103882,598.116455 459.149872,596.642883 461.380432,597.149841 
	C461.649994,600.005432 461.700653,602.515015 461.751312,605.024536 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M452.472107,597.385010 
	C452.769043,597.265503 453.200073,597.304504 453.720978,597.549255 
	C453.409302,597.684509 453.007782,597.614075 452.472107,597.385010 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M475.230530,669.553772 
	C475.392517,664.895935 475.727753,660.607117 475.738342,656.317444 
	C475.780212,639.357300 475.720367,622.396790 475.403870,604.716064 
	C475.108398,602.075378 475.107300,600.154968 475.314148,598.130798 
	C475.522095,598.026978 475.937866,597.819092 475.937866,597.819092 
	C476.603546,599.387634 477.841217,600.953430 477.848785,602.525146 
	C477.956390,624.855286 477.918121,647.186218 477.829346,669.516724 
	C477.823792,670.915771 477.108063,672.311890 476.402313,673.927063 
	C475.806122,672.737244 475.530365,671.329834 475.230530,669.553772 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M378.690369,294.061157 
	C379.254700,293.049103 379.819000,292.037048 380.975739,290.885742 
	C381.979553,290.932709 382.390961,291.118958 382.797913,291.751953 
	C382.793488,320.040649 382.793488,347.882568 382.793488,375.777588 
	C385.828125,375.777588 387.962067,375.777588 390.068848,376.170593 
	C391.022430,378.650665 392.003174,380.737732 392.701996,383.013947 
	C391.563934,383.500702 390.707764,383.798370 389.544952,383.853424 
	C387.350372,383.458191 385.462433,383.305511 382.991608,383.105713 
	C382.991608,390.832642 382.991608,397.949066 382.984558,405.028168 
	C382.977478,404.990845 383.051636,404.974304 383.051636,404.974304 
	C378.817688,405.622528 379.020752,402.662811 379.021393,399.952271 
	C379.024719,386.174408 379.094177,372.396484 379.068359,358.618713 
	C379.030396,338.365967 378.931427,318.113342 378.774170,297.039551 
	C378.690033,295.499298 378.690216,294.780243 378.690369,294.061157 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M379.045227,460.883759 
	C379.128448,446.130920 379.166779,431.377533 379.349060,416.625916 
	C379.373169,414.675964 378.177155,411.540955 382.610779,411.912659 
	C382.989166,412.006653 383.001709,411.921295 382.910919,412.387665 
	C382.791931,416.993866 382.799561,421.134277 382.728607,425.273376 
	C382.541534,436.185638 382.320343,447.097290 381.909973,458.255615 
	C381.074249,459.349121 380.441345,460.196167 379.612274,461.028870 
	C379.416138,461.014557 379.045197,460.883759 379.045227,460.883759 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M383.031738,412.013672 
	C382.973877,409.954620 382.873505,407.888489 382.912354,405.398346 
	C383.051636,404.974304 382.977478,404.990845 383.419952,404.964600 
	C386.196838,404.866943 388.531281,404.795563 391.308594,404.755676 
	C393.526398,404.825256 395.301270,404.863403 397.127136,405.279968 
	C397.063660,407.591614 396.949219,409.524933 396.400879,411.490967 
	C392.975159,411.753876 389.983307,411.984009 386.595825,412.151733 
	C385.134003,412.033295 384.067871,411.977295 383.001709,411.921265 
	C383.001709,411.921295 382.989166,412.006653 383.031738,412.013672 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M379.010834,461.320679 
	C379.045197,460.883759 379.416138,461.014557 379.612457,461.428497 
	C380.067993,464.166992 380.327179,466.491516 380.586365,468.816071 
	C380.217163,468.782104 379.847931,468.748108 379.200134,468.690369 
	C378.939819,466.363556 378.958130,464.060577 379.010834,461.320679 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M380.837036,286.211792 
	C381.063843,286.835297 381.136749,287.688232 381.079712,288.772156 
	C380.860901,288.149200 380.772034,287.295227 380.837036,286.211792 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M378.552765,294.278809 
	C378.690216,294.780243 378.690033,295.499298 378.667664,296.577484 
	C378.568695,296.123199 378.491943,295.309845 378.552765,294.278809 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M382.802338,291.305176 
	C382.390961,291.118958 381.979553,290.932709 381.242920,290.650085 
	C380.917694,290.553711 380.916382,290.317810 380.937958,290.202148 
	C381.417328,290.133057 381.875122,290.179596 382.632568,290.507721 
	C382.888916,290.961273 382.845642,291.133209 382.802338,291.305176 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M380.857452,469.144073 
	C380.327179,466.491516 380.067993,464.166992 379.808594,461.442810 
	C380.441345,460.196167 381.074249,459.349121 381.953156,458.643494 
	C381.773773,461.824280 383.383423,462.388550 386.311768,461.890015 
	C387.785950,461.990112 388.859161,462.036896 389.945557,462.510193 
	C389.959961,464.934143 389.961151,466.931580 389.531464,468.829407 
	C387.203796,468.845856 385.307007,468.961945 383.109680,469.096466 
	C383.109680,471.933685 383.109680,474.465759 383.036102,477.383270 
	C382.867645,478.796875 382.772766,479.825043 382.677856,480.853210 
	C382.161407,477.059509 381.644958,473.265778 380.857452,469.144073 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M382.724365,481.202209 
	C382.772766,479.825043 382.867645,478.796875 383.299011,477.472229 
	C384.102844,477.470490 384.570190,477.765259 385.026184,478.475403 
	C384.996857,480.276733 384.978851,481.662659 384.960876,483.048584 
	C384.960876,483.048584 384.484589,483.146301 384.057892,483.035522 
	C383.631195,482.924713 383.225494,482.904327 383.225494,482.904327 
	C383.225494,482.904327 382.872681,482.849640 382.698059,482.881958 
	C382.605927,482.459900 382.688385,482.005554 382.724365,481.202209 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M397.630341,504.650330 
	C397.093323,500.366425 397.013336,496.112061 397.113281,491.269104 
	C397.293152,490.680511 397.616089,490.331940 398.001556,490.394348 
	C400.233246,490.588318 402.079437,490.719849 404.179657,491.064453 
	C408.809692,490.851685 410.423309,487.925323 411.423950,483.960663 
	C414.264923,483.899994 416.663483,483.922363 419.025391,484.386383 
	C419.011871,486.881195 419.034973,488.934326 418.654205,490.998993 
	C416.224152,492.388397 413.233704,487.443237 411.547546,492.071777 
	C411.533875,492.637482 411.666534,493.141693 411.770386,494.127380 
	C411.630249,504.649170 411.630249,504.649170 418.938660,504.409180 
	C419.600677,510.156464 417.427460,512.640747 411.958557,511.954773 
	C411.958557,511.954773 412.013763,512.007568 412.041748,512.033569 
	C411.987518,511.602417 411.905304,511.145325 411.831665,510.108521 
	C411.840210,509.528809 411.770081,509.055206 411.770081,509.055206 
	C412.258453,505.962738 411.052338,504.525574 407.813416,504.665009 
	C404.577209,504.804382 401.329834,504.685547 397.630341,504.650330 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M389.765076,529.691650 
	C386.023010,528.476929 386.988831,526.361450 389.421936,524.002258 
	C389.753143,525.663879 389.786133,527.454956 389.765076,529.691650 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M389.755768,535.726440 
	C389.531952,535.120483 389.356323,534.106873 389.443756,532.977417 
	C389.739197,533.680664 389.771576,534.499695 389.755768,535.726440 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M411.688110,509.157837 
	C411.770081,509.055206 411.840210,509.528809 411.792908,509.762329 
	C411.562378,509.778625 411.515869,509.533478 411.688110,509.157837 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M385.307953,483.211670 
	C384.978851,481.662659 384.996857,480.276733 385.351990,478.437073 
	C386.124603,477.938507 386.560059,477.893707 387.332642,477.854858 
	C388.479462,477.224091 389.289185,476.587372 390.511871,476.056763 
	C393.083160,476.162842 395.241516,476.162842 397.342010,476.162842 
	C397.171539,473.315308 397.045898,471.216278 396.956146,468.743164 
	C397.635315,466.318573 398.278564,464.268127 399.342102,462.158020 
	C405.531342,460.918060 403.529144,466.368805 405.401581,468.965820 
	C407.554535,468.965820 409.808075,468.965820 411.946625,469.393982 
	C412.925537,475.695740 409.802887,477.489197 404.698395,476.615662 
	C404.343018,479.194061 404.079620,481.105072 403.821564,482.977478 
	C401.457123,483.268463 399.513794,483.507629 397.016602,483.814941 
	C397.232727,486.164490 397.424408,488.248230 397.616089,490.331940 
	C397.616089,490.331940 397.293152,490.680511 397.131500,490.854706 
	C394.842346,490.941101 392.714844,490.853333 390.253540,490.878296 
	C389.400726,487.946899 391.729767,483.307983 385.307953,483.211670 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M383.213959,483.089844 
	C383.225494,482.904327 383.631195,482.924713 383.816772,483.004272 
	C383.832672,483.244324 383.634583,483.352753 383.289429,483.527466 
	C383.170807,483.645782 383.202423,483.275360 383.213959,483.089844 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M418.835449,268.356262 
	C418.604858,261.534119 418.374298,254.711914 418.041626,247.472107 
	C417.939575,247.054504 417.929504,247.027054 418.371368,247.014847 
	C420.577301,247.036499 422.341370,247.070374 424.309814,247.126709 
	C424.514160,247.149155 424.900421,247.008240 425.188934,247.018433 
	C425.669922,247.029556 425.862366,247.030472 426.025208,247.024277 
	C425.995544,247.017151 425.983734,246.957291 425.922699,247.297272 
	C425.922974,248.092331 425.984314,248.547409 425.975311,249.371674 
	C425.930328,250.487396 425.955658,251.233917 425.890198,252.441879 
	C425.722290,256.972595 425.513824,261.045166 425.614441,265.110046 
	C425.681213,267.805115 424.617493,269.035614 421.715027,268.918701 
	C421.273804,268.806000 421.094666,268.771667 420.672791,268.618347 
	C419.898499,268.451660 419.366974,268.403961 418.835449,268.356262 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M411.583008,263.037567 
	C411.706940,264.775848 411.830872,266.514160 411.992737,268.615784 
	C412.030670,268.979156 412.051453,268.963898 411.604462,268.959290 
	C408.783447,269.062469 406.409393,269.170258 404.032104,268.821198 
	C404.017334,266.240051 404.005829,264.115753 403.994354,261.991455 
	C404.174835,259.869385 404.355316,257.747314 404.591095,254.975266 
	C406.439240,254.975266 408.338745,254.975266 410.808228,255.295105 
	C411.477020,255.776062 411.575836,255.937195 411.674683,256.098328 
	C411.557068,257.777039 411.439453,259.455780 411.361633,261.826477 
	C411.462006,262.691498 411.522522,262.864532 411.583008,263.037567 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M403.552246,261.995331 
	C404.005829,264.115753 404.017334,266.240051 404.083313,269.238220 
	C405.394135,276.279114 401.415100,276.394958 397.112366,275.788300 
	C397.047211,274.904144 397.008636,274.399017 396.970032,273.893921 
	C397.226624,271.591766 397.822388,269.266296 397.666260,266.992493 
	C397.378174,262.797577 398.956360,261.117218 403.552246,261.995331 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M396.531494,273.891785 
	C397.008636,274.399017 397.047211,274.904144 397.068420,276.216949 
	C397.059875,279.048798 397.068726,281.072906 396.705536,283.237244 
	C394.218597,283.397278 392.103729,283.417114 389.988892,283.436981 
	C389.482697,282.806519 388.976501,282.176056 388.295349,281.360321 
	C389.034851,279.054199 389.949310,276.933411 391.261719,273.889648 
	C392.260345,273.889648 394.176636,273.889648 396.531494,273.891785 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M412.021149,268.987183 
	C413.777161,268.951599 415.542664,268.908020 417.733215,269.336853 
	C418.121887,271.819305 418.085541,273.829315 417.611511,275.930603 
	C415.412537,276.014618 413.651184,276.007294 411.921997,275.616608 
	C411.899933,274.473053 411.845703,273.712921 411.767944,272.549194 
	C411.846771,271.085083 411.949127,270.024475 412.051453,268.963898 
	C412.051453,268.963898 412.030670,268.979156 412.021149,268.987183 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M413.042786,240.271576 
	C414.089142,240.255112 415.135529,240.238663 416.673645,240.482391 
	C417.617218,241.167252 418.069031,241.591965 418.578308,242.328522 
	C418.580872,243.478210 418.525970,244.316055 418.369812,245.241333 
	C418.140289,245.600677 418.199280,245.833496 418.380798,246.310364 
	C418.187195,246.738007 418.058350,246.882538 417.929504,247.027054 
	C417.929504,247.027054 417.939575,247.054504 417.948456,247.047852 
	C416.187744,247.041214 414.418152,247.041214 412.191467,247.041214 
	C412.096405,245.645630 412.002106,244.261261 412.117645,242.242157 
	C412.565887,241.162155 412.804352,240.716858 413.042786,240.271576 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M418.049194,275.839325 
	C418.085541,273.829315 418.121887,271.819305 418.176056,269.377747 
	C418.193878,268.946167 418.428802,268.768494 418.662140,268.856445 
	C418.895477,268.944397 419.055786,269.053680 419.045044,269.460571 
	C418.705902,271.858093 418.377533,273.848724 418.049194,275.839325 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M418.770020,268.467163 
	C419.366974,268.403961 419.898499,268.451660 420.374451,268.715332 
	C419.897858,268.972137 419.476837,269.012909 419.055786,269.053680 
	C419.055786,269.053680 418.895477,268.944397 418.805573,268.889893 
	C418.715668,268.835358 418.704559,268.578064 418.770020,268.467163 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M412.782379,240.219452 
	C412.804352,240.716858 412.565887,241.162155 412.166107,241.802826 
	C412.004761,241.998199 411.827118,241.642395 411.800751,241.445282 
	C411.820892,241.068253 411.867401,240.888321 411.952454,240.432465 
	C412.167969,240.160126 412.344971,240.163727 412.782379,240.219452 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M411.665009,262.904419 
	C411.522522,262.864532 411.462006,262.691498 411.399658,262.246307 
	C411.686188,262.158844 411.802582,262.424561 411.665009,262.904419 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M411.744385,255.929260 
	C411.575836,255.937195 411.477020,255.776062 411.252350,255.362518 
	C411.355713,255.326813 411.584900,255.543503 411.744385,255.929260 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M404.203308,246.858521 
	C404.069733,246.672623 404.192596,246.435501 404.489929,246.074707 
	C404.785004,246.273148 404.716827,246.558563 404.203308,246.858521 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M382.797913,291.751953 
	C382.845642,291.133209 382.888916,290.961273 382.977173,290.527161 
	C385.052521,290.215851 387.082947,290.166626 389.552734,290.054199 
	C389.992126,289.990967 389.928619,289.993530 389.986816,290.460510 
	C390.045746,319.243378 390.046509,347.559265 390.076294,375.859192 
	C390.105377,375.843231 390.096008,375.777588 390.096008,375.777588 
	C387.962067,375.777588 385.828125,375.777588 382.793488,375.777588 
	C382.793488,347.882568 382.793488,320.040649 382.797913,291.751953 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M390.006622,283.914490 
	C392.103729,283.417114 394.218597,283.397278 396.684326,283.210510 
	C397.035217,283.043579 396.991882,282.990845 396.923645,283.411438 
	C396.550629,285.732239 396.245819,287.632416 395.572510,289.598755 
	C393.445496,289.774414 391.687073,289.883972 389.928619,289.993530 
	C389.928619,289.993530 389.992126,289.990967 389.987671,290.022308 
	C389.996948,288.166443 390.010651,286.279205 390.006622,283.914490 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M574.889282,334.163696 
	C574.971375,350.114685 575.053528,366.065674 575.072510,382.487671 
	C575.009277,382.958679 574.987671,382.930206 574.537231,382.947571 
	C572.272278,383.208649 570.457703,383.452332 568.258545,383.590851 
	C565.609863,382.929047 563.345825,382.372437 561.116028,381.364655 
	C561.157166,378.760529 561.088684,376.604095 561.188599,374.455475 
	C561.336243,371.278076 560.789978,368.938965 556.778992,368.842041 
	C555.919556,368.821289 554.279846,366.778656 554.450623,365.976501 
	C554.754211,364.550842 555.967407,362.535583 557.158569,362.247131 
	C559.553894,361.667114 561.171936,361.134216 561.117676,358.278076 
	C561.026489,353.476868 561.265076,348.662140 560.973022,343.877014 
	C560.899048,342.665588 559.398376,340.599670 558.461182,340.546356 
	C554.519348,340.322266 553.924438,338.093048 554.044861,334.883057 
	C554.143982,332.240601 553.967407,329.587830 553.968140,326.506714 
	C554.272339,318.544312 554.272339,318.544312 561.376465,319.357300 
	C561.455688,321.174713 561.563416,322.570282 561.679321,324.276703 
	C562.781555,324.983002 563.875549,325.378448 565.343750,325.859192 
	C566.476868,326.057648 567.235779,326.170807 568.135315,326.413605 
	C568.275879,326.543213 568.565674,326.792694 568.480347,327.212646 
	C567.576172,332.776367 570.112000,334.584412 574.889282,334.163696 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M546.976196,276.023346 
	C549.030945,276.030640 551.085632,276.037964 553.580322,276.011566 
	C554.031250,282.905762 554.042297,289.833649 553.981140,297.289917 
	C553.908936,297.818298 554.014832,297.969421 553.630920,297.863220 
	C550.403320,297.235443 546.822571,297.815674 546.691467,292.936951 
	C546.754761,287.703308 546.756409,282.863556 546.834839,277.686279 
	C546.949402,276.888977 546.987244,276.429169 547.013306,275.983063 
	C547.001404,275.996765 546.976196,276.023346 546.976196,276.023346 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M554.550354,297.996613 
	C559.100342,298.030304 563.650330,298.063995 568.624084,298.566284 
	C568.879944,303.233612 568.712036,307.432281 568.060730,311.705292 
	C565.655090,311.851440 563.732910,311.923309 561.669739,311.849731 
	C561.528809,311.704315 561.262756,311.398865 561.352173,310.962433 
	C561.792236,304.381073 561.139526,303.865356 554.389648,304.315979 
	C554.465454,302.808167 554.546570,301.943512 554.651978,300.771179 
	C554.634216,299.641205 554.592285,298.818909 554.550354,297.996613 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M568.544128,311.630981 
	C568.712036,307.432281 568.879944,303.233612 569.096741,298.642487 
	C574.758972,296.900146 575.628601,297.531342 575.651367,302.989563 
	C575.662292,305.596069 575.659180,308.202637 575.732788,311.263611 
	C575.803223,311.718048 575.822693,311.743591 575.409790,311.702484 
	C572.845947,311.651245 570.695068,311.641113 568.544128,311.630981 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M582.806763,390.407898 
	C580.545044,391.002899 578.354736,391.176971 575.591736,391.361694 
	C575.019043,391.372375 574.888977,391.201660 574.989502,390.743652 
	C575.055908,387.833832 575.021790,385.382019 574.987671,382.930206 
	C574.987671,382.930206 575.009277,382.958679 574.996582,382.947876 
	C583.134521,383.140656 583.134521,383.140656 582.806763,390.407898 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M568.565674,326.792694 
	C568.565674,326.792694 568.275879,326.543213 568.243530,325.966095 
	C568.085693,323.589081 567.960144,321.789215 568.212585,319.825439 
	C571.077881,319.433441 573.565125,319.205322 576.024292,318.969666 
	C575.996155,318.962067 576.025757,318.911194 576.025757,318.911194 
	C575.819702,321.435455 575.613708,323.959747 574.958618,326.634521 
	C572.528259,326.787567 570.546936,326.790131 568.565674,326.792694 
z"/>
<path fill="#99B66B" opacity="1.000000" stroke="none" 
	d="
M568.480347,327.212646 
	C570.546936,326.790131 572.528259,326.787567 574.843506,326.946655 
	C575.129883,329.164948 575.082336,331.221619 574.962036,333.721008 
	C570.112000,334.584412 567.576172,332.776367 568.480347,327.212646 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M575.791992,311.704834 
	C576.270752,311.770752 576.758667,311.867706 577.251160,311.925812 
	C579.502380,312.191467 582.092285,311.848694 582.121826,315.383179 
	C582.155334,319.387451 579.167114,318.540375 576.442383,318.876984 
	C576.025757,318.911194 575.996155,318.962067 575.994385,318.522278 
	C575.936035,315.969543 575.879395,313.856567 575.822693,311.743591 
	C575.822693,311.743591 575.803223,311.718048 575.791992,311.704834 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M554.404785,297.923828 
	C554.592285,298.818909 554.634216,299.641205 554.561035,300.557922 
	C554.302185,299.758026 554.158508,298.863708 554.014832,297.969421 
	C554.014832,297.969421 553.908936,297.818298 553.954346,297.736328 
	C553.999756,297.654358 554.259155,297.851044 554.404785,297.923828 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M575.054443,418.559814 
	C570.779785,419.073853 566.524475,419.116852 561.837036,419.223999 
	C561.369202,419.094513 561.333557,418.900848 561.712952,418.405090 
	C562.786499,418.107239 563.572205,418.346832 564.079834,418.072266 
	C565.700562,417.195587 567.218079,416.128021 568.775452,415.134186 
	C567.406616,414.029816 566.131653,412.767120 564.634521,411.878906 
	C563.699280,411.324005 562.428955,411.333801 561.309021,411.090179 
	C561.420410,407.064301 561.531799,403.038391 562.293701,398.593140 
	C564.815186,398.028992 566.686218,397.884155 568.939026,397.717651 
	C571.064880,397.688812 572.808960,397.681671 574.552979,397.674530 
	C574.726562,404.479279 574.900208,411.284027 575.054443,418.559814 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M574.727783,397.362915 
	C572.808960,397.681671 571.064880,397.688812 568.881470,397.340057 
	C568.617126,395.002258 568.792175,393.020325 568.976379,391.053650 
	C568.985535,391.068878 569.016907,391.052246 569.439941,391.013000 
	C571.538330,391.049713 573.213623,391.125702 574.888977,391.201660 
	C574.888977,391.201660 575.019043,391.372375 575.114258,391.416168 
	C575.107239,393.323730 575.004944,395.187500 574.727783,397.362915 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M546.946655,262.056671 
	C548.672729,261.996552 550.412720,261.921814 552.826538,261.887024 
	C553.500305,261.927002 553.987610,261.985199 553.987610,261.985199 
	C553.987610,261.985199 554.105835,262.083221 554.171143,262.132935 
	C554.266174,263.533142 554.295898,264.883698 554.282837,266.978516 
	C554.252380,268.174438 554.264709,268.626038 554.277100,269.077637 
	C552.146057,269.067841 550.015076,269.058044 547.444153,269.025604 
	C547.004272,269.002991 547.032715,269.029510 547.012451,268.593475 
	C546.964233,266.412476 546.936279,264.667480 546.939697,262.695953 
	C546.971069,262.469391 546.986084,262.012177 546.986084,262.012177 
	C546.986084,262.012177 546.960632,262.042053 546.946655,262.056671 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M546.990112,268.989136 
	C546.977234,271.031097 546.978455,273.086914 546.977905,275.583038 
	C546.976196,276.023346 547.001404,275.996765 546.719849,276.062927 
	C546.250488,276.205475 546.062683,276.281830 545.477783,276.314117 
	C544.007751,276.286163 542.934814,276.302307 541.522339,276.124390 
	C540.783447,273.615112 540.384155,271.299927 540.425293,268.989441 
	C542.921448,269.005951 544.977112,269.017731 547.032715,269.029541 
	C547.032715,269.029510 547.004272,269.002991 546.990112,268.989136 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M554.458374,269.067871 
	C554.264709,268.626038 554.252380,268.174438 554.285156,267.390472 
	C554.507202,267.537537 554.684021,268.016968 554.929199,268.747192 
	C554.997559,268.997986 554.639587,269.058105 554.458374,269.067871 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M506.646698,233.017120 
	C508.068695,232.963638 509.144409,232.975662 510.624268,232.976959 
	C511.339569,240.198151 511.339569,240.198151 518.083496,240.082672 
	C518.570435,240.055740 518.546143,240.016479 518.622192,240.424026 
	C518.864685,243.054047 519.031067,245.276520 519.197510,247.498993 
	C519.197510,247.498993 518.976990,247.800903 518.464172,247.787689 
	C515.626221,247.605896 513.301147,247.437286 510.728027,247.176422 
	C510.314728,247.022736 510.149384,246.961304 509.987000,246.499435 
	C510.394714,241.887466 508.379669,239.912231 504.222290,239.462769 
	C504.122772,237.039505 504.052551,235.043335 503.982361,233.047150 
	C503.982361,233.047150 504.090332,233.049332 504.144775,233.044800 
	C504.899628,233.054398 505.600037,233.068527 506.646698,233.017120 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M519.600647,247.498505 
	C519.031067,245.276520 518.864685,243.054047 518.606506,240.407410 
	C520.721130,240.008316 522.927490,240.033371 525.594543,240.048172 
	C525.650818,242.392883 525.246399,244.747879 524.774170,247.498032 
	C523.127319,247.498032 521.565552,247.498032 519.600647,247.498505 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M554.010986,261.789001 
	C553.987610,261.985199 553.500305,261.927002 553.260254,261.881470 
	C553.223267,261.656555 553.442566,261.499847 553.862122,261.281860 
	C554.046204,261.197876 554.034302,261.592834 554.010986,261.789001 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M419.001617,240.000290 
	C419.000336,237.979218 418.999054,235.958130 418.989624,233.504578 
	C420.691437,233.065445 422.401367,233.058807 424.536316,233.049820 
	C425.041260,233.214081 425.121216,233.380676 425.360565,233.774017 
	C425.601349,235.766510 425.682739,237.532257 425.883026,239.647217 
	C426.001923,239.996429 426.053558,239.951935 425.610413,239.969788 
	C423.103760,239.972519 421.040253,239.957382 418.962585,239.965912 
	C418.948364,239.989578 419.001617,240.000290 419.001617,240.000290 
z"/>
<path fill="#1B1B1A" opacity="1.000000" stroke="none" 
	d="
M460.672241,233.984955 
	C461.070007,234.400131 461.165222,234.632172 461.483154,234.943634 
	C461.684540,236.434341 461.728760,237.818741 461.912018,239.603790 
	C462.051025,240.004440 462.045868,239.929291 461.613068,239.996033 
	C459.139130,240.123169 457.097961,240.183578 454.798401,240.138916 
	C454.364380,239.972534 454.188843,239.911194 454.019226,239.436752 
	C453.988220,237.368134 453.951263,235.712616 453.914307,234.057083 
	C453.942291,233.867493 453.970245,233.677887 454.035095,233.203369 
	C456.149353,233.221863 458.226746,233.525299 460.672241,233.984955 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M453.538452,234.022461 
	C453.951263,235.712616 453.988220,237.368134 453.687317,239.501541 
	C452.902313,240.035492 452.455231,240.091568 451.597778,240.080414 
	C449.802490,239.996170 448.417480,239.979141 447.022919,239.977509 
	C447.013336,239.992874 447.047241,239.961349 447.047241,239.961349 
	C447.289429,237.941696 447.531616,235.922058 447.917786,233.561066 
	C449.762085,233.475769 451.462341,233.731812 453.538452,234.022461 
z"/>
<path fill="#7C7DDF" opacity="1.000000" stroke="none" 
	d="
M433.011871,248.094025 
	C435.113892,247.858856 437.215912,247.623703 439.668396,247.190857 
	C440.018829,246.993179 439.972748,247.019836 439.991455,247.486908 
	C440.012817,254.921707 440.015533,261.889435 439.580078,268.842285 
	C433.853699,269.711426 432.175751,267.269012 432.787964,262.272125 
	C433.083160,259.862976 432.766022,257.378815 432.802246,254.551514 
	C432.848511,253.151642 432.818726,252.128845 432.855133,250.733307 
	C432.951538,249.605042 432.981720,248.849533 433.011871,248.094025 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M439.757690,240.444229 
	C441.813568,240.036285 443.975677,240.057556 446.592529,240.020081 
	C447.047241,239.961349 447.013336,239.992874 446.973694,240.219742 
	C446.934052,240.446609 446.923920,240.940872 446.915527,241.362823 
	C446.560242,245.334473 447.427643,247.781693 451.621521,248.279877 
	C452.584351,248.394241 453.306549,250.534470 454.135742,252.173325 
	C454.092621,253.173538 454.053223,253.742783 453.650574,254.319748 
	C451.685089,254.642761 450.082886,254.958054 448.273560,254.924545 
	C446.787933,250.536697 446.161987,245.798599 439.972748,247.019836 
	C439.972748,247.019836 440.018829,246.993179 440.024658,247.019287 
	C439.974976,244.988083 439.919434,242.930756 439.757690,240.444229 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M431.641907,240.042557 
	C432.076172,239.958633 432.566132,240.030258 432.811768,239.999283 
	C433.000763,241.968918 432.944092,243.969513 432.457275,246.466614 
	C430.012695,246.961151 427.998199,246.959213 425.983734,246.957291 
	C425.983734,246.957291 425.995544,247.017151 425.898193,246.596008 
	C425.885040,244.100555 425.969299,242.026245 426.053558,239.951935 
	C426.053558,239.951935 426.001923,239.996429 425.975800,240.018311 
	C427.702332,240.068939 429.454956,240.097702 431.641907,240.042557 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M425.922699,247.297272 
	C427.998199,246.959213 430.012695,246.961151 432.448486,246.893921 
	C432.897522,247.035767 432.925232,247.246796 432.982391,247.775925 
	C432.981720,248.849533 432.951538,249.605042 432.510468,250.648651 
	C430.081604,250.292007 428.063629,249.647247 426.045654,249.002502 
	C425.984314,248.547409 425.922974,248.092331 425.922699,247.297272 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M475.557678,233.002792 
	C475.993408,233.023926 475.942627,233.400085 475.901367,234.042679 
	C475.869171,236.467163 475.878204,238.249039 475.447449,240.025070 
	C472.897858,240.080765 470.788055,240.142319 468.668274,240.238647 
	C468.658264,240.273422 468.585907,240.271393 468.585907,240.271393 
	C468.725281,238.141739 468.864624,236.012085 469.009491,233.424713 
	C471.050629,232.971878 473.086304,232.976761 475.557678,233.002792 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M462.013458,240.007019 
	C463.906830,240.078110 465.837769,240.146591 468.177307,240.243240 
	C468.585907,240.271393 468.658264,240.273422 468.687805,240.617661 
	C468.682587,241.978836 468.647797,242.995789 468.193665,244.012146 
	C465.791016,244.001114 463.807709,243.990662 461.766418,243.662140 
	C461.765594,242.919174 461.822693,242.494293 461.941010,241.918518 
	C462.057831,241.430695 461.986084,241.117722 461.772614,240.581100 
	C461.758270,240.333481 462.045868,239.929291 462.045868,239.929291 
	C462.045868,239.929291 462.051025,240.004440 462.013458,240.007019 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M510.976013,247.268692 
	C513.301147,247.437286 515.626221,247.605896 518.310181,247.893631 
	C518.609253,249.863754 518.549561,251.714737 518.400635,254.007294 
	C518.311401,254.448883 518.356628,254.507599 518.356567,254.507599 
	C518.411865,255.254135 518.467163,256.000671 518.398010,257.600647 
	C517.859863,264.268860 517.446167,270.083618 516.770020,276.087128 
	C514.807129,276.214874 513.106689,276.153809 511.060486,275.959351 
	C507.413971,275.326233 504.968170,275.775604 504.052490,279.801819 
	C503.724457,281.244080 501.389221,282.161377 500.149414,283.486115 
	C499.139069,284.565674 497.661591,285.944122 497.738342,287.100616 
	C497.817017,288.286163 499.363129,289.638092 500.589539,290.393433 
	C501.445984,290.920929 502.845520,290.566589 504.022339,291.058472 
	C504.328094,295.043884 504.527496,298.363647 499.223480,297.923645 
	C498.556610,297.868317 497.165283,300.120544 497.023163,301.404724 
	C496.641296,304.854950 496.754578,308.359985 496.256897,311.851746 
	C493.790680,312.825439 491.738861,313.790710 489.577515,315.011688 
	C488.713135,316.427277 488.147919,318.374359 487.170349,318.609833 
	C483.081085,319.594849 482.684448,322.341858 482.994141,326.190887 
	C482.862732,340.743195 482.749329,354.811249 482.737213,368.879395 
	C482.731537,375.462952 482.820251,375.463043 489.719452,376.288696 
	C489.901794,378.830139 490.037140,380.923889 490.119873,383.482513 
	C490.015900,389.394592 490.171265,394.857178 489.768494,400.278290 
	C489.667084,401.643494 487.998047,403.622559 486.666016,404.013702 
	C483.909271,404.823120 483.009155,402.839355 482.995270,400.275177 
	C482.965759,394.829407 482.825684,389.384216 482.695679,383.763977 
	C482.528748,383.359131 482.330383,383.247589 481.639679,383.208801 
	C478.088837,383.351440 475.537811,382.803894 475.733337,378.419800 
	C475.764679,376.064331 475.722443,374.119110 475.734100,371.698059 
	C475.817169,365.774170 475.846375,360.326202 475.858398,354.522644 
	C475.781006,349.677429 471.848267,349.625580 468.987396,347.816895 
	C468.110504,344.876678 470.304443,340.721924 465.771179,340.305420 
	C462.057495,339.964233 461.583984,337.994659 461.685577,335.082245 
	C461.788239,332.139038 461.808655,329.192963 462.295837,326.210876 
	C467.870270,326.641266 469.410736,323.907928 468.856232,319.291962 
	C468.524902,316.533691 468.220398,313.772217 467.945679,310.574585 
	C468.100708,308.393799 468.213959,306.650604 468.709961,304.862762 
	C471.307343,303.859619 474.953217,303.352814 475.428772,301.845612 
	C476.327454,298.997528 477.238953,297.774384 480.226990,297.393890 
	C481.244080,297.264374 482.458130,294.993896 482.671051,293.565857 
	C482.807281,292.652344 481.161102,290.516998 480.507935,290.593170 
	C475.300690,291.200256 475.912079,287.723175 475.887085,284.554810 
	C475.864929,281.743896 475.763733,278.933594 475.794037,275.692871 
	C476.031342,273.230225 476.172058,271.197662 476.728119,269.140076 
	C480.618378,268.683044 483.461121,268.813538 483.303772,273.675629 
	C483.280914,274.381866 486.178925,275.182648 487.725311,275.939697 
	C489.405334,271.025024 494.485535,269.504944 497.595123,266.020569 
	C497.774689,265.819336 497.558807,265.260376 497.739746,265.065887 
	C500.712433,261.870636 504.817993,259.636536 506.163879,255.067368 
	C512.491150,255.382263 511.128815,250.952301 510.976013,247.268692 
z"/>
<path fill="#99B66B" opacity="1.000000" stroke="none" 
	d="
M518.586548,276.071472 
	C520.679016,276.434235 522.771423,276.796997 525.311890,277.569153 
	C525.876831,279.621857 525.993896,281.265106 526.006958,283.324646 
	C525.921692,285.436523 525.940308,287.132172 525.791199,289.206177 
	C525.761719,293.442017 525.899963,297.299469 525.983337,301.551758 
	C526.009644,302.735443 526.090759,303.524323 526.111816,304.714844 
	C526.104248,307.353455 526.156738,309.590393 526.116089,312.297638 
	C526.125854,317.576691 526.228760,322.385406 526.252563,327.634705 
	C526.098999,330.824463 526.102051,333.578552 525.929199,336.321594 
	C525.738831,339.342651 527.037537,340.517181 530.288818,340.036987 
	C531.017761,340.217957 531.438110,340.307404 531.887695,340.839172 
	C532.179077,348.101410 532.441162,354.921295 532.282715,361.816132 
	C529.971252,361.891052 528.080261,361.891052 525.821899,361.891052 
	C525.821899,356.376129 526.080811,351.720947 525.711853,347.116119 
	C525.529663,344.842987 524.291931,340.796783 523.354858,340.744812 
	C517.495422,340.419861 518.687378,336.585754 518.739807,333.081390 
	C518.801941,328.929626 518.919861,324.763794 518.650696,320.627686 
	C518.496948,318.264404 517.109070,315.933563 517.174683,313.613556 
	C517.320801,308.447510 518.031189,303.297394 518.656006,297.689453 
	C518.738403,294.428436 518.676331,291.618713 518.658020,288.358124 
	C518.621765,284.063354 518.541687,280.219513 518.455811,276.284393 
	C518.450012,276.193146 518.586548,276.071472 518.586548,276.071472 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M526.110901,282.908356 
	C525.993896,281.265106 525.876831,279.621857 525.665344,277.594482 
	C527.450500,276.763306 529.330139,276.316223 531.764648,275.992645 
	C532.545105,276.382599 532.770752,276.649048 532.926758,277.344177 
	C531.880676,283.365173 535.905884,283.190948 539.544800,283.889923 
	C539.289978,289.334656 544.395386,290.220978 546.629883,293.330811 
	C546.822571,297.815674 550.403320,297.235443 553.630920,297.863220 
	C554.158508,298.863708 554.302185,299.758026 554.536804,300.865570 
	C554.546570,301.943512 554.465454,302.808167 554.298950,304.571777 
	C554.722168,307.298309 555.230774,309.125916 555.433350,311.211761 
	C554.054565,315.673706 551.712830,318.292358 547.029114,317.504028 
	C547.052612,315.720154 547.076477,314.397888 547.065002,313.075989 
	C547.030396,309.096436 548.309753,304.349945 541.655334,304.334991 
	C541.094177,304.333740 540.184509,302.679565 540.051880,301.709229 
	C539.785522,299.760712 539.842468,297.748535 539.950623,295.772736 
	C540.128174,292.529419 540.031982,289.700134 535.518616,289.979584 
	C534.777222,290.025482 533.291260,289.031616 533.301453,288.542236 
	C533.409607,283.341248 529.178162,283.898193 526.110901,282.908356 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M532.996338,276.915497 
	C532.770752,276.649048 532.545105,276.382599 532.180786,275.894073 
	C532.264038,273.106018 533.310242,270.044586 529.477478,269.313812 
	C526.537903,268.753357 525.186096,267.030609 526.226501,264.545013 
	C526.743469,263.309998 529.115356,262.819000 530.690247,262.081360 
	C531.098816,261.890015 531.665894,262.037201 532.582031,262.017365 
	C533.003845,262.005829 532.976379,261.980835 532.931274,262.419373 
	C532.815613,269.163422 532.815613,269.163422 540.008850,269.007812 
	C540.000793,269.000366 539.984863,268.984711 539.984863,268.984711 
	C540.384155,271.299927 540.783447,273.615112 541.355286,276.255493 
	C541.122864,276.726227 540.717834,276.871796 539.856934,276.928802 
	C537.266113,276.865326 535.131226,276.890411 532.996338,276.915497 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M517.032410,275.898346 
	C517.446167,270.083618 517.859863,264.268860 518.360840,258.161469 
	C518.499573,263.674835 518.551086,269.480835 518.594543,275.679169 
	C518.586548,276.071472 518.450012,276.193146 518.296509,276.190765 
	C517.772766,276.091705 517.402588,275.995026 517.032410,275.898346 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M533.020691,261.273071 
	C533.276184,261.115662 533.498962,261.232697 533.606689,261.588654 
	C533.327515,261.864136 533.151978,261.922485 532.976379,261.980835 
	C532.976379,261.980835 533.003845,262.005829 533.017151,262.018890 
	C533.020142,261.863251 533.009766,261.694519 533.020691,261.273071 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M518.394165,254.502426 
	C518.356628,254.507599 518.311401,254.448883 518.333862,254.468567 
	C518.356323,254.488251 518.431763,254.497253 518.394165,254.502426 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M547.012451,268.593506 
	C544.977112,269.017731 542.921448,269.005951 540.425354,268.989441 
	C539.984863,268.984711 540.000793,269.000366 539.980042,268.568970 
	C539.979858,266.085724 540.000366,264.033936 540.010742,261.991608 
	C540.000488,262.001099 539.980835,262.021332 539.980835,262.021332 
	C541.767700,261.993347 543.554565,261.965393 545.879517,262.122314 
	C546.558655,262.530273 546.722229,262.735352 546.908325,262.922516 
	C546.936279,264.667480 546.964233,266.412476 547.012451,268.593506 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M538.964966,261.733032 
	C539.139771,259.745392 539.314575,257.757751 539.638428,255.443436 
	C539.851196,257.128204 539.914917,259.139618 539.979736,261.586182 
	C539.980835,262.021332 540.000488,262.001099 539.741882,261.949738 
	C539.310486,261.843262 539.137756,261.788147 538.964966,261.733032 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M546.939697,262.695953 
	C546.722229,262.735352 546.558655,262.530273 546.327026,262.132843 
	C546.236511,261.958466 546.612976,261.962769 546.799561,261.987488 
	C546.986084,262.012177 546.971069,262.469391 546.939697,262.695953 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M538.554810,261.790955 
	C539.137756,261.788147 539.310486,261.843262 539.752075,261.940247 
	C540.000366,264.033936 539.979858,266.085724 539.988098,268.576416 
	C532.815613,269.163422 532.815613,269.163422 532.931274,262.419373 
	C533.151978,261.922485 533.327515,261.864136 533.760010,261.699951 
	C535.392822,261.679047 536.768738,261.763977 538.554810,261.790955 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M475.887207,240.030930 
	C475.878204,238.249039 475.869171,236.467163 475.890137,234.232147 
	C479.662567,233.853073 483.404968,233.927155 487.616211,233.950104 
	C488.425018,233.939392 488.764984,233.979813 489.435089,234.433395 
	C489.743378,236.211578 489.721497,237.576584 489.543732,239.085876 
	C489.201019,239.442612 489.046082,239.676224 488.520630,239.937149 
	C486.059601,240.332642 484.000977,240.721954 481.516174,241.072632 
	C479.394806,240.953400 477.699585,240.872803 475.986603,240.600128 
	C475.968842,240.408035 475.887238,240.030930 475.887207,240.030930 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M503.528625,233.037659 
	C504.052551,235.043335 504.122772,237.039505 503.758698,239.495972 
	C501.509644,239.978790 499.694855,240.001343 497.657898,239.857666 
	C496.726807,239.500107 496.017883,239.308762 495.253113,238.755280 
	C495.695679,236.647614 496.194061,234.902084 496.692444,233.156555 
	C498.819916,233.113754 500.947388,233.070953 503.528625,233.037659 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M496.283081,233.121216 
	C496.194061,234.902084 495.695679,236.647614 495.035950,238.970490 
	C494.766357,239.936768 494.658142,240.325714 494.089020,240.622421 
	C492.386414,240.362686 491.144714,240.195190 489.875702,239.751770 
	C489.798828,239.297760 489.749237,239.119690 489.699615,238.941605 
	C489.721497,237.576584 489.743378,236.211578 489.692352,234.444199 
	C491.704193,233.723190 493.788971,233.404541 496.283081,233.121216 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M418.520844,242.016663 
	C418.069031,241.591965 417.617218,241.167252 417.063965,240.528610 
	C417.418701,240.262375 417.874847,240.210068 418.666321,240.079025 
	C419.001617,240.000290 418.948364,239.989578 418.879974,240.310822 
	C418.714661,241.093613 418.617737,241.555130 418.520844,242.016663 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M476.007660,447.931641 
	C475.987213,468.752594 475.966766,489.573517 475.956665,511.251495 
	C475.865753,516.836914 475.764404,521.565369 475.236694,526.230530 
	C472.721405,526.114136 470.632477,526.060974 468.494965,526.035400 
	C468.446381,526.062988 468.334808,526.069519 468.334808,526.069519 
	C468.182739,523.797302 468.030670,521.525024 468.047974,518.475586 
	C468.409790,515.817871 468.602173,513.937378 468.837158,511.586914 
	C468.906219,506.356476 468.932739,501.595947 468.910156,496.379120 
	C468.854889,482.216644 468.843689,468.510468 468.844574,454.804321 
	C468.845032,447.683044 468.911102,447.629883 476.030090,447.998962 
	C475.996918,447.997192 476.007660,447.931641 476.007660,447.931641 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M461.143829,582.784363 
	C460.353699,582.868835 459.563568,582.953369 458.033203,583.007324 
	C456.579376,582.895935 455.865723,582.815063 455.142761,582.332031 
	C455.817963,575.425354 450.194885,577.416199 447.067657,575.515869 
	C446.944550,570.352478 446.871368,565.651550 446.798157,560.950562 
	C446.815308,559.045227 446.832458,557.139832 446.757263,554.420044 
	C446.845245,551.799438 447.025635,549.993225 447.601013,548.095093 
	C454.406342,548.760620 455.027649,544.510986 455.440613,539.970947 
	C457.530457,540.438477 459.281219,540.723022 461.008514,540.983521 
	C460.985077,540.959534 460.941071,541.010193 460.902679,541.436523 
	C460.877716,543.845337 460.891144,545.827820 460.491028,547.885925 
	C456.276581,547.643799 454.737640,549.239807 454.917145,553.176575 
	C455.166626,558.646851 455.247192,564.164734 454.809418,569.614746 
	C454.402588,574.679077 456.321075,576.698914 461.221069,576.610291 
	C461.190247,578.942871 461.167053,580.863586 461.143829,582.784363 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M455.259949,511.690369 
	C455.008423,512.121704 454.803101,512.892822 454.498505,512.934265 
	C448.038513,513.813171 448.879120,518.615967 448.958496,523.051208 
	C449.001923,525.475891 448.956757,527.908081 445.520996,527.922241 
	C442.581757,527.934326 439.237823,528.746338 438.606720,524.328125 
	C438.395966,522.852661 438.188446,521.376831 438.332764,519.415527 
	C439.409180,518.856750 440.132263,518.783569 441.093994,519.032104 
	C441.596405,520.631775 441.720398,521.963196 442.181305,523.165527 
	C442.422455,523.794678 443.224670,524.549011 443.829773,524.601807 
	C444.425812,524.653748 445.627441,523.913391 445.645447,523.482361 
	C445.809143,519.566467 447.724518,515.321594 443.953918,511.636292 
	C444.372101,509.286346 444.539429,507.301147 445.040192,505.403961 
	C445.548859,503.476593 446.386383,501.636017 447.325623,500.101501 
	C447.716003,501.882904 448.104645,503.345154 447.960785,504.752960 
	C447.481689,509.441315 449.505798,511.001678 454.052704,510.141479 
	C454.539459,510.825836 454.899719,511.258118 455.259949,511.690369 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M411.836365,512.355957 
	C417.427460,512.640747 419.600677,510.156464 418.918518,504.437805 
	C419.021393,504.049194 418.952026,504.036011 419.385864,504.037170 
	C421.555481,504.030701 423.291229,504.023102 425.024292,504.432098 
	C425.425842,507.196198 425.830109,509.543701 426.166992,512.336670 
	C426.058746,514.885559 426.017822,516.989014 425.976898,519.092468 
	C421.201965,519.092468 416.427032,519.092468 411.714172,519.092468 
	C411.714172,516.685242 411.714172,514.721191 411.836365,512.355957 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M447.205994,548.187073 
	C447.025635,549.993225 446.845245,551.799438 446.584473,553.971069 
	C442.656708,554.743286 438.367981,556.353333 437.695862,549.911865 
	C437.583435,548.834595 435.026245,548.012451 433.035919,546.701782 
	C433.035919,545.456360 433.035919,543.655334 433.027771,541.441833 
	C433.019653,541.029297 432.978943,541.082214 432.978943,541.082214 
	C435.039764,541.039795 437.100616,540.997375 439.579132,540.981201 
	C439.996826,541.007507 439.950714,540.955566 439.984497,541.382080 
	C440.258881,548.056213 440.258881,548.056213 446.896362,547.906311 
	C446.957031,548.038086 447.205994,548.187073 447.205994,548.187073 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M425.986328,519.059937 
	C426.017822,516.989014 426.058746,514.885559 426.518158,512.297974 
	C428.602295,511.208496 430.267944,510.603149 432.198517,510.267029 
	C435.034119,511.012512 437.604797,511.488770 440.316956,512.030884 
	C440.458435,512.096802 440.759125,512.180664 440.755371,512.585815 
	C440.786224,514.897522 440.820801,516.803955 440.855377,518.710449 
	C440.132263,518.783569 439.409180,518.856750 438.327148,519.002075 
	C437.534576,519.046326 437.100952,519.018433 435.978882,518.962036 
	C434.558105,518.955872 433.825775,518.978333 432.645325,519.003723 
	C430.107513,519.012512 428.017883,519.018372 425.928253,519.024170 
	C425.928253,519.024170 425.995728,519.027344 425.986328,519.059937 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M458.963806,533.819336 
	C459.491730,533.286011 460.019653,532.752686 461.080566,532.358887 
	C463.944733,532.907715 466.275909,533.316956 468.720886,533.803345 
	C468.834717,533.880371 469.038544,534.037231 469.027527,534.413330 
	C468.333618,536.473450 467.650757,538.157471 466.590393,539.934204 
	C464.455627,540.354675 462.698334,540.682434 460.941071,541.010193 
	C460.941071,541.010193 460.985077,540.959534 461.005920,540.593811 
	C460.319580,538.098083 463.902954,534.587036 458.963806,533.819336 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M425.958435,525.960876 
	C425.958435,528.095459 425.958435,530.230042 425.958435,532.919373 
	C423.781555,533.198853 421.791595,533.454407 419.845490,533.704285 
	C418.256042,526.422546 418.533844,526.064270 425.602692,526.083313 
	C426.011017,526.010986 425.958435,525.960876 425.958435,525.960876 
z"/>
<path fill="#7C7DDF" opacity="1.000000" stroke="none" 
	d="
M447.137146,588.982483 
	C445.015533,589.658813 442.893951,590.335083 440.360779,591.024353 
	C439.988647,589.911194 440.205017,588.757446 440.034760,587.663940 
	C439.495667,584.201294 440.910217,582.890076 444.863464,583.514954 
	C445.801422,583.496765 446.160889,583.466248 446.693542,583.797485 
	C446.956879,585.766968 447.046997,587.374695 447.137146,588.982483 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M468.607056,533.726257 
	C466.275909,533.316956 463.944733,532.907715 461.353271,532.261475 
	C461.847351,528.592346 462.381256,524.923218 467.887817,526.093750 
	C468.334808,526.069519 468.446381,526.062988 468.515228,526.475586 
	C468.591736,529.167480 468.599396,531.446899 468.607056,533.726257 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M455.605347,511.625763 
	C454.899719,511.258118 454.539459,510.825836 454.148193,509.772400 
	C454.071106,507.876740 454.025116,506.602325 454.289001,505.237305 
	C455.003967,505.008026 455.409058,504.869324 456.240479,504.761810 
	C458.366760,504.719666 460.066650,504.646332 461.812256,505.038635 
	C461.936096,507.656677 462.014221,509.809082 462.075500,511.989655 
	C462.058624,512.017822 461.997192,512.041260 461.997192,512.041260 
	C459.981720,511.881226 457.966248,511.721191 455.605347,511.625763 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M433.046265,541.009216 
	C430.974091,540.814575 428.875275,540.640076 426.262787,540.422791 
	C426.163574,538.308716 426.069244,536.298950 425.961456,534.002197 
	C428.326324,534.002197 430.239227,534.002197 432.794708,534.002197 
	C432.794708,536.144897 432.794708,538.193481 432.886841,540.662109 
	C432.978943,541.082214 433.019653,541.029297 433.046265,541.009216 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M447.006042,534.173584 
	C449.040375,534.101135 451.074677,534.028687 453.628418,534.230103 
	C454.426331,534.693787 454.704865,534.883667 454.989868,535.420654 
	C454.976593,536.461548 454.956818,537.155334 454.723022,538.133789 
	C452.575226,541.652771 449.849152,541.168823 446.972107,539.603638 
	C446.976929,537.514526 446.991486,535.844055 447.006042,534.173584 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M446.576721,534.063477 
	C446.991486,535.844055 446.976929,537.514526 446.910767,539.882080 
	C446.868591,540.769104 446.878052,540.959045 446.457123,541.132080 
	C444.001404,541.061951 441.976074,541.008789 439.950714,540.955566 
	C439.950714,540.955566 439.996826,541.007507 440.019287,541.033997 
	C440.146118,538.941345 440.250488,536.822205 440.391785,533.953308 
	C442.401276,533.953308 444.274353,533.953308 446.576721,534.063477 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M425.880524,519.461304 
	C428.017883,519.018372 430.107513,519.012512 432.580566,519.393433 
	C432.600250,521.835327 432.236481,523.890381 431.872681,525.945435 
	C430.182312,525.959961 428.491943,525.974548 426.380005,525.974976 
	C425.958435,525.960876 426.011017,526.010986 426.037903,526.036255 
	C425.987457,524.007202 425.910126,521.952820 425.880524,519.461304 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M446.520355,583.435730 
	C446.160889,583.466248 445.801422,583.496765 445.193359,583.415771 
	C444.968445,577.700256 444.906738,572.093872 445.066925,566.493835 
	C445.116150,564.773560 445.772552,563.070557 446.474548,561.155151 
	C446.871368,565.651550 446.944550,570.352478 447.070557,575.937256 
	C447.249969,578.715210 447.376587,580.609375 447.257416,582.736450 
	C446.847870,583.124878 446.684113,583.280273 446.520355,583.435730 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M454.937012,537.849121 
	C454.956818,537.155334 454.976593,536.461548 455.023682,535.172913 
	C455.050964,534.578064 455.083160,534.080078 455.083160,534.080078 
	C456.118439,534.014648 457.153687,533.949280 458.576355,533.851624 
	C463.902954,534.587036 460.319580,538.098083 461.029358,540.617798 
	C459.281219,540.723022 457.530457,540.438477 455.440216,539.646118 
	C455.046143,538.708557 454.991577,538.278809 454.937012,537.849121 
z"/>
<path fill="#7C7DDF" opacity="1.000000" stroke="none" 
	d="
M468.794556,512.056824 
	C468.602173,513.937378 468.409790,515.817871 468.016174,518.054443 
	C466.036407,518.410461 464.257874,518.410461 462.018311,518.410461 
	C462.018311,516.166260 462.018311,514.539307 462.007751,512.476807 
	C461.997192,512.041260 462.058624,512.017822 462.497864,511.975159 
	C464.889618,511.973969 466.842072,512.015381 468.794556,512.056824 
z"/>
<path fill="#1B1B1A" opacity="1.000000" stroke="none" 
	d="
M432.009583,526.245239 
	C432.236481,523.890381 432.600250,521.835327 433.028748,519.390503 
	C433.825775,518.978333 434.558105,518.955872 435.653198,518.994812 
	C436.279449,520.655884 436.956451,522.322510 436.710876,523.839844 
	C436.329193,526.198303 435.718628,529.146057 432.009583,526.245239 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M454.807892,534.054443 
	C455.083160,534.080078 455.050964,534.578064 455.017181,534.825806 
	C454.704865,534.883667 454.426331,534.693787 454.063843,534.260620 
	C454.164124,534.021240 454.348389,534.025024 454.807892,534.054443 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M490.184204,554.954773 
	C490.097534,557.003418 490.010925,559.052002 489.436920,561.526245 
	C483.859741,561.187073 482.270905,563.696655 482.979858,568.527710 
	C482.990021,568.709656 483.060852,569.067261 483.060852,569.067261 
	C480.954865,569.067261 478.848907,569.067261 475.900787,569.067261 
	C475.900787,565.042847 475.900787,561.366638 475.959595,556.940674 
	C475.998657,555.821777 475.978912,555.452576 476.383423,554.974121 
	C478.392883,554.864868 479.978088,554.864868 482.040161,554.864868 
	C482.040161,552.184814 482.040161,550.071655 482.017578,547.980713 
	C481.995026,548.002930 481.949707,548.047119 481.949707,548.047119 
	C484.346436,548.000366 486.743134,547.953552 489.591858,548.294556 
	C490.090637,550.773132 490.137421,552.863953 490.184204,554.954773 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M461.228699,576.198486 
	C456.321075,576.698914 454.402588,574.679077 454.809418,569.614746 
	C455.247192,564.164734 455.166626,558.646851 454.917145,553.176575 
	C454.737640,549.239807 456.276581,547.643799 460.679382,547.964905 
	C461.281281,547.968201 461.638550,548.164551 461.638550,548.164551 
	C461.733917,557.089478 461.829285,566.014343 461.776306,575.495911 
	C461.627960,576.052551 461.228699,576.198486 461.228699,576.198486 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M475.925110,597.364380 
	C475.937866,597.819092 475.522095,598.026978 474.895447,598.070435 
	C472.593048,597.891296 470.917297,597.668640 469.198792,597.034851 
	C469.115784,592.328064 469.075592,588.032288 469.032166,583.318970 
	C468.973511,580.679688 468.918121,578.457947 468.862701,576.236206 
	C475.836151,576.154541 475.850677,576.154419 475.895081,582.952087 
	C475.925446,587.604431 475.908295,592.257080 475.925110,597.364380 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M468.439636,576.178833 
	C468.918121,578.457947 468.973511,580.679688 468.667542,583.171875 
	C465.891815,583.246094 463.477417,583.049805 461.063049,582.853516 
	C461.063049,582.853516 461.085175,582.796326 461.114502,582.790344 
	C461.167053,580.863586 461.190247,578.942871 461.221069,576.610291 
	C461.228699,576.198486 461.627960,576.052551 461.816132,575.954163 
	C464.008392,575.944336 466.012451,576.032898 468.439636,576.178833 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M483.056183,569.496826 
	C483.060852,569.067261 482.990021,568.709656 483.404480,568.450562 
	C485.801575,568.106018 487.784241,568.020569 489.766876,567.935059 
	C489.848969,570.335327 489.931061,572.735535 490.019653,575.585999 
	C483.117035,576.005676 483.117035,576.005676 483.056183,569.496826 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M475.959167,555.083374 
	C475.978912,555.452576 475.998657,555.821777 476.008545,556.463623 
	C473.895447,555.889160 471.792206,555.042114 469.354034,554.060242 
	C469.040192,552.523438 468.654419,550.634644 468.282440,548.216980 
	C468.296204,547.688110 468.624176,547.343750 468.900726,547.535522 
	C471.326233,547.740173 473.475159,547.753052 475.715942,547.807373 
	C475.807770,547.848816 475.968048,547.970886 475.942078,548.413330 
	C475.930450,550.931702 475.944824,553.007507 475.959167,555.083374 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M462.094574,548.100464 
	C461.638550,548.164551 461.281281,547.968201 461.092926,547.889221 
	C460.891144,545.827820 460.877716,543.845337 460.902679,541.436523 
	C462.698334,540.682434 464.455627,540.354675 466.817932,540.143677 
	C467.699799,540.809326 467.976593,541.358215 468.158356,542.305054 
	C468.250275,544.250000 468.437225,545.796875 468.624176,547.343750 
	C468.624176,547.343750 468.296204,547.688110 468.132233,547.860291 
	C466.162384,548.033752 464.356476,548.035034 462.094574,548.100464 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M489.965515,576.410156 
	C497.014221,576.270447 497.014221,576.270447 497.030182,582.897827 
	C492.482574,583.602234 488.939911,582.709595 489.965515,576.410156 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M490.001129,469.857605 
	C482.864349,469.169952 482.975861,470.917572 482.958252,462.113770 
	C482.952606,459.298492 482.660095,456.456421 482.951782,453.674072 
	C483.449341,448.928162 481.808197,446.750488 476.444366,447.833496 
	C476.007660,447.931641 475.996918,447.997192 475.981659,447.572845 
	C475.827576,444.893036 475.688721,442.637604 475.623199,439.895203 
	C475.847900,435.013641 475.999329,430.618958 476.560852,426.186218 
	C478.618866,426.157288 480.266785,426.166382 482.043579,426.345215 
	C482.355011,426.734467 482.560242,426.930237 482.807312,427.545563 
	C482.881683,430.661407 482.936890,433.333893 482.919678,436.451752 
	C482.915741,439.598999 482.984222,442.300934 483.037476,445.353577 
	C483.687531,446.172302 484.352753,446.640259 485.334167,447.180969 
	C486.077850,447.207642 486.505341,447.161530 487.308289,447.199768 
	C488.449768,447.374237 489.215759,447.464355 489.963654,447.985718 
	C490.010498,453.012207 490.075470,457.607452 490.123352,462.604431 
	C490.126099,464.093597 490.145905,465.181122 490.144836,466.627686 
	C490.083008,467.943695 490.042053,468.900635 490.001129,469.857605 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M496.839905,490.824341 
	C496.934357,492.596741 497.028839,494.369110 497.186859,496.508423 
	C497.157043,505.157623 497.043091,513.439758 496.983490,521.722229 
	C496.966980,524.017151 496.964386,525.950134 493.726013,526.030151 
	C490.283264,526.115112 490.065857,524.166443 490.081024,521.596558 
	C490.112244,516.295776 490.026520,510.994293 489.932159,504.781525 
	C489.896820,501.736603 489.934784,499.603302 489.936737,497.469971 
	C489.943146,490.353149 489.938171,490.353149 496.839905,490.824341 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M496.862732,490.407959 
	C489.938171,490.353149 489.943146,490.353149 489.936737,497.469971 
	C489.934784,499.603302 489.896820,501.736603 489.831848,504.305603 
	C483.082703,504.939941 483.046844,504.940247 482.994049,498.532501 
	C482.949127,493.080719 483.238159,487.614868 482.934631,482.180084 
	C482.676270,477.553131 484.334137,475.657227 489.369263,476.867432 
	C489.903503,477.502167 490.007782,477.834442 490.122986,478.553009 
	C490.140747,480.240417 490.147583,481.541504 490.232086,483.188843 
	C492.459656,483.696594 494.609558,483.858124 496.759491,484.019653 
	C496.801514,486.010315 496.843536,488.000946 496.862732,490.407959 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M475.663086,526.293762 
	C475.764404,521.565369 475.865753,516.836914 475.984802,511.743225 
	C482.891968,511.308044 482.985474,511.310272 482.827972,517.960083 
	C482.653351,525.334656 482.285156,532.704529 481.491760,540.470581 
	C480.422577,540.830078 479.862946,540.795410 479.055603,540.623779 
	C477.663544,538.285217 476.519196,536.083740 475.488312,533.471069 
	C475.622253,530.804565 475.642670,528.549133 475.663086,526.293762 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M490.058350,547.028992 
	C490.077118,543.723022 490.173309,540.415100 490.090515,537.111694 
	C490.022583,534.402405 491.560120,533.313477 493.786804,533.976257 
	C495.054626,534.353516 496.685455,535.952515 496.837372,537.166077 
	C497.244598,540.418701 496.942871,543.760132 496.508301,547.551575 
	C494.082001,547.700928 492.070160,547.364929 490.058350,547.028992 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M490.112061,478.166687 
	C490.007782,477.834442 489.903503,477.502167 489.766541,476.914978 
	C489.821564,474.710785 489.909332,472.761566 489.999115,470.334961 
	C490.042053,468.900635 490.083008,467.943695 490.345978,466.868225 
	C493.169006,469.496216 498.493286,470.387329 496.550354,476.138977 
	C494.187439,476.902222 492.149750,477.534454 490.112061,478.166687 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M490.019592,547.211304 
	C492.070160,547.364929 494.082001,547.700928 496.482513,548.016174 
	C496.931610,550.048645 496.992035,552.101807 497.044952,554.597778 
	C495.034607,554.998230 493.031769,554.955933 490.606567,554.934204 
	C490.137421,552.863953 490.090637,550.773132 490.035095,548.222900 
	C490.026367,547.763489 489.980865,547.393555 490.019592,547.211304 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M479.303284,540.760742 
	C479.862946,540.795410 480.422577,540.830078 481.402008,540.874573 
	C481.897705,542.976685 481.973633,545.068909 481.999634,547.604126 
	C481.949707,548.047119 481.995026,548.002930 481.585358,548.020996 
	C479.439819,548.016418 477.703918,547.993652 475.968048,547.970886 
	C475.968048,547.970886 475.807770,547.848816 475.726013,547.390076 
	C475.657104,544.955994 475.669922,542.980713 476.051788,540.938721 
	C477.381683,540.834961 478.342468,540.797852 479.303284,540.760742 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M525.031616,476.933868 
	C525.305969,469.746521 525.592651,462.559631 525.846985,455.371582 
	C525.922485,453.236237 525.906921,451.097687 525.967163,448.475433 
	C526.001221,447.990265 526.037964,447.952820 526.468994,447.928375 
	C528.903076,447.994690 530.906128,448.085480 532.957581,448.199463 
	C533.005981,448.222687 533.063477,448.132050 533.063477,448.132050 
	C533.004028,454.947998 532.944580,461.763947 532.720947,469.389160 
	C532.445801,473.519592 532.475891,476.854950 532.145142,480.154083 
	C532.034485,481.257996 531.035828,483.118744 530.384705,483.144318 
	C529.081299,483.195465 527.292419,482.565460 526.524292,481.578339 
	C525.586304,480.372894 525.491211,478.511566 525.031616,476.933868 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M524.650208,476.924164 
	C525.491211,478.511566 525.586304,480.372894 526.524292,481.578339 
	C527.292419,482.565460 529.081299,483.195465 530.384705,483.144318 
	C531.035828,483.118744 532.034485,481.257996 532.145142,480.154083 
	C532.475891,476.854950 532.445801,473.519592 532.697754,469.894226 
	C532.676758,477.513062 532.447205,485.435547 532.380859,493.359406 
	C532.347229,497.372711 529.723816,497.272339 526.516113,497.129364 
	C526.270447,492.285675 524.279602,489.879303 518.705444,490.992981 
	C518.218079,491.072388 518.019226,491.071106 517.921875,491.038605 
	C517.966187,488.222900 518.427551,485.410645 518.178345,482.662903 
	C517.746460,477.901611 519.400085,475.827911 524.650208,476.924164 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M546.817627,447.854706 
	C544.737427,448.158417 542.761353,447.975494 540.421936,447.754761 
	C540.058533,447.716919 539.934998,447.608124 539.936829,447.031738 
	C539.914551,441.988983 539.890381,437.522614 540.311523,433.089172 
	C542.533752,433.085266 544.310730,433.048462 546.087708,433.011627 
	C546.365723,437.797119 546.643738,442.582611 546.817627,447.854706 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M539.866211,433.056274 
	C539.890381,437.522614 539.914551,441.988983 539.895081,446.960815 
	C537.843689,447.662872 535.835815,447.859467 533.445740,448.094055 
	C533.063477,448.132050 533.005981,448.222687 532.955566,447.777618 
	C532.874146,445.943634 532.843201,444.554749 532.861633,442.696350 
	C533.006531,439.435272 533.101929,436.643707 533.574585,433.807800 
	C535.954529,433.540527 537.957275,433.317535 539.945618,433.062866 
	C539.931152,433.031219 539.866211,433.056274 539.866211,433.056274 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M489.809326,567.510071 
	C487.784241,568.020569 485.801575,568.106018 483.394287,568.268616 
	C482.270905,563.696655 483.859741,561.187073 489.367798,561.959961 
	C489.807983,563.673767 489.829895,565.379456 489.809326,567.510071 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M518.970703,448.007172 
	C518.930176,452.780487 518.882019,457.553741 518.851135,462.327118 
	C518.807373,469.072571 518.802551,469.082336 511.411804,468.992462 
	C510.969238,468.969513 510.995453,468.995819 511.014130,468.538025 
	C511.047760,461.296539 511.062714,454.512787 511.108032,447.599304 
	C511.138367,447.469513 511.252319,447.224274 511.658356,447.218262 
	C513.691223,447.233826 515.318054,447.255432 517.189331,447.408844 
	C517.964905,447.701416 518.495911,447.862183 519.008118,448.034302 
	C518.989258,448.045685 518.970703,448.007172 518.970703,448.007172 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M525.982422,448.008636 
	C523.924011,447.993103 521.884399,447.959229 519.407715,447.966248 
	C518.970703,448.007172 518.989258,448.045685 518.971863,447.630920 
	C518.783630,446.155975 518.612793,445.095795 518.480042,443.667908 
	C518.664307,439.982422 518.810425,436.664673 519.360474,433.310242 
	C525.464600,432.248108 525.649170,436.195068 525.807739,440.511261 
	C525.822021,443.278717 525.929993,445.615753 526.037964,447.952820 
	C526.037964,447.952820 526.001221,447.990265 525.982422,448.008636 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M496.875610,476.007996 
	C498.493286,470.387329 493.169006,469.496216 490.366852,466.509155 
	C490.145905,465.181122 490.126099,464.093597 490.574890,462.621399 
	C492.853088,462.229248 494.662689,462.216766 496.472290,462.215302 
	C503.604279,462.209503 503.604279,462.210815 504.168823,469.404877 
	C504.051422,471.164001 503.934021,472.923157 503.814636,475.028809 
	C501.973145,475.780945 500.133606,476.186554 497.782715,476.592529 
	C497.126495,476.406677 496.994598,476.211700 496.875610,476.007996 
z"/>
<path fill="#A5574A" opacity="1.000000" stroke="none" 
	d="
M496.550354,476.138977 
	C496.994598,476.211700 497.126495,476.406677 497.393829,476.707458 
	C497.260742,478.588959 497.005157,480.355896 496.244629,482.370300 
	C493.877930,482.692688 492.016174,482.767639 490.154449,482.842590 
	C490.147583,481.541504 490.140747,480.240417 490.122986,478.553009 
	C492.149750,477.534454 494.187439,476.902222 496.550354,476.138977 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M490.232086,483.188843 
	C492.016174,482.767639 493.877930,482.692688 496.049805,482.639893 
	C496.578644,482.912079 496.709900,483.201752 496.756592,483.775391 
	C494.609558,483.858124 492.459656,483.696594 490.232086,483.188843 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M504.587769,469.320953 
	C503.604279,462.210815 503.604279,462.209503 496.472290,462.215302 
	C494.662689,462.216766 492.853088,462.229248 490.591949,462.219696 
	C490.075470,457.607452 490.010498,453.012207 489.763367,447.758301 
	C488.698425,447.104919 487.815643,447.110168 486.932861,447.115448 
	C486.505341,447.161530 486.077850,447.207642 485.220520,446.902649 
	C484.211395,446.035309 483.632050,445.519073 483.052673,445.002838 
	C482.984222,442.300934 482.915741,439.598999 483.309540,436.445282 
	C485.584534,434.849182 487.670532,433.975006 489.138550,432.489929 
	C490.847748,430.760834 491.589478,427.854187 493.499634,426.586639 
	C499.100067,422.870239 501.238953,416.927795 504.571259,411.614746 
	C505.251160,411.840088 505.637360,411.976318 506.170441,412.373596 
	C507.541229,412.432526 508.765076,412.230438 510.249023,411.985352 
	C510.684479,411.954102 510.859833,411.965881 511.053406,412.443298 
	C511.096222,414.897675 511.118225,416.886383 511.145782,418.875061 
	C511.244873,426.017700 511.245544,426.017700 518.259521,426.558929 
	C518.394348,428.102020 518.420532,429.233978 518.396423,430.373596 
	C518.346191,430.381195 518.446045,430.588196 518.503296,431.052521 
	C518.692566,432.126862 518.824524,432.736908 518.956543,433.346924 
	C518.810425,436.664673 518.664307,439.982422 518.256226,443.859802 
	C517.644470,445.371979 517.294678,446.324524 516.944885,447.277039 
	C515.318054,447.255432 513.691223,447.233826 511.621277,446.763489 
	C510.614868,444.384583 510.158234,440.829376 509.471191,440.784302 
	C504.483246,440.456848 504.420044,436.877167 503.931488,433.000427 
	C503.822662,429.936096 505.350220,426.316589 500.665802,426.277496 
	C495.552216,426.234802 497.430481,430.160339 497.206055,432.835815 
	C497.165283,433.321442 497.326294,433.824036 497.359833,434.712341 
	C496.859009,438.249329 497.733917,440.098297 501.381409,440.213684 
	C502.348816,440.244263 504.074615,441.957092 504.067749,442.887329 
	C504.028534,448.206085 507.445770,448.018768 511.077667,447.729065 
	C511.062714,454.512787 511.047760,461.296539 511.026886,468.551025 
	C509.016235,469.093536 507.011475,469.165283 504.587769,469.320953 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M526.006958,283.324646 
	C529.178162,283.898193 533.409607,283.341248 533.301453,288.542236 
	C533.291260,289.031616 534.777222,290.025482 535.518616,289.979584 
	C540.031982,289.700134 540.128174,292.529419 539.950623,295.772736 
	C539.842468,297.748535 539.785522,299.760712 540.051880,301.709229 
	C540.184509,302.679565 541.094177,304.333740 541.655334,304.334991 
	C548.309753,304.349945 547.030396,309.096436 547.065002,313.075989 
	C547.076477,314.397888 547.052612,315.720154 547.341736,317.712036 
	C553.478027,318.486420 552.717834,323.429871 553.913025,326.939636 
	C553.967407,329.587830 554.143982,332.240601 554.044861,334.883057 
	C553.924438,338.093048 554.519348,340.322266 558.461182,340.546356 
	C559.398376,340.599670 560.899048,342.665588 560.973022,343.877014 
	C561.265076,348.662140 561.026489,353.476868 561.117676,358.278076 
	C561.171936,361.134216 559.553894,361.667114 557.158569,362.247131 
	C555.967407,362.535583 554.754211,364.550842 554.450623,365.976501 
	C554.279846,366.778656 555.919556,368.821289 556.778992,368.842041 
	C560.789978,368.938965 561.336243,371.278076 561.188599,374.455475 
	C561.088684,376.604095 561.157166,378.760529 561.094116,381.700928 
	C561.038818,382.934784 561.039734,383.381195 561.040649,383.827637 
	C559.324402,383.633270 557.608093,383.438873 555.443115,383.208252 
	C554.783569,382.888336 554.572876,382.604614 554.261108,381.917114 
	C554.058838,378.811584 555.448547,375.147858 550.404175,375.648804 
	C550.430054,375.644836 550.449341,375.645081 549.958801,375.604980 
	C548.816101,375.491638 548.164001,375.418396 547.494141,375.304108 
	C547.476379,375.263092 547.319275,375.321442 547.176819,374.852051 
	C547.037292,365.942505 547.040222,357.502319 547.392395,348.885834 
	C549.346619,343.065887 550.567078,337.438354 546.989990,331.650269 
	C547.624207,328.943634 548.094543,326.591370 544.491882,326.627899 
	C540.798828,326.665344 540.402893,323.901062 540.023499,321.406128 
	C539.708008,319.331726 540.039307,317.158905 540.374023,314.897766 
	C542.566406,312.228760 541.229065,310.836365 538.830200,310.124390 
	C536.969482,309.572083 534.996521,309.397644 533.028320,308.662445 
	C532.785400,303.382538 529.488342,303.831116 526.171875,304.313202 
	C526.090759,303.524323 526.009644,302.735443 526.322205,301.353882 
	C528.532715,296.652161 529.297302,292.609833 525.958923,288.827789 
	C525.940308,287.132172 525.921692,285.436523 526.006958,283.324646 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M554.362122,382.320923 
	C554.572876,382.604614 554.783569,382.888336 555.021240,383.343262 
	C554.737854,388.909637 554.342651,394.301605 554.143677,399.700836 
	C553.979492,404.159332 553.796204,408.642487 554.080994,413.085236 
	C554.385681,417.838989 552.703186,419.947052 547.403992,418.999878 
	C546.950500,418.957733 546.919922,418.928314 546.924438,418.495880 
	C546.758789,416.044647 546.588562,414.025848 546.547241,411.597137 
	C547.116455,405.266815 546.491760,404.728394 539.586182,405.380981 
	C537.829590,405.211823 536.236084,405.525055 535.251160,404.891754 
	C534.224609,404.231659 533.347839,402.702698 533.224182,401.471344 
	C533.121399,400.448029 534.218384,398.353638 534.762817,398.360657 
	C541.352173,398.445862 539.841309,393.729004 540.404175,390.009399 
	C546.423035,391.277008 547.449524,388.027374 547.625305,383.460449 
	C550.136536,383.020081 552.249329,382.670502 554.362122,382.320923 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M546.238525,432.633514 
	C544.310730,433.048462 542.533752,433.085266 540.311523,433.089172 
	C539.866211,433.056274 539.931152,433.031219 539.947388,432.591064 
	C539.964233,427.754974 539.964905,423.359070 540.401123,419.001831 
	C542.864441,419.003143 544.892212,418.965729 546.919922,418.928314 
	C546.919922,418.928314 546.950500,418.957733 546.965332,418.972839 
	C546.783203,423.410461 546.586243,427.832916 546.238525,432.633514 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M561.094238,384.295654 
	C561.039734,383.381195 561.038818,382.934784 561.059814,382.152100 
	C563.345825,382.372437 565.609863,382.929047 568.282166,383.968262 
	C568.799255,386.651276 568.908081,388.851776 569.016907,391.052246 
	C569.016907,391.052246 568.985535,391.068878 568.552429,391.024292 
	C565.906982,391.100403 563.694641,391.221130 561.482300,391.341858 
	C561.370850,389.149109 561.259338,386.956390 561.094238,384.295654 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M561.350769,391.709229 
	C563.694641,391.221130 565.906982,391.100403 568.543274,391.009033 
	C568.792175,393.020325 568.617126,395.002258 568.499634,397.361755 
	C566.686218,397.884155 564.815186,398.028992 562.491394,398.248932 
	C561.765442,396.241577 561.492371,394.159088 561.350769,391.709229 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M561.237793,411.518738 
	C562.428955,411.333801 563.699280,411.324005 564.634521,411.878906 
	C566.131653,412.767120 567.406616,414.029816 568.775452,415.134186 
	C567.218079,416.128021 565.700562,417.195587 564.079834,418.072266 
	C563.572205,418.346832 562.786499,418.107239 561.712524,418.109772 
	C561.253479,416.060120 561.210022,414.003693 561.237793,411.518738 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M469.241577,597.445923 
	C470.917297,597.668640 472.593048,597.891296 474.687500,598.174316 
	C475.107300,600.154968 475.108398,602.075378 475.097351,604.408325 
	C473.347687,604.867615 471.610138,604.914307 469.436310,604.960815 
	C469.000000,604.960754 469.000214,605.020630 468.969482,604.556946 
	C469.039703,601.877502 469.140656,599.661682 469.241577,597.445923 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M469.198792,597.034851 
	C469.140656,599.661682 469.039703,601.877502 468.998413,604.549561 
	C466.928619,605.001526 464.799164,604.997314 462.210510,605.008789 
	C461.700653,602.515015 461.649994,600.005432 461.439728,596.761292 
	C461.235657,594.718018 461.191223,593.409302 461.146790,592.100647 
	C461.321320,590.192444 461.495850,588.284302 461.459961,586.070801 
	C461.259766,585.031006 461.270081,584.296509 461.171692,583.207764 
	C463.477417,583.049805 465.891815,583.246094 468.670776,583.589478 
	C469.075592,588.032288 469.115784,592.328064 469.198792,597.034851 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M447.490479,588.949829 
	C447.046997,587.374695 446.956879,585.766968 446.693542,583.797485 
	C446.684113,583.280273 446.847870,583.124878 447.648132,582.807983 
	C450.573822,582.675781 452.862976,582.705017 455.152100,582.734253 
	C455.865723,582.815063 456.579376,582.895935 457.640869,583.070190 
	C454.607147,585.081482 451.225494,586.999329 447.490479,588.949829 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M460.721375,592.055359 
	C461.191223,593.409302 461.235657,594.718018 461.220764,596.415222 
	C459.149872,596.642883 456.103882,598.116455 456.214752,594.424011 
	C456.240112,593.579224 458.866577,592.812622 460.721375,592.055359 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M390.865662,404.724182 
	C388.531281,404.795563 386.196838,404.866943 383.427002,405.001892 
	C382.991608,397.949066 382.991608,390.832642 382.991608,383.105713 
	C385.462433,383.305511 387.350372,383.458191 389.480530,384.265869 
	C389.807831,386.596405 389.892914,388.271881 389.978577,390.382538 
	C390.274689,395.453217 390.570190,400.088684 390.865662,404.724182 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M389.977966,389.947357 
	C389.892914,388.271881 389.807831,386.596405 389.787170,384.508484 
	C390.707764,383.798370 391.563934,383.500702 393.096161,383.116638 
	C394.855286,383.037720 395.938354,383.045197 396.999603,383.030182 
	C396.977814,383.007690 396.958282,382.948120 396.978577,383.331909 
	C397.043579,384.481415 397.088287,385.247101 397.094666,386.367371 
	C396.312164,387.877136 395.800690,389.643646 394.766693,390.037170 
	C393.382355,390.564056 391.593262,390.027374 389.977966,389.947357 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M397.021393,383.052673 
	C395.938354,383.045197 394.855286,383.037720 393.378082,382.927551 
	C392.003174,380.737732 391.022430,378.650665 390.068848,376.170593 
	C390.096008,375.777588 390.105377,375.843231 390.515289,375.847412 
	C392.832153,375.952271 394.739105,376.052917 397.021393,376.173370 
	C397.021393,378.821411 397.021393,380.937042 397.021393,383.052673 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M385.910767,461.836639 
	C383.383423,462.388550 381.773773,461.824280 382.155945,458.397064 
	C382.320343,447.097290 382.541534,436.185638 382.728607,425.273376 
	C382.799561,421.134277 382.791931,416.993866 382.910919,412.387634 
	C384.067871,411.977295 385.134003,412.033295 386.604675,412.606049 
	C387.016296,415.602142 387.029449,418.081451 387.029358,420.560791 
	C387.028961,429.427856 387.147888,438.297363 386.972076,447.161011 
	C386.874939,452.057983 386.280823,456.945099 385.910767,461.836639 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M412.061615,468.965820 
	C409.808075,468.965820 407.554535,468.965820 405.401581,468.965820 
	C403.529144,466.368805 405.531342,460.918060 399.032837,462.056946 
	C397.850372,461.348816 397.397491,460.682159 397.013000,459.550171 
	C397.043884,449.034729 397.006439,438.984619 397.031738,428.479675 
	C397.062256,423.708557 397.030029,419.392303 397.091553,414.715271 
	C397.068451,413.389038 396.951599,412.423645 396.834778,411.458221 
	C396.949219,409.524933 397.063660,407.591614 397.152588,404.856140 
	C395.773895,398.027893 399.860168,397.473663 404.905090,397.503296 
	C404.905090,392.684326 404.905090,388.261871 405.091431,383.646606 
	C405.493042,382.952759 405.708313,382.451721 406.339478,381.977539 
	C408.127930,381.992706 409.500488,381.981049 410.937988,382.387573 
	C411.332092,385.603058 411.859772,388.393951 411.950531,391.198975 
	C412.162811,397.762512 412.077972,397.765259 418.727356,398.494873 
	C418.742950,402.030579 418.683990,405.114136 418.570129,408.262329 
	C418.450684,408.502106 418.483612,408.655823 418.699738,409.077545 
	C418.837769,409.561371 418.890076,409.755707 418.836975,410.337463 
	C418.809662,413.301544 418.887756,415.878235 418.955017,418.667542 
	C418.944153,418.880157 418.873138,419.299805 418.464355,419.162720 
	C416.109558,418.858734 414.163513,418.691895 411.344971,418.450256 
	C411.344971,413.111664 411.632690,407.979034 411.181000,402.912292 
	C411.033081,401.252838 409.080292,398.585754 407.748657,398.454651 
	C405.318298,398.215485 404.043060,400.140076 404.142242,403.096649 
	C404.414581,411.213318 404.231781,419.344086 404.326843,427.468109 
	C404.423523,435.733795 404.636719,443.998047 404.795502,452.438171 
	C404.792084,452.613373 404.886444,452.950928 404.943909,453.341125 
	C406.973633,454.299194 408.945953,454.867035 411.296509,455.543762 
	C411.515533,459.930450 411.743042,464.487457 411.993011,469.024414 
	C412.015442,469.004333 412.061615,468.965820 412.061615,468.965820 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M396.400879,411.490967 
	C396.951599,412.423645 397.068451,413.389038 396.738220,414.864990 
	C394.581482,419.991943 393.424866,424.580750 396.968994,428.934540 
	C397.006439,438.984619 397.043884,449.034729 396.649658,459.604980 
	C394.122772,460.778015 392.027557,461.430817 389.932343,462.083618 
	C388.859161,462.036896 387.785950,461.990112 386.311768,461.890015 
	C386.280823,456.945099 386.874939,452.057983 386.972076,447.161011 
	C387.147888,438.297363 387.028961,429.427856 387.029358,420.560791 
	C387.029449,418.081451 387.016296,415.602142 387.000336,412.668457 
	C389.983307,411.984009 392.975159,411.753876 396.400879,411.490967 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M404.905090,383.839447 
	C404.905090,388.261871 404.905090,392.684326 404.905090,397.503296 
	C399.860168,397.473663 395.773895,398.027893 397.101593,404.477753 
	C395.301270,404.863403 393.526398,404.825256 391.308594,404.755646 
	C390.570190,400.088684 390.274689,395.453217 389.978577,390.382538 
	C391.593262,390.027374 393.382355,390.564056 394.766693,390.037170 
	C395.800690,389.643646 396.312164,387.877136 397.377472,386.273804 
	C398.473389,384.996094 399.248108,384.166534 400.434937,383.307617 
	C401.934265,383.159576 403.021423,383.040894 404.222961,383.091461 
	C404.509064,383.470825 404.698303,383.663757 404.905090,383.839447 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M389.945557,462.510162 
	C392.027557,461.430817 394.122772,460.778015 396.581329,460.070343 
	C397.397491,460.682159 397.850372,461.348816 398.612518,462.116577 
	C398.278564,464.268127 397.635315,466.318573 396.528839,468.728638 
	C394.057037,469.071655 392.048431,469.055084 390.011902,469.017212 
	C389.983948,468.995911 389.962341,468.929016 389.962341,468.929016 
	C389.961151,466.931580 389.959961,464.934143 389.945557,462.510162 
z"/>
<path fill="#1B1B1A" opacity="1.000000" stroke="none" 
	d="
M386.995544,477.848907 
	C386.560059,477.893707 386.124603,477.938507 385.363312,478.021667 
	C384.570190,477.765259 384.102844,477.470490 383.372620,477.086792 
	C383.109680,474.465759 383.109680,471.933685 383.109680,469.096466 
	C385.307007,468.961945 387.203796,468.845856 389.531464,468.829407 
	C389.962341,468.929016 389.983948,468.995911 390.016602,469.443237 
	C389.991760,471.592407 389.934235,473.294281 389.582977,475.092834 
	C388.524689,476.075989 387.760132,476.962463 386.995544,477.848907 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M398.001556,490.394348 
	C397.424408,488.248230 397.232727,486.164490 397.016602,483.814941 
	C399.513794,483.507629 401.457123,483.268463 403.821564,482.977478 
	C404.079620,481.105072 404.343018,479.194061 404.698395,476.615662 
	C409.802887,477.489197 412.925537,475.695740 411.946625,469.393982 
	C412.061615,468.965820 412.015442,469.004333 412.454956,468.970734 
	C414.205078,468.939453 415.515839,468.933411 416.826385,468.945496 
	C425.324615,469.023865 425.324615,469.025146 425.987335,477.030731 
	C426.005737,477.009125 426.044708,476.967865 425.640686,476.880859 
	C420.598907,477.013153 417.656464,478.697662 418.986572,484.025848 
	C419.010803,483.997955 419.062073,483.944733 419.062073,483.944733 
	C416.663483,483.922363 414.264923,483.899994 410.991608,483.897247 
	C408.530029,483.916870 406.943176,483.916870 405.514679,483.916870 
	C404.915466,486.531799 404.420563,488.691589 403.925659,490.851379 
	C402.079437,490.719849 400.233246,490.588318 398.001556,490.394348 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M419.058075,490.987488 
	C419.034973,488.934326 419.011871,486.881195 419.025391,484.386383 
	C419.062073,483.944733 419.010803,483.997955 419.380951,483.972687 
	C423.759460,483.343658 426.950073,482.002441 426.044708,476.967865 
	C426.044708,476.967865 426.005737,477.009125 426.401062,476.900970 
	C428.807373,476.812653 430.818329,476.832489 432.835938,477.286407 
	C432.829956,479.710815 432.817322,481.701172 432.618835,483.801514 
	C432.433014,483.911560 432.047211,484.105896 431.615265,484.050079 
	C429.597931,484.079895 428.012543,484.165497 425.937653,484.277588 
	C425.937653,486.806366 425.937653,488.915833 425.786743,491.015442 
	C425.635834,491.005524 425.341370,490.936371 424.883667,490.907684 
	C422.599152,490.904816 420.772308,490.930634 418.979492,490.942993 
	C419.013458,490.929565 419.058075,490.987488 419.058075,490.987488 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M411.693878,492.010345 
	C413.233704,487.443237 416.224152,492.388397 418.654205,490.998993 
	C419.058075,490.987488 419.013458,490.929565 418.900757,491.387024 
	C418.953003,493.627045 419.117889,495.409607 419.078979,497.361694 
	C419.007599,498.288971 419.140015,499.046692 419.103699,500.201385 
	C418.940643,501.744263 418.946350,502.890137 418.952026,504.036011 
	C418.952026,504.036011 419.021393,504.049194 419.041565,504.020538 
	C411.630249,504.649170 411.630249,504.649170 411.823547,493.859009 
	C411.834930,492.742920 411.764404,492.376617 411.693878,492.010345 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M404.179657,491.064453 
	C404.420563,488.691589 404.915466,486.531799 405.514679,483.916870 
	C406.943176,483.916870 408.530029,483.916870 410.549255,483.980286 
	C410.423309,487.925323 408.809692,490.851685 404.179657,491.064453 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M411.547546,492.071777 
	C411.764404,492.376617 411.834930,492.742920 411.852325,493.377563 
	C411.666534,493.141693 411.533875,492.637482 411.547546,492.071777 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M389.876709,474.996124 
	C389.934235,473.294281 389.991760,471.592407 390.044556,469.464539 
	C392.048431,469.055084 394.057037,469.071655 396.492920,469.102753 
	C397.045898,471.216278 397.171539,473.315308 397.342010,476.162842 
	C395.241516,476.162842 393.083160,476.162842 390.481445,475.811890 
	C390.038055,475.460968 389.876709,474.996124 389.876709,474.996124 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M389.582977,475.092834 
	C389.876709,474.996124 390.038055,475.460968 390.068481,475.705811 
	C389.289185,476.587372 388.479462,477.224091 387.332642,477.854858 
	C387.760132,476.962463 388.524689,476.075989 389.582977,475.092834 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M432.726135,254.928619 
	C432.766022,257.378815 433.083160,259.862976 432.787964,262.272125 
	C432.175751,267.269012 433.853699,269.711426 439.594025,269.122253 
	C440.045441,269.603912 440.044769,269.790802 439.717194,270.101685 
	C437.327972,272.331238 437.674225,277.143616 432.380920,276.081604 
	C429.582428,276.516541 425.998718,274.451233 425.453369,278.767273 
	C424.167572,275.283203 423.072357,272.140167 421.977173,268.997101 
	C424.617493,269.035614 425.681213,267.805115 425.614441,265.110046 
	C425.513824,261.045166 425.722290,256.972595 426.154999,252.658401 
	C428.582458,253.251877 430.654297,254.090256 432.726135,254.928619 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M421.715027,268.918701 
	C423.072357,272.140167 424.167572,275.283203 425.521790,279.172852 
	C426.305603,285.924316 420.848572,281.396881 418.920319,284.218903 
	C418.920319,285.706085 418.920319,287.776123 418.495911,289.973053 
	C415.710297,290.082458 413.349091,290.064972 411.038879,289.610901 
	C411.080902,287.129272 411.071899,285.084198 411.162048,282.604065 
	C411.194153,280.103271 411.127136,278.037506 411.029480,275.991089 
	C410.998810,276.010498 411.037750,276.071777 411.258759,276.148560 
	C411.479767,276.225372 411.889832,276.000000 411.889832,276.000000 
	C413.651184,276.007294 415.412537,276.014618 417.611542,275.930603 
	C418.377533,273.848724 418.705902,271.858093 419.045044,269.460571 
	C419.476837,269.012909 419.897858,268.972137 420.617218,268.834320 
	C421.094666,268.771667 421.273804,268.806000 421.715027,268.918701 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M418.578308,242.328522 
	C418.617737,241.555130 418.714661,241.093613 418.894165,240.287170 
	C421.040253,239.957382 423.103760,239.972519 425.610413,239.969788 
	C425.969299,242.026245 425.885040,244.100555 425.927826,246.603134 
	C425.862366,247.030472 425.669922,247.029556 425.093384,246.957031 
	C424.464722,246.836349 424.263458,246.909286 424.105438,247.104248 
	C422.341370,247.070374 420.577301,247.036499 418.371368,247.014847 
	C418.058350,246.882538 418.187195,246.738007 418.436096,246.093277 
	C418.556122,245.593063 418.471069,245.153885 418.471069,245.153885 
	C418.525970,244.316055 418.580872,243.478210 418.578308,242.328522 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M432.802246,254.551514 
	C430.654297,254.090256 428.582458,253.251877 426.245819,252.196976 
	C425.955658,251.233917 425.930328,250.487396 425.975311,249.371674 
	C428.063629,249.647247 430.081604,250.292007 432.444244,251.021408 
	C432.818726,252.128845 432.848511,253.151642 432.802246,254.551514 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M424.309814,247.126709 
	C424.263458,246.909286 424.464722,246.836349 424.804871,246.946838 
	C424.900421,247.008240 424.514160,247.149155 424.309814,247.126709 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M411.604462,268.959290 
	C411.949127,270.024475 411.846771,271.085083 411.587006,272.690979 
	C411.298981,274.181488 411.168365,275.126617 411.037750,276.071777 
	C411.037750,276.071777 410.998810,276.010498 410.619507,276.088837 
	C404.751221,275.390137 404.004700,278.754944 403.813721,283.015778 
	C401.235718,283.015778 399.113800,283.003296 396.991882,282.990845 
	C396.991882,282.990845 397.035217,283.043579 397.056396,283.070312 
	C397.068726,281.072906 397.059875,279.048798 397.094971,276.596008 
	C401.415100,276.394958 405.394135,276.279114 404.086609,269.695068 
	C406.409393,269.170258 408.783447,269.062469 411.604462,268.959290 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M411.258759,276.148560 
	C411.168365,275.126617 411.298981,274.181488 411.610535,273.094543 
	C411.845703,273.712921 411.899933,274.473053 411.921997,275.616608 
	C411.889832,276.000000 411.479767,276.225372 411.258759,276.148560 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M418.369812,245.241333 
	C418.471069,245.153885 418.556122,245.593063 418.500854,245.810150 
	C418.199280,245.833496 418.140289,245.600677 418.369812,245.241333 
z"/>
<path fill="#7C7DDF" opacity="1.000000" stroke="none" 
	d="
M404.108612,382.922211 
	C403.021423,383.040894 401.934265,383.159576 400.061646,383.202972 
	C398.503601,383.067841 397.730927,383.007996 396.958282,382.948120 
	C396.958282,382.948120 396.977814,383.007690 396.999603,383.030151 
	C397.021393,380.937042 397.021393,378.821411 397.021393,376.173370 
	C394.739105,376.052917 392.832153,375.952271 390.486206,375.863373 
	C390.046509,347.559265 390.045746,319.243378 389.986816,290.460510 
	C391.687073,289.883972 393.445496,289.774414 395.904114,289.796631 
	C399.256805,289.964935 401.909302,290.001434 404.542358,290.364075 
	C404.478394,291.125488 404.433868,291.560730 404.269592,292.350647 
	C404.178802,294.211273 404.207764,295.717224 404.206879,297.425262 
	C404.177002,297.627319 404.024231,298.006165 403.677307,298.001648 
	C402.639893,297.974609 401.949432,297.952057 401.182129,297.857788 
	C401.105255,297.786102 401.031494,297.589233 400.884064,297.427643 
	C400.466553,297.106842 400.183075,297.077820 399.467651,297.195099 
	C398.331543,298.716492 397.004181,300.216095 396.990143,301.727844 
	C396.837158,318.216461 396.889374,334.706970 396.886414,351.196960 
	C396.885376,357.005066 396.886230,362.813202 396.886230,368.793915 
	C398.514435,368.793915 399.652740,368.793915 401.187317,368.855530 
	C402.383331,368.979279 403.183105,369.041382 403.995972,369.561401 
	C404.042236,374.320251 404.075409,378.621246 404.108612,382.922211 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M404.561798,290.037903 
	C401.909302,290.001434 399.256805,289.964935 396.272675,289.730530 
	C396.245819,287.632416 396.550629,285.732239 396.923645,283.411438 
	C399.113800,283.003296 401.235718,283.015778 404.234283,283.011658 
	C407.094940,283.009796 409.078918,283.024445 411.062927,283.039093 
	C411.071899,285.084198 411.080902,287.129272 411.045929,289.571228 
	C411.001984,289.968079 411.081390,289.982178 410.619202,290.019592 
	C408.291931,290.050629 406.426880,290.044281 404.561798,290.037903 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M553.968140,326.506714 
	C552.717834,323.429871 553.478027,318.486420 547.325928,318.173828 
	C551.712830,318.292358 554.054565,315.673706 555.754639,311.198090 
	C558.008850,311.083740 559.635803,311.241302 561.262756,311.398865 
	C561.262756,311.398865 561.528809,311.704315 561.634399,312.288757 
	C561.854309,314.906433 561.968506,316.939667 561.912964,318.970978 
	C561.743103,318.969025 561.404968,318.935425 561.404968,318.935425 
	C554.272339,318.544312 554.272339,318.544312 553.968140,326.506714 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M569.439941,391.013000 
	C568.908081,388.851776 568.799255,386.651276 568.666748,384.073425 
	C570.457703,383.452332 572.272278,383.208649 574.537231,382.947571 
	C575.021790,385.382019 575.055908,387.833832 574.989502,390.743652 
	C573.213623,391.125702 571.538330,391.049713 569.439941,391.013000 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M561.376465,319.357300 
	C561.404968,318.935425 561.743103,318.969025 562.287231,318.980194 
	C564.499146,319.324005 566.166809,319.656647 567.834534,319.989319 
	C567.960144,321.789215 568.085693,323.589081 568.103027,325.836487 
	C567.235779,326.170807 566.476868,326.057648 565.203735,325.610168 
	C563.683472,324.839172 562.677307,324.402527 561.671204,323.965851 
	C561.563416,322.570282 561.455688,321.174713 561.376465,319.357300 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M561.679321,324.276703 
	C562.677307,324.402527 563.683472,324.839172 564.829590,325.524872 
	C563.875549,325.378448 562.781555,324.983002 561.679321,324.276703 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M540.312866,277.017365 
	C540.717834,276.871796 541.122864,276.726227 541.694885,276.449585 
	C542.934814,276.302307 544.007751,276.286163 545.544067,276.542236 
	C546.257751,277.217590 546.507935,277.620697 546.758057,278.023773 
	C546.756409,282.863556 546.754761,287.703308 546.691467,292.936951 
	C544.395386,290.220978 539.289978,289.334656 539.705811,283.427002 
	C539.978760,280.739044 540.145874,278.878204 540.312866,277.017365 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M546.834839,277.686279 
	C546.507935,277.620697 546.257751,277.217590 545.941284,276.586365 
	C546.062683,276.281830 546.250488,276.205475 546.731750,276.049225 
	C546.987244,276.429169 546.949402,276.888977 546.834839,277.686279 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M568.212585,319.825439 
	C566.166809,319.656647 564.499146,319.324005 562.457092,318.982147 
	C561.968506,316.939667 561.854309,314.906433 561.775391,312.434143 
	C563.732910,311.923309 565.655090,311.851440 568.060730,311.705292 
	C570.695068,311.641113 572.845947,311.651245 575.409790,311.702484 
	C575.879395,313.856567 575.936035,315.969543 576.022522,318.529877 
	C573.565125,319.205322 571.077881,319.433441 568.212585,319.825439 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M561.352173,310.962433 
	C559.635803,311.241302 558.008850,311.083740 556.060669,310.939880 
	C555.230774,309.125916 554.722168,307.298309 554.304321,305.214905 
	C561.139526,303.865356 561.792236,304.381073 561.352173,310.962433 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M510.728027,247.176422 
	C511.128815,250.952301 512.491150,255.382263 506.163879,255.067368 
	C504.817993,259.636536 500.712433,261.870636 497.739746,265.065887 
	C497.558807,265.260376 497.774689,265.819336 497.595123,266.020569 
	C494.485535,269.504944 489.405334,271.025024 487.725311,275.939697 
	C486.178925,275.182648 483.280914,274.381866 483.303772,273.675629 
	C483.461121,268.813538 480.618378,268.683044 476.580505,268.736542 
	C476.032349,267.235443 476.047180,266.112762 476.440369,264.953125 
	C478.916107,263.868744 481.013519,262.821320 483.530334,261.823914 
	C489.958313,263.037964 490.308472,259.288422 490.397888,254.953918 
	C493.250305,254.768082 496.879181,256.140747 496.763580,251.933014 
	C496.637787,247.355148 498.914185,246.779236 502.569489,247.041107 
	C505.025055,247.217010 507.511414,246.962067 509.984009,246.899872 
	C510.149384,246.961304 510.314728,247.022736 510.728027,247.176422 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M509.987000,246.499451 
	C507.511414,246.962067 505.025055,247.217010 502.569489,247.041107 
	C498.914185,246.779236 496.637787,247.355148 496.763580,251.933014 
	C496.879181,256.140747 493.250305,254.768082 490.415527,254.980042 
	C490.005310,254.993164 490.050873,255.036636 490.049042,254.591888 
	C489.983093,246.219559 489.983093,246.219559 482.945496,247.623596 
	C482.599701,245.192535 482.271027,243.151886 481.942383,241.111267 
	C484.000977,240.721954 486.059601,240.332642 488.765442,239.969177 
	C489.412689,239.995026 489.903015,240.027710 489.903015,240.027710 
	C491.144714,240.195190 492.386414,240.362686 494.322754,240.657501 
	C495.971649,240.531204 496.925873,240.277557 497.880096,240.023895 
	C499.694855,240.001343 501.509644,239.978790 503.787994,239.923035 
	C508.379669,239.912231 510.394714,241.887466 509.987000,246.499451 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M454.139496,251.742371 
	C453.306549,250.534470 452.584351,248.394241 451.621521,248.279877 
	C447.427643,247.781693 446.560242,245.334473 447.294006,241.357529 
	C449.123260,240.669403 450.565704,240.408524 452.008118,240.147644 
	C452.455231,240.091568 452.902313,240.035492 453.681335,239.914627 
	C454.188843,239.911194 454.364380,239.972534 454.789520,240.549500 
	C454.833374,244.106537 454.582458,247.177063 458.973114,247.404083 
	C458.934906,247.573730 458.857544,247.913605 458.481812,247.885590 
	C454.986145,247.316956 454.813354,249.785477 454.139496,251.742371 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M459.011322,247.234436 
	C454.582458,247.177063 454.833374,244.106537 455.047974,240.654541 
	C457.097961,240.183578 459.139130,240.123169 461.613068,239.996033 
	C462.045868,239.929291 461.758270,240.333481 461.766907,240.894882 
	C461.810272,241.660660 461.845062,241.865036 461.879822,242.069412 
	C461.822693,242.494293 461.765594,242.919174 461.669281,243.996338 
	C460.757172,245.510559 459.884247,246.372498 459.011322,247.234436 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M451.597778,240.080414 
	C450.565704,240.408524 449.123260,240.669403 447.302368,240.935577 
	C446.923920,240.940872 446.934052,240.446609 446.983276,240.204376 
	C448.417480,239.979141 449.802490,239.996170 451.597778,240.080414 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M440.044098,269.977692 
	C440.044769,269.790802 440.045441,269.603912 440.032166,269.137085 
	C440.015533,261.889435 440.012817,254.921707 439.991455,247.486908 
	C446.161987,245.798599 446.787933,250.536697 448.209808,255.316132 
	C448.228302,257.933533 448.103485,259.810547 447.748169,262.065765 
	C447.227570,267.187195 446.937469,271.930389 446.297729,276.675568 
	C444.012909,276.465698 442.077759,276.253845 440.100067,275.608521 
	C440.053070,273.442566 440.048584,271.710144 440.044098,269.977692 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M454.135742,252.173325 
	C454.813354,249.785477 454.986145,247.316956 458.591278,248.246185 
	C459.748932,250.756805 460.421387,252.878815 461.057068,255.354370 
	C461.259033,256.836548 461.497711,257.965149 461.791748,259.440369 
	C461.907135,260.482788 461.967163,261.178650 461.755341,261.914398 
	C461.307373,262.004547 461.131256,262.054779 460.527710,262.058167 
	C458.358582,261.935303 456.616913,261.859283 454.836731,261.405823 
	C454.536804,258.789581 454.275330,256.550812 454.013855,254.312042 
	C454.053223,253.742783 454.092621,253.173538 454.135742,252.173325 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M453.650574,254.319748 
	C454.275330,256.550812 454.536804,258.789581 454.415894,261.426208 
	C452.015228,261.778564 449.996948,261.733063 447.978668,261.687561 
	C448.103485,259.810547 448.228302,257.933533 448.416870,255.664948 
	C450.082886,254.958054 451.685089,254.642761 453.650574,254.319748 
z"/>
<path fill="#7C7DDF" opacity="1.000000" stroke="none" 
	d="
M475.447449,240.025070 
	C475.887238,240.030930 475.968842,240.408035 475.906281,241.044098 
	C475.913422,243.765900 475.983124,245.851608 475.749451,247.971237 
	C475.243439,248.027237 475.040802,248.049332 474.417145,248.066071 
	C470.997711,248.312546 468.254456,248.225082 468.613007,244.012726 
	C468.647797,242.995789 468.682587,241.978836 468.697815,240.582886 
	C470.788055,240.142319 472.897858,240.080765 475.447449,240.025070 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M468.193665,244.012146 
	C468.254456,248.225082 470.997711,248.312546 474.464233,248.292252 
	C474.932343,248.523804 475.011566,248.979187 475.004486,249.387238 
	C474.993683,251.151428 474.989960,252.507568 474.837463,254.103043 
	C476.783691,254.626556 478.878693,254.910721 480.916687,255.491974 
	C481.549225,256.864868 482.238831,257.940704 482.985657,259.357788 
	C483.065582,260.390686 483.088257,261.082306 483.110931,261.773895 
	C481.013519,262.821320 478.916107,263.868744 476.370239,264.650879 
	C475.123749,263.580322 474.325745,262.775055 473.541199,261.586548 
	C472.209961,260.881348 470.865234,260.559387 469.242981,259.974304 
	C469.113983,254.531540 465.726471,254.577621 461.817688,255.025116 
	C461.576538,255.012512 461.093811,255.000824 461.093811,255.000824 
	C460.421387,252.878815 459.748932,250.756805 458.967041,248.274200 
	C458.857544,247.913605 458.934906,247.573730 458.973114,247.404083 
	C459.884247,246.372498 460.757172,245.510559 461.727234,244.314423 
	C463.807709,243.990662 465.791016,244.001114 468.193665,244.012146 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M461.941040,241.918518 
	C461.845062,241.865036 461.810272,241.660660 461.781250,241.142502 
	C461.986084,241.117722 462.057831,241.430695 461.941040,241.918518 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M418.965881,418.454956 
	C418.887756,415.878235 418.809662,413.301544 419.263367,410.373108 
	C424.480347,410.681183 426.189697,407.921600 424.784637,404.228180 
	C423.073883,399.731171 425.003723,395.986206 425.771484,392.356995 
	C425.978119,394.466431 426.100281,396.154633 426.243073,398.127563 
	C429.173309,397.455017 431.435974,396.935669 433.701019,396.415802 
	C432.561890,392.133759 430.268860,390.084259 425.917175,390.704193 
	C424.700043,388.676086 423.558075,386.981506 422.316254,385.138733 
	C423.798584,384.303986 424.908386,383.679016 426.454376,383.105774 
	C428.943665,383.089111 430.996765,383.020660 433.022766,383.395172 
	C433.461334,386.084320 431.366638,389.838898 435.572174,389.956757 
	C439.374573,390.063293 440.326263,391.452484 440.285004,394.950043 
	C440.273590,395.920166 442.410828,397.690430 443.551819,397.681976 
	C444.699432,397.673492 446.334900,396.109253 446.841156,394.856720 
	C447.409424,393.450775 446.952209,391.630280 447.305298,390.070526 
	C448.409637,390.131775 449.149750,390.112610 450.279205,390.183716 
	C451.851837,391.255341 454.056244,392.242950 454.050323,393.217163 
	C454.015778,398.913635 457.294495,398.125854 460.811859,397.487671 
	C461.846191,390.495331 461.846130,390.497040 455.396210,390.240021 
	C454.571960,390.207184 453.748932,390.145203 453.353333,389.821045 
	C453.959320,388.412415 454.292450,387.279419 454.291992,386.146515 
	C454.284760,369.103394 454.233704,352.060272 454.168915,334.672394 
	C453.807220,334.038055 453.469513,333.748505 452.769958,333.380676 
	C450.926971,333.358551 449.445770,333.414673 447.691254,333.134094 
	C447.325806,330.185242 447.233734,327.573120 447.513611,324.930969 
	C448.925781,323.279938 450.698853,321.719147 450.837982,320.024200 
	C451.058289,317.340942 450.184570,314.567871 450.216370,311.690735 
	C454.317719,311.625732 454.341125,309.145294 454.327759,306.490906 
	C454.275146,296.044342 454.275940,285.597443 454.277496,275.150665 
	C454.278473,268.725220 454.295227,268.725220 461.043030,269.398529 
	C460.791077,271.644806 460.514893,273.491669 460.121429,276.123016 
	C465.787354,276.123016 470.742401,276.123016 475.697449,276.123016 
	C475.763733,278.933594 475.864929,281.743896 475.887085,284.554810 
	C475.912079,287.723175 475.300690,291.200256 480.507935,290.593170 
	C481.161102,290.516998 482.807281,292.652344 482.671051,293.565857 
	C482.458130,294.993896 481.244080,297.264374 480.226990,297.393890 
	C477.238953,297.774384 476.327454,298.997528 475.428772,301.845612 
	C474.953217,303.352814 471.307343,303.859619 468.626831,304.434631 
	C468.057495,302.036560 467.954102,300.021942 467.904419,297.541718 
	C467.958130,292.532959 467.958130,287.989868 467.958130,283.615814 
	C463.485046,282.233154 461.293274,283.021484 461.674866,287.636780 
	C461.958527,291.067932 461.895294,294.527771 461.916687,298.435883 
	C461.857056,302.959534 461.867310,307.022125 461.859100,311.186859 
	C461.840607,311.288971 461.969940,311.451233 461.542053,311.584961 
	C458.864502,312.275299 454.871460,312.480774 454.676025,313.451111 
	C453.946167,317.074982 453.934204,321.084595 454.847382,324.642975 
	C455.102661,325.637787 459.463593,325.579010 461.884125,326.052887 
	C461.827118,326.102234 461.865143,326.248138 461.865143,326.248138 
	C461.808655,329.192963 461.788239,332.139038 461.685577,335.082245 
	C461.583984,337.994659 462.057495,339.964233 465.771179,340.305420 
	C470.304443,340.721924 468.110504,344.876678 468.948273,348.218628 
	C467.693329,355.247803 471.935486,354.884247 475.875549,354.878204 
	C475.846375,360.326202 475.817169,365.774170 475.605347,372.010620 
	C475.501709,374.809387 475.580750,376.819733 475.659790,378.830048 
	C475.537811,382.803894 478.088837,383.351440 481.741913,383.330444 
	C482.412933,383.655975 482.566620,383.802979 482.730957,383.938812 
	C482.825684,389.384216 482.965759,394.829407 482.995270,400.275177 
	C483.009155,402.839355 483.909271,404.823120 486.666016,404.013702 
	C487.998047,403.622559 489.667084,401.643494 489.768494,400.278290 
	C490.171265,394.857178 490.015900,389.394592 490.445801,383.535583 
	C492.822662,384.404236 494.820953,385.684723 496.861603,387.433960 
	C496.907837,391.041412 496.914215,394.180206 496.914978,397.318970 
	C496.916718,404.414459 496.915619,404.414459 504.182983,404.484863 
	C504.105865,405.929871 504.020813,406.979767 503.794250,408.265167 
	C503.796112,409.348389 503.939514,410.196106 504.153992,411.155212 
	C504.225037,411.266632 504.277527,411.525696 504.277527,411.525696 
	C501.238953,416.927795 499.100067,422.870239 493.499634,426.586639 
	C491.589478,427.854187 490.847748,430.760834 489.138550,432.489929 
	C487.670532,433.975006 485.584534,434.849182 483.381958,435.999939 
	C482.936890,433.333893 482.881683,430.661407 482.760376,427.314209 
	C482.522766,426.336487 482.262939,426.181793 481.914734,426.175507 
	C480.266785,426.166382 478.618866,426.157288 476.086609,426.152374 
	C471.719452,426.134399 468.200439,425.819641 464.772827,426.243744 
	C463.520020,426.398712 461.693298,428.236084 461.583832,429.438477 
	C461.484924,430.524933 463.230621,431.965637 464.421906,432.930328 
	C465.585785,433.872803 468.226624,434.389282 468.240540,435.129944 
	C468.339935,440.428070 472.249878,439.884277 475.549896,440.382141 
	C475.688721,442.637604 475.827576,444.893036 476.014832,447.574585 
	C468.911102,447.629883 468.845032,447.683044 468.844574,454.804321 
	C468.843689,468.510468 468.854889,482.216644 468.477234,496.445740 
	C461.489777,497.286682 461.489777,497.286682 461.766571,504.572998 
	C460.066650,504.646332 458.366760,504.719666 456.284363,504.375427 
	C455.922546,499.647186 455.943176,495.336517 456.373291,490.942810 
	C458.385742,489.954590 461.338715,489.107178 461.380249,488.135315 
	C461.745270,479.592651 461.591705,471.027863 461.591705,462.041656 
	C458.560669,462.041656 456.272919,462.041656 454.058197,461.648865 
	C454.131226,459.311951 454.131226,457.367767 454.131226,455.018402 
	C451.521820,455.018402 449.234436,455.018402 446.976410,454.551514 
	C447.014679,451.760437 447.052063,449.436005 447.027374,447.112213 
	C446.937317,438.643341 447.097473,440.486694 440.071350,439.571838 
	C440.154999,437.113861 440.198090,435.117523 440.174805,433.121979 
	C440.092133,426.037384 440.083557,426.037476 433.031433,425.427490 
	C432.660400,417.742004 433.973816,418.233917 425.869934,418.232361 
	C423.568573,418.231903 421.267212,418.376740 418.965881,418.454956 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M539.966736,389.964447 
	C539.841309,393.729004 541.352173,398.445862 534.762817,398.360657 
	C534.218384,398.353638 533.121399,400.448029 533.224182,401.471344 
	C533.347839,402.702698 534.224609,404.231659 535.251160,404.891754 
	C536.236084,405.525055 537.829590,405.211823 539.556946,405.768646 
	C540.027832,408.082733 540.097534,409.942688 540.121460,411.856628 
	C540.075806,411.910614 539.982056,412.016418 539.549927,411.971008 
	C533.031189,411.876740 533.031189,411.876740 532.938293,419.060669 
	C532.913269,419.084534 532.863464,419.132568 532.438354,419.141418 
	C529.999878,419.164337 527.986572,419.178345 525.929565,418.713318 
	C525.837280,409.102600 525.788635,399.970917 525.730103,390.393372 
	C525.701233,386.636749 525.856995,383.311066 525.575378,380.022827 
	C525.466003,378.745789 524.391541,376.503845 523.718018,376.489258 
	C518.567566,376.377838 518.643677,372.796478 518.503662,369.079285 
	C518.580688,368.613953 518.542664,368.355286 518.413391,367.626526 
	C518.440308,364.213715 518.489197,361.281219 518.607788,358.072144 
	C518.625366,356.310364 518.573181,354.825104 518.549561,352.939636 
	C518.839478,348.312531 516.941040,346.429932 512.477417,347.088684 
	C511.992859,347.047699 511.718933,347.075195 511.382782,346.695648 
	C511.149323,344.058807 510.983002,341.878204 510.839783,339.248230 
	C510.868591,329.629517 510.874298,320.460083 510.927368,310.801788 
	C510.945801,306.190674 510.916901,302.068451 510.956055,297.486572 
	C511.023376,292.275452 511.022644,287.523987 511.091003,282.364319 
	C511.242188,280.001648 511.324249,278.047180 511.406311,276.092773 
	C513.106689,276.153809 514.807129,276.214874 516.770020,276.087128 
	C517.402588,275.995026 517.772766,276.091705 518.302307,276.282013 
	C518.541687,280.219513 518.621765,284.063354 518.300171,288.468079 
	C514.863708,292.430450 515.082642,295.683838 518.511597,298.140808 
	C518.031189,303.297394 517.320801,308.447510 517.174683,313.613556 
	C517.109070,315.933563 518.496948,318.264404 518.650696,320.627686 
	C518.919861,324.763794 518.801941,328.929626 518.739807,333.081390 
	C518.687378,336.585754 517.495422,340.419861 523.354858,340.744812 
	C524.291931,340.796783 525.529663,344.842987 525.711853,347.116119 
	C526.080811,351.720947 525.821899,356.376129 525.821899,361.891052 
	C528.080261,361.891052 529.971252,361.891052 532.469482,361.998505 
	C533.200684,362.227936 533.324768,362.349945 533.349854,362.844727 
	C533.082642,364.816406 532.914490,366.415314 532.445679,367.913147 
	C530.800415,367.565247 529.454224,367.180786 528.112793,367.196869 
	C527.867737,367.199799 527.178833,369.335175 527.492126,369.625366 
	C528.556458,370.611267 529.954041,371.258728 531.278015,371.929596 
	C531.663513,372.124939 532.228333,371.966400 532.774231,372.423950 
	C532.777222,375.929443 532.717041,378.981110 532.318359,382.069763 
	C530.383179,383.077911 528.786438,384.049072 527.189697,385.020233 
	C527.460999,385.538483 527.732239,386.056763 528.003479,386.575043 
	C529.552307,386.375061 531.101074,386.175079 532.737122,386.373169 
	C533.456238,391.775360 537.429626,389.399536 540.020386,390.019379 
	C540.002441,390.001129 539.966736,389.964447 539.966736,389.964447 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M496.819244,386.965240 
	C494.820953,385.684723 492.822662,384.404236 490.498413,383.070679 
	C490.037140,380.923889 489.901794,378.830139 489.787842,375.833191 
	C489.927734,360.648407 490.046204,346.366821 490.443176,332.227661 
	C492.745575,333.195526 494.584259,335.744904 497.112671,333.130096 
	C497.238159,333.216888 497.410095,333.469055 497.316040,333.963287 
	C497.183594,351.984863 497.145203,369.512238 497.033875,387.025055 
	C496.960907,387.010529 496.819244,386.965240 496.819244,386.965240 
z"/>
<path fill="#99B66B" opacity="1.000000" stroke="none" 
	d="
M490.164673,332.085266 
	C490.046204,346.366821 489.927734,360.648407 489.740845,375.385498 
	C482.820251,375.463043 482.731537,375.462952 482.737213,368.879395 
	C482.749329,354.811249 482.862732,340.743195 483.427124,326.166779 
	C485.956635,325.784454 487.993652,325.910461 490.018311,326.022308 
	C490.005920,326.008118 489.980804,325.980042 489.958679,326.415985 
	C490.012573,328.596375 490.088623,330.340820 490.164673,332.085266 
z"/>
<path fill="#E4987F" opacity="1.000000" stroke="none" 
	d="
M511.021912,282.772522 
	C511.022644,287.523987 511.023376,292.275452 510.540588,297.463257 
	C505.574097,297.424255 503.653900,299.458954 504.009552,303.914337 
	C504.205566,306.370056 504.184418,308.843109 504.254944,311.757080 
	C503.853210,316.825470 503.457642,321.445099 502.736908,326.000183 
	C500.711853,325.816925 499.011932,325.698303 497.236389,325.145660 
	C496.997620,320.422180 496.834442,316.132721 496.671265,311.843262 
	C496.754578,308.359985 496.641296,304.854950 497.023163,301.404724 
	C497.165283,300.120544 498.556610,297.868317 499.223480,297.923645 
	C504.527496,298.363647 504.328094,295.043884 504.103149,290.698853 
	C503.867554,285.026031 505.517303,282.041351 511.021912,282.772522 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M475.794037,275.692871 
	C470.742401,276.123016 465.787354,276.123016 460.121429,276.123016 
	C460.514893,273.491669 460.791077,271.644806 461.030762,269.427032 
	C460.994293,269.056091 460.940918,269.023895 461.390533,269.014252 
	C466.886108,270.070129 469.577850,268.427521 468.980957,262.877441 
	C468.915955,262.272858 469.253082,261.625061 469.386505,261.019745 
	C469.369354,261.042145 469.451752,261.028992 469.577118,261.043610 
	C469.702484,261.058228 469.954803,261.051117 470.120972,261.363647 
	C471.042572,261.478699 471.798004,261.281250 472.544464,261.359894 
	C472.866241,261.747253 473.196991,261.858521 473.527710,261.969788 
	C474.325745,262.775055 475.123749,263.580322 475.991882,264.687866 
	C476.047180,266.112762 476.032349,267.235443 476.165161,268.761597 
	C476.172058,271.197662 476.031342,273.230225 475.794037,275.692871 
z"/>
<path fill="#9F4A8F" opacity="1.000000" stroke="none" 
	d="
M511.091003,282.364319 
	C505.517303,282.041351 503.867554,285.026031 504.083313,290.246490 
	C502.845520,290.566589 501.445984,290.920929 500.589539,290.393433 
	C499.363129,289.638092 497.817017,288.286163 497.738342,287.100616 
	C497.661591,285.944122 499.139069,284.565674 500.149414,283.486115 
	C501.389221,282.161377 503.724457,281.244080 504.052490,279.801819 
	C504.968170,275.775604 507.413971,275.326233 511.060486,275.959351 
	C511.324249,278.047180 511.242188,280.001648 511.091003,282.364319 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M461.969940,311.451233 
	C461.969940,311.451233 461.840607,311.288971 462.293640,311.188416 
	C464.465729,311.062622 466.184784,311.037384 467.903809,311.012177 
	C468.220398,313.772217 468.524902,316.533691 468.856232,319.291962 
	C469.410736,323.907928 467.870270,326.641266 462.295837,326.210876 
	C461.865143,326.248138 461.827118,326.102234 461.877502,325.586182 
	C461.941895,320.530487 461.955902,315.990845 461.969940,311.451233 
z"/>
<path fill="#99B66B" opacity="1.000000" stroke="none" 
	d="
M496.256897,311.851746 
	C496.834442,316.132721 496.997620,320.422180 497.162231,325.248047 
	C497.163727,325.784485 497.013672,325.985046 496.573975,325.972168 
	C494.083099,325.966217 492.031952,325.973145 489.980804,325.980042 
	C489.980804,325.980042 490.005920,326.008118 490.014893,325.562439 
	C489.911591,321.663177 489.799316,318.209564 489.687012,314.755981 
	C491.738861,313.790710 493.790680,312.825439 496.256897,311.851746 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M467.945648,310.574585 
	C466.184784,311.037384 464.465729,311.062622 462.312134,311.086304 
	C461.867310,307.022125 461.857056,302.959534 462.335968,298.448792 
	C464.500336,298.002930 466.175537,298.005157 467.850708,298.007385 
	C467.954102,300.021942 468.057495,302.036560 468.244049,304.479309 
	C468.213959,306.650604 468.100708,308.393799 467.945648,310.574585 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M489.577515,315.011688 
	C489.799316,318.209564 489.911591,321.663177 490.027283,325.576630 
	C487.993652,325.910461 485.956635,325.784454 483.486633,325.682556 
	C482.684448,322.341858 483.081085,319.594849 487.170349,318.609833 
	C488.147919,318.374359 488.713135,316.427277 489.577515,315.011688 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M475.858398,354.522644 
	C471.935486,354.884247 467.693329,355.247803 469.027039,348.656830 
	C471.848267,349.625580 475.781006,349.677429 475.858398,354.522644 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M475.733368,378.419800 
	C475.580750,376.819733 475.501709,374.809387 475.551453,372.486511 
	C475.722443,374.119110 475.764679,376.064331 475.733368,378.419800 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M482.695679,383.763977 
	C482.566620,383.802979 482.412933,383.655975 482.167633,383.376190 
	C482.330383,383.247589 482.528748,383.359131 482.695679,383.763977 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M532.889893,361.923584 
	C532.441162,354.921295 532.179077,348.101410 532.022583,340.413116 
	C532.161316,335.387146 532.194397,331.229584 532.282898,326.852173 
	C532.338318,326.632385 532.381104,326.182709 532.843018,326.168793 
	C534.536133,326.192413 535.767273,326.229950 537.120117,326.650208 
	C537.482056,330.976746 537.571594,334.938232 538.030334,338.856506 
	C538.239258,340.640869 539.177856,342.339783 539.831299,344.538818 
	C539.687744,350.968506 539.498352,356.936859 538.991089,362.854675 
	C536.931824,362.693451 535.190369,362.582703 533.448914,362.471954 
	C533.324768,362.349945 533.200684,362.227936 532.889893,361.923584 
z"/>
<path fill="#2F7EBD" opacity="1.000000" stroke="none" 
	d="
M532.227478,327.071991 
	C532.194397,331.229584 532.161316,335.387146 531.993286,339.970795 
	C531.438110,340.307404 531.017761,340.217957 530.000610,339.817932 
	C525.065430,336.171997 527.757751,331.470123 527.377319,327.328308 
	C528.140015,327.152344 528.581421,327.076080 529.346924,327.009094 
	C530.523193,327.036255 531.375305,327.054108 532.227478,327.071991 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M529.022888,326.999817 
	C528.581421,327.076080 528.140015,327.152344 527.198853,327.259674 
	C526.699158,327.290710 526.331665,327.194153 526.331665,327.194153 
	C526.228760,322.385406 526.125854,317.576691 526.480408,312.303589 
	C528.842773,311.543091 530.747681,311.246948 532.769897,311.199097 
	C532.979797,311.604431 533.072510,311.761475 533.046753,312.326538 
	C532.637451,315.212921 532.346680,317.691284 531.656982,320.155762 
	C526.399658,321.055267 525.935303,322.351959 529.089355,326.363953 
	C529.072754,326.576447 529.022888,326.999786 529.022888,326.999817 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M532.652588,310.950806 
	C530.747681,311.246948 528.842773,311.543091 526.573547,311.833282 
	C526.156738,309.590393 526.104248,307.353455 526.111816,304.714844 
	C529.488342,303.831116 532.785400,303.382538 532.962524,308.980408 
	C532.845459,310.111053 532.749023,310.530914 532.652588,310.950806 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M525.791199,289.206177 
	C529.297302,292.609833 528.532715,296.652161 526.377014,300.959045 
	C525.899963,297.299469 525.761719,293.442017 525.791199,289.206177 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M518.656006,297.689453 
	C515.082642,295.683838 514.863708,292.430450 518.256348,288.918945 
	C518.676331,291.618713 518.738403,294.428436 518.656006,297.689453 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M526.252563,327.634705 
	C526.331665,327.194153 526.699158,327.290710 526.877563,327.359314 
	C527.757751,331.470123 525.065430,336.171997 529.692017,339.726379 
	C527.037537,340.517181 525.738831,339.342651 525.929199,336.321594 
	C526.102051,333.578552 526.098999,330.824463 526.252563,327.634705 
z"/>
<path fill="#99B66B" opacity="1.000000" stroke="none" 
	d="
M539.856934,276.928802 
	C540.145874,278.878204 539.978760,280.739044 539.650635,283.062805 
	C535.905884,283.190948 531.880676,283.365173 532.926758,277.344177 
	C535.131226,276.890411 537.266113,276.865326 539.856934,276.928802 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M476.052826,247.937332 
	C475.983124,245.851608 475.913422,243.765900 475.924042,241.236191 
	C477.699585,240.872803 479.394806,240.953400 481.516205,241.072632 
	C482.271027,243.151886 482.599701,245.192535 482.960022,247.601700 
	C482.991730,247.970215 483.044586,247.968719 482.608154,247.949951 
	C480.132080,247.933243 478.092468,247.935287 476.052826,247.937332 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M489.875702,239.751770 
	C489.903015,240.027710 489.412689,239.995026 489.167877,239.962997 
	C489.046082,239.676224 489.201019,239.442612 489.543732,239.085876 
	C489.749237,239.119690 489.798828,239.297760 489.875702,239.751770 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M497.657898,239.857681 
	C496.925873,240.277557 495.971649,240.531204 494.783691,240.749756 
	C494.658142,240.325714 494.766357,239.936768 495.091766,239.332611 
	C496.017883,239.308762 496.726807,239.500107 497.657898,239.857681 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M461.812256,505.038635 
	C461.489777,497.286682 461.489777,497.286682 468.526306,496.902100 
	C468.932739,501.595947 468.906219,506.356476 468.837158,511.586914 
	C466.842072,512.015381 464.889618,511.973969 462.514771,511.947021 
	C462.014221,509.809082 461.936096,507.656677 461.812256,505.038635 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M475.236694,526.230530 
	C475.642670,528.549133 475.622253,530.804565 475.112000,533.492188 
	C472.760956,533.962036 470.899750,533.999634 469.038544,534.037231 
	C469.038544,534.037231 468.834717,533.880371 468.720886,533.803345 
	C468.599396,531.446899 468.591736,529.167480 468.563812,526.447998 
	C470.632477,526.060974 472.721405,526.114136 475.236694,526.230530 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M454.723022,538.133789 
	C454.991577,538.278809 455.046143,538.708557 455.101105,539.463074 
	C455.027649,544.510986 454.406342,548.760620 447.601013,548.095093 
	C447.205994,548.187073 446.957031,548.038086 446.882996,547.491638 
	C446.835144,545.013123 446.861328,543.081055 446.887482,541.148987 
	C446.878052,540.959045 446.868591,540.769104 446.920502,540.300781 
	C449.849152,541.168823 452.575226,541.652771 454.723022,538.133789 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M455.142761,582.332031 
	C452.862976,582.705017 450.573822,582.675781 447.893982,582.575073 
	C447.376587,580.609375 447.249969,578.715210 447.120422,576.399658 
	C450.194885,577.416199 455.817963,575.425354 455.142761,582.332031 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M453.979095,505.327881 
	C454.025116,506.602325 454.071106,507.876740 454.021667,509.520264 
	C449.505798,511.001678 447.481689,509.441315 447.960785,504.752960 
	C448.104645,503.345154 447.716003,501.882904 447.326965,499.751617 
	C447.074585,498.117828 447.066528,497.178070 447.289825,495.916870 
	C447.731689,493.774841 447.942200,491.954254 448.583405,490.136475 
	C450.482391,490.159912 451.950653,490.180481 453.408691,490.653259 
	C453.591980,495.846252 453.785553,500.587067 453.979095,505.327881 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M447.058411,496.238281 
	C447.066528,497.178070 447.074585,498.117828 447.081299,499.407532 
	C446.386383,501.636017 445.548859,503.476593 445.040192,505.403961 
	C444.539429,507.301147 444.372101,509.286346 443.570496,511.641449 
	C442.308014,512.093567 441.533569,512.137146 440.759125,512.180664 
	C440.759125,512.180664 440.458435,512.096802 440.265076,511.674957 
	C440.056244,510.535248 440.040710,509.817383 440.373535,508.915039 
	C441.144470,506.692230 441.701477,504.670807 441.960876,502.611908 
	C442.456085,498.681946 442.781677,494.730621 443.335144,490.784882 
	C443.492615,490.781616 443.807709,490.763550 443.969727,491.098022 
	C445.107300,493.034424 446.082855,494.636353 447.058411,496.238281 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M440.755371,512.585815 
	C441.533569,512.137146 442.308014,512.093567 443.465881,512.044800 
	C447.724518,515.321594 445.809143,519.566467 445.645447,523.482361 
	C445.627441,523.913391 444.425812,524.653748 443.829773,524.601807 
	C443.224670,524.549011 442.422455,523.794678 442.181305,523.165527 
	C441.720398,521.963196 441.596405,520.631775 441.093994,519.032166 
	C440.820801,516.803955 440.786224,514.897522 440.755371,512.585815 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M419.282776,497.192139 
	C419.117889,495.409607 418.953003,493.627045 418.866791,491.400482 
	C420.772308,490.930634 422.599152,490.904816 424.788147,491.367981 
	C425.082275,495.915863 425.014252,499.974792 424.971558,504.051605 
	C424.996948,504.069519 425.026978,504.015472 425.026978,504.015503 
	C423.291229,504.023102 421.555481,504.030701 419.385864,504.037170 
	C418.946350,502.890137 418.940643,501.744263 419.309387,500.054688 
	C419.550140,498.738007 419.416473,497.965088 419.282776,497.192139 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M431.933594,509.997803 
	C430.267944,510.603149 428.602295,511.208496 426.585510,511.852539 
	C425.830109,509.543701 425.425842,507.196198 425.024292,504.432068 
	C425.026978,504.015472 424.996948,504.069519 425.408661,504.049316 
	C428.113007,503.974609 430.405609,503.920135 432.788879,504.256348 
	C432.564240,506.430664 432.248932,508.214233 431.933594,509.997803 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M446.457123,541.132080 
	C446.861328,543.081055 446.835144,545.013123 446.822327,547.359863 
	C440.258881,548.056213 440.258881,548.056213 439.984497,541.382080 
	C441.976074,541.008789 444.001404,541.061951 446.457123,541.132080 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M432.198517,510.267029 
	C432.248932,508.214233 432.564240,506.430664 432.799133,503.798065 
	C432.827148,499.258484 432.935608,495.567932 433.471924,491.896851 
	C438.190002,491.302887 439.258179,493.506531 439.207672,497.385529 
	C439.156860,501.285980 439.727142,505.194519 440.025208,509.099518 
	C440.040710,509.817383 440.056244,510.535248 440.123596,511.609070 
	C437.604797,511.488770 435.034119,511.012512 432.198517,510.267029 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M469.027527,534.413330 
	C470.899750,533.999634 472.760956,533.962036 474.998474,533.903320 
	C476.519196,536.083740 477.663544,538.285217 479.055603,540.623779 
	C478.342468,540.797852 477.381683,540.834961 475.658051,540.959961 
	C472.681274,541.334229 470.467346,541.620605 468.253387,541.907043 
	C467.976593,541.358215 467.699799,540.809326 467.195435,540.050964 
	C467.650757,538.157471 468.333618,536.473450 469.027527,534.413330 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M454.289001,505.237305 
	C453.785553,500.587067 453.591980,495.846252 453.512970,490.631592 
	C453.627502,490.157776 453.802704,490.022583 454.021484,490.234772 
	C454.814758,490.639923 455.389313,490.832916 455.963837,491.025879 
	C455.943176,495.336517 455.922546,499.647186 455.858032,504.344238 
	C455.409058,504.869324 455.003967,505.008026 454.289001,505.237305 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M475.942078,548.413330 
	C477.703918,547.993652 479.439819,548.016418 481.607910,547.998779 
	C482.040161,550.071655 482.040161,552.184814 482.040161,554.864868 
	C479.978088,554.864868 478.392883,554.864868 476.383423,554.974121 
	C475.944824,553.007507 475.930450,550.931702 475.942078,548.413330 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M468.158356,542.305054 
	C470.467346,541.620605 472.681274,541.334229 475.288971,541.026611 
	C475.669922,542.980713 475.657104,544.955994 475.634216,547.348633 
	C473.475159,547.753052 471.326233,547.740173 468.900726,547.535522 
	C468.437225,545.796875 468.250275,544.250000 468.158356,542.305054 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M475.623199,439.895203 
	C472.249878,439.884277 468.339935,440.428070 468.240540,435.129944 
	C468.226624,434.389282 465.585785,433.872803 464.421906,432.930328 
	C463.230621,431.965637 461.484924,430.524933 461.583832,429.438477 
	C461.693298,428.236084 463.520020,426.398712 464.772827,426.243744 
	C468.200439,425.819641 471.719452,426.134399 475.676514,426.190430 
	C475.999329,430.618958 475.847900,435.013641 475.623199,439.895203 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M483.037476,445.353577 
	C483.632050,445.519073 484.211395,446.035309 484.904358,446.829895 
	C484.352753,446.640259 483.687531,446.172302 483.037476,445.353577 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M487.308289,447.199768 
	C487.815643,447.110168 488.698425,447.104919 489.781494,447.327087 
	C489.215759,447.464355 488.449768,447.374237 487.308289,447.199768 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M482.043579,426.345215 
	C482.262939,426.181793 482.522766,426.336487 482.741211,426.870880 
	C482.560242,426.930237 482.355011,426.734467 482.043579,426.345215 
z"/>
<path fill="#99B66B" opacity="1.000000" stroke="none" 
	d="
M526.468994,447.928375 
	C525.929993,445.615753 525.822021,443.278717 526.208008,440.497040 
	C528.738708,441.090240 530.775452,442.128052 532.812195,443.165833 
	C532.843201,444.554749 532.874146,445.943634 532.907166,447.754395 
	C530.906128,448.085480 528.903076,447.994690 526.468994,447.928375 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M532.861633,442.696350 
	C530.775452,442.128052 528.738708,441.090240 526.301758,440.066650 
	C525.649170,436.195068 525.464600,432.248108 519.360535,433.310242 
	C518.824524,432.736908 518.692566,432.126862 518.541260,430.999359 
	C518.521973,430.481873 518.446655,430.365967 518.446655,430.365967 
	C518.420532,429.233978 518.394348,428.102020 518.358032,426.101929 
	C518.362061,423.065491 518.376343,420.897156 518.476929,418.389709 
	C518.468384,416.469330 518.373535,414.888123 518.220093,413.019104 
	C518.110718,412.542267 518.059814,412.353210 518.121643,411.753662 
	C518.200989,404.312195 518.167542,397.281189 518.531860,390.193970 
	C521.199707,390.371582 523.469849,390.605438 525.739990,390.839264 
	C525.788635,399.970917 525.837280,409.102600 525.881653,419.136414 
	C525.952759,422.067139 526.028076,424.095764 526.079590,426.537231 
	C524.598145,433.189331 529.557739,432.860474 533.170776,433.890137 
	C533.166809,433.874542 533.197388,433.852112 533.197388,433.852112 
	C533.101929,436.643707 533.006531,439.435272 532.861633,442.696350 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M539.965515,418.963165 
	C539.964905,423.359070 539.964233,427.754974 539.961853,432.622681 
	C537.957275,433.317535 535.954529,433.540527 533.574585,433.807800 
	C533.197388,433.852112 533.166809,433.874542 533.114868,433.460571 
	C532.882385,430.764526 532.701782,428.482391 532.573364,425.756775 
	C532.704895,423.253052 532.784180,421.192810 532.863464,419.132568 
	C532.863464,419.132568 532.913269,419.084534 533.380981,419.059174 
	C535.912537,419.035309 537.976379,419.036804 540.021484,419.019562 
	C540.002747,419.000824 539.965515,418.963165 539.965515,418.963165 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M503.870911,433.472443 
	C504.420044,436.877167 504.483246,440.456848 509.471191,440.784302 
	C510.158234,440.829376 510.614868,444.384583 511.215240,446.769501 
	C511.252319,447.224274 511.138367,447.469513 511.108032,447.599304 
	C507.445770,448.018768 504.028534,448.206085 504.067749,442.887329 
	C504.074615,441.957092 502.348816,440.244263 501.381409,440.213684 
	C497.733917,440.098297 496.859009,438.249329 497.695374,434.584106 
	C500.000488,433.865692 501.935699,433.669067 503.870911,433.472443 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M517.189331,447.408844 
	C517.294678,446.324524 517.644470,445.371979 518.218079,444.227539 
	C518.612793,445.095795 518.783630,446.155975 518.990723,447.619537 
	C518.495911,447.862183 517.964905,447.701416 517.189331,447.408844 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M518.390625,418.728851 
	C518.376343,420.897156 518.362061,423.065491 518.249268,425.690796 
	C511.245544,426.017700 511.244873,426.017700 511.145782,418.875061 
	C511.118225,416.886383 511.096222,414.897675 511.038208,412.459595 
	C511.004791,412.010254 510.965393,412.031281 511.404663,411.987244 
	C513.898926,412.016876 515.953857,412.090485 518.008850,412.164124 
	C518.059814,412.353210 518.110718,412.542267 518.149536,413.351746 
	C518.221863,415.557739 518.306213,417.143311 518.390625,418.728851 
z"/>
<path fill="#2F7EBD" opacity="1.000000" stroke="none" 
	d="
M504.571259,411.614746 
	C504.277527,411.525696 504.225037,411.266632 504.180725,410.776123 
	C504.069550,409.533600 504.002655,408.781616 503.935730,408.029633 
	C504.020813,406.979767 504.105865,405.929871 504.151245,404.466125 
	C504.111572,404.052277 504.059784,404.104645 504.477478,404.196594 
	C511.320496,404.593079 511.320496,404.593079 510.965393,412.031281 
	C510.965393,412.031281 511.004791,412.010254 511.019958,411.993958 
	C510.859833,411.965881 510.684479,411.954102 509.852936,411.953796 
	C508.139008,412.014374 507.081268,412.063477 506.023529,412.112579 
	C505.637360,411.976318 505.251160,411.840088 504.571259,411.614746 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M503.931488,433.000427 
	C501.935699,433.669067 500.000488,433.865692 497.729736,434.190552 
	C497.326294,433.824036 497.165283,433.321442 497.206055,432.835815 
	C497.430481,430.160339 495.552216,426.234802 500.665802,426.277496 
	C505.350220,426.316589 503.822662,429.936096 503.931488,433.000427 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M506.170441,412.373596 
	C507.081268,412.063477 508.139008,412.014374 509.592834,411.996796 
	C508.765076,412.230438 507.541229,412.432526 506.170441,412.373596 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M518.396423,430.373596 
	C518.446655,430.365967 518.521973,430.481873 518.484009,430.535034 
	C518.446045,430.588196 518.346191,430.381195 518.396423,430.373596 
z"/>
<path fill="#99B66B" opacity="1.000000" stroke="none" 
	d="
M532.055908,320.169617 
	C532.346680,317.691284 532.637451,315.212921 533.458435,312.344299 
	C536.217712,312.588379 539.914978,310.312927 540.084106,315.029694 
	C540.039307,317.158905 539.708008,319.331726 540.023499,321.406128 
	C540.402893,323.901062 540.798828,326.665344 544.491882,326.627899 
	C548.094543,326.591370 547.624207,328.943634 546.876709,332.105865 
	C546.892151,338.300995 546.967712,343.681549 547.043213,349.062134 
	C547.040222,357.502319 547.037292,365.942505 547.069214,375.019714 
	C547.104065,375.656738 546.980286,376.033875 546.990601,376.020325 
	C547.000916,376.006775 547.022339,375.980286 546.586792,375.918121 
	C544.098633,375.838409 542.045959,375.820862 539.951172,375.353058 
	C539.708984,370.903595 539.508972,366.904388 539.308960,362.905182 
	C539.498352,356.936859 539.687744,350.968506 539.919250,344.067017 
	C539.991760,338.488586 539.992310,333.842834 540.065552,329.198212 
	C540.099487,327.043304 539.684326,325.506165 536.998413,326.267487 
	C535.767273,326.229950 534.536133,326.192413 532.867676,326.129944 
	C532.430298,326.104980 532.442017,326.016449 532.390991,325.681213 
	C532.245239,323.620514 532.150574,321.895081 532.055908,320.169617 
z"/>
<path fill="#99B66B" opacity="1.000000" stroke="none" 
	d="
M554.261108,381.917114 
	C552.249329,382.670502 550.136536,383.020081 547.547607,383.285248 
	C547.071411,383.200806 546.876343,382.870819 546.916687,382.450012 
	C546.978821,380.012878 547.000549,377.996582 547.022339,375.980286 
	C547.022339,375.980286 547.000916,376.006775 547.138245,375.859497 
	C547.275574,375.712219 547.511841,375.345123 547.511841,375.345123 
	C548.164001,375.418396 548.816101,375.491638 549.940918,375.614868 
	C550.413574,375.664825 550.378235,375.652740 550.378235,375.652740 
	C555.448547,375.147858 554.058838,378.811584 554.261108,381.917114 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M547.392395,348.885834 
	C546.967712,343.681549 546.892151,338.300995 546.929932,332.464783 
	C550.567078,337.438354 549.346619,343.065887 547.392395,348.885834 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M540.374023,314.897766 
	C539.914978,310.312927 536.217712,312.588379 533.576965,311.936249 
	C533.072510,311.761475 532.979797,311.604431 532.769897,311.199097 
	C532.749023,310.530914 532.845459,310.111053 533.007690,309.373199 
	C534.996521,309.397644 536.969482,309.572083 538.830200,310.124390 
	C541.229065,310.836365 542.566406,312.228760 540.374023,314.897766 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M550.404175,375.648804 
	C550.378235,375.652740 550.413574,375.664825 550.431458,375.654968 
	C550.449341,375.645081 550.430054,375.644836 550.404175,375.648804 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M547.494141,375.304108 
	C547.511841,375.345123 547.275574,375.712219 547.127930,375.873047 
	C546.980286,376.033875 547.104065,375.656738 547.211670,375.489075 
	C547.319275,375.321442 547.476379,375.263092 547.494141,375.304108 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M547.149170,383.376007 
	C547.449524,388.027374 546.423035,391.277008 540.404175,390.009399 
	C539.966736,389.964447 540.002441,390.001129 539.993042,389.572876 
	C540.075684,387.065735 540.167664,384.986908 540.670898,382.912842 
	C543.013550,382.902008 544.944946,382.886414 546.876343,382.870819 
	C546.876343,382.870819 547.071411,383.200806 547.149170,383.376007 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M540.167175,411.802673 
	C540.097534,409.942688 540.027832,408.082733 539.987427,405.835083 
	C546.491760,404.728394 547.116455,405.266815 546.170410,411.593262 
	C543.832092,411.933777 541.999634,411.868225 540.167175,411.802673 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M540.121460,411.856628 
	C541.999634,411.868225 543.832092,411.933777 546.041443,412.003174 
	C546.588562,414.025848 546.758789,416.044647 546.924438,418.495880 
	C544.892212,418.965729 542.864441,419.003143 540.401123,419.001831 
	C539.965515,418.963165 540.002747,419.000824 539.994873,418.580078 
	C539.985291,416.111664 539.983643,414.064056 539.982056,412.016418 
	C539.982056,412.016418 540.075806,411.910614 540.121460,411.856628 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M396.978577,383.331909 
	C397.730927,383.007996 398.503601,383.067841 399.649536,383.232300 
	C399.248108,384.166534 398.473389,384.996094 397.415833,385.919220 
	C397.088287,385.247101 397.043579,384.481415 396.978577,383.331909 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M432.829285,476.852325 
	C430.818329,476.832489 428.807373,476.812653 426.382690,476.922577 
	C425.324615,469.025146 425.324615,469.023865 416.826385,468.945496 
	C415.515839,468.933411 414.205078,468.939453 412.432495,468.990784 
	C411.743042,464.487457 411.515533,459.930450 411.296509,455.543762 
	C408.945953,454.867035 406.973633,454.299194 405.316895,453.214569 
	C406.211670,448.943024 406.790833,445.188293 407.370026,441.433533 
	C406.734863,441.371857 406.099701,441.310150 405.464539,441.248474 
	C405.242676,444.919952 405.020782,448.591461 404.798889,452.262939 
	C404.636719,443.998047 404.423523,435.733795 404.326843,427.468109 
	C404.231781,419.344086 404.414581,411.213318 404.142242,403.096649 
	C404.043060,400.140076 405.318298,398.215485 407.748657,398.454651 
	C409.080292,398.585754 411.033081,401.252838 411.181000,402.912292 
	C411.632690,407.979034 411.344971,413.111664 411.344971,418.450256 
	C414.163513,418.691895 416.109558,418.858734 418.433777,419.600891 
	C418.812012,424.283569 418.812012,428.390900 418.812012,432.165344 
	C420.957031,432.985657 422.469513,433.564087 423.993103,434.502441 
	C424.568481,436.868469 425.132751,438.874512 425.325348,440.977295 
	C424.337189,441.765350 423.200958,442.445465 423.185699,443.149872 
	C423.053009,449.282013 423.018005,455.421997 423.228485,461.550262 
	C423.264679,462.604736 424.752167,464.585083 425.310150,464.496979 
	C431.820282,463.469116 430.929718,468.047516 431.301727,471.875549 
	C431.378845,472.669159 432.408112,473.370239 433.007843,474.360840 
	C433.019226,475.091156 433.021332,475.574585 432.958862,476.252625 
	C432.894257,476.447296 432.829285,476.852325 432.829285,476.852325 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M425.687012,391.935791 
	C425.003723,395.986206 423.073883,399.731171 424.784637,404.228180 
	C426.189697,407.921600 424.480347,410.681183 419.368774,409.985748 
	C418.890076,409.755707 418.837769,409.561371 418.761902,408.932251 
	C418.738281,408.497528 418.625000,408.197662 418.625000,408.197662 
	C418.683990,405.114136 418.742950,402.030579 418.801025,398.028229 
	C418.554810,390.036011 418.309509,382.962585 418.251770,375.613159 
	C419.645447,373.266663 420.851501,371.196167 422.447174,369.057465 
	C423.890869,368.980377 424.944946,368.971497 425.925659,369.424408 
	C425.907562,374.275482 425.962860,378.664734 426.018158,383.054016 
	C424.908386,383.679016 423.798584,384.303986 422.316254,385.138733 
	C423.558075,386.981506 424.700043,388.676086 425.831726,390.925446 
	C425.821442,391.480255 425.687012,391.935791 425.687012,391.935791 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M423.981995,434.142517 
	C422.469513,433.564087 420.957031,432.985657 418.812012,432.165344 
	C418.812012,428.390900 418.812012,424.283569 418.842590,419.738007 
	C418.873138,419.299805 418.944153,418.880157 418.955017,418.667542 
	C421.267212,418.376740 423.568573,418.231903 425.869934,418.232361 
	C433.973816,418.233917 432.660400,417.742004 432.926392,425.398224 
	C432.871216,425.796509 432.662048,425.858002 432.257446,425.875732 
	C425.907166,425.172638 425.535461,428.852875 425.851685,433.444305 
	C425.093048,433.815460 424.537537,433.978973 423.981995,434.142517 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M418.064178,375.889191 
	C418.309509,382.962585 418.554810,390.036011 418.726440,397.576050 
	C412.077972,397.765259 412.162811,397.762512 411.950531,391.198975 
	C411.859772,388.393951 411.332092,385.603058 410.976562,382.025757 
	C411.111664,379.673950 411.273163,378.102142 411.568054,376.407898 
	C411.701447,376.285492 411.968445,376.040802 412.399078,375.964783 
	C414.574524,375.888916 416.319366,375.889038 418.064178,375.889191 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M404.222961,383.091461 
	C404.075409,378.621246 404.042236,374.320251 404.218567,369.239990 
	C404.636566,366.373779 404.845032,364.286896 405.494385,362.135071 
	C407.720398,362.056732 409.505493,362.043304 411.256348,362.029633 
	C411.222076,362.029388 411.161560,361.989685 411.316925,362.429535 
	C411.637665,367.259857 411.803070,371.650330 411.968445,376.040802 
	C411.968445,376.040802 411.701447,376.285492 411.203613,376.225586 
	C408.927338,376.165710 407.148895,376.165710 404.531158,376.165710 
	C405.066528,378.389984 405.495026,380.170319 405.923584,381.950684 
	C405.708313,382.451721 405.493042,382.952759 405.091431,383.646606 
	C404.698303,383.663757 404.509064,383.470825 404.222961,383.091461 
z"/>
<path fill="#1B1B1A" opacity="1.000000" stroke="none" 
	d="
M406.339478,381.977539 
	C405.495026,380.170319 405.066528,378.389984 404.531158,376.165710 
	C407.148895,376.165710 408.927338,376.165710 411.070221,376.348022 
	C411.273163,378.102142 411.111664,379.673950 410.911591,381.607605 
	C409.500488,381.981049 408.127930,381.992706 406.339478,381.977539 
z"/>
<path fill="#1B1B1A" opacity="1.000000" stroke="none" 
	d="
M397.031769,428.479675 
	C393.424866,424.580750 394.581482,419.991943 396.644470,415.225769 
	C397.030029,419.392303 397.062256,423.708557 397.031769,428.479675 
z"/>
<path fill="#1B1B1A" opacity="1.000000" stroke="none" 
	d="
M404.795471,452.438171 
	C405.020782,448.591461 405.242676,444.919952 405.464539,441.248474 
	C406.099701,441.310150 406.734863,441.371857 407.370026,441.433533 
	C406.790833,445.188293 406.211670,448.943024 405.259460,452.824341 
	C404.886444,452.950928 404.792084,452.613373 404.795471,452.438171 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M418.570099,408.262329 
	C418.625000,408.197662 418.738281,408.497528 418.676147,408.642822 
	C418.483612,408.655823 418.450684,408.502106 418.570099,408.262329 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M425.640686,476.880859 
	C426.950073,482.002441 423.759460,483.343658 419.356689,484.000580 
	C417.656464,478.697662 420.598907,477.013153 425.640686,476.880859 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M440.373566,508.915039 
	C439.727142,505.194519 439.156860,501.285980 439.207672,497.385529 
	C439.258179,493.506531 438.190002,491.302887 433.404114,491.697662 
	C432.908447,491.479034 432.770020,491.080109 432.905945,490.752441 
	C434.531952,489.522308 436.022034,488.619781 436.803070,488.146759 
	C435.124786,486.720795 433.585999,485.413330 432.047211,484.105896 
	C432.047211,484.105896 432.433014,483.911560 433.018890,483.772644 
	C435.961670,483.391785 438.318542,483.149841 440.745239,483.326965 
	C441.602570,486.093384 442.390106,488.440765 443.177673,490.788147 
	C442.781677,494.730621 442.456085,498.681946 441.960876,502.611908 
	C441.701477,504.670807 441.144470,506.692230 440.373566,508.915039 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M432.770020,491.080109 
	C432.770020,491.080109 432.908447,491.479034 432.976257,491.678223 
	C432.935608,495.567932 432.827148,499.258484 432.708466,503.407349 
	C430.405609,503.920135 428.113007,503.974609 425.383301,504.031403 
	C425.014252,499.974792 425.082275,495.915863 425.245850,491.396667 
	C425.341370,490.936371 425.635834,491.005524 426.217590,490.983337 
	C428.789581,491.000824 430.779816,491.040466 432.770020,491.080109 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M432.905945,490.752441 
	C430.779816,491.040466 428.789581,491.000824 426.368530,490.993256 
	C425.937653,488.915833 425.937653,486.806366 425.937653,484.277588 
	C428.012543,484.165497 429.597931,484.079895 431.615265,484.050079 
	C433.585999,485.413330 435.124786,486.720795 436.803070,488.146759 
	C436.022034,488.619781 434.531952,489.522308 432.905945,490.752441 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M440.675446,482.907898 
	C438.318542,483.149841 435.961670,483.391785 433.204712,483.662598 
	C432.817322,481.701172 432.829956,479.710815 432.835938,477.286407 
	C432.829285,476.852325 432.894257,476.447296 433.344910,476.463440 
	C437.517120,477.093750 441.644348,477.273743 440.675446,482.907898 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M419.078979,497.361694 
	C419.416473,497.965088 419.550140,498.738007 419.478149,499.657654 
	C419.140015,499.046692 419.007599,498.288971 419.078979,497.361694 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M425.999054,368.962616 
	C424.944946,368.971497 423.890869,368.980377 422.079468,369.000885 
	C420.578918,368.983734 419.835663,368.954926 419.007263,368.508728 
	C418.875549,366.093994 418.828979,364.096680 418.809753,361.619812 
	C418.789825,358.872711 418.742554,356.605103 418.650208,353.935364 
	C418.627136,351.624756 418.649170,349.716278 418.721802,347.362610 
	C418.545868,337.555176 418.319305,328.192993 418.040405,318.465698 
	C417.672943,316.430054 417.357819,314.759460 417.310272,312.951416 
	C418.031464,312.184906 418.485046,311.555878 418.950073,310.468292 
	C418.969604,305.997894 418.977692,301.986053 418.993713,297.524231 
	C418.974548,294.664856 418.947418,292.255493 418.920319,289.846130 
	C418.920319,287.776123 418.920319,285.706085 418.920319,284.218903 
	C420.848572,281.396881 426.305603,285.924316 425.712402,279.513916 
	C425.998718,274.451233 429.582428,276.516541 432.406403,276.535065 
	C432.519623,281.579376 432.187866,286.162445 431.516693,290.649231 
	C429.720123,290.418427 428.263031,290.283905 425.972229,290.072449 
	C425.972229,316.619812 425.972229,342.837769 425.959991,369.026947 
	C425.947754,368.998199 425.999054,368.962616 425.999054,368.962616 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M431.856140,290.745483 
	C432.187866,286.162445 432.519623,281.579376 432.825867,276.542908 
	C437.674225,277.143616 437.327972,272.331238 439.717194,270.101685 
	C440.048584,271.710144 440.053070,273.442566 440.069031,276.049744 
	C440.030243,279.283600 439.980042,281.642700 439.591614,284.095337 
	C438.148499,286.217682 436.922577,288.191437 435.969421,290.289124 
	C434.773254,292.921570 433.590637,293.832367 431.856140,290.745483 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M418.495911,289.973053 
	C418.947418,292.255493 418.974548,294.664856 418.673157,297.384888 
	C416.123352,297.192780 413.902039,296.690002 411.568115,295.753845 
	C411.330780,293.540985 411.206085,291.761597 411.081390,289.982178 
	C411.081390,289.982178 411.001984,289.968079 410.994934,290.007751 
	C413.349091,290.064972 415.710297,290.082458 418.495911,289.973053 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M411.162048,282.604065 
	C409.078918,283.024445 407.094940,283.009796 404.690369,282.999207 
	C404.004700,278.754944 404.751221,275.390137 410.650208,276.069458 
	C411.127136,278.037506 411.194153,280.103271 411.162048,282.604065 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M410.619202,290.019592 
	C411.206085,291.761597 411.330780,293.540985 411.439240,296.039062 
	C411.426361,297.175171 411.429749,297.592560 411.334869,298.423584 
	C411.306152,300.518494 411.375732,302.199799 411.350891,304.149597 
	C411.190582,304.595886 411.124603,304.773743 410.714722,305.094635 
	C409.587585,307.665588 408.232178,310.072693 408.142975,312.525848 
	C407.969177,317.303375 408.564148,322.104553 408.733673,326.899750 
	C409.036224,335.457611 409.256165,344.018402 409.550903,352.576538 
	C409.554596,352.684143 410.089417,352.773438 409.932648,352.719971 
	C412.153412,350.952332 414.138763,349.372101 416.439514,347.701904 
	C417.393677,347.677246 418.032410,347.742523 418.671173,347.807800 
	C418.649170,349.716278 418.627136,351.624756 418.262207,354.130249 
	C415.880280,354.947906 413.841248,355.168549 411.595337,355.625000 
	C411.448120,357.115295 411.507843,358.369751 411.512299,360.023132 
	C411.401550,360.958008 411.346069,361.493958 411.290588,362.029877 
	C409.505493,362.043304 407.720398,362.056732 405.244995,361.785950 
	C404.460236,359.524719 404.292969,357.548096 404.282562,355.570587 
	C404.181458,336.382507 404.106598,317.194336 404.024231,298.006165 
	C404.024231,298.006165 404.177002,297.627319 404.402405,297.093506 
	C404.548309,295.038422 404.468811,293.517212 404.389343,291.995972 
	C404.433868,291.560730 404.478394,291.125488 404.542358,290.364075 
	C406.426880,290.044281 408.291931,290.050629 410.619202,290.019592 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M400.791046,368.793915 
	C399.652740,368.793915 398.514435,368.793915 396.886230,368.793915 
	C396.886230,362.813202 396.885376,357.005066 396.886414,351.196960 
	C396.889374,334.706970 396.837158,318.216461 396.990143,301.727844 
	C397.004181,300.216095 398.331543,298.716492 399.632141,297.326019 
	C400.469971,297.585724 400.742035,297.635193 401.031494,297.589233 
	C401.031494,297.589233 401.105255,297.786102 401.099792,298.322449 
	C400.993256,322.170502 400.892151,345.482208 400.791046,368.793915 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M401.187317,368.855530 
	C400.892151,345.482208 400.993256,322.170502 401.176666,298.394165 
	C401.949432,297.952057 402.639893,297.974609 403.677307,298.001648 
	C404.106598,317.194336 404.181458,336.382507 404.282562,355.570587 
	C404.292969,357.548096 404.460236,359.524719 404.804108,361.850891 
	C404.845032,364.286896 404.636566,366.373779 404.205475,368.782104 
	C403.183105,369.041382 402.383331,368.979279 401.187317,368.855530 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M404.269562,292.350647 
	C404.468811,293.517212 404.548309,295.038422 404.432281,296.891418 
	C404.207764,295.717224 404.178802,294.211273 404.269562,292.350647 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M400.884064,297.427643 
	C400.742035,297.635193 400.469971,297.585724 400.050751,297.309906 
	C400.183075,297.077820 400.466553,297.106842 400.884064,297.427643 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M483.530334,261.823914 
	C483.088257,261.082306 483.065582,260.390686 483.064301,258.972137 
	C483.126007,257.198242 483.166321,256.151306 483.632446,255.080444 
	C486.055817,255.049881 488.053345,255.043259 490.050873,255.036621 
	C490.050873,255.036636 490.005310,254.993164 489.987671,254.967041 
	C490.308472,259.288422 489.958313,263.037964 483.530334,261.823914 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M490.049042,254.591888 
	C488.053345,255.043259 486.055817,255.049881 483.329468,255.045380 
	C482.398163,255.014740 482.195679,254.995270 482.143402,254.613800 
	C482.543945,252.157455 482.794281,250.063080 483.044617,247.968719 
	C483.044586,247.968719 482.991730,247.970215 482.977203,247.992126 
	C489.983093,246.219559 489.983093,246.219559 490.049042,254.591888 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M461.006592,269.027588 
	C454.295227,268.725220 454.278473,268.725220 454.277496,275.150665 
	C454.275940,285.597443 454.275146,296.044342 454.327759,306.490906 
	C454.341125,309.145294 454.317719,311.625732 449.899597,311.577393 
	C448.479553,311.484650 447.818878,311.363159 447.158264,310.787140 
	C447.054535,308.507904 446.950745,306.683197 446.915649,304.482056 
	C446.937531,303.094055 446.890717,302.082520 446.906250,300.618835 
	C446.939362,297.138855 446.910156,294.111115 446.898560,290.612823 
	C446.826599,285.652740 446.736969,281.163177 446.647369,276.673615 
	C446.937469,271.930389 447.227570,267.187195 447.748169,262.065765 
	C449.996948,261.733063 452.015228,261.778564 454.454346,261.803650 
	C456.616913,261.859283 458.358582,261.935303 460.551514,262.487671 
	C460.982147,264.983948 460.961517,267.003937 460.940918,269.023895 
	C460.940918,269.023895 460.994293,269.056091 461.006592,269.027588 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M446.297729,276.675568 
	C446.736969,281.163177 446.826599,285.652740 446.515472,290.605194 
	C444.396423,290.735291 442.678070,290.402496 440.861816,289.868835 
	C440.615387,289.408569 440.440155,289.168701 440.223389,288.534454 
	C440.115662,286.747650 440.022736,285.374725 439.929810,284.001801 
	C439.980042,281.642700 440.030243,279.283600 440.111511,276.483246 
	C442.077759,276.253845 444.012909,276.465698 446.297729,276.675568 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M461.390533,269.014252 
	C460.961517,267.003937 460.982147,264.983948 460.978973,262.534485 
	C461.131256,262.054779 461.307373,262.004547 462.213867,261.907715 
	C465.097351,261.573242 467.250519,261.285309 469.403687,260.997345 
	C469.253082,261.625061 468.915955,262.272858 468.980957,262.877441 
	C469.577850,268.427521 466.886108,270.070129 461.390533,269.014252 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M469.386536,261.019745 
	C467.250519,261.285309 465.097351,261.573242 462.485657,261.867859 
	C461.967163,261.178650 461.907135,260.482788 461.868866,259.038361 
	C461.946686,257.205780 462.002747,256.121735 462.058807,255.037704 
	C465.726471,254.577621 469.113983,254.531540 469.224335,260.172058 
	C469.483185,260.632935 469.451752,261.028992 469.451752,261.028992 
	C469.451752,261.028992 469.369354,261.042145 469.386536,261.019745 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M461.817688,255.025116 
	C462.002747,256.121735 461.946686,257.205780 461.813507,258.691772 
	C461.497711,257.965149 461.259033,256.836548 461.057068,255.354370 
	C461.093811,255.000824 461.576538,255.012512 461.817688,255.025116 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M482.608154,247.949951 
	C482.794281,250.063080 482.543945,252.157455 481.886261,254.658585 
	C481.310486,255.108536 481.142090,255.151718 480.973694,255.194901 
	C478.878693,254.910721 476.783691,254.626556 475.146271,253.938843 
	C475.406403,252.016586 475.208984,250.497894 475.011566,248.979187 
	C475.011566,248.979187 474.932343,248.523804 474.885254,248.297607 
	C475.040802,248.049332 475.243439,248.027237 475.749451,247.971237 
	C478.092468,247.935287 480.132080,247.933243 482.608154,247.949951 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M480.916687,255.491974 
	C481.142090,255.151718 481.310486,255.108536 481.736023,255.020569 
	C482.195679,254.995270 482.398163,255.014740 482.903625,255.069290 
	C483.166321,256.151306 483.126007,257.198242 483.007080,258.630859 
	C482.238831,257.940704 481.549225,256.864868 480.916687,255.491974 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M469.577118,261.043610 
	C469.451752,261.028992 469.483185,260.632935 469.501831,260.435181 
	C470.865234,260.559387 472.209961,260.881348 473.541199,261.586548 
	C473.196991,261.858521 472.866241,261.747253 472.111359,261.349274 
	C471.109741,261.058777 470.532257,261.054932 469.954803,261.051117 
	C469.954803,261.051117 469.702484,261.058228 469.577118,261.043610 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M475.004486,249.387238 
	C475.208984,250.497894 475.406403,252.016586 475.295044,253.699493 
	C474.989960,252.507568 474.993683,251.151428 475.004486,249.387238 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M449.889893,390.093475 
	C449.149750,390.112610 448.409637,390.131775 447.326538,390.098297 
	C446.983612,390.045685 447.052124,390.031830 447.066132,389.564514 
	C447.080139,384.849060 447.080139,380.600952 447.080139,376.265045 
	C444.444366,375.968506 442.529419,375.753082 440.324127,375.504974 
	C440.202484,373.171295 440.092560,371.062653 439.958252,368.973328 
	C439.933838,368.992676 439.965424,369.046509 440.406982,369.032440 
	C442.721222,369.018372 444.593872,369.018372 446.804077,369.018372 
	C446.515686,365.537415 448.985260,361.374268 442.659607,361.851105 
	C441.921265,359.434692 441.423981,357.221161 441.351868,354.990356 
	C448.404999,355.669525 447.002686,351.069672 446.889771,346.972321 
	C446.965607,346.442535 446.995941,346.230621 447.211121,345.656860 
	C447.558044,341.833771 447.720093,338.372467 447.889435,334.670654 
	C447.932220,334.111237 447.954834,333.791504 447.964600,333.470825 
	C449.445770,333.414673 450.926971,333.358551 452.923218,333.656372 
	C453.689819,334.345917 453.941345,334.681519 454.192902,335.017151 
	C454.233704,352.060272 454.284760,369.103394 454.291992,386.146515 
	C454.292450,387.279419 453.959320,388.412415 453.078979,389.772461 
	C451.547699,390.030853 450.718781,390.062164 449.889893,390.093475 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M447.691254,333.134094 
	C447.954834,333.791504 447.932220,334.111237 447.710938,335.040405 
	C447.358887,339.106689 447.192566,342.562714 447.026276,346.018707 
	C446.995941,346.230621 446.965607,346.442535 446.460999,346.938232 
	C443.975616,347.132751 441.964478,347.043488 439.977173,346.974487 
	C440.001038,346.994720 440.049408,347.034454 440.092285,346.565155 
	C440.045319,336.959259 439.955475,327.822693 439.893890,318.269653 
	C440.015167,315.829620 440.108185,313.806000 440.622131,311.799561 
	C443.013031,311.882751 444.982971,311.948792 446.971436,312.476318 
	C447.040558,316.945557 447.091095,320.953278 447.141632,324.961029 
	C447.233734,327.573120 447.325806,330.185242 447.691254,333.134094 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M456.373291,490.942810 
	C455.389313,490.832916 454.814758,490.639923 454.114258,489.774780 
	C454.000061,480.053101 454.011902,471.003571 454.029144,461.982605 
	C454.034576,462.011139 453.985168,462.041656 453.985168,462.041656 
	C456.272919,462.041656 458.560669,462.041656 461.591705,462.041656 
	C461.591705,471.027863 461.745270,479.592651 461.380249,488.135315 
	C461.338715,489.107178 458.385742,489.954590 456.373291,490.942810 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M461.542053,311.584961 
	C461.955902,315.990845 461.941895,320.530487 461.934509,325.536804 
	C459.463593,325.579010 455.102661,325.637787 454.847382,324.642975 
	C453.934204,321.084595 453.946167,317.074982 454.676025,313.451111 
	C454.871460,312.480774 458.864502,312.275299 461.542053,311.584961 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M504.143311,404.070984 
	C496.915619,404.414459 496.916718,404.414459 496.914978,397.318970 
	C496.914215,394.180206 496.907837,391.041412 496.861603,387.433960 
	C496.819244,386.965240 496.960907,387.010529 497.384064,387.052124 
	C499.934753,388.089355 502.062317,389.084961 504.178802,390.538757 
	C504.131775,395.366180 504.095764,399.735413 504.059784,404.104645 
	C504.059784,404.104645 504.111572,404.052277 504.143311,404.070984 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M440.000366,440.033813 
	C447.097473,440.486694 446.937317,438.643341 447.027374,447.112213 
	C447.052063,449.436005 447.014679,451.760437 446.995880,454.524353 
	C446.985992,454.964111 447.052002,454.974731 446.645752,454.873291 
	C444.385223,455.139191 442.530945,455.506531 440.507202,455.840942 
	C440.337708,455.807953 439.988434,455.831757 440.000305,455.707245 
	C440.012177,455.582733 439.938538,455.343689 439.996094,454.872925 
	C439.949707,452.219788 439.845764,450.037445 439.829895,447.427307 
	C439.965271,444.661133 440.012512,442.322693 440.029846,439.978821 
	C439.999969,439.973328 440.000366,440.033813 440.000366,440.033813 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M446.962372,390.017914 
	C446.952209,391.630280 447.409424,393.450775 446.841156,394.856720 
	C446.334900,396.109253 444.699432,397.673492 443.551819,397.681976 
	C442.410828,397.690430 440.273590,395.920166 440.285004,394.950043 
	C440.326263,391.452484 439.374573,390.063293 435.572174,389.956757 
	C431.366638,389.838898 433.461334,386.084320 432.995911,383.416321 
	C432.996155,382.994507 432.960175,383.052582 433.404846,383.069885 
	C435.965179,383.703644 439.935516,384.403412 439.912140,384.923767 
	C439.653839,390.673248 443.198578,390.443604 447.052124,390.031830 
	C447.052124,390.031830 446.983612,390.045685 446.962372,390.017914 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M426.454376,383.105774 
	C425.962860,378.664734 425.907562,374.275482 425.925659,369.424408 
	C425.999054,368.962616 425.947754,368.998199 426.401001,368.998474 
	C428.917511,368.985992 430.980743,368.973236 433.009796,369.360596 
	C432.970459,374.191345 432.965302,378.621979 432.960175,383.052582 
	C432.960175,383.052582 432.996155,382.994507 433.023010,382.973389 
	C430.996765,383.020660 428.943665,383.089111 426.454376,383.105774 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M432.976227,425.825775 
	C440.083557,426.037476 440.092133,426.037384 440.174805,433.121979 
	C440.198090,435.117523 440.154999,437.113861 440.071350,439.571838 
	C440.000366,440.033813 439.999969,439.973328 439.595215,440.011780 
	C437.193970,439.982422 435.197510,439.914642 433.155029,439.433838 
	C433.054810,437.636139 433.000610,436.251495 432.948120,434.425995 
	C432.853882,431.276123 432.757965,428.567047 432.662048,425.858002 
	C432.662048,425.858002 432.871216,425.796509 432.976227,425.825775 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M467.904419,297.541748 
	C466.175537,298.005157 464.500336,298.002930 462.405823,297.987793 
	C461.895294,294.527771 461.958527,291.067932 461.674866,287.636780 
	C461.293274,283.021484 463.485046,282.233154 467.958130,283.615814 
	C467.958130,287.989868 467.958130,292.532959 467.904419,297.541748 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M450.279205,390.183716 
	C450.718781,390.062164 451.547699,390.030853 452.650940,390.048096 
	C453.748932,390.145203 454.571960,390.207184 455.396210,390.240021 
	C461.846130,390.497040 461.846191,390.495331 460.811859,397.487671 
	C457.294495,398.125854 454.015778,398.913635 454.050323,393.217163 
	C454.056244,392.242950 451.851837,391.255341 450.279205,390.183716 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M446.966522,454.991272 
	C449.234436,455.018402 451.521820,455.018402 454.131226,455.018402 
	C454.131226,457.367767 454.131226,459.311951 454.058197,461.648865 
	C453.985168,462.041656 454.034576,462.011139 453.595062,461.996399 
	C447.138428,463.115143 446.552856,459.517365 447.052002,454.974731 
	C447.052002,454.974731 446.985992,454.964111 446.966522,454.991272 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M447.513611,324.930969 
	C447.091095,320.953278 447.040558,316.945557 447.082458,312.298920 
	C447.174927,311.660034 447.158234,311.241699 447.158234,311.241699 
	C447.818878,311.363159 448.479553,311.484650 449.457001,311.719482 
	C450.184570,314.567871 451.058289,317.340942 450.837982,320.024200 
	C450.698853,321.719147 448.925781,323.279938 447.513611,324.930969 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M425.771484,392.356995 
	C425.687012,391.935791 425.821442,391.480255 425.906891,391.259003 
	C430.268860,390.084259 432.561890,392.133759 433.701019,396.415802 
	C431.435974,396.935669 429.173309,397.455017 426.243073,398.127563 
	C426.100281,396.154633 425.978119,394.466431 425.771484,392.356995 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M503.794220,408.265167 
	C504.002655,408.781616 504.069550,409.533600 504.109680,410.664673 
	C503.939514,410.196106 503.796112,409.348389 503.794220,408.265167 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M454.168915,334.672394 
	C453.941345,334.681519 453.689819,334.345917 453.285034,333.734619 
	C453.469513,333.748505 453.807220,334.038055 454.168915,334.672394 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M525.730103,390.393372 
	C523.469849,390.605438 521.199707,390.371582 518.099060,390.139709 
	C515.283386,390.003601 513.298279,389.865601 511.292206,389.324707 
	C511.155060,387.041718 511.038910,385.161591 510.984161,382.821045 
	C511.042023,375.818024 511.042023,375.818024 504.295868,375.386810 
	C504.543518,370.855072 504.809937,366.769775 505.481415,362.610046 
	C506.955017,362.284760 508.023529,362.033966 509.220764,362.124023 
	C510.024048,364.254211 510.698700,366.043579 511.756958,368.220032 
	C512.418213,368.841431 512.695862,369.075775 512.957031,369.341858 
	C512.940552,369.373627 513.068054,369.309052 513.699707,369.256714 
	C515.172974,369.266144 516.014526,369.327850 516.953247,369.429779 
	C517.358215,369.358795 517.666016,369.247620 517.991699,369.106415 
	C518.009583,369.076416 518.076904,369.057709 518.167969,369.119629 
	C518.258972,369.181519 518.457947,369.275757 518.457947,369.275757 
	C518.643677,372.796478 518.567566,376.377838 523.718018,376.489258 
	C524.391541,376.503845 525.466003,378.745789 525.575378,380.022827 
	C525.856995,383.311066 525.701233,386.636749 525.730103,390.393372 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M503.062073,326.064758 
	C503.457642,321.445099 503.853210,316.825470 504.669678,311.767273 
	C507.020386,311.316071 508.950226,311.303375 510.880035,311.290649 
	C510.874298,320.460083 510.868591,329.629517 510.517334,339.438538 
	C508.459198,340.207916 506.746613,340.337738 504.941162,340.109344 
	C504.465057,337.653931 504.081787,335.556732 503.759155,333.064850 
	C503.567200,330.468384 503.314606,328.266571 503.062073,326.064758 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M540.259766,382.908051 
	C540.167664,384.986908 540.075684,387.065735 540.011047,389.591125 
	C537.429626,389.399536 533.456238,391.775360 532.792419,385.980713 
	C532.725952,384.137726 532.691345,383.085266 532.656738,382.032776 
	C532.717041,378.981110 532.777222,375.929443 532.828003,372.030640 
	C532.794495,370.127075 532.770386,369.070618 532.746338,368.014191 
	C532.914490,366.415314 533.082642,364.816406 533.349854,362.844727 
	C535.190369,362.582703 536.931824,362.693451 538.991150,362.854675 
	C539.508972,366.904388 539.708984,370.903595 539.949341,375.805786 
	C540.079651,378.775208 540.169739,380.841614 540.259766,382.908051 
z"/>
<path fill="#A34E89" opacity="1.000000" stroke="none" 
	d="
M505.034027,340.467529 
	C506.746613,340.337738 508.459198,340.207916 510.494263,339.887848 
	C510.983002,341.878204 511.149323,344.058807 511.294556,347.072510 
	C511.275116,350.031464 511.276733,352.157318 511.314453,354.702606 
	C511.085815,356.459595 510.821136,357.797180 510.429443,359.351044 
	C509.898956,360.305939 509.495514,361.044556 509.092072,361.783173 
	C508.023529,362.033966 506.955017,362.284760 505.453796,362.078735 
	C505.025421,354.570465 505.029724,347.518982 505.034027,340.467529 
z"/>
<path fill="#99B66B" opacity="1.000000" stroke="none" 
	d="
M510.556458,359.134766 
	C510.821136,357.797180 511.085815,356.459595 511.780304,354.841553 
	C514.754211,355.097229 518.178040,354.163635 518.538086,358.348724 
	C518.489197,361.281219 518.440308,364.213715 518.302368,367.855530 
	C518.167786,368.729187 518.122375,368.893463 518.076904,369.057709 
	C518.076904,369.057709 518.009583,369.076416 517.797974,369.135681 
	C517.332214,369.219666 517.088806,369.284546 516.856079,369.389587 
	C516.014526,369.327850 515.172974,369.266144 513.693726,369.228516 
	C513.056091,369.252594 512.973511,369.310120 512.973511,369.310120 
	C512.695862,369.075775 512.418213,368.841431 511.829102,367.950134 
	C511.197235,364.573700 510.876831,361.854248 510.556458,359.134766 
z"/>
<path fill="#99B66B" opacity="1.000000" stroke="none" 
	d="
M510.927368,310.801758 
	C508.950226,311.303375 507.020386,311.316071 504.675842,311.318542 
	C504.184418,308.843109 504.205566,306.370056 504.009552,303.914337 
	C503.653900,299.458954 505.574097,297.424255 510.472534,297.922913 
	C510.916901,302.068451 510.945801,306.190674 510.927368,310.801758 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M539.549927,411.971008 
	C539.983643,414.064056 539.985291,416.111664 540.013550,418.598816 
	C537.976379,419.036804 535.912537,419.035309 533.406006,419.035278 
	C533.031189,411.876740 533.031189,411.876740 539.549927,411.971008 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M532.438354,419.141418 
	C532.784180,421.192810 532.704895,423.253052 532.184082,425.732971 
	C529.862854,426.143219 527.983154,426.133789 526.103394,426.124359 
	C526.028076,424.095764 525.952759,422.067139 525.925293,419.615479 
	C527.986572,419.178345 529.999878,419.164337 532.438354,419.141418 
z"/>
<path fill="#2F7EBD" opacity="1.000000" stroke="none" 
	d="
M512.683228,347.108002 
	C516.941040,346.429932 518.839478,348.312531 518.236572,353.066650 
	C511.141510,355.463013 513.199158,350.251465 512.683228,347.108002 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M532.445679,367.913147 
	C532.770386,369.070618 532.794495,370.127075 532.764771,371.576782 
	C532.228333,371.966400 531.663513,372.124939 531.278015,371.929596 
	C529.954041,371.258728 528.556458,370.611267 527.492126,369.625366 
	C527.178833,369.335175 527.867737,367.199799 528.112793,367.196869 
	C529.454224,367.180786 530.800415,367.565247 532.445679,367.913147 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M512.477417,347.088684 
	C513.199158,350.251465 511.141510,355.463013 518.208130,353.466888 
	C518.573181,354.825104 518.625366,356.310364 518.607788,358.072144 
	C518.178040,354.163635 514.754211,355.097229 511.744202,354.422180 
	C511.276733,352.157318 511.275116,350.031464 511.361694,347.528717 
	C511.718933,347.075195 511.992859,347.047699 512.477417,347.088684 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M532.318359,382.069763 
	C532.691345,383.085266 532.725952,384.137726 532.705200,385.582642 
	C531.101074,386.175079 529.552307,386.375061 528.003479,386.575043 
	C527.732239,386.056763 527.460999,385.538483 527.189697,385.020233 
	C528.786438,384.049072 530.383179,383.077911 532.318359,382.069763 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M518.167969,369.119629 
	C518.122375,368.893463 518.167786,368.729187 518.324341,368.335876 
	C518.542664,368.355286 518.580688,368.613953 518.503662,369.079285 
	C518.457947,369.275757 518.258972,369.181519 518.167969,369.119629 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M504.941162,340.109344 
	C505.029724,347.518982 505.025421,354.570465 505.048737,362.153198 
	C504.809937,366.769775 504.543518,370.855072 504.232544,375.830444 
	C503.081024,382.348236 506.124115,383.715881 510.922760,383.281464 
	C511.038910,385.161591 511.155060,387.041718 510.832794,389.350159 
	C508.326202,389.879150 506.258026,389.979858 504.189850,390.080536 
	C502.062317,389.084961 499.934753,388.089355 497.457031,387.066650 
	C497.145203,369.512238 497.183594,351.984863 497.707092,333.992188 
	C500.027618,333.504395 501.863068,333.481964 503.698517,333.459503 
	C504.081787,335.556732 504.465057,337.653931 504.941162,340.109344 
z"/>
<path fill="#99B66B" opacity="1.000000" stroke="none" 
	d="
M503.759155,333.064850 
	C501.863068,333.481964 500.027618,333.504395 497.801147,333.497955 
	C497.410095,333.469055 497.238159,333.216888 497.142548,332.687744 
	C497.035828,330.100739 497.024750,328.042908 497.013672,325.985046 
	C497.013672,325.985046 497.163727,325.784485 497.237885,325.682068 
	C499.011932,325.698303 500.711853,325.816925 502.736877,326.000183 
	C503.314606,328.266571 503.567200,330.468384 503.759155,333.064850 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M496.573975,325.972168 
	C497.024750,328.042908 497.035828,330.100739 497.017029,332.600952 
	C494.584259,335.744904 492.745575,333.195526 490.443176,332.227661 
	C490.088623,330.340820 490.012573,328.596375 489.958679,326.415985 
	C492.031952,325.973145 494.083099,325.966217 496.573975,325.972168 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M470.120972,261.363647 
	C470.532257,261.054932 471.109741,261.058777 472.120300,261.073212 
	C471.798004,261.281250 471.042572,261.478699 470.120972,261.363647 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M537.120117,326.650208 
	C539.684326,325.506165 540.099487,327.043304 540.065552,329.198212 
	C539.992310,333.842834 539.991760,338.488586 539.873413,343.605652 
	C539.177856,342.339783 538.239258,340.640869 538.030334,338.856506 
	C537.571594,334.938232 537.482056,330.976746 537.120117,326.650208 
z"/>
<path fill="#367CB9" opacity="1.000000" stroke="none" 
	d="
M532.442017,326.016479 
	C532.442017,326.016449 532.430298,326.104980 532.405701,326.143860 
	C532.381104,326.182709 532.338318,326.632385 532.282898,326.852173 
	C531.375305,327.054108 530.523193,327.036255 529.346924,327.009094 
	C529.022888,326.999786 529.072754,326.576447 529.503662,326.351868 
	C530.770325,326.090332 531.606201,326.053406 532.442017,326.016479 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M532.390991,325.681213 
	C531.606201,326.053406 530.770325,326.090332 529.520203,326.139404 
	C525.935303,322.351959 526.399658,321.055267 531.656982,320.155762 
	C532.150574,321.895081 532.245239,323.620514 532.390991,325.681213 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M446.645752,454.873291 
	C446.552856,459.517365 447.138428,463.115143 453.589630,461.967834 
	C454.011902,471.003571 454.000061,480.053101 453.895477,489.562622 
	C453.802704,490.022583 453.627502,490.157776 453.523224,490.179443 
	C451.950653,490.180481 450.482391,490.159912 448.655029,489.719330 
	C448.480957,483.489441 448.699280,477.680237 448.810242,471.868958 
	C448.824646,471.113586 448.437134,469.797791 447.979553,469.671753 
	C443.434845,468.419800 442.326874,464.273254 440.273224,460.485535 
	C440.247498,458.978241 440.254822,457.878235 440.369873,456.649719 
	C440.580231,456.316620 440.646576,456.100830 440.676666,455.873901 
	C442.530945,455.506531 444.385223,455.139191 446.645752,454.873291 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M440.306305,460.892822 
	C442.326874,464.273254 443.434845,468.419800 447.979553,469.671753 
	C448.437134,469.797791 448.824646,471.113586 448.810242,471.868958 
	C448.699280,477.680237 448.480957,483.489441 448.224365,489.716492 
	C447.942200,491.954254 447.731689,493.774841 447.289795,495.916870 
	C446.082855,494.636353 445.107300,493.034424 444.152924,490.770996 
	C444.839447,485.701599 445.634857,481.308075 446.125000,476.880737 
	C446.459381,473.860870 446.477295,470.849701 442.057739,470.552887 
	C441.292725,470.501495 440.219055,468.837952 440.029083,467.785339 
	C439.683685,465.871521 439.894501,463.857330 439.960876,461.627960 
	C440.134460,461.211548 440.220398,461.052185 440.306305,460.892822 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M439.873230,461.885010 
	C439.894501,463.857330 439.683685,465.871521 440.029083,467.785339 
	C440.219055,468.837952 441.292725,470.501495 442.057739,470.552887 
	C446.477295,470.849701 446.459381,473.860870 446.125000,476.880737 
	C445.634857,481.308075 444.839447,485.701599 443.990906,490.436523 
	C443.807709,490.763550 443.492615,490.781616 443.335144,490.784882 
	C442.390106,488.440765 441.602570,486.093384 440.745239,483.326965 
	C441.644348,477.273743 437.517120,477.093750 433.409485,476.268799 
	C433.021332,475.574585 433.019226,475.091156 433.234497,474.013855 
	C433.544556,469.738220 433.637207,466.056519 434.167511,462.319733 
	C436.361176,462.138123 438.117218,462.011566 439.873230,461.885010 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M504.178802,390.538757 
	C506.258026,389.979858 508.326202,389.879150 510.853790,389.752991 
	C513.298279,389.865601 515.283386,390.003601 517.701294,390.195892 
	C518.167542,397.281189 518.200989,404.312195 518.121643,411.753662 
	C515.953857,412.090485 513.898926,412.016876 511.404663,411.987244 
	C511.320496,404.593079 511.320496,404.593079 504.477478,404.196594 
	C504.095764,399.735413 504.131775,395.366180 504.178802,390.538757 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M526.079590,426.537231 
	C527.983154,426.133789 529.862854,426.143219 532.131836,426.176453 
	C532.701782,428.482391 532.882385,430.764526 533.118896,433.476196 
	C529.557739,432.860474 524.598145,433.189331 526.079590,426.537231 
z"/>
<path fill="#7C3D84" opacity="1.000000" stroke="none" 
	d="
M518.476929,418.389709 
	C518.306213,417.143311 518.221863,415.557739 518.208008,413.639557 
	C518.373535,414.888123 518.468384,416.469330 518.476929,418.389709 
z"/>
<path fill="#9D478C" opacity="1.000000" stroke="none" 
	d="
M540.670959,382.912842 
	C540.169739,380.841614 540.079651,378.775208 539.991455,376.256042 
	C542.045959,375.820862 544.098633,375.838409 546.586792,375.918121 
	C547.000549,377.996582 546.978821,380.012878 546.916687,382.450012 
	C544.944946,382.886414 543.013550,382.902008 540.670959,382.912842 
z"/>
<path fill="#BC826E" opacity="1.000000" stroke="none" 
	d="
M439.960876,461.627991 
	C438.117218,462.011566 436.361176,462.138123 433.752655,462.191925 
	C430.785980,461.999481 428.671814,461.879791 425.697052,461.711365 
	C425.697052,454.664886 425.697052,447.772705 425.697021,440.880554 
	C425.132751,438.874512 424.568481,436.868469 423.993103,434.502441 
	C424.537537,433.978973 425.093048,433.815460 426.223297,433.376282 
	C428.847473,433.689331 430.896912,434.278076 432.946381,434.866821 
	C433.000610,436.251495 433.054810,437.636139 433.067139,439.885437 
	C433.060455,443.145691 433.095673,445.541290 433.078918,448.370178 
	C431.681702,454.839752 435.738678,455.167236 439.938538,455.343689 
	C439.938538,455.343689 440.012177,455.582733 440.055023,455.948273 
	C440.097870,456.313812 440.262177,456.778229 440.262146,456.778229 
	C440.254822,457.878235 440.247498,458.978241 440.273224,460.485535 
	C440.220398,461.052185 440.134460,461.211548 439.960876,461.627991 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M425.325378,440.977295 
	C425.697052,447.772705 425.697052,454.664886 425.697052,461.711365 
	C428.671814,461.879791 430.785980,461.999481 433.315002,462.246979 
	C433.637207,466.056519 433.544556,469.738220 433.225220,473.766968 
	C432.408112,473.370239 431.378845,472.669159 431.301727,471.875549 
	C430.929718,468.047516 431.820282,463.469116 425.310150,464.496979 
	C424.752167,464.585083 423.264679,462.604736 423.228485,461.550262 
	C423.018005,455.421997 423.053009,449.282013 423.185699,443.149872 
	C423.200958,442.445465 424.337189,441.765350 425.325378,440.977295 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M419.092407,368.926117 
	C419.835663,368.954926 420.578918,368.983734 421.689880,369.069122 
	C420.851501,371.196167 419.645447,373.266663 418.251770,375.613159 
	C416.319366,375.889038 414.574524,375.888916 412.399078,375.964783 
	C411.803070,371.650330 411.637665,367.259857 411.803467,362.419739 
	C413.096497,362.004059 414.058350,362.038055 415.070892,362.452576 
	C415.425171,364.426056 415.443451,366.163086 416.146088,367.554596 
	C416.529541,368.314056 418.072418,368.488068 419.092407,368.926117 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M432.948120,434.425995 
	C430.896912,434.278076 428.847473,433.689331 426.426392,433.168640 
	C425.535461,428.852875 425.907166,425.172638 432.257446,425.875732 
	C432.757965,428.567047 432.853882,431.276123 432.948120,434.425995 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M415.020203,362.072052 
	C414.058350,362.038055 413.096497,362.004059 411.648102,361.979858 
	C411.161560,361.989685 411.222076,362.029388 411.256348,362.029633 
	C411.346069,361.493958 411.401550,360.958008 411.594604,359.739288 
	C411.755585,357.834045 411.778931,356.611633 411.802246,355.389160 
	C413.841248,355.168549 415.880280,354.947906 418.307281,354.532410 
	C418.742554,356.605103 418.789825,358.872711 418.440094,361.552795 
	C417.035492,362.000854 416.027832,362.036469 415.020203,362.072052 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M433.044006,368.960480 
	C430.980743,368.973236 428.917511,368.985992 426.413239,369.027222 
	C425.972229,342.837769 425.972229,316.619812 425.972229,290.072449 
	C428.263031,290.283905 429.720123,290.418427 431.516693,290.649231 
	C433.590637,293.832367 434.773254,292.921570 435.969421,290.289124 
	C436.922577,288.191437 438.148499,286.217682 439.591583,284.095337 
	C440.022736,285.374725 440.115662,286.747650 440.233429,288.779053 
	C440.375397,289.777710 440.609222,289.988434 440.959747,290.069702 
	C442.678070,290.402496 444.396423,290.735291 446.497864,291.075714 
	C446.910156,294.111115 446.939362,297.138855 446.559021,300.579590 
	C444.166962,300.012756 442.184418,299.032959 439.744720,297.960693 
	C437.359253,297.868195 435.431000,297.868195 433.210938,297.868195 
	C433.210938,304.927490 433.210938,311.412537 433.210938,318.270508 
	C435.647339,318.422668 437.756470,318.554382 439.865631,318.686096 
	C439.955475,327.822693 440.045319,336.959259 439.654236,346.614685 
	C433.768982,346.230011 432.498260,348.880280 432.873016,353.783112 
	C433.259674,358.842316 432.960602,363.953918 432.982056,369.022003 
	C433.002625,369.001404 433.044006,368.960480 433.044006,368.960480 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M418.721802,347.362610 
	C418.032410,347.742523 417.393677,347.677246 416.054840,347.579498 
	C414.047943,346.663574 411.637573,345.817474 411.606232,344.890900 
	C411.315369,336.294373 411.434296,327.683990 411.434296,318.830780 
	C414.147308,318.830780 416.120026,318.830780 418.092743,318.830780 
	C418.319305,328.192993 418.545868,337.555176 418.721802,347.362610 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M418.040405,318.465698 
	C416.120026,318.830780 414.147308,318.830780 411.434296,318.830780 
	C411.434296,327.683990 411.315369,336.294373 411.606232,344.890900 
	C411.637573,345.817474 414.047943,346.663574 415.739441,347.669434 
	C414.138763,349.372101 412.153412,350.952332 409.932648,352.719971 
	C410.089417,352.773438 409.554596,352.684143 409.550903,352.576538 
	C409.256165,344.018402 409.036224,335.457611 408.733673,326.899750 
	C408.564148,322.104553 407.969177,317.303375 408.142975,312.525848 
	C408.232178,310.072693 409.587585,307.665588 410.823914,305.476379 
	C411.516663,307.518738 411.756348,309.322357 412.219055,311.386597 
	C413.975616,312.127777 415.509155,312.608307 417.042664,313.088867 
	C417.357819,314.759460 417.672943,316.430054 418.040405,318.465698 
z"/>
<path fill="#6195EA" opacity="1.000000" stroke="none" 
	d="
M411.996033,311.125977 
	C411.756348,309.322357 411.516663,307.518738 411.167816,305.333374 
	C411.124603,304.773743 411.190582,304.595886 411.478851,303.844666 
	C411.611847,301.517548 411.522491,299.763763 411.433136,298.009979 
	C411.429749,297.592560 411.426361,297.175171 411.551880,296.472504 
	C413.902039,296.690002 416.123352,297.192780 418.665222,297.834869 
	C418.977692,301.986053 418.969604,305.997894 418.517883,310.486847 
	C416.048187,311.017975 414.022125,311.071960 411.996033,311.125977 
z"/>
<path fill="#261B33" opacity="1.000000" stroke="none" 
	d="
M415.070892,362.452576 
	C416.027832,362.036469 417.035492,362.000854 418.412781,362.032318 
	C418.828979,364.096680 418.875549,366.093994 419.007263,368.508728 
	C418.072418,368.488068 416.529541,368.314056 416.146088,367.554596 
	C415.443451,366.163086 415.425171,364.426056 415.070892,362.452576 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M412.219055,311.386597 
	C414.022125,311.071960 416.048187,311.017975 418.506439,310.945404 
	C418.485046,311.555878 418.031464,312.184906 417.310272,312.951416 
	C415.509155,312.608307 413.975616,312.127777 412.219055,311.386597 
z"/>
<path fill="#7E71D0" opacity="1.000000" stroke="none" 
	d="
M411.334869,298.423584 
	C411.522491,299.763763 411.611847,301.517548 411.573242,303.576233 
	C411.375732,302.199799 411.306152,300.518494 411.334869,298.423584 
z"/>
<path fill="#8D62C8" opacity="1.000000" stroke="none" 
	d="
M411.595337,355.625000 
	C411.778931,356.611633 411.755585,357.834045 411.649902,359.340332 
	C411.507843,358.369751 411.448120,357.115295 411.595337,355.625000 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M447.158264,310.787140 
	C447.158234,311.241699 447.174927,311.660034 447.063904,311.837463 
	C444.982971,311.948792 443.013031,311.882751 440.591919,311.374146 
	C440.145691,308.613281 440.150665,306.294983 440.489868,304.146790 
	C442.831696,304.497467 444.839325,304.678009 446.846954,304.858521 
	C446.950745,306.683197 447.054535,308.507904 447.158264,310.787140 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M446.915649,304.482056 
	C444.839325,304.678009 442.831696,304.497467 440.462921,303.717407 
	C440.135162,301.429657 440.168549,299.741394 440.201904,298.053162 
	C442.184418,299.032959 444.166962,300.012756 446.496704,301.031769 
	C446.890717,302.082520 446.937531,303.094055 446.915649,304.482056 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M440.861816,289.868835 
	C440.609222,289.988434 440.375397,289.777710 440.248230,289.192932 
	C440.440155,289.168701 440.615387,289.408569 440.861816,289.868835 
z"/>
<path fill="#351F60" opacity="1.000000" stroke="none" 
	d="
M433.404846,383.069885 
	C432.965302,378.621979 432.970459,374.191345 433.009796,369.360596 
	C433.044006,368.960480 433.002625,369.001404 433.420685,368.977234 
	C435.886719,368.953369 437.934662,368.953674 439.982666,368.953979 
	C440.092560,371.062653 440.202484,373.171295 440.324127,375.504974 
	C442.529419,375.753082 444.444366,375.968506 447.080139,376.265045 
	C447.080139,380.600952 447.080139,384.849060 447.066132,389.564514 
	C443.198578,390.443604 439.653839,390.673248 439.912140,384.923767 
	C439.935516,384.403412 435.965179,383.703644 433.404846,383.069885 
z"/>
<path fill="#946ED1" opacity="1.000000" stroke="none" 
	d="
M439.958252,368.973328 
	C437.934662,368.953674 435.886719,368.953369 433.400146,368.997833 
	C432.960602,363.953918 433.259674,358.842316 432.873016,353.783112 
	C432.498260,348.880280 433.768982,346.230011 439.611359,347.083984 
	C440.049408,347.034454 440.001038,346.994720 440.021851,347.397766 
	C440.337311,350.203064 440.632019,352.605377 440.926697,355.007660 
	C441.423981,357.221161 441.921265,359.434692 442.510254,362.153259 
	C441.723114,364.787720 440.844269,366.917114 439.965393,369.046509 
	C439.965424,369.046509 439.933838,368.992676 439.958252,368.973328 
z"/>
<path fill="#362F6C" opacity="1.000000" stroke="none" 
	d="
M441.351868,354.990356 
	C440.632019,352.605377 440.337311,350.203064 439.997986,347.377502 
	C441.964478,347.043488 443.975616,347.132751 446.415497,347.256104 
	C447.002686,351.069672 448.404999,355.669525 441.351868,354.990356 
z"/>
<path fill="#362767" opacity="1.000000" stroke="none" 
	d="
M440.406982,369.032440 
	C440.844269,366.917114 441.723114,364.787720 442.751343,362.356201 
	C448.985260,361.374268 446.515686,365.537415 446.804077,369.018372 
	C444.593872,369.018372 442.721222,369.018372 440.406982,369.032440 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M447.211121,345.656860 
	C447.192566,342.562714 447.358887,339.106689 447.703674,335.280945 
	C447.720093,338.372467 447.558044,341.833771 447.211121,345.656860 
z"/>
<path fill="#6E84DE" opacity="1.000000" stroke="none" 
	d="
M439.744720,297.960693 
	C440.168549,299.741394 440.135162,301.429657 440.128723,303.547272 
	C440.150665,306.294983 440.145691,308.613281 440.170929,311.356995 
	C440.108185,313.806000 440.015167,315.829620 439.893890,318.269653 
	C437.756470,318.554382 435.647339,318.422668 433.210938,318.270508 
	C433.210938,311.412537 433.210938,304.927490 433.210938,297.868195 
	C435.431000,297.868195 437.359253,297.868195 439.744720,297.960693 
z"/>
<path fill="#1E1D20" opacity="1.000000" stroke="none" 
	d="
M433.130920,447.936920 
	C433.095673,445.541290 433.060455,443.145691 433.113159,440.298462 
	C435.197510,439.914642 437.193970,439.982422 439.625092,440.017273 
	C440.012512,442.322693 439.965271,444.661133 439.417450,447.453735 
	C436.988251,447.917603 435.059570,447.927246 433.130920,447.936920 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M433.078918,448.370178 
	C435.059570,447.927246 436.988251,447.917603 439.329346,447.881500 
	C439.845764,450.037445 439.949707,452.219788 439.996094,454.872925 
	C435.738678,455.167236 431.681702,454.839752 433.078918,448.370178 
z"/>
<path fill="#794A3F" opacity="1.000000" stroke="none" 
	d="
M440.369904,456.649719 
	C440.262177,456.778229 440.097870,456.313812 440.043152,456.072784 
	C439.988434,455.831757 440.337708,455.807953 440.507202,455.840912 
	C440.646576,456.100830 440.580231,456.316620 440.369904,456.649719 
z"/>
<path fill="#3A3B70" opacity="1.000000" stroke="none" 
	d="
M510.984161,382.821045 
	C506.124115,383.715881 503.081024,382.348236 504.251312,376.276917 
	C511.042023,375.818024 511.042023,375.818024 510.984161,382.821045 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M510.429443,359.351044 
	C510.876831,361.854248 511.197235,364.573700 511.445496,367.563049 
	C510.698700,366.043579 510.024048,364.254211 509.220764,362.124023 
	C509.495514,361.044556 509.898956,360.305939 510.429443,359.351044 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M516.953247,369.429779 
	C517.088806,369.284546 517.332214,369.219666 517.780029,369.165710 
	C517.666016,369.247620 517.358215,369.358795 516.953247,369.429779 
z"/>
<path fill="#6F559E" opacity="1.000000" stroke="none" 
	d="
M512.957031,369.341858 
	C512.973511,369.310120 513.056091,369.252594 513.062073,369.280823 
	C513.068054,369.309052 512.940552,369.373627 512.957031,369.341858 
z"/>
</svg>`,
  description: 'zerebro',
  recommendedInjectedWallets: [
    { name: 'MetaMask', url: 'https://metamask.io' },
    { name: 'Coinbase Wallet', url: 'https://wallet.coinbase.com' },
    // Add other recommended wallets if desired
  ]
}

// Initialize Onboard
const onboard = Onboard({
  wallets: [injected, walletConnect],
  chains: onboardChains,
  appMetadata,
  // Optional: Customize other Onboard settings here
})

export default onboard
