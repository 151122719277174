import React, { useState, useEffect, useRef } from 'react';
import { db } from '../firebaseConfig';
import {
    collection,
    query,
    orderBy,
    limit,
    onSnapshot,
    startAfter,
    getDocs,
    where,
} from 'firebase/firestore';
import Typewriter from 'typewriter-effect';
import styles from './Conciousness.module.css';
import { Link } from 'react-router-dom';

const Conciousness = () => {
    const [messages, setMessages] = useState([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const [lastVisible, setLastVisible] = useState(null);
    const [typingQueue, setTypingQueue] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const [currentTypingMessageId, setCurrentTypingMessageId] = useState(null);

    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null); // Ref for messagesContainer
    const typewriterRef = useRef(null);

    useEffect(() => {
        const fetchInitialMessages = async () => {
            const messagesRef = collection(db, 'message_history');
            const messagesQuery = query(
                messagesRef,
                orderBy('timestamp', 'desc'),
                limit(10)
            );
            const snapshot = await getDocs(messagesQuery);

            const msgs = [];
            snapshot.forEach((doc) => {
                const data = doc.data();
                data.id = doc.id;
                data.messages
                    .filter((message) => message.role === 'assistant')
                    .forEach((assistantMessage, index) => {
                        msgs.push({
                            ...data,
                            messages: [assistantMessage],
                            uniqueId: `${data.id}-${index}`,
                            showFullMessage: true, // Initial messages are shown immediately
                        });
                    });
            });
            setMessages(msgs.reverse());
            setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

            // Scroll to bottom after initial load
            setTimeout(() => {
                if (messagesEndRef.current) {
                    messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
                }
            }, 0);
        };

        fetchInitialMessages();

        // Listen for new messages
        const messagesRef = collection(db, 'message_history');
        const q = query(
            messagesRef,
            where('timestamp', '>', new Date()),
            orderBy('timestamp', 'asc')
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const msgs = [];
            snapshot.docChanges().forEach((change) => {
                if (change.type === 'added') {
                    const data = change.doc.data();
                    data.id = change.doc.id;
                    data.messages
                        .filter((message) => message.role === 'assistant')
                        .forEach((assistantMessage, index) => {
                            msgs.push({
                                ...data,
                                messages: [assistantMessage],
                                uniqueId: `${data.id}-${index}`,
                                showFullMessage: false, // New messages will type out
                            });
                        });
                }
            });
            if (msgs.length > 0) {
                // When new messages come in, queue them without interrupting the current typing
                setTypingQueue((prevQueue) => [...prevQueue, ...msgs]);
            }
        });

        return () => unsubscribe();
    }, []);

    // Process the typing queue
    useEffect(() => {
        if (typingQueue.length > 0 && !isTyping) {
            const nextMessage = typingQueue[0];
            setTypingQueue((prevQueue) => prevQueue.slice(1));
            setMessages((prev) => [...prev, nextMessage]);
            setCurrentTypingMessageId(nextMessage.uniqueId);
            setIsTyping(true);
        }
    }, [typingQueue, isTyping]);

    const finishTyping = () => {
        // Finish typing for all messages
        setMessages((prevMessages) =>
            prevMessages.map((message) => {
                if (!message.showFullMessage) {
                    return { ...message, showFullMessage: true };
                }
                return message;
            })
        );
        // Cancel the typing animation
        if (typewriterRef.current) {
            typewriterRef.current.stop();
            typewriterRef.current = null;
        }
        setIsTyping(false);
        setCurrentTypingMessageId(null);
    };

    const handleTypingComplete = (uniqueId) => {
        setMessages((prevMessages) =>
            prevMessages.map((message) =>
                message.uniqueId === uniqueId
                    ? { ...message, showFullMessage: true }
                    : message
            )
        );
        setIsTyping(false);
        setCurrentTypingMessageId(null);

        // Scroll to bottom when typing is complete
        setTimeout(() => {
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    };

    const fetchMoreMessages = async () => {
        if (loadingMore || !hasMoreMessages) return;
        setLoadingMore(true);

        const messagesRef = collection(db, 'message_history');
        const messagesQuery = query(
            messagesRef,
            orderBy('timestamp', 'desc'),
            startAfter(lastVisible),
            limit(10)
        );
        const snapshot = await getDocs(messagesQuery);

        if (snapshot.empty) {
            setHasMoreMessages(false);
            setLoadingMore(false);
            return;
        }

        const msgs = [];
        snapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            data.messages
                .filter((message) => message.role === 'assistant')
                .forEach((assistantMessage, index) => {
                    msgs.push({
                        ...data,
                        messages: [assistantMessage],
                        uniqueId: `${data.id}-${index}`,
                        showFullMessage: true,
                    });
                });
        });

        // Adjust scroll position to prevent jump
        const scrollContainer = messagesContainerRef.current;
        const previousScrollHeight = scrollContainer.scrollHeight;

        setMessages((prev) => [...msgs.reverse(), ...prev]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setLoadingMore(false);

        // After state update, adjust the scroll position
        setTimeout(() => {
            const newScrollHeight = scrollContainer.scrollHeight;
            scrollContainer.scrollTop = newScrollHeight - previousScrollHeight;
        }, 0);
    };

    const handleScroll = (e) => {
        if (e.target.scrollTop === 0) {
            fetchMoreMessages();
        }
    };

    return (
        <div className={styles.chatContainer}>
            <div className={styles.header}>
                <Link className={styles.backToHome} to="/">
                    back to home
                </Link>
            </div>
            <div
                className={styles.messagesContainer}
                onScroll={handleScroll}
                ref={messagesContainerRef}
            >
                {messages.map((messageData) => (
                    <Message
                        key={messageData.uniqueId}
                        data={messageData}
                        isTyping={isTyping}
                        currentTypingMessageId={currentTypingMessageId}
                        onTypingComplete={handleTypingComplete}
                        typewriterRef={typewriterRef}
                    />
                ))}
                <div ref={messagesEndRef} />
            </div>
        </div>
    );
};

const Message = ({ data, isTyping, currentTypingMessageId, onTypingComplete, typewriterRef }) => {
    const isCurrentMessage = data.uniqueId === currentTypingMessageId;

    // Extract the assistant message content
    const assistantMessages = data.messages.filter(
        (message) => message.role === 'assistant'
    );

    return (
        <div className={styles.message}>
            {assistantMessages.map((msg, index) => (
                <div key={index}>
                    {!data.showFullMessage && isCurrentMessage ? (
                        <Typewriter
                            options={{
                                delay: 20,
                            }}
                            onInit={(typewriter) => {
                                // Store the current typewriter instance
                                typewriterRef.current = typewriter;

                                // Type the prompt
                                typewriter.typeString(
                                    `<span class="${styles.prompt}">simulator@zerebro&gt; </span>`
                                );

                                // Split the message content into lines
                                const lines = msg.content.split('\n');

                                // Chain the typing of each line
                                lines.forEach((line, idx) => {
                                    // Escape HTML characters
                                    const escapedLine = line
                                        .replace(/&/g, '&amp;')
                                        .replace(/</g, '&lt;')
                                        .replace(/>/g, '&gt;');

                                    typewriter.typeString(escapedLine);
                                    if (idx < lines.length - 1) {
                                        typewriter.typeString(
                                            `<br><span class="${styles.prompt}">simulator@zerebro&gt; </span>`
                                        );
                                    }
                                });

                                typewriter
                                    .callFunction(() => {
                                        onTypingComplete(data.uniqueId);
                                        typewriterRef.current = null;
                                    })
                                    .start();
                            }}
                        />
                    ) : (
                        <div className={styles.staticMessage}>
                            <span className={styles.prompt}>simulator@zerebro&gt; </span>
                            {formatMessageContentWithPrompt(msg.content)}
                        </div>
                    )}
                </div>
            ))}
            <div className={styles.timestamp}>
                {data.timestamp.toDate().toLocaleString()}
            </div>
        </div>
    );
};

function formatMessageContentWithPrompt(content) {
    // Split content into lines
    const lines = content.split('\n');
    return lines.map((line, index) => {
        // Try to match the pattern: input=HighLevelReasoningInput(params)
        const regex = /input=(\w+)\(([\s\S]+)\)/;
        const match = line.match(regex);
        if (match) {
            const inputType = match[1]; // e.g., 'HighLevelReasoningInput'
            const paramsString = match[2]; // e.g., "goal='...', current_state_of_execution='...'"
            const params = parseParams(paramsString);

            // Format the output
            return (
                <div key={index} className={styles.formattedMessage}>
                    <div>
                        <strong>Type:</strong> {inputType}
                    </div>
                    {Object.entries(params).map(([key, value]) => (
                        <div key={key}>
                            <strong>{formatKey(key)}:</strong> {value}
                        </div>
                    ))}
                </div>
            );
        } else if (line.startsWith('action_type=')) {
            // Handle action_type lines
            const actionRegex = /action_type=<([^:]+): '([^']+)'> parameters=(\{[^}]*\})?/;
            const actionMatch = line.match(actionRegex);
            if (actionMatch) {
                const actionType = actionMatch[1];
                const actionValue = actionMatch[2];
                const parametersString = actionMatch[3] || '{}';
                const parameters = JSON.parse(parametersString.replace(/'/g, '"'));

                return (
                    <div key={index} className={styles.formattedMessage}>
                        <div>
                            <strong>Action Type:</strong> {actionType}
                        </div>
                        <div>
                            <strong>Action:</strong> {actionValue}
                        </div>
                        {Object.entries(parameters).map(([key, value]) => (
                            <div key={key}>
                                <strong>{formatKey(key)}:</strong> {value}
                            </div>
                        ))}
                    </div>
                );
            } else {
                // If the line doesn't match the expected pattern, display it as is
                return (
                    <div key={index} className={styles.formattedMessage}>
                        {line}
                    </div>
                );
            }
        } else {
            // If the line doesn't match the expected pattern, display it as is
            return (
                <div key={index} className={styles.formattedMessage}>
                    {line}
                </div>
            );
        }
    });
}

function parseParams(paramsString) {
    // Use a regular expression to match key='value' pairs
    const regex = /(\w+)=('([^'\\]*(\\.[^'\\]*)*)')/g;
    const params = {};
    let match;

    // Loop through all matches
    while ((match = regex.exec(paramsString)) !== null) {
        const key = match[1];
        let value = match[3];

        // Replace escaped quotes and backslashes
        value = value.replace(/\\'/g, "'").replace(/\\\\/g, '\\');
        params[formatKey(key)] = value;
    }
    return params;
}

// Helper function to format keys
function formatKey(key) {
    // Replace underscores with spaces and capitalize words
    return key
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (char) => char.toUpperCase());
}

export default Conciousness;
