// src/components/ConversationDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import styles from './ConversationDetail.module.css';
import { Link } from 'react-router-dom';

function ConversationDetail() {
  const { id } = useParams();
  const [conversation, setConversation] = useState(null);
  const [hasUpvoted, setHasUpvoted] = useState(false);

  useEffect(() => {
    const fetchConversation = async () => {
      const docRef = doc(db, 'conversations', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setConversation({
          id: docSnap.id,
          ...docSnap.data(),
        });
      } else {
        console.log('No such document!');
      }
    };

    fetchConversation();

    // Check if the user has already upvoted this conversation
    const upvotedConversations =
      JSON.parse(localStorage.getItem('upvotedConversations')) || [];
    if (upvotedConversations.includes(id)) {
      setHasUpvoted(true);
    }
  }, [id]);

  const handleUpvote = async () => {
    if (hasUpvoted) {
      alert('You have already upvoted this conversation.');
      return;
    }

    try {
      const convRef = doc(db, 'conversations', id);
      await updateDoc(convRef, {
        upvotes: increment(1),
      });

      // Update local upvotes count
      setConversation((prevConversation) => ({
        ...prevConversation,
        upvotes: prevConversation.upvotes + 1,
      }));

      // Mark this conversation as upvoted in localStorage
      const upvotedConversations =
        JSON.parse(localStorage.getItem('upvotedConversations')) || [];
      upvotedConversations.push(id);
      localStorage.setItem(
        'upvotedConversations',
        JSON.stringify(upvotedConversations)
      );

      setHasUpvoted(true);
    } catch (error) {
      console.error('Error upvoting conversation:', error);
    }
  };

  if (!conversation) {
    return <p>Loading conversation...</p>;
  }

  return (
    <div className={styles.conversationDetail}>
      <div className={styles.cli}>
        <Link to="/" className={styles.cliBack}>
          return to home
        </Link>
        <h2 className={styles.cliText}>conversation {id}</h2>
        <p className={styles.cliText}>
          timestamp:{' '}
          {conversation.timestamp &&
            new Date(
              conversation.timestamp.seconds * 1000
            ).toLocaleString()}
        </p>
        <p className={styles.cliText}>{conversation.starting_prompt}</p>

        {/* Upvote Button */}
        <div className={styles.upvoteSection}>
          <p className={styles.cliText}>upvotes: {conversation.upvotes}</p>
          <button
            onClick={handleUpvote}
            disabled={hasUpvoted}
            className={styles.upvoteButton}
          >
            {hasUpvoted ? 'Upvoted' : 'Upvote'}
          </button>
        </div>

        <div className={styles.chatHistory}>
          {conversation.conversation_history.slice(0, 20).map((reply, index) => (
            <pre key={index} className={styles.cliCommand}>
              $ {reply}
            </pre>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ConversationDetail;