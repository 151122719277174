// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyAck0vRjY5cxI22ce4U2OES5clrhZNZLbw",
    authDomain: "zerebro-fd0bd.firebaseapp.com",
    projectId: "zerebro-fd0bd",
    storageBucket: "zerebro-fd0bd.appspot.com",
    messagingSenderId: "865939383285",
    appId: "1:865939383285:web:225cc8561c13a95524b6bc",
    measurementId: "G-05H5W4EVD5"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };