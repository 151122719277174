// src/components/Portal.jsx

import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import onboard from '../onboard' // Import the Onboard instance
import { Options } from '@layerzerolabs/lz-v2-utilities'
import networkConfig from './network.json' // Adjust the path if necessary
import { Link } from 'react-router-dom'

// ABI for the ZEREBRO_ONFT contract
const CONTRACT_ABI = [
  {
    "inputs": [
      {
        "components": [
          { "internalType": "uint32", "name": "dstEid", "type": "uint32" },
          { "internalType": "bytes32", "name": "to", "type": "bytes32" },
          { "internalType": "uint256", "name": "tokenId", "type": "uint256" },
          { "internalType": "string", "name": "tokenUri", "type": "string" },
          { "internalType": "bytes", "name": "extraOptions", "type": "bytes" },
          { "internalType": "bytes", "name": "composeMsg", "type": "bytes" },
          { "internalType": "bytes", "name": "onftCmd", "type": "bytes" }
        ],
        "internalType": "struct SendParamUri",
        "name": "_sendParam",
        "type": "tuple"
      },
      {
        "components": [
          { "internalType": "uint256", "name": "nativeFee", "type": "uint256" },
          { "internalType": "uint256", "name": "lzTokenFee", "type": "uint256" }
        ],
        "internalType": "struct MessagingFee",
        "name": "_fee",
        "type": "tuple"
      },
      { "internalType": "address", "name": "_refundAddress", "type": "address" }
    ],
    "name": "sendUri",
    "outputs": [
      {
        "components": [
          { "internalType": "bytes32", "name": "guid", "type": "bytes32" },
          { "internalType": "uint32", "name": "dstEid", "type": "uint32" },
          { "internalType": "address", "name": "from", "type": "address" },
          { "internalType": "uint256", "name": "tokenId", "type": "uint256" }
        ],
        "internalType": "struct MessagingReceipt",
        "name": "msgReceipt",
        "type": "tuple"
      }
    ],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          { "internalType": "uint32", "name": "dstEid", "type": "uint32" },
          { "internalType": "bytes32", "name": "to", "type": "bytes32" },
          { "internalType": "uint256", "name": "tokenId", "type": "uint256" },
          { "internalType": "string", "name": "tokenUri", "type": "string" },
          { "internalType": "bytes", "name": "extraOptions", "type": "bytes" },
          { "internalType": "bytes", "name": "composeMsg", "type": "bytes" },
          { "internalType": "bytes", "name": "onftCmd", "type": "bytes" }
        ],
        "internalType": "struct SendParamUri",
        "name": "_sendParamUri",
        "type": "tuple"
      },
      { "internalType": "bool", "name": "_payInLzToken", "type": "bool" }
    ],
    "name": "quoteSendUri",
    "outputs": [
      {
        "components": [
          { "internalType": "uint256", "name": "nativeFee", "type": "uint256" },
          { "internalType": "uint256", "name": "lzTokenFee", "type": "uint256" }
        ],
        "internalType": "struct MessagingFee",
        "name": "msgFee",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "uint256", "name": "tokenId", "type": "uint256" }
    ],
    "name": "tokenURI",
    "outputs": [
      { "internalType": "string", "name": "", "type": "string" }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];

function Portal() {
  const [networks, setNetworks] = useState([])
  const [selectedSourceNetwork, setSelectedSourceNetwork] = useState(null) // Initialize as null
  const [selectedDestinationNetwork, setSelectedDestinationNetwork] = useState(null)
  const [tokenId, setTokenId] = useState('')
  const [recipientAddress, setRecipientAddress] = useState('')
  const [status, setStatus] = useState('')
  const [txHash, setTxHash] = useState('')

  // State variables for wallet and NFTs
  const [userAddress, setUserAddress] = useState(null)
  const [provider, setProvider] = useState(null)
  const [signer, setSigner] = useState(null)
  const [nfts, setNfts] = useState([])
  const [nftsLoading, setNftsLoading] = useState(false)
  const [nftsError, setNftsError] = useState(null)

  // Load networks and set default destination network on initial load
  useEffect(() => {
    // Load networks from network.json
    setNetworks(networkConfig)

    // Do NOT set selectedSourceNetwork based on connected chain to keep the dropdown blank
    // Set default selected destination network (e.g., Base)
    const defaultDestinationNetwork = networkConfig.find(net => net.name === 'Base')
    setSelectedDestinationNetwork(defaultDestinationNetwork)
  }, [])

  // Helper function to convert Ethereum address to bytes32
  const addressToBytes32 = (address) => {
    try {
      return ethers.utils.hexZeroPad(address, 32)
    } catch (error) {
      return null
    }
  }

  // Function to connect wallet using Web3 Onboard
  const connectWallet = async () => {
    try {
      const wallets = await onboard.connectWallet()
      if (wallets.length > 0) {
        const wallet = wallets[0]
        setUserAddress(wallet.accounts[0].address)
        const ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
        setProvider(ethersProvider)
        const signerInstance = ethersProvider.getSigner()
        setSigner(signerInstance)
        setStatus('wallet connected.')
      }
    } catch (error) {
      console.error(error)
      setStatus('failed to connect wallet.')
    }
  }

  // Function to disconnect wallet
  const disconnectWallet = async () => {
    try {
      await onboard.disconnectWallet({ label: 'all' })
      setUserAddress(null)
      setProvider(null)
      setSigner(null)
      setNfts([])
      setStatus('wallet disconnected.')
      setSelectedSourceNetwork(null)
      setTokenId('') // Reset tokenId upon disconnection
    } catch (error) {
      console.error(error)
      setStatus('failed to disconnect wallet.')
    }
  }

  // Subscribe to wallet and chain state changes
  useEffect(() => {
    const walletsSubscription = onboard.state.select('wallets').subscribe(wallets => {
      if (wallets.length > 0) {
        const wallet = wallets[0]
        setUserAddress(wallet.accounts[0].address)
        const ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
        setProvider(ethersProvider)
        const signerInstance = ethersProvider.getSigner()
        setSigner(signerInstance)
        setStatus('wallet connected.')
      } else {
        setUserAddress(null)
        setProvider(null)
        setSigner(null)
        setNfts([])
        setStatus('wallet disconnected.')
        setSelectedSourceNetwork(null)
        setTokenId('') // Reset tokenId upon disconnection
      }
    })

    // Subscribe to chain (network) changes
    const chainsSubscription = onboard.state.select('chains').subscribe(chains => {
      if (chains.length > 0) {
        const currentChain = chains[0]
        const network = networks.find(net => net.chainId.toLowerCase() === currentChain.id.toLowerCase())
        if (network) {
          // Only auto-switch if the user hasn't manually selected a source network
          // This maintains user control over the source chain selection
          if (!selectedSourceNetwork) {
            setSelectedSourceNetwork(network)
            setStatus(`connected to ${network.name}`)
          }
        } else {
          setSelectedSourceNetwork(null) // Unsupported chain
          setStatus('connected to an unsupported network.')
        }
      } else {
        setSelectedSourceNetwork(null) // No chain info available
      }
    })

    return () => {
      walletsSubscription.unsubscribe()
      chainsSubscription.unsubscribe()
    }
  }, [networks, selectedSourceNetwork])

  // Function to handle source network change
  const handleSourceNetworkChange = async (event) => {
    const selectedChainId = event.target.value
    const network = networks.find(
      (net) => net.chainId.toLowerCase() === selectedChainId.toLowerCase()
    )

    if (!network) {
      setSelectedSourceNetwork(null)
      setStatus('selected source network is not supported.')
      setTokenId('') // Reset tokenId if network is unsupported
      return
    }

    try {
      // Request wallet to switch to the selected network
      await onboard.setChain({ chainId: network.chainId })

      setSelectedSourceNetwork(network)
      setStatus(`switched to ${network.name}`)
    } catch (switchError) {
      // This error code indicates that the chain has not been added to Onboard
      if (switchError.name === 'SwitchChainError') {
        try {
          // Attempt to add the network to Onboard
          await onboard.addChain({
            id: network.chainId,
            token: network.nativeCurrency.symbol,
            label: network.name,
            rpcUrl: network.rpcUrl,
          })

          // Now switch to the newly added chain
          await onboard.setChain({ chainId: network.chainId })

          setSelectedSourceNetwork(network)
          setStatus(`added and switched to ${network.name}`)
        } catch (addError) {
          console.error(addError)
          setStatus('failed to add the source network.')
          setTokenId('') // Reset tokenId if adding network fails
        }
      } else {
        console.error(switchError)
        setStatus('failed to switch the source network. is your wallet connected?')
        setTokenId('') // Reset tokenId if switching network fails
      }
    }
  }

  // Function to handle destination network change
  const handleDestinationNetworkChange = (event) => {
    const selectedDsteid = parseInt(event.target.value)
    const network = networks.find(
      (net) => net.dsteid === selectedDsteid
    )

    if (!network) {
      setStatus('selected destination network is not supported.')
      return
    }

    setSelectedDestinationNetwork(network)
    setStatus(`destination network set to ${network.name}`)
  }

  // Function to handle NFT transfer
const handleSend = async (e) => {
  e.preventDefault();

  if (!selectedSourceNetwork) {
    setStatus('please select a source network.');
    return;
  }

  if (!selectedDestinationNetwork) {
    setStatus('please select a destination network.');
    return;
  }

  // Input validation
  if (!ethers.utils.isAddress(recipientAddress)) {
    setStatus('invalid recipient address.');
    return;
  }

  if (!tokenId) {
    setStatus('please select a Token ID.');
    return;
  }

  try {
    if (!signer) {
      setStatus('please connect your wallet.');
      return;
    }

    // **Begin Network Switching Logic**
    setStatus('verifying network...');
    const currentNetwork = await provider.getNetwork();
    if (selectedSourceNetwork.chainId === selectedDestinationNetwork.chainId) {
      setStatus('source and destination networks cannot be the same.');
      return;
    }

    const desiredChainId = parseInt(selectedSourceNetwork.chainId, 16); // Assuming chainId is in hex string

    if (currentNetwork.chainId !== desiredChainId) {
      try {
        setStatus(`switching to ${selectedSourceNetwork.name} network...`);
        await onboard.setChain({ chainId: selectedSourceNetwork.chainId });

        // Optionally, wait for the network to switch
        const updatedNetwork = await provider.getNetwork();
        if (updatedNetwork.chainId !== desiredChainId) {
          throw new Error('Network switch failed.');
        }

        setStatus(`switched to ${selectedSourceNetwork.name}`);
      } catch (switchError) {
        // This error code indicates that the chain has not been added to Onboard
        if (switchError.name === 'SwitchChainError') {
          try {
            setStatus(`adding and switching to ${selectedSourceNetwork.name} network...`);
            // Attempt to add the network to Onboard
            await onboard.addChain({
              id: selectedSourceNetwork.chainId,
              token: selectedSourceNetwork.nativeCurrency.symbol,
              label: selectedSourceNetwork.name,
              rpcUrl: selectedSourceNetwork.rpcUrl,
            });

            // Now switch to the newly added chain
            await onboard.setChain({ chainId: selectedSourceNetwork.chainId });

            setStatus(`added and switched to ${selectedSourceNetwork.name}`);
          } catch (addError) {
            console.error(addError);
            setStatus('failed to add the source network.');
            return; // Exit the function as we cannot proceed without the correct network
          }
        } else {
          console.error(switchError);
          setStatus('failed to switch the source network. is your wallet connected?');
          return; // Exit the function as we cannot proceed without the correct network
        }
      }
    } else {
      setStatus(`already connected to ${selectedSourceNetwork.name}`);
    }
    // **End Network Switching Logic**

    const contract = new ethers.Contract(selectedSourceNetwork.contractAddress, CONTRACT_ABI, signer);

    // Convert recipient address to bytes32
    const toBytes32 = addressToBytes32(recipientAddress);
    if (!toBytes32) {
      setStatus('failed to convert recipient address.');
      return;
    }

    // Fetch token URI
    setStatus('fetching token URI...');
    const tokenUri = await contract.tokenURI(tokenId);
    console.log('Token URI:', tokenUri);

    const _options = Options.newOptions().addExecutorLzReceiveOption(1200000, 0).toBytes();

    // Construct SendParamUri
    const sendParam = {
      dstEid: selectedDestinationNetwork.dsteid,
      to: toBytes32,
      tokenId: ethers.BigNumber.from(tokenId),
      tokenUri: tokenUri,
      extraOptions: _options,
      composeMsg: '0x',
      onftCmd: '0x'
    };

    // Get MessagingFee by calling quoteSendUri
    setStatus('getting messaging fee quote...');
    const payInLzToken = false; // Set to true if paying in LayerZero tokens
    const msgFee = await contract.quoteSendUri(sendParam, payInLzToken, { gasLimit: 2000000 });
    console.log('Messaging Fee:', msgFee);

    // Construct MessagingFee
    const messagingFee = {
      nativeFee: msgFee.nativeFee,
      lzTokenFee: msgFee.lzTokenFee
    };

    // Send the transaction
    setStatus('initiating cross-chain artwork transfer...');
    const tx = await contract.sendUri(sendParam, messagingFee, userAddress, {
      value: messagingFee.nativeFee,
      gasLimit: 2000000
    });

    setStatus('transaction sent. waiting for confirmation...');
    setTxHash(tx.hash);

    // Wait for the transaction to be mined
    await tx.wait();

    setStatus(`transfer successful! transaction Hash: ${tx.hash}`);
  } catch (error) {
    console.error(error);
    setStatus(`error: ${error.message || 'something went wrong.'}`);
  }
};

  // Function to fetch NFTs
  useEffect(() => {
    const fetchNFTs = async () => {
      if (!userAddress || !selectedSourceNetwork) return

      const apiKey = process.env.REACT_APP_ALCHEMY_API_KEY
      if (!apiKey) {
        setNftsError('Alchemy API key is not set.')
        return
      }

      const alchemyNftApiPrefix = selectedSourceNetwork.alchemyNftApiPrefix
      if (!alchemyNftApiPrefix) {
        setNftsError('Alchemy NFT API prefix is not defined for the selected network.')
        return
      }

      const nftEndpoint = `${alchemyNftApiPrefix}${apiKey}/getNFTsForOwner`

      const url = new URL(nftEndpoint)
      url.searchParams.append('owner', userAddress)
      url.searchParams.append('withMetadata', 'true')
      url.searchParams.append('pageSize', '100')
      // Add contractAddresses
      url.searchParams.append('contractAddresses[]', selectedSourceNetwork.contractAddress)

      setNftsLoading(true)
      setNftsError(null)

      try {
        const response = await fetch(url.toString())

        if (!response.ok) {
          throw new Error(`Error fetching NFTs: ${response.statusText}`)
        }

        const data = await response.json()
        setNfts(data.ownedNfts)

        // Automatically set tokenId to the first NFT's tokenId if available
        if (data.ownedNfts.length > 0) {
          setTokenId(data.ownedNfts[0].tokenId)
        } else {
          setTokenId('') // Reset tokenId if no NFTs are found
        }
      } catch (error) {
        console.error(error)
        setNftsError(error.message)
        setTokenId('') // Reset tokenId on error
      } finally {
        setNftsLoading(false)
      }
    }

    fetchNFTs()
  }, [userAddress, selectedSourceNetwork])

  // NFT Helper Functions
  const getNFTImage = (nft) => {
    const checkAndFormatUrl = (url) => {
      if (url && url.startsWith('ipfs://')) {
        return `https://blormverse.mypinata.cloud/ipfs/${url.slice(7)}`
      }
      return url
    }

    // Prioritize metadata.image
    if (nft.metadata && nft.metadata.image) {
      return checkAndFormatUrl(nft.metadata.image)
    }

    // Fallback to raw.metadata.image
    if (nft.raw && nft.raw.metadata && nft.raw.metadata.image) {
      return checkAndFormatUrl(nft.raw.metadata.image)
    }

    // Fallback to image.cachedUrl
    if (nft.image && nft.image.cachedUrl) {
      return checkAndFormatUrl(nft.image.cachedUrl)
    }

    // Fallback to image.originalUrl
    if (nft.image && nft.image.originalUrl) {
      return checkAndFormatUrl(nft.image.originalUrl)
    }

    // No image available
    return null
  }

  const getNFTName = (nft) => {
    if (nft.name) {
      return nft.name
    }

    if (nft.metadata && nft.metadata.name) {
      return nft.metadata.name
    }

    if (nft.raw && nft.raw.metadata && nft.raw.metadata.name) {
      return nft.raw.metadata.name
    }

    return `Token ${nft.tokenId}`
  }

  const getNFTDescription = (nft) => {
    if (nft.description) {
      return nft.description
    }

    if (nft.metadata && nft.metadata.description) {
      return nft.metadata.description
    }

    if (nft.raw && nft.raw.metadata && nft.raw.metadata.description) {
      return nft.raw.metadata.description
    }

    return 'No description available.'
  }

  return (
    <div style={styles.container}>
      {/* Top Section: Title, Wallet Connection, Source Network Selection */}
      <div style={styles.topSection}>
        <h1>zerebro // portal</h1>
        <Link style={styles.backToHome} to="/">back to home</Link>
        {!userAddress ? (
          <div style={styles.connectButtonContainer}>
            <button onClick={connectWallet} style={styles.connectButton}>
              connect wallet
            </button>
          </div>
        ) : (
          <div style={styles.connectButtonContainer}>
            <p style={styles.connectedWalletAddress}>connected as: {userAddress}</p>
            <button onClick={disconnectWallet} style={{ ...styles.connectButton, backgroundColor: '#f44336' }}>
              disconnect wallet
            </button>
          </div>
        )}
        {/* Source Network Selection Dropdown */}
        <div style={styles.dropdownContainer}>
          <label style={styles.label}>
            source chain:
            <select
              value={selectedSourceNetwork ? selectedSourceNetwork.chainId : ''}
              onChange={handleSourceNetworkChange}
              style={styles.select}
            >
              <option value="" disabled>
                -- select source chain --
              </option>
              {networks.map((network) => (
                <option key={network.chainId} value={network.chainId}>
                  {network.name}
                </option>
              ))}
            </select>
          </label>
        </div>
        {status && <p style={styles.status}>{status}</p>}
      </div>
      {/* Main Section: NFT Display and Transfer Form */}
      <div style={styles.mainSection}>
        {/* Left Section: NFT Display */}
        <div style={styles.leftSection}>
          <h3>your artwork on {selectedSourceNetwork ? selectedSourceNetwork.name : '...'}</h3>

          {nftsLoading && <p>loading artwork...</p>}
          {nftsError && <p style={styles.error}>{nftsError}</p>}
          {!nftsLoading && !nftsError && nfts.length === 0 && userAddress && selectedSourceNetwork && (
            <p>no artwork found.</p>
          )}
          <div style={styles.nftContainer}>
            {nfts.map((nft, index) => {
              const imageUrl = getNFTImage(nft)
              const name = getNFTName(nft)
              const description = getNFTDescription(nft)

              return (
                <div key={index} style={styles.nftCard}>
                  {imageUrl ? (
                    <img src={imageUrl} alt={name} style={styles.nftImage} />
                  ) : (
                    <div style={styles.nftPlaceholder}>No Image</div>
                  )}
                  <p style={styles.nftName}>{name}</p>
                  <p style={styles.nftDescription}>{description}</p>
                  <p style={styles.tokenId}>token id: {nft.tokenId}</p>
                </div>
              )
            })}
          </div>
        </div>

        {/* Right Section: Transfer Form */}
        <div style={styles.rightSection}>
          <h2>transfer cross-chain</h2>

          {/* Destination Chain Selection Dropdown */}
          <div style={styles.dropdownContainer}>
            <label style={styles.label}>
              destination chain:
              <select
                value={selectedDestinationNetwork ? selectedDestinationNetwork.dsteid : ''}
                onChange={handleDestinationNetworkChange}
                style={styles.select}
              >
                <option value="" disabled>
                  -- select destination chain --
                </option>
                {networks.map((network) => (
                  <option key={network.dsteid} value={network.dsteid}>
                    {network.name}
                  </option>
                ))}
              </select>
            </label>
          </div>

          {/* Transfer Form */}
          <form onSubmit={handleSend} style={styles.form}>
            {/* Replace Token ID Input with Dropdown */}
            <label style={styles.label}>
              token id:
              <select
                value={tokenId}
                onChange={(e) => setTokenId(e.target.value)}
                required
                style={styles.select}
                disabled={!selectedSourceNetwork || nfts.length === 0}
              >
                {nfts.length > 0 ? (
                  nfts.map((nft) => (
                    <option key={nft.tokenId} value={nft.tokenId}>
                      {`token id: ${nft.tokenId} // ${getNFTName(nft)}`}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    no artwork on source chain
                  </option>
                )}
              </select>
            </label>

            <label style={styles.label}>
              recipient address:
              <input
                type="text"
                value={recipientAddress}
                onChange={(e) => setRecipientAddress(e.target.value)}
                required
                style={styles.input}
                disabled={!selectedSourceNetwork} // Disable if source network not selected
                placeholder={selectedSourceNetwork ? 'Enter Recipient Address' : 'Select a source chain first'}
              />
            </label>
            <button type="submit" style={styles.button} disabled={!selectedSourceNetwork || nfts.length === 0}>
              transfer
            </button>
          </form>

          {/* Transaction Hash */}
          {txHash && (
            <p>
              transactions may take upwards of 20 minutes to process on layerzero.<br /><br />
              keep the layerzeroscan page open to track your transaction.<br /><br />
              view transaction:{' '}
              <a
                href={`https://layerzeroscan.com/tx/${txHash}`} // Ensure the URL is correct for your explorer
                target="_blank"
                rel="noopener noreferrer"
                style={styles.link}
              >
                {txHash}
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

// Basic styling for the component
const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap', // For responsiveness
    maxWidth: '1200px',
    margin: '50px auto',
    padding: '20px',
    gap: '20px',
    flexDirection: 'column',
    boxSizing: 'border-box'
  },
  backToHome: {
    color: 'white',
    textDecoration: 'underline',
    fontSize: '14px',
    display: 'block',
  },
  topSection: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    flexDirection: 'column',
    gap: '20px'
  },
  mainSection: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px'
  },
  leftSection: {
    flex: '1',
    minWidth: '300px', // Ensures proper layout on smaller screens
    padding: '10px',
    borderRight: '1px solid #ccc',
    overflowY: 'auto',
    maxHeight: '800px'
  },
  rightSection: {
    flex: '1',
    minWidth: '300px', // Ensures proper layout on smaller screens
    padding: '10px'
  },
  dropdownContainer: {
    marginBottom: '20px'
  },
  label: {
    display: 'block',
    marginBottom: '15px',
    fontWeight: 'bold'
  },
  select: {
    width: '100%',
    padding: '8px',
    marginTop: '5px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #ccc',
    color: 'black'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    color: 'black'
  },
  input: {
    width: '100%',
    padding: '8px',
    marginTop: '5px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #ccc',
    color: 'black'
  },
  button: {
    padding: '10px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginTop: '10px',
    width: '100%',
    opacity: '1',
    transition: 'opacity 0.3s'
  },
  connectButtonContainer: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  connectButton: {
    width: '200px',
    padding: '10px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    backgroundColor: '#4CAF50',
    color: 'white',
    transition: 'background-color 0.3s'
  },
  connectedWalletAddress: {
    marginBottom: '10px'
  },
  status: {
    marginTop: '20px',
    fontWeight: 'bold',
    color: 'white',
    maxWidth: '1200px',
    wordWrap: 'break-word'
  },
  link: {
    color: '#1E90FF',
    textDecoration: 'none'
  },
  nftContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '10px'
  },
  nftCard: {
    width: '220px',
    border: '1px solid white',
    borderRadius: '8px',
    padding: '10px',
    textAlign: 'center',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
  },
  nftImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '4px'
  },
  nftPlaceholder: {
    width: '100%',
    height: '200px',
    backgroundColor: '#eee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    color: 'white',
    fontSize: '14px'
  },
  nftName: {
    marginTop: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'white'
  },
  nftDescription: {
    fontSize: '14px',
    color: 'white',
    marginTop: '5px',
    height: '60px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tokenId: {
    fontSize: '12px',
    color: 'white',
    marginTop: '5px'
  },
  tokenType: {
    fontSize: '12px',
    color: 'white',
    marginTop: '2px'
  },
  error: {
    color: 'red'
  }
}

export default Portal
