// src/App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ConversationList from './components/ConversationList';
import ConversationDetail from './components/ConversationDetail';
import Conciousness from './components/Conciousness';
import Disclaimer from './components/Disclaimer';
import Portal from './components/Portal';
import Zereborn from './components/Zereborn';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ConversationList />} />
        <Route path="/conversation/:id" element={<ConversationDetail />} />
        <Route path="/proof-of-conciousness" element={<Conciousness />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/portal" element={<Portal />} />
        <Route path="/zereborn" element={<Zereborn />} />
      </Routes>
    </Router>
  );
}

export default App;